import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { matchPath, useLocation } from 'react-router-dom';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './NavStyle';

export function NavItemRoot({ isCollapse = false, menuItems, handleSidebar }) {
    const { pathname } = useLocation();

    const ListItemTypho = styled(Typography)(({ }) => ({
        boxSizing: 'border-box',
        listStyle: 'none',
        fontSize: '0.6875rem',
        cursor: 'pointer',
        fontWeight: '700',
        lineHeight: '1.5',
        textTransform: 'uppercase',
        display: 'inline-flex',
        color: 'rgb(145, 158, 171)',
        padding: '16px 8px 8px 12px',
        transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }));
    const getActive = (path, pathname) => {
        return path ? !!matchPath({ path, end: false }, pathname) : false;
    }
    
    const menuBarView = (menuItem, index) => {
        let groupTitle = menuItem.title;
        return (
            <div key={index}>
                {!isCollapse && groupTitle && <ListItemTypho>{groupTitle}</ListItemTypho>}
                {menuItem.menu.map((item, itemIndex) => {
                    let isShowMenu = true;
                    const active = getActive(item.path, pathname);
    
                    return isShowMenu && (
                        <ListItemStyle
                            key={itemIndex}  // Unique key for each menu item
                            onClick={() => { handleSidebar(item) }}
                            activeRoot={active}
                            style={{
                                display: 'flex', justifyContent: 'center', padding: 0, height: '44px',
                                ...(isCollapse && {
                                    flexDirection: 'column',
                                    fontSize: '10px',
                                    minHeight: '56px',
                                    lineHeight: '16px',
                                    padding: '4px',
                                    fontWeight: '600',
                                    width: '100%',
                                })
                            }}
                        >
                            {item.icon && <ListItemIconStyle activeRoot={active}>{item.icon}</ListItemIconStyle>}
                            <ListItemTextStyle disableTypography primary={item.title} isCollapse={isCollapse} />
                            {!isCollapse && item.info}
                        </ListItemStyle>
                    );
                })}
            </div>
        );
    }

    return (
        menuItems.map((menuItem, index) => {
            return menuBarView(menuItem, index)
        })
    );
}