import { PostAdd, RemoveDone, Save } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MenuItem, Popover,Box ,CircularProgress} from '@mui/material';

const ScheduledPostActionPopup = ({
  popoverElement,
  handleCloseMenu,
  handleSaveAsDraft,
  handleEditAsNewPost,
  hasEditAccess,
  hasApproveAccess,
  handleDeletePost,
  loading,
  handleUnapprovePost,
}) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { p: 1, width: 170, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
      }}
    >
      {' '}
      {loading ? (
                   <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                   <CircularProgress size={24} />
               </Box>
                ) : 
                <>
      <MenuItem sx={{ color: 'warning.main' }} onClick={handleSaveAsDraft}>
        <Save sx={{ mr: 1 }} /> Save As Draft
      </MenuItem>
      <MenuItem sx={{ color: 'info.main' }} onClick={handleEditAsNewPost}>
        <PostAdd sx={{ mr: 1 }} /> Edit as New Post
      </MenuItem>
      {hasEditAccess && <MenuItem sx={{ color: 'error.main' }} onClick={handleDeletePost}>
        <DeleteForeverIcon sx={{ mr: 1 }} /> Delete Post
      </MenuItem>}
      {hasApproveAccess && <MenuItem sx={{ color: 'error.main' }} onClick={handleUnapprovePost}>
        <RemoveDone  sx={{ mr: 1 }} /> Unapprove Post
      </MenuItem>}
      </>}
    </Popover>
  );
};
export default ScheduledPostActionPopup;
