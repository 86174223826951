import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import PreviewComponent from './preview/previewComponent';
import PostConditionComponent from './post-condition/post-condition.component';
import PostPreviewComponent from './post-preview.component';

const PostTabComponent = ({
  updateRequestData,
  handleConditionErrors,
  googleMyBusinessConditionRef,
  tiktokConditionRef,
  linkedInConditionRef,
  youtubeConditionRef,
  twitterConditionRef,
  instagramConditionRef,
  facebookConditionRef,
  pinterestConditionRef,
  previewRef,
  newPostForm,
  tabValue,
  setTabValue
}) => {

  //State Variable
  useEffect(() => {
    handleUpdateNewFormData();
  }, [tabValue]);
  const [height, setHeight] = useState(window.innerHeight - 230);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleUpdateNewFormData = () => {
    let newFormData = { ...newPostForm };
    let facebookRequestData = newFormData.scheduled_event_requests_data.facebook_requests_data;
    let instagramRequestData = newFormData.scheduled_event_requests_data.instagram_requests_data;
    let youtubeRequestData = newFormData.scheduled_event_requests_data.youtube_requests_data;
    let twitterRequestData = newFormData.scheduled_event_requests_data.twitter_requests_data;
    let linkedinRequestData = newFormData.scheduled_event_requests_data.linkedin_requests_data;
    let gbusinessRequestData = newFormData.scheduled_event_requests_data.gbusiness_requests_data;
    let pinterestRequestData = newFormData.scheduled_event_requests_data.pinterest_requests_data;
    let tiktokRequestsData = newFormData.scheduled_event_requests_data.tiktok_requests_data;
    if (facebookRequestData?.length > 0) {
      if (facebookConditionRef && facebookConditionRef.current) {
        facebookConditionRef.current.handleRequestData(facebookRequestData);
      }
    }
    if (instagramRequestData?.length > 0) {
      if (instagramConditionRef && instagramConditionRef.current) {
        instagramConditionRef.current.handleRequestData(instagramRequestData);
      }
    }
    if (youtubeRequestData?.length > 0) {
      if (youtubeConditionRef && youtubeConditionRef.current) {
        youtubeConditionRef.current.handleRequestData(youtubeRequestData);
      }
    }
    if (twitterRequestData?.length > 0) {
      if (twitterConditionRef && twitterConditionRef.current) {
        twitterConditionRef.current.handleRequestData(twitterRequestData);
      }
    }
    if (linkedinRequestData?.length > 0) {
      if (linkedInConditionRef && linkedInConditionRef.current) {
        linkedInConditionRef.current.handleRequestData(linkedinRequestData);
      }
    }
    if (gbusinessRequestData?.length > 0) {
      if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
        googleMyBusinessConditionRef.current.handleRequestData(gbusinessRequestData);
      }
    }
    if (pinterestRequestData?.length > 0) {
      if (pinterestConditionRef && pinterestConditionRef.current) {
        pinterestConditionRef.current.handleRequestData(pinterestRequestData);
      }
    }
    if (tiktokRequestsData?.length > 0) {
      if (tiktokConditionRef && tiktokConditionRef.current) {
        tiktokConditionRef.current.handleRequestData(tiktokRequestsData);
      }
    }

    //Preview Ref
    if (previewRef && previewRef.current) {
      let fData = JSON.parse(JSON.stringify(newFormData));
      previewRef.current.handlePreviewData(fData);
    }
  };
  return (
    <Card sx={{ p: 1, height: 'calc(100vh - 190px)' }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList onChange={handleChangeTab}>
            <Tab iconPosition="start" label="Conditions" value={1} />
            <Tab iconPosition="start" label="Preview" value={2} />
          </TabList>
        </Box>
        <TabPanel value={1} sx={{ maxHeight: height, overflow: 'auto' }}>
          <PostConditionComponent
            updateRequestData={updateRequestData}
            handleConditionErrors={handleConditionErrors}
            googleMyBusinessConditionRef={googleMyBusinessConditionRef}
            tiktokConditionRef={tiktokConditionRef}
            linkedInConditionRef={linkedInConditionRef}
            youtubeConditionRef={youtubeConditionRef}
            twitterConditionRef={twitterConditionRef}
            instagramConditionRef={instagramConditionRef}
            facebookConditionRef={facebookConditionRef}
            pinterestConditionRef={pinterestConditionRef}
          />
        </TabPanel>
        <TabPanel value={2} sx={{ maxHeight: height, overflow: 'auto' }}>
          <PostPreviewComponent ref={previewRef} />
        </TabPanel>
      </TabContext>
    </Card>
  );
};
export default PostTabComponent;
