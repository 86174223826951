import { formatDistanceToNowStrict } from 'date-fns';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import Image from 'src/components/image/Image';
import { CommonHelper } from 'src/utils/commonHelper';
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

const ReelVideo = styled('video')(({ theme }) => ({
  width: '100%',
  maxHeight: '300px',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[4],
  objectFit: 'cover',
  backgroundColor: 'black',
}));

export default function ChatMessageItem({ message, conversation, onOpenLightbox, handleDeleteMessage, userId, userPic }) {
  const isMe = message.from.id === userId;
  const isImage = message.image_url ? true : false;
  const isVideo = message.share_url || message.video_url ? true : false;
  const theme = useTheme();
  // const firstName = senderDetails.name && senderDetails.name.split(' ')[0];
  const handleDelete = () => {
    handleDeleteMessage(message.id);
  }
  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        {!isMe && (
          <Avatar alt={message.from.name || message.from.username} src={message.from.profile_pic ? message.from.profile_pic :  message.from.username || message.from.name } sx={{ width: 32, height: 32, mr: 2, bgcolor: theme.palette.primary.main }} />
        )}
        {isMe && (
          <Avatar alt={message.from.name || message.from.username} src={userPic ? userPic : message.to.username || message.to.name} sx={{ width: 32, height: 32, mr: 2, bgcolor: theme.palette.primary.main }} />
        )}

        <div>
          <InfoStyle
            variant="caption"
            sx={{
              ...(isMe && { justifyContent: 'flex-end' }),
            }}
          >
            {!isMe && `${message.from.name || message.from.username},`}&nbsp;
            {isMe &&`${message.from.name || message.from.username},`}&nbsp;
            {CommonHelper.DateTimeFromCurrentTime(message.created_time)}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isMe && { color: 'grey.800', bgcolor: 'primary.lighter' }),
              ...(isImage && { p: 0 }),
              ...(isVideo && { p: 0 }),
            }}
          >
            {isImage ? (
              <Image
                alt="attachment"
                src={message.image_url}
                // onClick={() => onOpenLightbox(message.body)}
                sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
              />
            ) :
              isVideo ? (
                <ReelVideo
                  alt="attachment"
                  src={message.share_url || message.video_url}
                  controls
                // onClick={() => onOpenLightbox(message.body)}
                // sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                />
              ) : (
                <Typography variant="body2">{message.text}</Typography>
              )}
            {!message.text && !message.share_url && !message.thumb_url && <Typography variant="body1" sx={{ fontWeight: 500 }}>Post Content Unavailable</Typography>}
          </ContentStyle>
        </div>
      </Box>
      {/* <div onClick={handleDelete}>Delete</div> */}
    </RootStyle>
  );
}