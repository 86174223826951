import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PreviewComponent from "./preview/previewComponent";

const PostPreviewComponent = forwardRef(({ }, ref) => {
    const [postForm, setPostForm] = useState(null);
    
    const filterErrorData = (newFormData) => {
        const updatedData = { ...newFormData }; 
        const { scheduled_event_requests_data } = updatedData;
        const clearServiceErrors = (serviceData) => {
          return serviceData.filter((data) => {
            return data?.errors?.length == 0; 
          });
        };
      
        if (scheduled_event_requests_data.facebook_requests_data.length > 0) {
          scheduled_event_requests_data.facebook_requests_data = clearServiceErrors(scheduled_event_requests_data.facebook_requests_data);
        }
        if (scheduled_event_requests_data.instagram_requests_data.length > 0) {
          scheduled_event_requests_data.instagram_requests_data = clearServiceErrors(scheduled_event_requests_data.instagram_requests_data);
        }
        if (scheduled_event_requests_data.twitter_requests_data.length > 0 ) {
          scheduled_event_requests_data.twitter_requests_data = clearServiceErrors(scheduled_event_requests_data.twitter_requests_data);
        }
        if (scheduled_event_requests_data.youtube_requests_data.length > 0 ) {
          scheduled_event_requests_data.youtube_requests_data = clearServiceErrors(scheduled_event_requests_data.youtube_requests_data);
        }
        if (scheduled_event_requests_data.linkedin_requests_data.length > 0) {
          scheduled_event_requests_data.linkedin_requests_data = clearServiceErrors(scheduled_event_requests_data.linkedin_requests_data);
        }
        if (scheduled_event_requests_data.gbusiness_requests_data.length > 0) {
          scheduled_event_requests_data.gbusiness_requests_data = clearServiceErrors(scheduled_event_requests_data.gbusiness_requests_data);
        }
        if (scheduled_event_requests_data.pinterest_requests_data.length > 0) {
          scheduled_event_requests_data.pinterest_requests_data = clearServiceErrors(scheduled_event_requests_data.pinterest_requests_data);
        }
        if (scheduled_event_requests_data.tiktok_requests_data.length > 0) {
          scheduled_event_requests_data.tiktok_requests_data = clearServiceErrors(scheduled_event_requests_data.tiktok_requests_data);
        }
        return updatedData;
      };
    const handlePreviewData = (data) => {
        let fErrorData = filterErrorData({...data});
        setPostForm(fErrorData);
    }

    useImperativeHandle(ref, () => ({
        handlePreviewData,
    }));

    return (
        postForm ?
            <PreviewComponent newPostForm={postForm} />
            :
            <div>No data</div>
    );
});
export default PostPreviewComponent;