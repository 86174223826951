import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box, Button,
    Grid, Select, MenuItem, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';

// Styled label component
const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
}));

const UpdateTeamMember = ({ handleClose, handleSuccess, updateData }) => {
    const [role, setRole] = useState(updateData.role_id || '');
    const [email, setEmail] = useState(updateData.email || ''); 
    const [name, setName] = useState(`${updateData.first_name} ${updateData.last_name}` || ''); 
    const [loader, setLoader] = useState(false);
    const [roles, setRoles] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { showToast } = useToaster();

    useEffect(() => {
        fetchRoles(); 
    }, []);

    const fetchRoles = () => {
        setLoader(true);
        let postProps = {
            url: userModuleURL.newUserInit,
            successCallback: successCallback,
            failureCallback
        };
        HttpServices.Get(postProps);
    }

    const successCallback = (data, message) => {
        showToast(message, 's');
        setRoles(data?.role_list || []);
    }

    const failureCallback = (message) => {
        showToast(message, 's');
    }

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    }

    const handleUpdateMember = () => {
        setLoading(true);
        const roleProps = {
            user_id: updateData.user_id, 
            role_id: role,
        };

        const postProps = {
            url: userModuleURL.updateUser,
            body: roleProps,
            successCallback: formSuccesCallback,
            failureCallback: formFailureCallback,
        };

        HttpServices.Post(postProps);
    }

    const formSuccesCallback = (data, message) => {
        setLoading(false);
        handleSuccess(message);
    }

    const formFailureCallback = (message) => {
        showToast(message, 'e');
        setLoading(false);
    }

    return (
        <Dialog open maxWidth="md">
            <DialogTitle>
                <Typography variant='h4' gutterBottom component="div">
                    Update Team Member
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        maxHeight: 'calc(100vh - 330px)',
                        minHeight: 'calc(100vh -330px)',
                    }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <LabelStyle>Name</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <LabelStyle>Email Address</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <LabelStyle>Role</LabelStyle>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                type="text"
                                fullWidth
                                value={name}
                                disabled // Disable name field if not editable
                                placeholder='Name'
                                sx={{ mb: 1, mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                type="email"
                                fullWidth
                                value={email}
                                disabled // Disable email field if not editable
                                placeholder='Email address'
                                sx={{ mb: 1, mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                fullWidth
                                value={role}
                                onChange={handleRoleChange}
                                placeholder='Role'
                                margin="normal"
                                sx={{ mb: 1, mt: 1 }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">
                                    <Typography variant="body3" color="text.secondary">Select the role</Typography>
                                </MenuItem>
                                {roles.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.role_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    onClick={handleUpdateMember} // Use the update member function
                >
                    Update Member
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateTeamMember;
