import { CommonHelper } from '../commonHelper';
import { useRef } from 'react';
const CarouselComponent = ({ media_urls }) => {
  let media = media_urls.split(',')[0];
  let fileType = CommonHelper.GetFileTypeFromUrl(media);
  const videoRef = useRef(null);
  const imgRef = useRef(null);
  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) {
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) {
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      videoElement.msRequestFullscreen();
    }
  };
  const handleImageClick = () => {
    const imgElement = imgRef.current;
    if (imgElement.requestFullscreen) {
      imgElement.requestFullscreen();
    } else if (imgElement.mozRequestFullScreen) {
      imgElement.mozRequestFullScreen();
    } else if (imgElement.webkitRequestFullscreen) {
      imgElement.webkitRequestFullscreen();
    } else if (imgElement.msRequestFullscreen) {
      imgElement.msRequestFullscreen();
    }
  };
  return (
    // media_urls.map(media => {
    //     let fileType = CommonHelper.GetFileTypeFromUrl(media);
    //     return fileType === "mp4" ? <>
    //         <video width={50} height={50} src={media} ref={videoRef}
    //             onClick={handleVideoClick} style={{ cursor: 'pointer', borderRadius: '5px' }} />
    //     </> :
    //         <img width={50} height={50} src={media} style={{ margin: '0px 5px', cursor: 'pointer', borderRadius: '2px' }} ref={imgRef}
    //             onClick={handleImageClick} />
    // })

    fileType === 'mp4' ? (
      <>
        <video
          width={'100%'}
          height={'100%'}
          src={media}
          ref={videoRef}
          //onClick={handleVideoClick}
          style={{ cursor: 'pointer', borderRadius: '5px' }}
        />
      </>
    ) : (
      <img
        width={'100%'}
        height={'100%'}
        src={media}
        style={{ cursor: 'pointer', borderRadius: '2px', objectFit: 'cover' }}
        ref={imgRef}
      // onClick={handleImageClick}
      />
    )
  );
};
export default CarouselComponent;
