const formatTime = (value) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(value);
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime;
};

const CalenderType = {
    DayMonth: 'dayGridMonth',
    ListWeek: 'listWeek',
    // TimeGridDay: 'timeGridDay',
    // TimeGridWeek: 'timeGridWeek',
};

const get = (value) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(value);
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime;
};

const GetEventColor = (event) => {
    switch (event.status) {
        case 'SUCCESS':
            return "#4CAF50"; // Green
        case 'FAILED':
            return "#F44336"; // Red
        case 'YET_TO_DO':
            return "#CAF4FF"; // Blue
        case 'REJECTED':
            return "#FFD0EC"; // Pink
        case 'ELLAPSED':
            return "#9C27B0"; // Purple
        case 'PENDING':
            return "#F8FDCF"; // Yellow
        default:
            return "#FFEEF4"; // Grey
    }
};


export const CalendarUtil = {
    formatTime,
    CalenderType,
    GetEventColor
};