import { useEffect, useState } from 'react';
import PaymentDialogComponent from 'src/pages/payment/payment-dialog.component';
import HttpServices from 'src/services/httpService';
import { paymentModuleUrl } from 'src/services/urlService';

const PlanPaymentComponent = ({ planId, amount, currency, upgradeItems, handlePaymentDialog }) => {

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    createPaymentLink();
  }, []);
  
  const successCallback = (data, message) => {
    setClientSecret(data.payment_intent);
  }
  const createPaymentLink = () => {
    let paymentForm = {
      currency: currency,
      new_plan_id: planId,
      add_ons: upgradeItems,
    };
    let postProps = {
      url: paymentModuleUrl.createPayment,
      body: paymentForm,
      successCallback
    }
    HttpServices.Post(postProps);
  };
  return (
    clientSecret && <PaymentDialogComponent clientSecret={clientSecret} amount={amount} currency={currency} handleClose={handlePaymentDialog} />
  );
}
export default PlanPaymentComponent;