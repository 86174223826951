import { useEffect, useState } from "react";
import GBusinessStandardPreview from "./gbusiness-standard-preview.component";
import GBusinessEventPreview from "./gbusiness-event-preview.component";
import GBusinessOfferPreview from "./gbusiness-offer-preview.component";
import { CommonHelper } from "src/utils/commonHelper";

const GBusinessPreviewOrganize = ({ postType, previewData, profileId }) => {
    const [data, setData] = useState({});
    useEffect(() => {
        if (!previewData || !profileId) return;
        let media_data = CommonHelper.ProcessMediaUrl("https://qa.brand2social.com/api/image-server/fetch-media?path=d6d6cca4bf41672ded701c82592e90profile-picture.jpeg,https://qa.brand2social.com/api/image-server/fetch-media?path=d6d6cca4bf41672ded701c82592e90profile-picture.jpeg,https://qa.brand2social.com/api/image-server/fetch-media?path=d6d6cca4bf41672ded701c82592e90profile-picture.jpeg")
        let data = previewData[profileId].data
        for (var i = 0; i < data.length; i++) {
            if (data[i].post_type === postType) {
                data[i].media_data = CommonHelper.ProcessMediaUrl(data[i].media_url)
                setData(data[i])
            }
        }

    }, [postType, previewData, profileId]);


    return (
        <>
            {data && data?.post_type === "STANDARD" && <GBusinessStandardPreview data={data} />}
            {data && data?.post_type === "EVENT" && <GBusinessEventPreview data={data} />}
            {data && data?.post_type === "OFFER" && <GBusinessOfferPreview data={data} />}
        </>
    );
};


export default GBusinessPreviewOrganize;