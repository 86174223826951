import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Config } from 'src/config';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';

export default function CheckoutForm({ amount }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToaster();

  const getUserEmail = () => {
    let email = '';
    let userDetails = CommonHelper.GetLoggedInUserDetailsFromLocalStorage();
    if (userDetails) email = userDetails.email;
    return email;
  }
  const userEmail = getUserEmail();

  useEffect(() => {
    var emailElement = document.getElementById("Field-emailInput");
    if (emailElement)
      emailElement.disabled = true;
  }, [])

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          showToast('Payment succeeded!', 'e');
          break;
        case 'processing':
          showToast('Your payment is processing.', 'e');
          break;
        case 'requires_payment_method':
          showToast('Your payment was not successful, please try again.', 'e');
          break;
        default:
          showToast('Something went wrong.', 'e');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: Config.Stripe.redirectUri,
      },
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      showToast(error.message, 'e');
    } else {
      showToast('An unexpected error occurred.', 'e');
    }

    setIsLoading(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement id="link-authentication-element" options={{
          defaultValues: {
            email: userEmail
          },
        }} />
        <PaymentElement id="payment-element" />
        <Stack direction="row" alignItems="center" sx={{ mt: 3, mb: 1, float: 'left' }}>
          <Typography sx={{ fontWeight: 800 }}>Total = {amount}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1, float: 'right' }}>
          <LoadingButton disabled={isLoading || !stripe || !elements} id="submit" type='submit' loading={isLoading} color='primary'
            variant="outlined">
            Pay Now
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}
