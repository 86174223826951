
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChangePasswordForm = ({ onSent, onGetPassword, token }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const changePasswordSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
    });
    const methods = useForm({
        resolver: yupResolver(changePasswordSchema),
        defaultValues: { password: '' },
    });
    const changePasswordSuccessCallback = (data, message) => {
        onSent();
        onGetPassword(data.password);
    }
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const onSubmit = (data) => {
        let changePasswordForm = {
            token: token,
            password: data.password
        }
        let postProps = {
            url: userModuleURL.changePasswordRequest,
            body: changePasswordForm,
            successCallback: changePasswordSuccessCallback
        }
        HttpServices.Post(postProps);
    }
    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <RHFTextField name="password" type={showPassword ? "text" : "password"} label="Change Password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {showPassword?<VisibilityIcon/>:<VisibilityOffIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                        Change Password
                    </LoadingButton>
                </Stack>
            </FormProvider>
        </>
    );
}
export default ChangePasswordForm;