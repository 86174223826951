import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Card,
  IconButton,
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import NoPostFound from '../publishedPosts/noPost.component';
import ManageUserAction from './manageUsersActions';
import TeamListToolbar from './teamListToolbar';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import UpdateTeamMember from './updateUsers.component';

const ManageUsers = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [height, setHeight] = useState(window.innerHeight - 250);
  const [loader, setLoader] = useState(false);
  const [selectedPopOverPost, setPopOverPost] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const [openUpdateUserModel, setUpdateUserModel] = useState(false);
  const [updateTeamMemberData, setUpdateTeamMemberData] = useState(false);
  const { showToast } = useToaster();

  useEffect(() => {
    getAllUsers();
  }, [])

  const getAllUsers = () => {
    setLoader(true);
    let postProps = {
      url: userModuleURL.getAllUsers,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  }
  const successCallback = (data, message) => {
    setTeamMembers(data);
    setLoader(false);
    showToast(message, 's');
  }
  const failureCallback = (message) => {
    setLoader(false);
    showToast(message, 'e');
  }
  const statusSuccessCallback = (data, message) => {
    getAllUsers();
    handleCloseMenu();
    showToast(message, 's');
  }
  const statusFailureCallback = (message) => {
    showToast(message, 'e');
    handleCloseMenu();
  }
  const handleRemoveUserFromOrg = () => {
    let bodyProps = {
      user_id: selectedPopOverPost.user_id
    }
    let postProps = {
      url: userModuleURL.removeUserFromOrg,
      body: bodyProps,
      successCallback: statusSuccessCallback,
      failureCallback: statusFailureCallback
    }
    HttpServices.Post(postProps);
  }
  const handleOpenConfirm = (action) => {
    setCurrentAction(action);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setCurrentAction(null);
  };
  const handleConfirmAction = () => {
    if (currentAction === 'org') {
      handleRemoveUserFromOrg();
    } else if (currentAction === 'brand') {
      handleRemoveFromBrand();
    }
    handleCloseConfirm();
  };
  const handleRemoveFromBrand = () => {
    let bodyProps = {
      user_id: selectedPopOverPost.user_id
    }
    let postProps = {
      url: userModuleURL.removeFromBrand,
      body: bodyProps,
      successCallback: statusSuccessCallback,
      failureCallback: statusFailureCallback
    }
    HttpServices.Post(postProps);
  }
  const handleAccountStatus = (member) => {
    let bodyProps = {
      user_id: member.user_id,
    };
    let postProps = {
      url: member.is_enabled ? userModuleURL.disableUser : userModuleURL.enableUser,
      body: bodyProps,
      successCallback: statusSuccessCallback,
      failureCallback: statusFailureCallback
    }
    HttpServices.Post(postProps);
  }
  const handleOpenMenu = (event, row) => {
    setPopOverPost(row);
    setPopoverElement(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setPopOverPost(null);
    setPopoverElement(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleUpdate = ()=>{
    setUpdateUserModel(true)
    setUpdateTeamMemberData(selectedPopOverPost)
    handleCloseMenu();
  }
  const postActionProps = {
    popoverElement: popoverElement,
    handleCloseMenu,
    handleRemoveUserFromOrg,
    handleRemoveFromBrand,
    handleUpdate,
    handleOpenConfirm,
    selectedPopOverPost: selectedPopOverPost,
  };
  const handleClose = () => {
    setUpdateUserModel(false);
  };
  const handleSuccess = (message)=>{
    message && showToast(message, "s");
    setUpdateUserModel(false);
    getAllUsers();
  }
  const handleRefreshGrid = () => {
    getAllUsers();
  }
  return (
    <>{loader ?
      <Spinner />
      : <>
        {teamMembers && teamMembers.length > 0 ?
          <Card sx={{ m: 1 ,minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
            <TeamListToolbar handleRefreshGrid={handleRefreshGrid} />
            <DynamicHeightComponent >
              <Scrollbar>
                <TableContainer sx={{
                  maxHeight: height, height: height - 60
                }} >
                  <Table stickyHeader>
                    <UserListHead
                      headLabel={[
                        { id: 'profile', label: 'Profile', alignRight: false },
                        { id: 'name', label: 'Team Member', alignRight: false },
                        { id: 'email', label: 'Email', alignRight: false },
                        { id: 'role', label: 'Role', alignRight: false },
                        { id: 'status', label: 'Status', alignRight: false },
                        { id: 'action', label: 'Action', alignRight: false },
                      ]}
                      rowCount={teamMembers.length}
                      isShowCheckbox={false}
                    />
                    <TableBody>
                      {teamMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((member) => (
                        <TableRow hover key={member.id} tabIndex={-1}>
                          <TableCell component="th" scope="row" sx={{ py: 3 }}>
                            <Avatar
                              src={member.image_url ? member.image_url : member.first_name.charAt(0).toUpperCase()}
                              alt={member.first_name.charAt(0).toUpperCase()}
                              sx={{ height: 40, width: 40, bgcolor: '#00AB55', color: '#ffffff' }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{member.first_name}</Typography>
                          </TableCell>
                          <TableCell align="left">{member.email}</TableCell>
                          <TableCell align="left">
                            <Label color={member.status === 'active' ? 'success' : 'error'}>
                              {member.role_name}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <StyledComponent.SwitchIcon sx={{ m: 1 }} checked={member.is_enabled} onChange={() => { handleAccountStatus(member) }}></StyledComponent.SwitchIcon>
                          </TableCell>
                          <TableCell align="left">
                            {!member?.is_current_user && <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                handleOpenMenu(e, member);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[ 10,15, 25]}
                    count={teamMembers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </Scrollbar>
            </DynamicHeightComponent>
            <ConfirmationDialog
                  open={confirmOpen}
                  onClose={handleCloseConfirm}
                  onConfirm={handleConfirmAction}
                  title={`Confirm Removal`}
                  message={`Are you sure you want to ${currentAction === 'org' ? 'remove this user from the org' : 'remove this user from the brand'}?`}
                />
                {openUpdateUserModel && <UpdateTeamMember handleClose={handleClose} handleSuccess={handleSuccess} updateData={updateTeamMemberData} />}
          </Card>
          :
          <Card style={{minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
            <TeamListToolbar handleRefreshGrid={handleRefreshGrid} />
            <NoPostFound height={height} />
          </Card>}
        <ManageUserAction {...postActionProps} />
      </>}
    </>

  );
};

export default ManageUsers;
