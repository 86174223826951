import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CommonHelper } from './commonHelper';

dayjs.extend(utc);
dayjs.extend(timezone)

// ----------------------------------------------------------------------

/**
 * Format a date in a specific timezone.
 * @param {string | number | Date} date - The input date to format.
 * @param {string} newFormat - The format string (e.g., 'DD/MM/YYYY', 'YYYY-MM-DD').
 * @returns {string} - The formatted date.
 */
export function fDate(date, newFormat) {
  const fm = newFormat || 'DD/MM/YYYY'; // Default to numeric format
  const timezoneName = CommonHelper.getBrandTimeZone();
  return date ? dayjs.utc(date).tz(timezoneName).format(fm) : '';
}

/**
 * Format a date and time in a specific timezone.
 * @param {string | number | Date} date - The input date to format.
 * @param {string} newFormat - The format string (e.g., 'DD/MM/YYYY HH:mm', 'YYYY-MM-DDTHH:mm').
 * @returns {string} - The formatted date and time.
 */
export function fDateTime(date, newFormat) {
  const fm = newFormat || 'DD/MM/YYYY hh:mm A'; // Default to numeric date and time with AM/PM
  const timezoneName = CommonHelper.getBrandTimeZone();
  return date ? dayjs.utc(date).tz(timezoneName).format(fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
