import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import HttpServices from "src/services/httpService";
import { userModuleURL } from "src/services/urlService";
import Spinner from "src/utils/Loader/spinner";
import { useToaster } from "src/utils/toaster/toasterContext";
import EllapsedPostGrid from "./ellapsed-post-grid.component";
import { useParams } from "react-router-dom";

const EllapsedPost = ({ publicUserName }) => {
    const [loader, setLoader] = useState(true);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const [ellapsedPostData, setEllapsedPostData] = useState([]);
    const { showToast } = useToaster();
    const { urlKey } = useParams();

    useEffect(() => {
        getEllapsedPosts("scheduled_time", "asc");
    }, [])
    const getEllapsedPosts = (sortBy = "", sortOrder = "") => {
        setOrder(sortOrder)
        setOrderBy(sortBy)
        let getProps = {
            url: userModuleURL.getElapsedPostPublic + `?sort_by=${sortBy}&sort_order=${sortOrder}&url_key=${urlKey}`,
            successCallback: getEllapsedPostSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }
    const getEllapsedPostSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setEllapsedPostData(data);
        setLoader(false);
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }
    const handleReCallEllapsedPostPost = (sortBy = "", sortOrder = "") => {
        setLoader(true);
        getEllapsedPosts(sortBy, sortOrder);
    }
    return (
        <>
            {loader ?
                <Spinner />
                :
                <EllapsedPostGrid
                    ellapsedPostList={ellapsedPostData}
                    orderBy={orderBy}
                    order={order}
                    publicUserName={publicUserName}
                    handleReCallEllapsedPostPost={handleReCallEllapsedPostPost}
                />
            }
        </>
    );
}
export default EllapsedPost;