import { Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { CalendarUtil } from '../calendarUtil';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const CalendarGridMonthView = ({ event }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDraftPost = (row, open = false) => {
    if (!isMobile) {
      let data = {
        content: postView,
        data: row,
      };
      showRightSidebar(data, '500px');
    }
  };

  const postView = (row) => {
    let data = {
      id: row,
    };
    let postDetails = {
      data: data,
    };
    return <PostPreview postDetails={postDetails}></PostPreview>;
  };

  const { showRightSidebar } = useRightSidebar();

  return (
    <>
      <Paper
        elevation={5}
        container
        sx={{
          padding: '10px',
          overflow: 'hidden',
          backgroundColor: CalendarUtil.GetEventColor(event.extendedProps.data),
        }}
        onClick={() => {
          handleOpenDraftPost(event.extendedProps.data.id, true);
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
          {PostDetailsHelper.getProfile(
            event.extendedProps.data.created_by.name,
            event.extendedProps.data.created_by.email,
            event.extendedProps.data.created_by.profile_pic
          )}
          <Stack direction="column" alignItems="flex-start" sx={{ width: '100%' }}>
            <Typography
              variant={'subtitle1'}
              sx={{
                fontSize: '14px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 130,
              }}
            >
              {event.extendedProps.data.created_by.name}
            </Typography>
            <Typography variant={'subtitle2'} sx={{ fontSize: '12px' }}>
              {CommonHelper.formatDateAndTime(
                CommonHelper.GetBrandTimeFromUTC(event.extendedProps.data.scheduled_time),
                'time'
              )}
            </Typography>
          </Stack>
        </Stack>
        {/* <Divider /> */}
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          {PostDetailsHelper.getSocialMediaViewWithTooltip(event.extendedProps.data.connected_profile_details)}
        </Stack>
        {/* <Divider /> */}
        <Stack direction="column" alignItems="flex-start">
          <Stack direction="column" spacing={1.5} sx={{ minWidth: 0, width: '100%' }}>
            {PostDetailsHelper.getPostMedias(event.extendedProps.data.media_url, 8)}
            {/* <Divider orientation="vertical" flexItem /> */}
            <Typography
              variant="body2"
              sx={{
                marginTop: '0.5rem !important',
                display: '-webkit-box',
                overflow: 'hidden',
                lineHeight: 1.2,
                fontSize: '12px',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3, // Limit to 3 lines
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'normal', // Ensure text wraps
                maxHeight: `${3 * 1.5}em`, // Adjust based on line height
                maxWidth: 500, // Example: Set a maximum width to make lines shorter
              }}
            >
              {event.title}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
export default CalendarGridMonthView;
