import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const VerifyEmailComponent = () => {
  const [isUserVerified, setUserverified] = useState(false);
  const [isWrongCode, setWrongCode] = useState(false);

  useEffect(() => {
    checkAndGetToken();
  }, []);
  const successCallback = (data, message) => {
    setUserverified(true);
  }
  const failureCallback = (message) => {
    setWrongCode(true);
  }
  const checkAndGetToken = () => {
    const popupWindowURL = new URL(window.location.href);
    const token = popupWindowURL.searchParams.get("token");
    if (token) {
      let verifyForm = {
        token
      };
      let postProps = {
        url: userModuleURL.verifyUser,
        body: verifyForm,
        successCallback,
        failureCallback
      }
      HttpServices.Post(postProps);
    }
  }

  return (
    <>
      <Helmet>
        <title> Verify User </title>
      </Helmet>

      {isUserVerified ? <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Email Verification
          </Typography>

          <Typography sx={{ color: 'text.secondary',mb:1.5}}>
            Your email is Verified successfully.
          </Typography>

          <Box
            component="img"
            src="/assets/email_verification.png"
          />
        </StyledContent>
      </Container>
        :
        <>
          {isWrongCode ?
            <Container>
              <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
                <Typography variant="h3" paragraph>
                  Token Expired
                </Typography>

                <Typography sx={{ color: 'text.secondary' }}>
                  Please contact your adminstrator.
                </Typography>
              </StyledContent>
            </Container>
            :
            <Container>
              <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
                <Typography variant="h3" paragraph>
                  Validating your token
                </Typography>

                <Typography sx={{ color: 'text.secondary' }}>
                  Please wait, we are validing your email.
                </Typography>
              </StyledContent>
            </Container>}
        </>
      }
    </>
  );
}
export default VerifyEmailComponent;
