import { Navigate } from "react-router-dom";
import { LocalStorage } from "./utils/storage/local-storage";
import { CommonHelper } from "./utils/commonHelper";

const PrivateRouteComponent = ({ children, permissions }) => {
    let authUser = LocalStorage.getItem("token");
    let isRouteLoaded = LocalStorage.getItem("isRouteLoaded");
    if (authUser) {
        if (isRouteLoaded && JSON.parse(isRouteLoaded)) {
            if (permissions) {
                let permissionObj = CommonHelper.GetBrandInfoFromLocalStorage("permissions");
                if (permissionObj && permissionObj[permissions]) {
                    return children;
                }
                else {
                    return <Navigate to={CommonHelper.DefaultPermissionRoute} />;
                }
            }
            else { //If permission is empty then the route is no-brand, no-organization
                return <Navigate to="/no-brand" />;
            }
        }
    }
    else
        return <Navigate to="/login" />;
}
export default PrivateRouteComponent;