import { useEffect, useState} from "react";
import TwitterPostVideoPreview from "./twitter-post-video-preview.component";
import TwitterPostImagesPreview from "./twitter-post-images-preview.component";
import { PostUtil } from "src/pages/new-post-new/post-util";

const TwitterPostPreview = ({ data }) => {
    const [requestData, setRequestData] = useState({});
    const [isVideo, setIsVideo] = useState(false);
    useEffect(() => {
        if (!data) return;
        if (data?.media_data?.count == 1 && data?.media_data.media_data[0].type === PostUtil.MediaType.VIDEO) {
            setIsVideo(true)
        }

    }, [data]);
    return (
        <>
            {isVideo ?
                <TwitterPostVideoPreview data={data}> </TwitterPostVideoPreview>
                :
                <TwitterPostImagesPreview data={data}></TwitterPostImagesPreview>}

        </>
    );
};

export default TwitterPostPreview;
