import { Cancel, Delete, Edit, PostAdd, Save } from '@mui/icons-material';
import { MenuItem, Popover, CircularProgress, Box } from '@mui/material';

const PendingApprovalGridActionPopup = ({
    popoverElement,
    approvePostDetails,
    handleCloseMenu,
    saveAsDraft,
    editPost,
    editAsNewPost,
    deletePost,
    loading,
}) => {
    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: { p: 1, width: 170, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
            }}
        >
            {' '} {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress size={24} />
                </Box>
            ) :
                <><MenuItem sx={{ color: 'info.main' }} onClick={editPost}>
                    <Edit sx={{ mr: 1, color: 'info.main' }} /> Edit Post
                </MenuItem>
                    <MenuItem sx={{ color: 'info.main' }} onClick={editAsNewPost}>
                        <PostAdd sx={{ mr: 1, color: 'info.main' }} /> Edit As New Post
                    </MenuItem>
                    <MenuItem sx={{ color: 'warning.main' }} onClick={saveAsDraft}>
                        <Save sx={{ mr: 1, color: 'warning.main' }} /> Save As Draft
                    </MenuItem>
                    {approvePostDetails.has_delete_access && <MenuItem sx={{ color: 'error.main' }} onClick={deletePost}>
                        <Delete sx={{ mr: 1, color: 'error.main' }} /> Delete Post
                    </MenuItem>}</>}

        </Popover>
    );
};
export default PendingApprovalGridActionPopup;
