import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message, cancelButtonName = 'Cancel', submitButtonName = 'Submit' }) => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => onClose()}>{cancelButtonName}</Button>
        <Button variant="contained" onClick={() => {
          onConfirm();
        }} autoFocus>
          {submitButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
