
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useState, useRef, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import Instacomment from '../../../assets/comment.svg';
import { FavoriteBorderRounded as FavoriteBorderRoundedIcon, Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, BookmarkBorder as BookmarkBorderIcon, NavigateBefore, NavigateNext } from '@mui/icons-material';
import InstagramShareIcon from '../../../assets/instagram-share-icon.svg';
import InstagramCarouselPost from 'src/pages/scheduledPost/Preview/InstagramPreview/instagram-carousel-post-preview.component';
import TextIconLabel from 'src/components/TextIconLabel';

const InstagramPostCard = ({ media_url,media_type, description, profile, share_count, comment_count, like_count, handleFocusComment }) => {
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES);
  let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  const [currentIndex, setCurrentIndex] = useState('');
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const profileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">{CommonHelper.DateTimeFromCurrentTime(profile?.date)}</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card sx={{
          maxWidth: 400,
          minWidth: respwidth ? 400 : null,
          boxShadow: 'none',
          border: 'solid 2px',
          borderColor: theme.palette.preview.postcard.color,
        }}>
          <CardHeader
            avatar={
              <Avatar
                src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
                alt={profile.profile_name ? profile.profile_name : profile.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={profile?.profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={profileTime()}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardMedia sx={{ pl: 2, pr: 2, pt: 2 }}>
              {InstagramCarouselPost(mediaFiles)}
            </CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center', }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextIconLabel
                  aria-label="chatbubbleoutline"
                  sx={{ typography: 'subtitle2', marginLeft: '1rem', color: theme.palette.preview.postcard.icon }}
                  value={like_count !== 0 ? like_count : ''}
                  icon={<FavoriteBorderRoundedIcon />}
                />

                <TextIconLabel
                  aria-label="chatbubbleoutline"
                  sx={{ typography: 'subtitle2',marginLeft: '1rem' }}
                  value={comment_count !== 0 ? comment_count : ''}
                  icon={<IconButton
                  sx={{p:0}}
                    onClick={handleFocusComment}
                    disabled={!hasManageLikesCommentPermission}>
                    <img
                      src={Instacomment}
                      alt="share"
                      style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px'}}
                      onClick={handleFocusComment}
                    ></img> </IconButton>}
                />
                <TextIconLabel
                  aria-label="chatbubbleoutline"
                  sx={{ typography: 'subtitle2',marginLeft: '1rem'  }}
                  value={share_count !== 0 ? share_count : ''}
                  icon={<img
                    src={InstagramShareIcon}
                    alt="share"
                    style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', }}
                  ></img>}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <BookmarkBorderIcon sx={{ marginRight: '1rem' }} />
              </Paper>
            </Stack>
          </CardActions>

          <CardContent >
            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            ><span style={{ position: 'relative', fontWeight: 'bold', marginRight: '5px' }}>{profile?.profile_name}</span>
              {description}
            </Typography>
            {!showFullDescription && description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
export default InstagramPostCard;
