import { useEffect } from "react";
import { useState } from "react";
import FacebookPostVideoPreview from "./facebook-post-video-preview.component";
import FacebookPostImagesPreview from "./facebook-post-images-preview.component";
import { PostUtil } from "src/pages/new-post-new/post-util";

const FacebookPostPreview = ({ data }) => {
    const [requestData, setRequestData] = useState({});
    const [isVideo, setIsVideo] = useState(false);
    useEffect(() => {
        if (!data) return;
        if (data?.media_data?.count == 1 && data?.media_data.media_data[0].type === PostUtil.MediaType.VIDEO) {
            setIsVideo(true)
        }

    }, [data]);
    return (
        <>
            {isVideo ?
                <FacebookPostVideoPreview data={data}> </FacebookPostVideoPreview>
                :
                <FacebookPostImagesPreview data={data}></FacebookPostImagesPreview>}

        </>
    );
};

export default FacebookPostPreview;
