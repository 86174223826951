import {
    Box,
    Card,
    Stack,
    Typography,
    Grid,
    Button,
    useMediaQuery,
    createTheme,
} from '@mui/material';
import { useState } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Label from './components/Label';
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from "src/components/ConfirmationDialog";

const RootStyle = styled(Card)(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.up(414)]: {
        padding: theme.spacing(5),
    },
    // boxShadow: theme.customShadows.z8,
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 1)} !important`,
    transition: 'transform 0.3s ease-out',
}));

const PricingPlanCard = ({
    name,
    price,
    no_of_scheduled_posts,
    no_of_draft,
    no_of_social_account,
    id,
    selectedPlan,
    is_current_plan,
    plan_validity,
    no_of_users,
    no_of_brands,
    no_of_scheduled_days_ahead,
    total_storage,
    has_cname,
    closePopup,
    currency
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const customTheme = createTheme({
        breakpoints: {
            values: {
                mdCustom: 1100,
            },
        },
    });
    const navigate = useNavigate();
    const { showToast } = useToaster();
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);
    const [planId, setPlanId] = useState(null);

    const responsiveFontSize = useMediaQuery(theme.breakpoints.between('md', 'xl'));
    const failureCallback = (message) => {
        message && showToast(message);
        setConfirmationDialogue(false)
    };

    const successCallback = (data, message) => {
        setConfirmationDialogue(false)
        closePopup();
        window.location.reload()
    };
    const handleOpenDialog = (id) => {
        setPlanId(id);
        setConfirmationDialogue(true);
    }
    const startTrailAndClosePopup = () => {
        let formBody = {
            plan_id: planId,
        }
        let postProps = {
            url: userModuleURL.addStartTrail,
            body: formBody,
            successCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    }

    const getBackGroundColor = () => {
        const lightGreen = theme.palette.pricing.lightGreen;
        const lightRed = `linear-gradient(140deg, #00C853 0%, #B9F6CA 100%)`;
        return is_current_plan ? lightRed : selectedPlan?.id === id ? lightGreen : '';
    };

    return (


        <Grid container sx={{ p: 1 }}>
            <Grid item xs={12} md={selectedPlan?.id === id ? 7 : 12}>
                <RootStyle style={{
                    background: getBackGroundColor(), border: '1px solid primary.main', borderRadius: '12px', padding: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
                }} >
                    {is_current_plan && (
                        <Label sx={{ top: { xs: 4, sm: 16 }, right: { xs: 8, sm: 16 }, position: 'absolute', background: 'white' }}>
                            CURRENT PLAN
                        </Label>
                    )}
                    <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                        {name.replace('_', ' ')}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
                        <Typography variant="h4" sx={{ mx: 1 }}>
                            {currency === "INR" ? `₹${price}` : `$${price}`}
                        </Typography>
                        {price !== 0 && (
                            <Typography
                                gutterBottom
                                component="span"
                                variant="subtitle2"
                                sx={{ alignSelf: 'flex-end', color: 'text.secondary' }}
                            >
                                /{plan_validity === 30 ? 'MONTH' : 'YEAR'}LY
                            </Typography>
                        )}
                    </Box>

                    <Stack component="ul" spacing={2} sx={{ my: 2 }}>
                        <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {no_of_social_account} Social Profiles
                            </Typography>
                        </Stack>
                        <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {no_of_users} Number of Users
                            </Typography>
                        </Stack>
                        {/* <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {no_of_brands} Number of Brand
                            </Typography>
                        </Stack> */}
                        {/* <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {no_of_scheduled_posts} Scheduled Posts
                            </Typography>
                        </Stack> */}
                        {/* <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {no_of_scheduled_days_ahead} Scheduled Posts Ahead
                            </Typography>
                        </Stack> */}
                        {/* <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {no_of_draft} Drafts
                            </Typography>
                        </Stack> */}
                        <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                {total_storage} GB Storage
                            </Typography>
                        </Stack>
                        <Stack
                            component="li"
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                            sx={{ typography: 'body2', color: 'text.primary' }}
                        >
                            {has_cname ? (
                                <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
                            ) : (
                                <GppBadIcon sx={{ color: 'error.main', width: 20, height: 20 }} />
                            )}
                            <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                                White Label
                            </Typography>
                        </Stack>
                    </Stack>
                    <Button variant='contained' fullWidth onClick={() => { handleOpenDialog(id) }}>Start 15 Days Trail</Button>
                </RootStyle>
                <ConfirmationDialog
                    open={confirmationDialogue}
                    onClose={() => setConfirmationDialogue(false)}
                    onConfirm={startTrailAndClosePopup}
                    title="Confirm Active Trial Plan"
                    message="Are you sure you want to proceed? Your trial plan will expire in 15 days"
                />
            </Grid>
        </Grid >
    );
};

export default PricingPlanCard;  