import React, { useRef, useState, useEffect } from 'react';
import {
    Box, Button, Grid, Typography, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slider,
    useTheme,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';

const VideoThumbnail = ({ videoSrc, mediaType, handleClose, handleChangeThumbnail }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [thumbnails, setThumbnails] = useState([]);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [error, setError] = useState(null);
    const [fileUploadprogress, setFileUploadprogress] = useState();
    const { showToast } = useToaster();
    const [blobUrl, setBlobUrl] = useState(null);
    const [frameCaptured, setFrameCaptured] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        let isMounted = true;

        if (videoSrc && videoSrc.media_url) {
            const convertVideoToBlobUrl = async () => {
                try {
                    const response = await fetch(videoSrc.media_url);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    if (isMounted) {
                        setBlobUrl(url);
                    }
                } catch (error) {
                    console.error('Error converting video src to Blob URL:', error);
                }
            };

            convertVideoToBlobUrl();
        }

        // Cleanup function
        return () => {
            isMounted = false;
        };
    }, [videoSrc]);

    const failureCallback = (message) => {
        showToast(message, 'e');
    };

    const successCallback = (data, message) => {
        message && showToast(message, 's');
        handleChangeThumbnail(data[0]?.media_url);
        handleClose();
    };

    // Function to capture the first frame of the video
    const captureFirstFrame = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        try {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], 'thumbnail.jpeg', { type: 'image/jpeg' });
                    setThumbnails([file]);
                    setSelectedThumbnail(file);
                    setError(null);
                } else {
                    setError('Failed to capture frame.');
                }
            }, 'image/jpeg');
        } catch (error) {
            setError('Failed to capture frame. This may be due to cross-origin content.');
        }
    };

    const captureFrame = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        try {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], `thumbnail_${Date.now()}.jpeg`, { type: 'image/jpeg' });
                    setThumbnails((prev) => [...prev, file]);
                    setSelectedThumbnail(file);
                    setError(null);
                } else {
                    setError('Failed to capture frame.');
                }
            }, 'image/jpeg');
        } catch (error) {
            setError('Failed to capture frame. This may be due to cross-origin content.');
        }
    };


    const handleVideoLoaded = () => {
        const video = videoRef.current;

        if (!frameCaptured) {
            video.currentTime = 0;

            video.onseeked = () => {
                if (!frameCaptured) {
                    captureFirstFrame();
                    setFrameCaptured(true);
                    video.onseeked = null;
                }
            };
        }
        setDuration(video.duration);
    };

    const handleSliderChange = (event, newValue) => {
        setCurrentTime(newValue);
        videoRef.current.currentTime = newValue;
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleThumbnailSelect = (thumbnail) => {
        setSelectedThumbnail(thumbnail);
    };

    const handleThumbnailUpload = () => {
        let uploadFilesProps = {
            files: [selectedThumbnail],
            setFileUploadprogress,
            successCallback,
            failureCallback,
        };
        CommonHelper.UploadMediaFilesToServer(uploadFilesProps);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setThumbnails((prev) => [...prev, file]);
            setSelectedThumbnail(file);
        }
    };

    const onClose = () => {
        handleClose();
    };

    return (
        <Dialog
            open
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '80%',
                    maxWidth: '800px',
                }
            }}
        >
            <DialogTitle>Upload {mediaType === 'pinterest' ? 'Cover Image' :'Thumbnail'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <video
                            ref={videoRef}
                            src={blobUrl}
                            controls
                            crossOrigin="anonymous"
                            onLoadedData={handleVideoLoaded}
                            onTimeUpdate={handleTimeUpdate}
                            style={{ width: '100%', maxHeight: '400px', borderRadius: '8px' }}
                        />
                        <canvas ref={canvasRef} style={{ display: 'none' }} />

                        <Box my={1}>
                            <Typography variant="h6">Slide to select your {mediaType === 'pinterest' ? 'Cover Image' :'Thumbnail'}</Typography>
                            <Slider
                                value={currentTime}
                                onChange={handleSliderChange}
                                min={0}
                                max={duration}
                                aria-labelledby="video-slider"
                                valueLabelDisplay="auto"
                                sx={{ color: 'primary.main' }}
                            />
                        </Box>

                        <Box my={1} display="flex" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={captureFrame}>
                                Capture Frame
                            </Button>
                            <Button variant="contained" component="label" sx={{ ml: 1 }}>
                                Upload from Device
                                <input type="file" accept="image/*" onChange={handleFileChange} hidden />
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>

                        {error && (
                            <Box>
                                <Typography variant="body1" color="error">
                                    {error}
                                </Typography>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {thumbnails.length > 0 && (
                            <Box >
                                <Typography variant="h6">Select your {mediaType === 'pinterest' ? 'Cover Image' :'Thumbnail'}</Typography>
                                <Grid container spacing={1}>
                                    {thumbnails.map((file, index) => (
                                        <Grid item xs={4} sm={3} md={2} key={index}>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Thumbnail ${index}`}
                                                style={{
                                                    border: selectedThumbnail === file ? `3px solid ${theme.palette.primary.main}` : 'none',
                                                    cursor: 'pointer',
                                                    width: '100%',
                                                    height: 'auto',
                                                    borderRadius: '8px',
                                                    boxSizing: 'border-box',
                                                    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                }}
                                                onClick={() => handleThumbnailSelect(file)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}

                        {selectedThumbnail && (
                            <Box my={2}>
                                <Typography variant="h6">{mediaType === 'pinterest' ? 'Cover Image' :'Thumbnail'} Preview</Typography>
                                <img
                                    src={URL.createObjectURL(selectedThumbnail)}
                                    alt="Selected Thumbnail"
                                    style={{ width: '100%', maxWidth: '300px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div style={{ flex: 1 }}>{fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}</div>
                <Button variant="outlined" color="error" onClick={onClose}>
                    Cancel
                </Button>
                {selectedThumbnail && (
                    <Button variant="contained" onClick={handleThumbnailUpload}>
                        Upload
                    </Button>
                )}
            </DialogActions>
        </Dialog>


    );
};

export default VideoThumbnail;