import { Avatar, Badge, Typography, useMediaQuery, useTheme } from '@mui/material';
import FacebookIcon from '../../../src/assets/facebook.svg';
import InstagramIcon from '../../../src/assets/instagram.svg';
import YoutubeIcon from '../../../src/assets/youtube.png';
import TwitterIcon from '../../../src/assets/twitter.svg';
import LinkedInIcon from '../../../src/assets/linked_in.svg';
import TikTokIcon from '../../../src/assets/tiktok.svg';
import PinterestIcon from '../../assets/pinterest-icon.svg'
import GoogleMyBusinessIcon from '../../../src/assets/google-my-business-round-logo.svg';
import { PostUtil } from 'src/pages/new-post-new/post-util';

const SocialMediaAccountImage = (name) => { 
  let sourceFile = null;
  switch (name.toUpperCase()) {
    case PostUtil.ServiceName.TWITTER:
      sourceFile = TwitterIcon;
      break;
    case PostUtil.ServiceName.FACEBOOK:
      sourceFile = FacebookIcon;
      break;
    case PostUtil.ServiceName.YOUTUBE:
      sourceFile = YoutubeIcon;
      break;
    case PostUtil.ServiceName.INSTAGRAM:
      sourceFile = InstagramIcon;
      break;
    case PostUtil.ServiceName.TIKTOK:
      sourceFile = TikTokIcon;
      break;
    case PostUtil.ServiceName.LINKEDIN:
      sourceFile = LinkedInIcon;
      break;
    case PostUtil.ServiceName.GBUSINESS:
      sourceFile = GoogleMyBusinessIcon;
      break;
    case PostUtil.ServiceName.PINTEREST:
      sourceFile = PinterestIcon;
      break;
    default:
      break;
  }
  return <Avatar style={{ width: 20, height: 20, backgroundColor: 'white' }} alt={name} src={sourceFile} />;
};

const AccountBadgeWithName = (network) => {
  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={SocialMediaAccountImage(network.social_media)}
        sx={{
          '& .MuiBadge-badge': {
            height: 20,
            width: 20,
            minWidth: 20,
            borderRadius: '50%',
            border: '2px solid white', // adds a border for better separation
            margin: '-2px', // adds slight spacing between badge and avatar
          },
        }}
      >
        <Avatar
          src={network.connected_profile_image || network.connected_profile_name || network.connected_profile_id}
          alt={network.connected_profile_name || network.connected_profile_id}
          sx={{ height: 30, width: 30, bgcolor: 'primary.main' }}
        />
      </Badge>
      <Typography
        noWrap="none"
        sx={{
          fontSize: '0.875rem',
          fontWeight: 700,
          lineHeight: 1.5,
          textTransform: 'uppercase',
          color: 'text.secondary',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '15ch',
          ml:1.2
        }}
      >
        {network.connected_profile_name || network.connected_profile_id}
      </Typography>
    </>
  );
};

const MobileViewAccountBadgeWithName = (network) => {
  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={SocialMediaAccountImage(network.social_media)}
        //sx={{ mr: 1, zIndex: 0 }}
        sx={{
          '& .MuiBadge-badge': {
            height: 20,
            width: 20,
            minWidth: 20,
            borderRadius: '50%',
            border: '2px solid white', // adds a border for better separation
            margin: '-4px', // adds slight spacing between badge and avatar
          },
        }}
      >
        <Avatar
          src={network.connected_profile_image || network.connected_profile_name || network.connected_profile_id}
          alt={network.connected_profile_name || network.connected_profile_id}
          sx={{ height: 30, width: 30, bgcolor: 'primary.main' }}
        />
      </Badge>
      <Typography
        noWrap="none"
        sx={{
          fontSize: '0.875rem',
          fontWeight: 700,
          lineHeight: 1.5,
          textTransform: 'uppercase',
          color: 'text.secondary',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '27ch',
        }}
      >
        {network.connected_profile_name || network.connected_profile_id}
      </Typography>
    </>
  );
};

const InitialAccountBadgeWithName = (network) => {
  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={SocialMediaAccountImage(network.service_name)}
        sx={{ mr: 3, zIndex: 0 }}
      >
        <Avatar
          src={network.profile_image || network.profile_name || network.id}
          alt={network.profile_name || network.id}
          sx={{ height: 40, width: 40, bgcolor: 'primary.main' }}
        />
      </Badge>
      {network.profile_name || network.id}
    </>
  );
};
export const CommonFunction = {
  AccountBadgeWithName,
  MobileViewAccountBadgeWithName,
  InitialAccountBadgeWithName,
};
