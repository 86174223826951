import {
  Box,
  Card,
  Avatar,
  Typography,
  IconButton,
  useTheme,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PublicIcon from '@mui/icons-material/Public';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useRef, useState, useEffect } from 'react';
import FBcomment from '../../../../assets/comment-white.svg';
import FBlike from '../../../../assets/fblike-white.svg';
import FBshare from '../../../../assets/fbshare-white.svg';

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
});

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: 'white',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
}));

const FacebookReelsPreview = ({ data }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [isRellsPlaying, setRellsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const ConnectedProfiles = data.connected_profile_details;

 useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
      });
      setShowControls(true);
    }
  };

  const handleVideoPlay = () => {
    setRellsPlaying(true);
  };
  const handleVideoPause = () => {
    setRellsPlaying(false);
  };
  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card sx={{ height: 460, width: 300, maxWidth: 500, minWidth: respwidth ? 300 : 200, borderRadius: '8px' }}>
          <video
            preload="auto"
            crossOrigin="anonymous"
            src={data.media_url}
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', borderRadius: '10px' }}
            ref={videoRef}
            controls={showControls}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
          />
          {!isRellsPlaying && (
            <PlayButton onClick={handlePlayButtonClick}>
              <PlayArrowIcon sx={{ fontSize: 40 }} />
            </PlayButton>
          )}
          <GradientOverlay />

          <Box
            sx={{
              position: 'absolute',
              bottom: '18px',
              width: 'calc(100% - 62px)',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '18px',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.7rem',
                marginBottom: '8px',
                color: '#fff',
              }}
            >
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 35,
                  width: 35,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
              <Typography variant="body2">{data?.connected_profile_details?.connected_profile_name}</Typography>
              <PublicIcon sx={{ fontSize: 15, mt: '0.3rem', ml: '0.2rem' }} />
            </Box>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                color: 'white',
              }}
            >
              {data.description}
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '16px',
              right: '12px',
              display: 'flex',
              flexDirection: 'column',
              gap: '29px',
              alignItems: 'center',
            }}
          >
            <img
              src={FBlike}
              style={{
                height: 'auto',
                width: '100%',
                maxWidth: '18px',
                maxHeight: '18px',
                marginRight: '1rem',
                color: '#ffffff',
              }}
            />
            <img
              src={FBcomment}
              style={{
                height: 'auto',
                width: '100%',
                maxWidth: '20px',
                maxHeight: '20px',
                marginRight: '1rem',
                color: 'white',
              }}
            />
            <img
              src={FBshare}
              style={{
                height: 'auto',
                width: '100%',
                maxWidth: '20px',
                maxHeight: '20px',
                marginRight: '1rem',
                color: 'white',
              }}
            />
            <MoreHorizIcon sx={{ color: 'white', marginRight: '1rem' }} />
            <Box
              sx={{ width: '24px', height: '24px', marginTop: '-21px', borderRadius: '4.3px', maskSize: 'cover' }}
            ></Box>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default FacebookReelsPreview;
