import { useTheme } from '@emotion/react';
import { Hidden, ListItemText, TableBody, useMediaQuery } from '@mui/material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import DraftPostGridAction from './PostGridAction.component';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';

const DraftPostGridBody = ({ draftList, page, rowsPerPage, handleReCallDraft }) => {
  const theme = useTheme();
  let maxWidth = useMediaQuery(theme.breakpoints.down('sm')) ? 500 : 400;

  const handleOpenDraftPost = (row, open = false) => {
    let data = {
      content: postView,
      data: row,
    };
    showRightSidebar(data, '500px');
  };

  const postView = (row) => {
    let data = {
      id: row,
    };
    let postDetails = {
      data: data,
    };
    return <PostPreview postDetails={postDetails}></PostPreview>;
  };

  const { showRightSidebar } = useRightSidebar();

  return (
    <>
      <TableBody>
        {draftList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
          return (
            <>
              <StyledComponent.StyledTableRow
                onClick={() => {
                  handleOpenDraftPost(row.id, true);
                }}
              >
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          // margin: '4px 0px 0px',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
                    ></ListItemText>
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.GetPostContent(row.description, maxWidth)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell onClick={(event) => event.stopPropagation()}>
                    {PostDetailsHelper.getPostMedias(row.media_url)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  <StyledComponent.HoverContent
                    className="hover-content"
                    onClick={(event) => event.stopPropagation()}
                    sx={{ width: '1rem' }}
                  >
                    <DraftPostGridAction draftList={row} handleReCallDraft={handleReCallDraft} />
                  </StyledComponent.HoverContent>
                </StyledComponent.StyledTableCell>
              </StyledComponent.StyledTableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default DraftPostGridBody;
