import TagManager from 'react-gtm-module';
import { Config } from 'src/config';

const InitializeGoogleTagManager = (userId) => {
    const tagManagerArgs = {
        gtmId: Config.GoogleTagManager.id,
        dataLayer: {
            userId,
            userProject: 'user account creation'
        }
    }
    TagManager.initialize(tagManagerArgs);
    return null;
}
export default InitializeGoogleTagManager;
