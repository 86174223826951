import {
    Box,
    Grid,
    Typography,
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    Paper,
    Stack,
    useMediaQuery,
    useTheme,
    Badge,
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import {
    FavoriteBorderRounded as FavoriteBorderRoundedIcon, Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, BookmarkBorder as BookmarkBorderIcon, VerifiedUser as VerifiedUserIcon, Share as ShareIcon, MoreHoriz as MoreHorizIcon,
    IosShare as IosShareIcon,
    StarBorder as StarBorderIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import GbusinessVerifyUser from '../../../assets/gbusiness-verify-user.svg';

const PinterestPostCard = ({ title, description, media_url, profile }) => {
    let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
    const [showFullDescription, setShowFullDescription] = useState(false);
    const theme = useTheme();
    const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
    const handleToggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    const imageView = () => {
        let xWidth = 12

        return (
            <Grid container sx={{ position: 'relative' }}>
                <Grid
                    item
                    xs={xWidth}
                    sx={{
                        maxWidth: 500,
                        maxHeight: 460,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex',
                        opacity: 1,
                        transform: 'scale(0.99)'
                    }}
                >
                    {mediaFiles && mediaFiles[0]?.url && (
                        <>
                            <img
                                src={mediaFiles[0].url}
                                alt={mediaFiles[0].url}
                                width={'100%'}
                                height={460}
                                style={{ objectFit: 'cover' }}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Stack container justifyContent="flex-start" alignItems="flex-start">
                <Card sx={{ width: 300, maxWidth: 500, minWidth: respwidth ? 300 : 200, boxShadow: 'none', border: 'solid 2px', borderColor: theme.palette.preview.postcard.color }}>
                    <Box sx={{ position: 'relative' }}>
                        {imageView()}
                    </Box>

                    <Box sx={{ alignSelf: 'flex-start', p: 1.3 }}>
                        <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Paper sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem', mb: 1 }}>
                                <MoreHorizIcon fontSize="small" />
                                <IosShareIcon fontSize='small' />
                                <StarBorderIcon fontSize='small' />
                            </Paper>
                            <Paper sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <Box sx={{ p: 1, borderRadius: '50px', display: 'flex' }}>
                                    <Typography sx={{}}>Board</Typography>
                                    <KeyboardArrowDownIcon />
                                </Box>
                                <Box sx={{ p: 1, backgroundColor: 'error.main', borderRadius: '50px' }}>
                                    <Typography sx={{ color: 'white' }}>Save</Typography>
                                </Box>
                            </Paper>
                        </Stack>
                        {/* <Typography
                            style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                                fontSize: '0.9rem',
                                color: 'black',
                                textDecoration: 'underline',
                                marginBottom: '8px',
                            }}
                        >
                            {data.link}
                        </Typography> */}

                        <Typography
                            style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                                fontSize: '1.3rem',
                                color: 'black',
                                fontWeight: 700
                            }}
                        >
                            {title}
                        </Typography>

                        {/* <Typography
                            style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                                fontSize: '0.8rem',
                                color: 'black'
                            }}
                        >
                            {data.description}
                        </Typography> */}

                        <Typography
                            noWrap={!showFullDescription}
                            sx={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                                overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                                fontSize: '0.875rem',
                            }}
                        >
                            {description}
                        </Typography>
                        {!showFullDescription && description.length > 100 && (
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    fontSize: '0.875rem',
                                    color: theme.palette.primary.main
                                }}
                                onClick={handleToggleDescription}
                            >
                                Show More
                            </Typography>
                        )}
                        {showFullDescription && (
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    fontSize: '0.875rem',
                                    color: theme.palette.primary.main
                                }}
                                onClick={handleToggleDescription}
                            >
                                Show Less
                            </Typography>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: 2,
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                                <Avatar
                                    src={profile.image_url ? profile.image_url : profile.profile_name}
                                    alt={profile.profile_name ? profile.profile_name : profile.id}
                                    sx={{
                                        height: 25,
                                        width: 25,
                                        bgcolor: theme.palette.primary.main,
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 1,
                                        overflow: 'hidden',
                                        WebkitBoxDecorationBreak: 'clone',
                                        whiteSpace: 'pre-line',
                                        textOverflow: 'ellipsis',
                                        fontSize: '1rem',
                                        fontWeight: '700'
                                    }}
                                >
                                    {profile?.profile_name}
                                </Typography>
                            </Box>
                            <Box sx={{ p: 1, backgroundColor: 'background.shadow', borderRadius: '50px' }}>
                                <Typography >Follow</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Stack>
        </>
    );
};

export default PinterestPostCard;
