import { Popover, MenuItem, Typography } from '@mui/material';
import { DeleteForever as DeleteForeverIcon, Upload as UploadIcon, Visibility as VisibilityIcon, ChangeCircle as ChangeCircleIcon } from '@mui/icons-material';
import { CommonHelper } from 'src/utils/commonHelper';

const IntegratedActionPopup = ({ popoverElement, handleCloseMenu, handleUpdateAccount, handleDeletePost, handleReAuthenticate, handleViewLink }) => {
  const isCustomDomian = CommonHelper.CustomDomain()
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 'auto',
          boxShadow: 3,
          borderRadius: 2,
          backdropFilter: 'blur(8px)',
          backgroundColor: 'action.disabledOpacity',
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
          '& .MuiMenuItem-root': {
            px: 2.5,
            py: 1.5,
            typography: 'body2',
            borderRadius: 2,
            transition: 'background-color 0.2s, transform 0.2s',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'action.disabledOpacity',
              transform: 'scale(1.02)',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
              marginRight: 2,
            },
            '& .MuiTypography-root': {
              fontWeight: 500,
            },
          },
        },
      }}
    >
      <MenuItem onClick={handleViewLink}>
        <VisibilityIcon sx={{ mr: 1, color: 'primary.main' }} />
        <Typography sx={{ fontWeight: 500 }}>View Profile</Typography>
      </MenuItem>
      <MenuItem sx={{ color: 'common.black' }} onClick={handleUpdateAccount}>
        <UploadIcon sx={{ mr: 1, color: 'primary.main' }} />
        <Typography sx={{ fontWeight: 500 }}>Update</Typography>
      </MenuItem>
      {!isCustomDomian &&<MenuItem sx={{ color: 'common.black' }} onClick={handleReAuthenticate}>
        <ChangeCircleIcon sx={{ mr: 1, color: 'primary.main' }} />
        <Typography sx={{ fontWeight: 500 }}>Re-Authenticate</Typography>
      </MenuItem>}
      <MenuItem sx={{ color: 'error.main' }} onClick={handleDeletePost}>
        <DeleteForeverIcon sx={{ mr: 1 }} />
        <Typography sx={{ fontWeight: 500 }}>Delete</Typography>
      </MenuItem>
    </Popover>
  );
};

export default IntegratedActionPopup;
