import { Dialog, DialogContent, DialogTitle, ToggleButtonGroup, ToggleButton, Stack, Typography } from "@mui/material";
import StripePaymentComponent from "src/components/stripe/stripe-payment.component";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag"; // Import country flags

const PaymentDialogComponent = ({ clientSecret, amount, handleClose, currency }) => {
    // const [currency, setCurrency] = useState(inrAmount != 0 ? 'INR' : 'USD');
    // const handleCurrencyChange = (event, newCurrency) => {
    //     if (newCurrency !== null) {
    //         setCurrency(newCurrency);
    //     }
    // };
    return (
        <Dialog onClose={() => { handleClose(false) }} open>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">Payment</Typography>
                    {/* {inrAmount != 0 && <ToggleButtonGroup
                        value={currency}
                        exclusive
                        onChange={handleCurrencyChange}
                        aria-label="currency switch"
                    >
                        <ToggleButton value="INR" aria-label="India">
                            <ReactCountryFlag
                                countryCode="IN"
                                svg
                                style={{
                                    width: '24px',
                                    height: '18px',
                                    marginRight: '8px',
                                }}
                            />
                            INR
                        </ToggleButton>
                        <ToggleButton value="USD" aria-label="USA">
                            <ReactCountryFlag
                                countryCode="US"
                                svg
                                style={{
                                    width: '24px',
                                    height: '18px',
                                    marginRight: '8px',
                                }}
                            />
                            USD
                        </ToggleButton>
                    </ToggleButtonGroup>} */}
                </Stack> </DialogTitle>
            <DialogContent dividers>
                <StripePaymentComponent clientSecret={clientSecret} amount={currency === "INR" ? `₹${amount}` : `$${amount}`} />
            </DialogContent>
        </Dialog>
    );
}
export default PaymentDialogComponent;