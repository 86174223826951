import { Stack } from '@mui/material';
import NoPost from 'src/svg/no-post';
import NoPublishedPost from 'src/svg/no-published-post';

export default function NoPostFound({ height }) {
  return (
    <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: height, height: height, }}>
      {NoPublishedPost()}
      <Stack sx={{ marginTop: '-15px', width: '250px' }}>
        {NoPost()}
      </Stack>
    </Stack>
  );
}
