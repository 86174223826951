import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { NavConfig } from 'src/navConfig';
import BrandSelect from './brand-select.component';
import AccountPopover from '../AccountPopover';
import NotificationsPopover from '../NotificationsPopover';

const HEADER_MOBILE = 64;

const customTheme = createTheme({
  breakpoints: {
    values: {
      mdCustom: 1100,
      mdCustom0: 1250
    },
  },
});

const StyledRoot = styled(AppBar)(({ theme, headerName }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
  [customTheme.breakpoints.up('mdCustom')]: {
    width: '100%',
    ...(headerName !== "" && {
      width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_WIDTH + 70}px)`,
    }),
    height: '64px',
    zIndex: '1101',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    transition: 'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom: '1px solid rgba(0, 171, 85, 0.24)',
    borderBottomStyle: 'dashed'
  },

}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [customTheme.breakpoints.up('mdCustom')]: {
    padding: theme.spacing(0, 2.5),
  },
}));

export default function MobileHeader({ headerName, handleChangeBrand }) {

  return (
    <StyledRoot headerName={headerName}>
      <StyledToolbar>
        <BrandSelect handleChangeBrand={handleChangeBrand} />
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <NotificationsPopover />
          <AccountPopover headerName={headerName} />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
