import { Box, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import DraftPostActionPopup from "./PostGridActionPopup.component";
import { useNavigate } from 'react-router-dom';
import { postsModuleURL,imageModeuleUrl } from "src/services/urlService";
import { useToaster } from "src/utils/toaster/toasterContext";
import HttpServices from "src/services/httpService";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import { CommonHelper } from "src/utils/commonHelper";

const DraftPostGridAction = ({ draftList, handleReCallDraft }) => {
    const navigate = useNavigate();
    const { showToast } = useToaster();
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);

    const handleOpenMenu = (event, draftList) => {
        setPopOverPost(draftList);
        setPopoverElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const closeConfirmationDialogue = () => {
        setConfirmationDialogue(null);
    };

    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
        setLoading(false);
        closeConfirmationDialogue();
    };
    const handleUploadDraft = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(draftList.id,newPostSucessCallback,failureCallback)
    };
    const fetchMediaMetaData = (data) => {
        let mediaBody = {
          media_urls: data?.media_url.split(",")
        };
        let postProps = {
          url: imageModeuleUrl.fetchMediaMetadata,
          body: mediaBody,
          successCallback: (imageData,message)=>{fetchMediaSuccessCallback(imageData,message,data)},
          failureCallback: failureCallback
        }
        HttpServices.Post(postProps);
      }
      const fetchMediaSuccessCallback = (imageData,mesaage,data) => {
        uploadDataToPost( data,imageData ? imageData.media_datas : []);
      }
      const newPostSucessCallback = (data) => {
        if (data.media_url && data.media_url.length > 0)
          fetchMediaMetaData(data);
        else
          uploadDataToPost(data);
      }
   
      const uploadDataToPost = (data,imageData = []) => {
        setLoading(false);
       const newPostData = CommonHelper.CommonNewPostAddMediaAllService(data,imageData)
       navigate('/new-post', { state: {data:newPostData} });
     }
    
    const handleDeleteDraft = () => {
        handleCloseMenu();
        setConfirmationDialogue({
            isOpen: true,
            title: "Confirm Deletion",
            message: "Are you sure you want to delete this Draft?",
            submitFunction: confirmDeletePost
        });
    };

    const confirmDeletePost = () => {
        let postProps = {
            url: postsModuleURL.deleteDraft + draftList.id,
            successCallback: deleteSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
        closeConfirmationDialogue();
    };

    const deleteSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue();
        handleReCallDraft();
    };

    const postActionProps = {
        loading,
        popoverElement: popoverElement,
        handleCloseMenu,
        handleUploadDraft,
        handleDeleteDraft,
        selectedPopOverPost: selectedPopOverPost,
    };

    return (
        <>
            <Box display="flex" alignItems="right">
                    <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => {
                            handleOpenMenu(e, draftList);
                        }}
                        sx={{ padding: '6px', marginRight: '2px' }}
                    >
                        <MoreVertIcon />
                    </IconButton>
            <DraftPostActionPopup {...postActionProps} />
            </Box>
            {confirmationDialogue && <ConfirmationDialog
                open={confirmationDialogue.isOpen}
                onClose={() => closeConfirmationDialogue()}
                onConfirm={confirmationDialogue.submitFunction}
                title={confirmationDialogue.title}
                message={confirmationDialogue.message}
            />}
        </>
    );
}

export default DraftPostGridAction;
