import { ErrorOutline as ErrorOutlineIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Stack,
    TextField,
    FormControl,
    Select,
    Card,
    Link,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme, styled } from '@mui/material/styles';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import ImageDraggable from 'src/components/image/image-draggable';
import TextIconLabel from 'src/components/TextIconLabel';
import { NewPostStyledComponent } from 'src/pages/new-post-new/newPostStyled.component';
import { CommonHelper } from 'src/utils/commonHelper';
import PostDescription from '../post-description.component';
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import MovieFilterSharpIcon from '@mui/icons-material/MovieFilterSharp';
import ErrorIcon from '@mui/icons-material/Error';
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import PhotoCameraSharpIcon from '@mui/icons-material/PhotoCameraSharp';
import { PostUtil } from '../post-util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TiktokConditionComponent = forwardRef(({ updateRequestData }, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const postConfiguration = PostUtil.ServiceConfigurations.TIKTOK.POST;

    //State Variable
    const [isAspectRatioNotSupportedForTheSelectedVideo, setAspectRatioNotSupportedForTheSelectedVideo] = useState(false);
    const [socialMediaRequestData, setSocialMediaRequestData] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [manualExpand, setManualExpand] = useState(true);

    useEffect(() => {
        checkForErrors();
    }, []);

    useImperativeHandle(ref, () => ({
        checkForErrors,
        handleRequestData,
    }));

    const handleRequestData = (data) => {
        checkForErrors(data, false);
    };

    const updateRequestDataToParent = (data) => {
        updateRequestData(data, PostUtil.ServiceName.TIKTOK);
    };

    const handleChangeSocialMediaRequestData = (data, isUpdateDataToParent = true) => {
        let formattedRequestData = CommonHelper.B2SGroupBy(data, 'connected_profile_id');
        setRequestData({ ...formattedRequestData });
        setSocialMediaRequestData([...data]);
        if (isUpdateDataToParent === true) {
            updateRequestDataToParent(data);
        }
    };

    const checkForErrors = (data = null, isUpdateDataToParent = true) => {
        let sRequestData = data ? [...data] : [...socialMediaRequestData];
        if (sRequestData?.length > 0) {
            sRequestData.map((request) => {
                let errors = getTikTokErrors(request);
                request['errors'] = errors;
            });
            handleChangeSocialMediaRequestData(sRequestData, isUpdateDataToParent);
        } else {
            handleChangeSocialMediaRequestData([], []);
        }
    };

    const handleReOrderFiles = (profileId, postType, oldIndex, newIndex) => {
        let sRequestData = [...socialMediaRequestData];
        let reqData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
        let files = [...reqData.media_data];
        let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
        reqData.media_url = mediaFiles.map((a) => a.media_url).join();
        reqData.media_data = mediaFiles;
        checkForErrors(sRequestData);
        // handleChangeSocialMediaRequestData(sRequestData);
    };

    const handleRemoveFiles = (profileId, postType, mediaUrl) => {
        let sRequestData = [...socialMediaRequestData];
        let rData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
        let files = [...rData.media_data];
        let index = files.findIndex((a) => a.media_url === mediaUrl);
        if (index >= 0) files.splice(index, 1);
        rData.media_url = files.map((a) => a.media_url).join();
        rData.media_data = files;
        checkForErrors(sRequestData);
        // handleChangeSocialMediaRequestData(sRequestData);
    };

    const handleChangeDescription = (profileId, profile, value) => {
        let sRequestData = [...socialMediaRequestData];
        let connectedProfile = sRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
        );
        connectedProfile.description = value;
        checkForErrors(sRequestData);
        // handleChangeSocialMediaRequestData(sRequestData);
    };
    const handleTitle = (profileId, profile, value) => {
        let sRequestData = [...socialMediaRequestData];
        let connectedProfile = sRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
        );
        connectedProfile.title = value;
        checkForErrors(sRequestData);
    };
    const handleTiktokPrivacyType = (profileId, profile, value) => {
        let sRequestData = [...socialMediaRequestData];
        let connectedProfile = sRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
        );
        connectedProfile.privacy = value;
        checkForErrors(sRequestData);
    };
    const handleTikTokActions = (profileId, profile, action_type) => {
        let sRequestData = [...socialMediaRequestData];
        let connectedProfile = sRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
        );
        switch (action_type) {
            case PostUtil.TikTokOptions.DISABLE_COMMENT:
                connectedProfile.allow_user_to_comment = !connectedProfile.allow_user_to_comment;
                break;
            case PostUtil.TikTokOptions.DISABLE_DUET:
                connectedProfile.allow_user_to_duet = !connectedProfile.allow_user_to_duet;
                break;
            case PostUtil.TikTokOptions.DISABLE_SWITCH:
                connectedProfile.allow_user_to_switch = !connectedProfile.allow_user_to_switch;
                break;
            case PostUtil.TikTokOptions.DISCLOSURE_CONTENT:
                connectedProfile.disclosure_content = !connectedProfile.disclosure_content;
                break;
            case PostUtil.TikTokOptions.BRAND:
                connectedProfile.your_brand = !connectedProfile.your_brand;
                break;
            case PostUtil.TikTokOptions.BRAND_CONTENT:
                connectedProfile.branded_content = !connectedProfile.branded_content;
                break;
            default:
                break;
        }
        checkForErrors(sRequestData);
    };


    const handlePostChange = (profileId, postType) => {
        let sRequestData = [...socialMediaRequestData];
        let profileCount = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId))?.length;
        let index = sRequestData.findIndex(
            (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType
        );
        if (index >= 0) {
            if (profileCount > 1) {
                sRequestData.splice(index, 1);
            }
        } else {
            let sNetwork = { ...sRequestData.find((a) => a.connected_profile_id === parseInt(profileId)) };
            sNetwork['post_type'] = postType;
            sNetwork['errors'] = getTikTokErrors(sNetwork);
            sRequestData.push(sNetwork);
        }
        checkForErrors(sRequestData);
        // handleChangeSocialMediaRequestData(sRequestData);
    };

    const tiktokDirectPostConditions = (request) => {
        let fErrors = [];

        let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('VIDEO'))?.length > 1;
        let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
        let isMultipleImageFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE'))?.length > 1;
        let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
        if (isImageFileAvailable && isVideoFileAvailable) {
            fErrors.push("You can't post both an image and a video in the same post.");
        }
        if (isMultipleVideoFiles) {
            fErrors.push("You can't post multiple videos.");
        } else {
            let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
            if (videoFile) {
                if (videoFile.video_length > postConfiguration.VIDEO.MAXSECONDS) {
                    fErrors.push(
                        `Maximum video length for the post is ${postConfiguration.VIDEO.MAXSECONDS} seconds. Please choose a video with a maximum length of ${postConfiguration.VIDEO.MAXSECONDS} seconds.`
                    );
                }

                if (videoFile.video_length < postConfiguration.VIDEO.MINSECONDS) {
                    fErrors.push(
                        `Minimum video length for the post is ${postConfiguration.VIDEO.MINSECONDS} seconds. Please choose a video with at least ${postConfiguration.VIDEO.MINSECONDS} seconds.`
                    );
                }

                if (videoFile.aspect_ratio.width < postConfiguration.VIDEO.MINWIDTH || videoFile.aspect_ratio.height < postConfiguration.VIDEO.MINHEIGHT) {
                    fErrors.push(
                        `Video resolution is too low. Minimum resolution should be ${postConfiguration.VIDEO.MINWIDTH}x${postConfiguration.VIDEO.MINHEIGHT}.`
                    );
                }
            }
        }
        let hasAttachments = request.media_data?.length > 0;
        if (!hasAttachments && !request.description) {
            fErrors.push('Please enter a description');
        }
        if (!hasAttachments) {
            fErrors.push('Please select any video or image');
        }
         if (isImageFileAvailable) {
            request.media_data.forEach((imageFile) => {
                if (imageFile.media_type === 'IMAGE') {
                    if (imageFile.aspect_ratio.width < postConfiguration.IMAGE.MINWIDTH || imageFile.aspect_ratio.height < postConfiguration.IMAGE.MINHEIGHT) {
                        fErrors.push(
                            `Image resolution is too low. Minimum resolution should be 360x360 pixels.`
                        );
                    }
                    if ((imageFile.aspect_ratio.width >= 1080 || imageFile.aspect_ratio.height >= 1920) && 
                        (imageFile.aspect_ratio.width >= 1920 || imageFile.aspect_ratio.height >= 1080)) {
                        fErrors.push(
                            `Image resolution is too high. Maximum resolution should be 1080x1920 or 1920x1080 pixels.`
                        );
                    }
                }
            });
        }
        
    
        let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE');
        if (imageFiles?.length > postConfiguration.IMAGE.MAXIMAGECOUNT) {
            fErrors.push(`You can upload a maximum of ${postConfiguration.IMAGE.MAXIMAGECOUNT} images. Please remove some images.`);
        }
        if (request.description && request.description.length > postConfiguration.DESCRIPTION_MAX) {
            fErrors.push('Tiktok description should not exceed 2200 characters');
          }
        if (request.title && request.description.title > postConfiguration.TITLE_MAX) {
            fErrors.push('Tiktok title should not exceed 90 characters');
          }
        return fErrors;
    };
    

    const tiktokUploadToInboxConditions = (request) => {
        let fErrors = [];

        let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('VIDEO'))?.length > 1;
        let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
        let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');

        if (isImageFileAvailable && isVideoFileAvailable) {
            fErrors.push("You can't post both an image and a video in the same post.");
        }

        if (isMultipleVideoFiles) {
            fErrors.push("You can't post multiple videos.");
        } else {
            let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
            if (videoFile) {
                if (videoFile.video_length > postConfiguration.VIDEO.MAXSECONDS) {
                    fErrors.push(
                        `Maximum video length for the post is ${postConfiguration.VIDEO.MAXSECONDS} seconds. Please choose a video with a maximum length of ${postConfiguration.VIDEO.MAXSECONDS} seconds.`
                    );
                }

                if (videoFile.video_length < postConfiguration.VIDEO.MINSECONDS) {
                    fErrors.push(
                        `Minimum video length for the post is ${postConfiguration.VIDEO.MINSECONDS} seconds. Please choose a video with at least ${postConfiguration.VIDEO.MINSECONDS} seconds.`
                    );
                }

                if (videoFile.aspect_ratio.width < postConfiguration.VIDEO.MINWIDTH || videoFile.aspect_ratio.height < postConfiguration.VIDEO.MINHEIGHT) {
                    fErrors.push(
                        `Video resolution is too low. Minimum resolution should be ${postConfiguration.VIDEO.MINWIDTH}x${postConfiguration.VIDEO.MINHEIGHT}.`
                    );
                }
            }
        }

        let hasAttachments = request.media_data?.length > 0;
        if (!hasAttachments && !request.description) {
            fErrors.push('Please enter a description');
        }
        if (!hasAttachments) {
            fErrors.push('Please select any video or image');
        }
        if (isImageFileAvailable) {
            request.media_data.forEach((imageFile) => {
                if (imageFile.media_type === 'IMAGE') {
                    if (imageFile.aspect_ratio.width < postConfiguration.IMAGE.MINWIDTH || imageFile.aspect_ratio.height < postConfiguration.IMAGE.MINHEIGHT) {
                        fErrors.push(
                            `Image resolution is too low. Minimum resolution should be 360x360 pixels.`
                        );
                    }
                    if ((imageFile.aspect_ratio.width >= 1080 || imageFile.aspect_ratio.height >= 1920) && 
                        (imageFile.aspect_ratio.width >= 1920 || imageFile.aspect_ratio.height >= 1080)) {
                        fErrors.push(
                            `Image resolution is too high. Maximum resolution should be 1080x1920 or 1920x1080 pixels.`
                        );
                    }
                }
            });
        }
        let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE');
        if (imageFiles?.length > postConfiguration.IMAGE.MAXIMAGECOUNT) {
            fErrors.push(`You can upload a maximum of ${postConfiguration.IMAGE.MAXIMAGECOUNT} images. Please remove some images.`);
        }
        if (request.description && request.description.length > postConfiguration.DESCRIPTION_MAX) {
            fErrors.push('Tiktok description should not exceed 2200 characters');
          }
        if (request.title && request.description.title > postConfiguration.TITLE_MAX) {
            fErrors.push('Tiktok title should not exceed 90 characters');
          }
        return fErrors;
    };

    const getTikTokErrors = (request) => {
        let fErrors = [];
        let postType = request.post_type;

        if (postType === PostUtil.PostType.DIRECT_POST) {
            let postErrors = tiktokDirectPostConditions(request);
            fErrors = fErrors.concat(postErrors);
        }
        else if (postType === PostUtil.PostType.UPLOAD_TO_INBOX) {
            let postErrors = tiktokUploadToInboxConditions(request);
            fErrors = fErrors.concat(postErrors);
        }
        return fErrors;
    };

    const handleAccordion = (profileId) => {
        setManualExpand((prev) => !prev);
        let sRequestData = [...socialMediaRequestData];
        let reqDatas = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId));
        if (reqDatas?.length > 0) {
            reqDatas.map((data) => {
                data['isExpanded'] = !data['isExpanded'];
            });
        }
        handleChangeSocialMediaRequestData(sRequestData, false);
    };
    const checkAspectRatioChanges = () => {
        // return mediaFiles.some((a) => a.media_type.includes('VIDEO')) && selectedPostTypes === PostUtil.PostType.REELS && (changeFacebookAspectRatio || isAspectRatioNotSupportedForTheSelectedVideo);
    };
    const handleChangeAspectRatio = () => { };
    const checkPostTypeChecked = (profileId, postType) => {
        return requestData[profileId].some((a) => a.post_type === postType);
    };
    const errorView = (request) => {
        return (
            <Box style={{ marginTop: '0.5rem' }}>
                <Typography sx={{ fontSize: 16, fontWeight: 800 }}>Error Description</Typography>
                {request.errors.map((errorMessage) => {
                    return (
                        <TextIconLabel
                            icon={<ErrorOutlineIcon sx={{ color: theme.palette.error.main, mr: 1, fontSize: 18 }} />}
                            value={errorMessage}
                            sx={{ typography: 'caption', color: theme.palette.error.main, mb: 1, fontWeight: 800, fontSize: 14 }}
                        />
                    );
                })}
            </Box>
        );
    };

    const TiktokTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const POST_TYPES = {
        UPLOAD_TO_INBOX: {
            value: 'UPLOAD_TO_INBOX',
            icon: <CollectionsSharpIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
        },
        DIRECT_POST: {
            value: 'DIRECT_POST',
            icon: <MovieFilterSharpIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
        },
    };
    const TikTokActionView = (profileId, profile) => {
        let sRequestData = [...socialMediaRequestData];
        let connectedProfile = sRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
        );
        let IsActionview = true;
        let videoFile = connectedProfile.media_data?.find((a) => a.media_type === 'VIDEO');
        if (videoFile) {
            if (videoFile.video_length >= postConfiguration.VIDEO.ACTIONMAXSECONDS) {
                IsActionview = false;
            }

        }
        return (
            <Stack>
                <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }}>Allow users to</Typography>
                <Stack direction="row" >
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={profile.allow_user_to_comment}
                            onChange={(event) => handleTikTokActions(profileId, profile, PostUtil.TikTokOptions.DISABLE_COMMENT)} />} label="Comment" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={profile.allow_user_to_duet && IsActionview}
                            disabled={!IsActionview}
                            onChange={(event) => handleTikTokActions(profileId, profile, PostUtil.TikTokOptions.DISABLE_DUET)} />} label="Duet" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={profile.allow_user_to_switch && IsActionview}
                            disabled={!IsActionview}
                            onChange={(event) => handleTikTokActions(profileId, profile, PostUtil.TikTokOptions.DISABLE_SWITCH)} />} label="Stitch" />
                    </FormGroup>
                </Stack>
                {!IsActionview && (
                    <Typography color="error" variant="body2">
                        Duet and Stitch are not available for videos longer than {postConfiguration.VIDEO.ACTIONMAXSECONDS} seconds.
                    </Typography>
                )}
            </Stack>
        )
    }
    return (
        <>
            {requestData &&
                Object.keys(requestData)?.length > 0 &&
                Object.keys(requestData).map((profileId) => {
                    let isExpanded = socialMediaRequestData.find(
                        (a) => a.connected_profile_id === parseInt(profileId)
                    )?.isExpanded;

                    let hasErrors = requestData[profileId].some((profile) => profile.errors && profile.errors.length > 0);
                    let borderColor = hasErrors ? theme.palette.error.main : theme.palette.text.border;
                    let connectedProfileLength = requestData[profileId].some(
                        (profile) =>
                            profile?.connected_profile_details?.connected_profile_name &&
                            profile?.connected_profile_details?.connected_profile_name.length > 10
                    );
                    let paddingTop = connectedProfileLength ? 0.5 : 0.5;

                    return (
                        <Accordion
                            expanded={manualExpand || hasErrors}
                            onChange={() => {
                                handleAccordion(profileId);
                            }}
                            style={{ ...NewPostStyledComponent.AccordionStyle, borderColor }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                    ...NewPostStyledComponent.AccordionSummary,
                                    paddingTop,
                                }}
                            >
                                <Grid container justifyContent="space-between">
                                    <Grid alignItems="center" display="flex">
                                        {CommonFunction.AccountBadgeWithName(
                                            requestData[profileId].find((profile) => profile.connected_profile_id.toString() === profileId)
                                                ?.connected_profile_details
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <FormGroup style={{ display: 'inline' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkPostTypeChecked(profileId, PostUtil.PostType.DIRECT_POST)}
                                                        onChange={() => handlePostChange(profileId, PostUtil.PostType.DIRECT_POST)}
                                                    />
                                                }
                                                label={
                                                    <NewPostStyledComponent.AccordianLabelStyles>
                                                        DIRECT POST
                                                    </NewPostStyledComponent.AccordianLabelStyles>
                                                }
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkPostTypeChecked(profileId, PostUtil.PostType.UPLOAD_TO_INBOX)}
                                                        onChange={() => handlePostChange(profileId, PostUtil.PostType.UPLOAD_TO_INBOX)}
                                                    />
                                                }
                                                label={
                                                    <NewPostStyledComponent.AccordianLabelStyles>
                                                        UPLOAD TO INBOX
                                                    </NewPostStyledComponent.AccordianLabelStyles>
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0.5 }}>
                                {requestData[profileId].map((profile) => {
                                    return (
                                        <>
                                            <Box
                                                style={{
                                                    ...NewPostStyledComponent.AccordionStyle,
                                                    borderColor:
                                                        profile.errors && profile.errors.length > 0
                                                            ? theme.palette.error.main
                                                            : theme.palette.background.paper,
                                                    padding: '0.5rem',
                                                    marginBottom: '0.5rem',
                                                }}
                                            >
                                                {profile.post_type === POST_TYPES[profile.post_type].value && (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            color: theme.palette.primary.main,
                                                            marginTop: 4,
                                                            fontSize: '0.875rem',
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        {POST_TYPES[profile.post_type].icon}
                                                        {POST_TYPES[profile.post_type].value.toUpperCase()}
                                                    </Box>
                                                )}
                                                <PostDescription
                                                    handleChangeDescription={(event) => {
                                                        handleChangeDescription(profileId, profile, event);
                                                    }}
                                                    valueDescription={profile.description}
                                                />
                                                {profile.media_data?.length > 0 && (
                                                    <Box style={{ marginTop: 3 }}>
                                                        <ImageDraggable
                                                            mediaFiles={profile.media_data}
                                                            handleRemoveFiles={(e) => {
                                                                handleRemoveFiles(profileId, profile.post_type, e);
                                                            }}
                                                            handleReOrderFiles={(e, k) => {
                                                                handleReOrderFiles(profileId, profile.post_type, e, k);
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                {PostUtil.PostType.DIRECT_POST === profile.post_type && <Grid container sx={{ display: 'flex', alignItems: 'center' }} xs={12}>
                                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                                        <Stack >
                                                            <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }}>Who can view this video</Typography>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    labelId="select-label"
                                                                    id="select"
                                                                    value={profile.privacy}
                                                                    onChange={(event) => {
                                                                        handleTiktokPrivacyType(profileId, profile, event.target.value);
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem value={PostUtil.TikTokPrivacyLevel.PUBLIC_TO_EVERYONE}>
                                                                        Public to Everyone
                                                                    </MenuItem>
                                                                    <MenuItem value={PostUtil.TikTokPrivacyLevel.MUTUAL_FOLLOW_FRIENDS}>
                                                                        Mutual Follow Friends
                                                                    </MenuItem>
                                                                    <MenuItem value={PostUtil.TikTokPrivacyLevel.FOLLOWER_OF_CREATOR}>
                                                                        Follower of Creator
                                                                    </MenuItem>
                                                                    <MenuItem value={PostUtil.TikTokPrivacyLevel.SELF_ONLY}>
                                                                        Self Only
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                                        {TikTokActionView(profileId, profile)}
                                                    </Grid>
                                                    <Grid item xs={12} mt={1}>
                                                        <Stack direction="row" alignItems="center" justifyContent="space-between" >
                                                            <Typography sx={{ fontWeight: 800 }}>Disclose video content</Typography>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={<NewPostStyledComponent.SwitchStyle checked={profile.disclosure_content}
                                                                        onChange={(event) => handleTikTokActions(profileId, profile, PostUtil.TikTokOptions.DISCLOSURE_CONTENT)}
                                                                    />}
                                                                />

                                                            </FormGroup>
                                                        </Stack>
                                                        <Typography>Enable to show paid promotion disclosure.</Typography>
                                                        {profile.disclosure_content &&
                                                            <>
                                                                <Stack direction="row" alignItems="center" sx={{ pt: 1, gap: 1 }}>
                                                                    <FormGroup >
                                                                        <Checkbox sx={{ p: 0 }} checked={profile.your_brand}
                                                                            onChange={(event) => handleTikTokActions(profileId, profile, PostUtil.TikTokOptions.BRAND)} />
                                                                    </FormGroup>
                                                                    <Typography sx={{ fontWeight: 800 }}>Your brand</Typography>
                                                                    <TiktokTooltip title="You are promoting yourself or your own business." placement="top">
                                                                        <InfoOutlinedIcon sx={{ fontSize: 'medium', color: 'text.secondary' }} />
                                                                    </TiktokTooltip>
                                                                </Stack>
                                                                <Stack direction="row" alignItems="center" sx={{ pt: 1, gap: 1 }}>
                                                                    <FormGroup >
                                                                        <Checkbox sx={{ p: 0 }} checked={profile.branded_content}
                                                                            disabled={profile.privacy === PostUtil.TikTokPrivacyLevel.MUTUAL_FOLLOW_FRIENDS}
                                                                            onChange={(event) => handleTikTokActions(profileId, profile, PostUtil.TikTokOptions.BRAND_CONTENT)} />
                                                                    </FormGroup>
                                                                    <Typography sx={{ fontWeight: 800 }}>Brand content</Typography>
                                                                    <TiktokTooltip title="You are in a paid partnership with a brand. After posting the video, open your TikTok mobile app and link the campaign under the video’s “Ad settings”." placement="top">
                                                                        <InfoOutlinedIcon sx={{ fontSize: 'medium', color: 'text.secondary' }} />
                                                                    </TiktokTooltip>
                                                                </Stack>
                                                            </>
                                                        }

                                                    </Grid>
                                                </Grid>}
                                                {false ? (
                                                    // checkAspectRatioChanges() ?
                                                    <AccordionDetails sx={{ padding: 2 }}>
                                                        <Typography sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
                                                            Additional Information
                                                        </Typography>
                                                        <Grid container sx={{ mt: 2, mb: 3, display: 'flex', alignItems: 'center' }} xs={12}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={profile.changeFacebookAspectRatio}
                                                                            onChange={(event) => handleChangeAspectRatio(PostUtil.ServiceName.FACEBOOK)}
                                                                        />
                                                                    }
                                                                    label="Change Aspect Ratio for video"
                                                                />
                                                            </FormGroup>
                                                        </Grid>
                                                        {profile.errors && profile.errors.length > 0 && errorView(profile)}
                                                    </AccordionDetails>
                                                ) : (
                                                    profile.errors && profile.errors.length > 0 && errorView(profile)
                                                )}
                                            </Box>
                                        </>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </>
    );
});
export default memo(TiktokConditionComponent);
