import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
// import ScheduledPostActionPopup from "./PostGridActionPopup.component";
import { DoneAll, HighlightOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ConfirmationDialog from "src/components/ConfirmationDialog";
import HttpServices from "src/services/httpService";
import { postsModuleURL, userModuleURL,imageModeuleUrl } from "src/services/urlService";
import { useToaster } from "src/utils/toaster/toasterContext";
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from "src/utils/commonHelper";
import PendingApprovalGridActionPopup from './pending-approval-grid-action-popup.component';

const PendingApprovaldGridAction = ({ approvePost, handleReCallPendingApprovalPost }) => {
    const theme = useTheme()
    const navigate = useNavigate();
    const { showToast } = useToaster();
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);

    const handleOpenMenu = (event, approvePost) => {
        setPopOverPost(approvePost);
        setPopoverElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const closeConfirmationDialogue = () => {
        setConfirmationDialogue(null)
    };

    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
        closeConfirmationDialogue()
        setLoading(false);
    };

    const fetchMediaMetaData = (data, editAsNew = false) => {
        let mediaBody = {
            media_urls: data?.media_url.split(",")
        };
        let postProps = {
            url: imageModeuleUrl.fetchMediaMetadata,
            body: mediaBody,
            successCallback: (imageData, message) => { fetchMediaSuccessCallback(imageData, message, data, editAsNew) },
            failureCallback: failureCallback
        }
        HttpServices.Post(postProps);
    }
    const fetchMediaSuccessCallback = (imageData, mesaage, data, editAsNew) => {
        if (editAsNew) {
            uploadEditDataToNewPost(data, imageData ? imageData.media_datas : []);
        }
        else {
            uploadDataToPost(data, imageData ? imageData.media_datas : []);
        }

    }
    const newPostSucessCallback = (data) => {
        if (data.media_url && data.media_url.length > 0)
            fetchMediaMetaData(data);
        else
            uploadDataToPost(data);
    }

    const editNewPostSucessCallback = (data) => {
        let editAsNew = true
        if (data.media_url && data.media_url.length > 0)
            fetchMediaMetaData(data, editAsNew);
        else
            uploadEditDataToNewPost(data);
    }

    const uploadDataToPost = (data, imageData = []) => {
        setLoading(false);
        const updatedData = {
            ...data,
            media_data: imageData,
            schedule_id: approvePost?.id
        };
        const newPostData = CommonHelper.CommonNewPostAddMediaAllService(updatedData, imageData)
        navigate('/new-post', { state: { data: newPostData } });
    }
    const uploadEditDataToNewPost = (data, imageData = []) => {
        setLoading(false);
        const newPostData = CommonHelper.CommonNewPostAddMediaAllService(data, imageData)
        navigate('/new-post', { state: { data: newPostData } });
    }
    const handleEditPost = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(approvePost.id, newPostSucessCallback, failureCallback)
    };
    const handleEditAsNewPost = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(approvePost.id, editNewPostSucessCallback, failureCallback)
    };

    const handleApprovePost = () => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Post Now",
            "message": "Are you sure you want to Approve this Schedule?",
            "submitFunction": confirmApprovePost
        })
    };

    const confirmApprovePost = () => {
        let props = {
            schedule_id: approvePost.id,
        };
        let postProps = {
            url: userModuleURL.approvePost,
            body: props,
            successCallback: approvePostSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const approvePostSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallPendingApprovalPost();
    };


    const handleDeletePost = () => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Deletion",
            "message": "Are you sure you want to delete this Schedule?",
            "submitFunction": confirmDeletePost
        })
    };

    const confirmDeletePost = () => {
        let postProps = {
            url: postsModuleURL.deleteSchedule + approvePost.id,
            successCallback: deleteSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
        closeConfirmationDialogue()
    };


    const deleteSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallPendingApprovalPost();
    };


    const handleSaveAsDraft = () => {
        let props = {
            schedule_post_id: approvePost.id,
        };
        let postProps = {
            url: postsModuleURL.savePostAsDraft,
            body: props,
            successCallback: saveAsDraftSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const saveAsDraftSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
    };

    const handleRejectPost = () => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Deletion",
            "message": "Are you sure you want to Reject this Schedule?",
            "submitFunction": confirmRejectPost
        })

    };

    const confirmRejectPost = (data, message) => {
        let props = {
            schedule_id: approvePost.id,
        };
        let postProps = {
            url: userModuleURL.rejectPost,
            body: props,
            successCallback: unapproveSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    }

    const unapproveSuccessCallback = (data, message) => {
        message && showToast(message,'s');
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallPendingApprovalPost();
    };



    const postActionProps = {
        popoverElement: popoverElement,
        popoverElement,
        handleCloseMenu,
        approvePostDetails :approvePost,
        approvePost: handleApprovePost,
        editAsNewPost: handleEditAsNewPost,
        editPost: handleEditPost,
        rejectPost: handleRejectPost,
        saveAsDraft: handleSaveAsDraft,
        deletePost: handleDeletePost,
        loading,
        selectedPopOverPost: selectedPopOverPost,
    };



    return (
        <>
            <Box  align='center'>
                {approvePost.has_approve_access &&<Tooltip title="Approve Post">
                    <IconButton
                        size="large"
                        color="inherit"
                        sx={{
                            padding: '2px',
                            marginRight: '2px',
                            color: theme.palette.primary.main // Use the primary color from the theme
                        }}
                        onClick={(e) => {
                            handleApprovePost(e, approvePost);
                        }}
                    >
                        <DoneAll />
                    </IconButton>
                </Tooltip>}
                {approvePost.has_approve_access && <Tooltip title="Reject Post">
                    <IconButton
                        size="large"
                        color="inherit"
                        sx={{
                            padding: '2px',
                            marginRight: '2px',
                            color: theme.palette.error.main // Use the error color from the theme
                        }}
                        onClick={(e) => {
                            handleRejectPost(e, approvePost);
                        }}
                    >
                        <HighlightOff />
                    </IconButton>
                </Tooltip>}
                <IconButton
                    size="large"
                    color="inherit"
                    onClick={(e) => {
                        handleOpenMenu(e, approvePost);
                    }}
                    sx={{ padding: '6px', marginRight: '2px' }}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>
            <PendingApprovalGridActionPopup  {...postActionProps} />
            {confirmationDialogue && <ConfirmationDialog
                open={confirmationDialogue.isOpen}
                onClose={() => closeConfirmationDialogue()}
                onConfirm={confirmationDialogue.submitFunction}
                title={confirmationDialogue.title}
                message={confirmationDialogue.message}
            />}
        </>
    )

}

export default PendingApprovaldGridAction;