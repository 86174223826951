import { useEffect, useState, useRef, useCallback } from 'react';
import ChatMessageItem from './ChatMessageItem';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useTheme,Stack } from '@mui/material';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';

export default function ChatMessageList({ conversation, scrollHeightBeforeLoad, handleScrollHeightBeforeLoad, handleNextMessage, isNextChatMessageLoading, handleDeleteMessage, userId, userPic }) {
  const scrollRef = useRef(null);
  const theme = useTheme();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [reversedMessages, setReversedMessages] = useState([]);

  useEffect(() => {
    setReversedMessages([]);
    if (conversation?.messages) {
      setReversedMessages([...conversation.messages].reverse());
    }
  }, [conversation]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [reversedMessages]);

  // Preserve scroll position after loading more messages
  useEffect(() => {
    if (isNextChatMessageLoading) {
      handleScrollHeightBeforeLoad && handleScrollHeightBeforeLoad(scrollRef.current?.scrollHeight || 0);
    } else if (scrollRef.current && scrollHeightBeforeLoad > 0) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight - scrollHeightBeforeLoad;
    }
  }, [isNextChatMessageLoading, reversedMessages]);

  // Function to handle automatic loading of more messages when scrolled to top
  const handleScroll = useCallback(() => {
    if (scrollRef.current.scrollTop === 0 && !isNextChatMessageLoading) {
      handleNextMessage(conversation.pagination);
    }
  }, [handleNextMessage, conversation.pagination, isNextChatMessageLoading]);

  // Attach the scroll event listener
  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleOpenLightbox = (url) => {
    const imagesLightbox = conversation?.messages
      .filter((message) => message.contentType === 'image')
      .map((message) => message.body);
    const selectedImageIndex = imagesLightbox.findIndex((image) => image === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImageIndex);
  };

  return (
    <>
      {isNextChatMessageLoading && (
        <Stack display="flex" alignItems="center" justifyContent="center">
          <ThreeDotLoader justifyContent="center" display="" height="" sx={{ ml: 2}} />
        </Stack>
      )}
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
        {reversedMessages.map((message) => (
          <ChatMessageItem
            key={message.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
            handleDeleteMessage={handleDeleteMessage}
            userId={userId}
            userPic={userPic}
          />
        ))}
      </Scrollbar>
    </>
  );
}
