const FacebookRedirect = () => {
    const popupWindowURL = new URL(window.location.href);
    const code = popupWindowURL.searchParams.get("code");
    const error = popupWindowURL.searchParams.get("error");
    const error_description = popupWindowURL.searchParams.get("error_description");
    if (code) {
        localStorage.setItem("fb_autho_code", `${code}`);
        window.close();
    }
    else if (error) {
        localStorage.setItem("fb_error", `${error_description}`);
        window.close();
    }
    return null;
}
export default FacebookRedirect;