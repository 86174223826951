import { Cancel, Delete, Edit, PostAdd, Save, } from '@mui/icons-material';
import { MenuItem, Popover,Box ,CircularProgress} from '@mui/material';

const EllapsedPostGridActionPopup = ({
    popoverElement,
    handleCloseMenu,
    saveAsDraft,
    editPost,
    ellapsedPost,
    editAsNewPost,
    deletePost,
    loading,
}) => {
    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: { p: 1, width: 170, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
            }}
        >
            {' '}
            {loading ? (
                   <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                   <CircularProgress size={24} />
               </Box>
                ) : 
                <>
            {ellapsedPost?.has_edit_access && <MenuItem sx={{ color: 'info.main' }} onClick={editPost}>
                <Edit sx={{ mr: 1, color: 'info.main' }} /> Edit Post
            </MenuItem>}
            <MenuItem sx={{ color: 'info.main' }} onClick={editAsNewPost}>
                <PostAdd sx={{ mr: 1, color: 'info.main' }} /> Edit As New Post
            </MenuItem>
            <MenuItem sx={{ color: 'warning.main' }} onClick={saveAsDraft}>
                <Save sx={{ mr: 1, color: 'warning.main' }} /> Save As Draft
            </MenuItem>
            {ellapsedPost?.has_delete_access && <MenuItem sx={{ color: 'error.main' }} onClick={deletePost}>
                <Delete sx={{ mr: 1, color: 'error.main' }} /> Delete Post
            </MenuItem>}
            </>}
        </Popover>
    );
};
export default EllapsedPostGridActionPopup;
