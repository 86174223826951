import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import ScheduledHeaderReportComponent from 'src/components/reports/scheduled-report-header.component';

const ScheduledReport = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();

  // State Variables
  const [connectedProfileId, setConnectedProfileId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [viewBy, setViewBy] = useState('day');
  const [subReportId, setSubReportId] = useState('');
  const [reportId, setReportId] = useState('');
  const [loading, setLoading] = useState(true);
  const [serviceName, setServiceName] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const serviceName = searchParams.get('service_name');
    const profileId = searchParams.get('connected_profile_id');
    const startDateParam = searchParams.get('from');
    const endDateParam = searchParams.get('to');
    const viewByParam = searchParams.get('view_by');
    const subTypeIdParam = searchParams.get('sub_report_id');
    const reportIdParam = searchParams.get('report_id');

    // Set state based on URL parameters
    if (serviceName) setServiceName(serviceName);
    if (profileId) setConnectedProfileId(parseInt(profileId, 10));
    if (startDateParam) setStartDate(new Date(startDateParam));
    if (endDateParam) setEndDate(new Date(endDateParam));
    if (viewByParam) setViewBy(viewByParam);
    if (reportIdParam) setReportId(reportIdParam);
    if (subTypeIdParam) setSubReportId(subTypeIdParam);

    // Set loading to false only when all required parameters are set
    if (profileId && startDateParam && endDateParam) {
      setLoading(false);
    }
  }, [location.search]);

  const renderComponent = () => {
    // Check if all required parameters are set
    if (loading) {
      return <Typography variant="h6">Loading report data...</Typography>;
    } else {
      return (
        <ScheduledHeaderReportComponent
          connectedProfileId={connectedProfileId}
          startDate={startDate}
          endDate={endDate}
          viewBy={viewBy}
          reportId={reportId}
          subReportId={subReportId}
          serviceName={serviceName}
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Scheduled Reports</title>
      </Helmet>
      <Box>
        {renderComponent()}
      </Box>
    </>
  );
};

export default ScheduledReport;
