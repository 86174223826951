import { useEffect, useState } from "react";
import TiktokDirectPostPreview from "./tiktok-direct-post-preview.component";
import TiktokUploadToInboxPostPreview from "./tiktok-upload-to-inbox-post-preview.component";
import { CommonHelper } from "src/utils/commonHelper";

const TiktokPreviewOrganize = ({ postType, previewData, profileId }) => {
    const [data, setData] = useState({});
    useEffect(() => {
        if (!previewData || !profileId) return;
        let media_data = CommonHelper.ProcessMediaUrl("https://qa.brand2social.com/api/image-server/fetch-media?path=d6d6cca4bf41672ded701c82592e90profile-picture.jpeg,https://qa.brand2social.com/api/image-server/fetch-media?path=d6d6cca4bf41672ded701c82592e90profile-picture.jpeg,https://qa.brand2social.com/api/image-server/fetch-media?path=d6d6cca4bf41672ded701c82592e90profile-picture.jpeg")
        let data = previewData[profileId].data
        for (var i = 0; i < data.length; i++) {
            if (data[i].post_type === postType) {
                data[i].media_data = CommonHelper.ProcessMediaUrl(data[i].media_url)
                setData(data[i])
            }
        }

    }, [postType, previewData, profileId]);


    return (
        <>
            {data && data?.post_type === "DIRECT_POST" && <TiktokDirectPostPreview data={data} />}
            {data && data?.post_type === "UPLOAD_TO_INBOX" && <TiktokUploadToInboxPostPreview data={data} />}
        </>
    );
};


export default TiktokPreviewOrganize;