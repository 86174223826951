import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { NavigateBefore, NavigateNext, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
}));

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
});

const Dot = styled('div')(({ theme, active }) => ({
  width: active ? '8px' : '6px',
  height: active ? '8px' : '6px',
  borderRadius: '50%',
  margin: '0 2px',
  backgroundColor: active ? theme.palette.common.white : theme.palette.preview.postcard.dotcolor,
}));

const DotsContainer = styled('div')({
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const InstagramCarouselPost = (mediaFiles) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {});
      setShowControls(true);
    }
  };

  const handleVideoPlay = () => {
    setVideoPlaying(true);
  };
  const handleVideoPause = () => {
    setVideoPlaying(false);
  };

  useEffect(() => {
    if (currentIndex >= mediaFiles.length) {
      setCurrentIndex(mediaFiles.length - 1);
    }
  }, [currentIndex, mediaFiles.length]);

  const previousImage = () => {
    setCurrentIndex(currentIndex === 0 ? mediaFiles.length - 1 : currentIndex - 1);
  };

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % mediaFiles.length);
  };

  const renderMedia = (media) => {
    if (media === undefined) return;
    if (media.type === 'image') {
      return (
        <img
          src={media.url}
          alt={media.url}
          width={350}
          height={250}
          style={{ objectFit: 'cover', borderRadius: '5px' }}
        />
      );
    } else if (media.type === 'video') {
      return (
        <Box style={{ position: 'relative' }}>
          <video
            preload="auto"
            crossOrigin="anonymous"
            src={media.url}
            width={350}
            height={250}
            ref={videoRef}
            controls={showControls}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            style={{ objectFit: 'cover', borderRadius: '5px' }}
          />
          {!isVideoPlaying && (
            <>
              <PlayButton onClick={handlePlayButtonClick}>
                <PlayArrowIcon sx={{ fontSize: 40 }} />
              </PlayButton>
              <GradientOverlay />
            </>
          )}
        </Box>
      );
    } else {
      return <img src={media} alt={media} width={350} height={250} style={{ objectFit: 'cover' }} />;
    }
  };

  return (
    <Grid
      container
      sx={{
        maxWidth: 500,
        maxHeight: 260,
        overflow: 'hidden',
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {mediaFiles.length === 1 ? (
        renderMedia(mediaFiles[0])
      ) : (
        <Box sx={{ position: 'relative' }}>
          {renderMedia(mediaFiles[currentIndex])}
          <NavigateBefore
            onClick={previousImage}
            sx={{
              position: 'absolute',
              zIndex: 1,
              left: 20,
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'white',
              boxShadow: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 0,
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
            }}
          />
          <NavigateNext
            onClick={nextImage}
            sx={{
              position: 'absolute',
              zIndex: 1,
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'white',
              boxShadow: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 0,
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
            }}
          />
          <DotsContainer>
            {mediaFiles.map((_, index) => (
              <Dot key={index} active={index === currentIndex} />
            ))}
          </DotsContainer>
        </Box>
      )}
    </Grid>
  );
};

export default InstagramCarouselPost;
