import { useEffect } from "react";
import { useState } from "react";
import TiktokPostImagesPreview from "./titkok-post-image-preview.component";
import TiktokVideoPreview from "./tiktok-post-video-preview.component";
import { PostUtil } from "src/pages/new-post-new/post-util";

const TiktokDirectPostPreview = ({ data }) => {
    const [requestData, setRequestData] = useState({});
    const [isVideo, setIsVideo] = useState(false);
    useEffect(() => {
        if (!data) return;
        if (data?.media_data?.count == 1 && data?.media_data.media_data[0].type === PostUtil.MediaType.VIDEO) {
            setIsVideo(true)
        }

    }, [data]);
    return (
        <>
            {isVideo ?
                <TiktokVideoPreview data={data}> </TiktokVideoPreview>
                :
                <TiktokPostImagesPreview data={data}></TiktokPostImagesPreview>}

        </>
    );
};

export default TiktokDirectPostPreview;
