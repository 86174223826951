import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useState } from 'react';
import { useToaster } from 'src/utils/toaster/toasterContext';

export default function ResetPasswordForm({ onSent, onGetEmail }) {
    const [isSubmitting, setSubmit] = useState(false);
    const { showToast } = useToaster();
    const ResetPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const methods = useForm({
        resolver: yupResolver(ResetPasswordSchema),
        defaultValues: { email: '' },
    });

    const { handleSubmit } = methods;

    const handleResetPasswordSuccess = (data, message) => {
        message && showToast(message);
        onSent();
        onGetEmail(data.email);
    }

    const handleResetPasswordFailure = (message) => {
        message && showToast(message);
        setSubmit(false);
    }

    const onSubmit = async (data) => {
        setSubmit(true);
        let requestModel = {
            email: data.email
        };
        let postProps = {
            url: userModuleURL.resetPassword,
            body: requestModel,
            successCallback: handleResetPasswordSuccess,
            failureCallback: handleResetPasswordFailure
        }
        HttpServices.Post(postProps);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <RHFTextField name="email" label="Email address" />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Reset Password
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
