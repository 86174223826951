import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import HttpServices from "src/services/httpService";
import { userModuleURL } from "src/services/urlService";
import Spinner from "src/utils/Loader/spinner";
import { useToaster } from "src/utils/toaster/toasterContext";
import RejectedApprovalGrid from "./rejected-post-grid.component";

const RejectedApproval = () => {
    const [loader, setLoader] = useState(true);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const [rejectedApprovalData, setRejectedApprovalData] = useState([]);
    const { showToast } = useToaster();

    useEffect(() => {
        getRejectedApprovals("scheduled_time", "asc");
    }, [])
    const getRejectedApprovals = (sortBy = "", sortOrder = "") => {
        setOrder(sortOrder)
        setOrderBy(sortBy)
        let getProps = {
            url: userModuleURL.getRejectedApprovals + `?sort_by=${sortBy}&sort_order=${sortOrder}`,
            successCallback: getRejectedApprovalSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }
    const getRejectedApprovalSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setRejectedApprovalData(data);
        setLoader(false);
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }
    const handleReCallRejectedApprovalPost = (sortBy = "", sortOrder = "") => {
        setLoader(true);
        getRejectedApprovals(sortBy, sortOrder);
    }
    return (
        <>
            {loader ?
                <Spinner />
                :
                <Card>
                    <RejectedApprovalGrid 
                    rejectedApprovalList={rejectedApprovalData} 
                    orderBy={orderBy}
                    order={order}
                    handleReCallRejectedApprovalPost={handleReCallRejectedApprovalPost}
                    />
                </Card>
            }
        </>
    );
}
export default RejectedApproval;