import {
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  IconButton,
  SvgIcon,
  useTheme,
  Divider,
  Stack,
  Paper,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FavoriteBorderRounded as FavoriteBorderRoundedIcon,
  Public as PublicIcon,
  PlayArrow as PlayArrowIcon,
  MoreHoriz as MoreHorizIcon,
  FiberManualRecord as FiberManualRecordIcon,
  BookmarkBorder as BookmarkBorderIcon,
} from '@mui/icons-material';
import { useRef, useState, useEffect } from 'react';
import Instagramshare from '../../../../assets/instagram-share-icon-white.svg';

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
});

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
}));

const StaticLine1 = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 10,
  left: 6,
  width: '70%',
  height: '4px',
  backgroundColor: 'white',
  zIndex: 2,
  borderRadius: '3px',
}));

const StaticLine2 = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 6,
  width: '30%',
  height: '4px',
  backgroundColor: 'gray',
  zIndex: 2,
  borderRadius: '3px',
}));

const InstagramStoryPreview = ({ data }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [isStoryPlaying, setStoryPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const ConnectedProfiles = data.connected_profile_details;

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {});
      setShowControls(true);
    }
  };

  const handleVideoPlay = () => {
    setStoryPlaying(true);
  };
  const handleVideoPause = () => {
    setStoryPlaying(false);
  };
  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card sx={{ height: 460, width: 300, maxWidth: 500, minWidth: respwidth ? 300 : 200, borderRadius: '8px' }}>
          <StaticLine2 />
          <StaticLine1 />
          <video
            preload="auto"
            crossOrigin="anonymous"
            src={data.media_url}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              position: 'absolute',
              borderRadius: '10px',
            }}
            ref={videoRef}
            controls={showControls}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
          />
          {!isStoryPlaying && (
            <PlayButton onClick={handlePlayButtonClick}>
              <PlayArrowIcon sx={{ fontSize: 40 }} />
            </PlayButton>
          )}
          <GradientOverlay />
          <Box
            sx={{
              position: 'absolute',
              top: '18px',
              width: 'calc(100% - 62px)',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '18px',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.7rem',
                marginTop: '8px',
                color: '#fff',
              }}
            >
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 35,
                  width: 35,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
              <Typography
                variant="body2"
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                  color: 'white',
                }}
              >
                {data?.connected_profile_details?.connected_profile_name}
              </Typography>
              <FiberManualRecordIcon sx={{ fontSize: 5 }} />
              <PublicIcon sx={{ fontSize: 15, mr: 1 }} />
            </Box>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '16px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: '0.2rem',
              padding: 0.8,
            }}
          >
            <Typography
              sx={{
                height: 'auto',
                width: '100%',
                border: 'solid 1px',
                borderColor: theme.palette.preview.postcard.color,
                padding: '4px',
                borderRadius: '20px',
                paddingRight: '6rem',
                color: 'white',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            >
              {data.description ? data.description : 'send message'}
            </Typography>
            <FavoriteBorderRoundedIcon sx={{ color: 'white' }} />
            <img
              src={Instagramshare}
              style={{
                height: 'auto',
                width: '100%',
                maxWidth: '20px',
                maxHeight: '20px',
                color: 'white',
              }}
            />
          </Box>
        </Card>
        {/* <StyledCard>
          <Box sx={{ position: 'relative', top: '12px', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
            <Box
              sx={{
                position: 'relative',
                width: 'auto',
                height: '460px',
              }}
            >
              <StaticLine2 />
              <StaticLine1 />
              <video
                preload="auto"
                crossOrigin="anonymous"
                src={data.media_url}
                width="100%"
                height="100%"
                style={{
                  objectFit: 'cover',
                  position: 'absolute',
                  borderRadius: '10px',
                }}
                ref={videoRef}
                controls={showControls}
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
              />
              {!isStoryPlaying && (
                <PlayButton onClick={handlePlayButtonClick}>
                  <PlayArrowIcon sx={{ fontSize: 40 }} />
                </PlayButton>
              )}
              <GradientOverlay />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '18px',
                width: 'calc(100% - 62px)',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '18px',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.7rem',
                  marginTop: '8px',
                  color: '#fff',
                }}
              >
                <Avatar
                  src={
                    ConnectedProfiles.connected_profile_image
                      ? ConnectedProfiles.connected_profile_image
                      : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                  }
                  alt={
                    ConnectedProfiles.connected_profile_name
                      ? ConnectedProfiles.connected_profile_name
                      : ConnectedProfiles.connected_profile_id
                  }
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: 35,
                    width: 35,
                    bgcolor: theme.palette.primary.main,
                  }}
                  style={{ color: 'white' }}
                />
                <Typography variant="body2">{data?.connected_profile_details?.connected_profile_name}</Typography>
                <FiberManualRecordIcon sx={{ fontSize: 5 }} />
                <PublicIcon sx={{ fontSize: 15, mr: 1 }} />
              </Box>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: '16px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  height: 'auto',
                  width: '100%',
                  border: 'solid 1px',
                  borderColor: theme.palette.preview.postcard.color,
                  marginRight: '1rem',
                  marginLeft: '1rem',
                  padding: '2px 10px',
                  borderRadius: '20px',
                  paddingRight: '6rem',
                  color: 'white',
                }}
              >
                send message
              </Typography>
              <FavoriteBorderRoundedIcon sx={{ color: 'white', marginRight: '1rem' }} />
              <img
                src={Instagramshare}
                style={{
                  height: 'auto',
                  width: '100%',
                  maxWidth: '20px',
                  maxHeight: '20px',
                  color: 'white',
                }}
              />
            </Box>
          </Box>
        </StyledCard> */}
      </Stack>
    </>
  );
};

export default InstagramStoryPreview;
