const GoogleRedirect = () => {
    const popupWindowURL = new URL(window.location.href);
    const urlParams = new URLSearchParams(popupWindowURL.search);
    var code = urlParams.get("code");
    var error = urlParams.get("error");
    var error_description = urlParams.get("error_description");
    if (code) {
        localStorage.setItem("google_auth_code", `${code}`);
        window.close();
    }
    else if (error) {
        localStorage.setItem("google_signin_error", `${error_description}`);
        window.close();
    }
}
export default GoogleRedirect;