import { useState, useEffect, useRef } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Drawer, IconButton } from '@mui/material';
import ChatConversationList from './ChatConversationList';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import useResponsive from 'src/hooks/useResponsive';
import GroupIcon from '@mui/icons-material/Group';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent'; // Ensure correct import

const ToggleButtonStyle = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.darker,
  },
}));

const SIDEBAR_WIDTH = 320;
const SIDEBAR_COLLAPSE_WIDTH = 96;

export default function ChatSidebar({ chatConversation, selectedConversation, handleConversation, isNextConversationLoading, handleNextConversation, userId, userPic, icon }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  const [openSidebar, setOpenSidebar] = useState(true);
  const scrollRef = useRef(null);

  // Scroll handler to load more messages
  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isAtBottom = scrollHeight - scrollTop <= clientHeight + 5; // Check if scrolled to the bottom

      if (isAtBottom && !isNextConversationLoading) {
        handleNextConversation(); // Fetch more messages when scrolled to the bottom
      }
    }
  };

  useEffect(() => {
    const ref = scrollRef.current;
    if (ref) {
      ref.addEventListener('scroll', handleScroll);
      return () => {
        ref.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isNextConversationLoading]); // Dependency to rerun effect if loading state changes

  const renderContent = (
    <>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
        <ChatConversationList
          chatConversation={chatConversation}
          isOpenSidebar={openSidebar}
          selectedConversation={selectedConversation}
          handleConversation={handleConversation}
          userPic={userPic}
          userId={userId}
          icon={icon}
        />
      </Scrollbar>
      {isNextConversationLoading && (
        <Stack display="flex" alignItems="center" justifyContent="center">
          <ThreeDotLoader height="" />
        </Stack>
      )}
    </>
  );

  return (
    <>
      {!isDesktop && (
        <ToggleButtonStyle onClick={() => setOpenSidebar((prev) => !prev)}>
          <GroupIcon sx={{ height: "18px", width: "18px" }} />
        </ToggleButtonStyle>
      )}
      <Drawer
        open={openSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        sx={{
          width: SIDEBAR_WIDTH,
          transition: theme.transitions.create('width'),
          '& .MuiDrawer-paper': {
            position: 'static',
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {renderContent}
      </Drawer>
    </>
  );
}
