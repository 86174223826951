import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'src/utils/storage/local-storage';
import InitializeGoogleTagManager from 'src/utils/tagManagerInitialize';
import WelcomeImage from "../../assets/app-image/welcome.svg";

const StyledContent = styled('div')(({ theme }) => ({
    minWidth: 600,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
}));

const WelcomePageComponent = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            let userDetails = LocalStorage.getItem('user_details');
            let userId = null;
            if (userDetails) {
                let userInfo = JSON.parse(userDetails);
                if (userInfo && userInfo.id)
                    userId = userInfo.id;
            }
            if (userId) {
                InitializeGoogleTagManager();
                window.location.href = window.location.origin;
            }
            else
                navigate("/login");
        }, 3000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <Helmet>
                <title> Welcome </title>
            </Helmet>
            <Dialog aria-labelledby="customized-dialog-title" open maxWidth="xl">
                <DialogContent dividers>
                    <StyledContent sx={{ textAlign: 'center', alignItems: 'center', mt: 5 }}>
                        <Typography variant="h3" paragraph>
                            Welcome!
                        </Typography>

                        <Typography sx={{ color: 'text.secondary' }}>
                            Brand2Social, A Social Media Scheduler
                        </Typography>

                        <Box
                            component="img"
                            src={WelcomeImage}
                        />
                    </StyledContent>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default WelcomePageComponent;
