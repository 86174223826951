import Drawer from '@mui/material/Drawer';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

const CancelButton = styled('div')({
    position: 'absolute',
    top: '12px',
    left: '-50px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50px',
    width: '35px',
    height: '35px',
    textAlign: 'center',
    padding: '12px 0',
    fontSize: '12px',
    '@media (min-width: 768px)': {
        fontSize: '0.86rem',
    },
    boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.3)',
    color: 'rgba(0, 0, 0, 0.5)',
    cursor: 'pointer',
    fontWeight: 'bold',
});

const RightSideBar = ({ handleCloseRightSidebar, handleContent,sidebarWidth }) => {
    const drawerStyle = {
        width: sidebarWidth,
        '& .MuiDrawer-paper': {
            width: sidebarWidth,
        },
    };
    return (<Drawer
        anchor={'right'}
        open
        sx={drawerStyle}
        onClose={handleCloseRightSidebar}
    >
        <CancelButton onClick={handleCloseRightSidebar}>
            <CancelIcon />
        </CancelButton>
        {handleContent()}
    </Drawer >);
}
export default RightSideBar;