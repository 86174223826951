import { useState } from "react";
import { useEffect } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { Config } from "src/config";
import { useToaster } from "src/utils/toaster/toasterContext";
import LinkedInPageAuthorizationModal from "../linkedinIntegration/linkedinPageAuthorizationModal";
// var openedWindow;
const LinkedInPageReAuthenticate = ({ AccountId, handleClose, getConnectedNetworks }) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [pages, setPages] = useState([]);
    const [profileId, setProfileId] = useState("");
    const [selectedPages, setSelectedPages] = useState([]);
    const { showToast } = useToaster();
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthCodeReceivedFromLinkedIn();
        }, Config.Common.socialAccountsIntegrationInterval);
        return (() => {
            clearInterval(intervalId);
        })
    }, [])

    const getLinkedPageOauthToken = () => {
        window.open(
            `${Config.LinkedIn.authorizeUrl}?response_type=code&client_id=${Config.LinkedIn.clientId}&scope=${Config.LinkedIn.scope}&state=${Config.LinkedIn.state}&redirect_uri=${Config.LinkedIn.redirectUri}`, "_blank"
        );
    };

    const checkAuthCodeReceivedFromLinkedIn = () => {
        var code = localStorage.getItem("linkedin_code");
        var error = localStorage.getItem("linkedin_error");
        if (code) {
            localStorage.removeItem("linkedin_code");
            clearInterval(intervalId);
            getLinkedInPages(code);
        }
        else if (error) {
            localStorage.removeItem("linkedin_error");
            clearInterval(intervalId);
            showToast(error, 'e');
            handleClose();
        }
    };
    const linkedPagesSuccessCallback = (data, message) => {
        showToast(message, 's');
        getConnectedNetworks();
        handleClose();
    }
    const linkedPagesFailureCallback = (message) => {
        showToast(message, 's');
        handleClose();
    }
    const getLinkedInPages = (code) => {
        let linkedInForm = {
            code,
            account_id:AccountId
        };
        let postProps = {
            url: integrateModuleURL.reAuthenticateLinkedInPages,
            body: linkedInForm,
            successCallback: linkedPagesSuccessCallback,
            failureCallback: linkedPagesFailureCallback
        }
        HttpServices.Post(postProps);
    };

   
    return (
        <>
            {isAuthorizationModalOpen &&
                <LinkedInPageAuthorizationModal
                    handleConnectLinkedInPage={getLinkedPageOauthToken}
                    handleClose={handleClose}
                />}
        </>
    )
};
export default LinkedInPageReAuthenticate;
