import { useRef, useState, useEffect, memo, forwardRef, useImperativeHandle } from 'react';
import { Box, TableContainer, Card } from '@mui/material';
import FacebookConditionComponent from './facebook-condition.component';
import InstagramConditionComponent from './instagram-condition.component';
import TwitterConditionComponent from './twitter-condition.component';
import YoutubeConditionComponent from './youtube-condition.component';
import LinkedInConditionComponent from './linkedin-condition.component';
import GBusinessConditionComponent from './googleMyBusiness-condition.component';
import PinterstConditionComponent from './pinterst-condition.component';
import TiktokConditionComponent from './tiktok-condition.component';

const PostConditionComponent = forwardRef(
  (
    {
      updateRequestData,
      handleConditionErrors,
      googleMyBusinessConditionRef,
      tiktokConditionRef,
      linkedInConditionRef,
      youtubeConditionRef,
      twitterConditionRef,
      instagramConditionRef,
      facebookConditionRef,
      pinterestConditionRef,
    },
    ref
  ) => {
    const [height, setHeight] = useState(window.innerHeight - 200);
    return (
      <Card style={{ boxShadow: 'none', borderRadius: 1 }}>
        {/* {newPostForm?.scheduled_event_requests_data?.facebook_requests_data?.length > 0 && ( */}
        <FacebookConditionComponent ref={facebookConditionRef} updateRequestData={updateRequestData} />
        {/* requestData={newPostForm.scheduled_event_requests_data.facebook_requests_data}
           handleConditionErrors={handleConditionErrors} */}
        <InstagramConditionComponent ref={instagramConditionRef} updateRequestData={updateRequestData} />
        <TwitterConditionComponent ref={twitterConditionRef} updateRequestData={updateRequestData} />
        <YoutubeConditionComponent ref={youtubeConditionRef} updateRequestData={updateRequestData} />
        <LinkedInConditionComponent ref={linkedInConditionRef} updateRequestData={updateRequestData} />
        <GBusinessConditionComponent ref={googleMyBusinessConditionRef} updateRequestData={updateRequestData} />
        <PinterstConditionComponent ref={pinterestConditionRef} updateRequestData={updateRequestData} />
        <TiktokConditionComponent ref={tiktokConditionRef} updateRequestData={updateRequestData} />
        {/* )} */}
        {/* {newPostForm.scheduled_event_requests_data.instagram_requests_data?.length > 0 && (
        <InstagramConditionComponent
          ref={instagramConditionRef}
          newPostForm={newPostForm}
          handleConditionErrors={handleConditionErrors}
        />
      )}
      {newPostForm.scheduled_event_requests_data.twitter_requests_data?.length > 0 && (
        <TwitterConditionComponent
          ref={twitterConditionRef}
          newPostForm={newPostForm}
          handleConditionErrors={handleConditionErrors}
        />
      )}
      {newPostForm.scheduled_event_requests_data.youtube_requests_data?.length > 0 && (
        <YoutubeConditionComponent
          ref={youtubeConditionRef}
          newPostForm={newPostForm}
          handleConditionErrors={handleConditionErrors}
        />
      )}
      {newPostForm.scheduled_event_requests_data.linkedin_requests_data?.length > 0 && (
        <LinkedInConditionComponent
          ref={linkedInConditionRef}
          newPostForm={newPostForm}
          handleConditionErrors={handleConditionErrors}
        />
      )}
      {newPostForm.scheduled_event_requests_data.gbusiness_requests_data?.length > 0 && (
        <GBusinessConditionComponent
          ref={googleMyBusinessConditionRef}
          newPostForm={newPostForm}
          handleConditionErrors={handleConditionErrors}
        />
      )} */}
      </Card>
    );
  }
);

export default PostConditionComponent;
