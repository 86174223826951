import React, { useState } from 'react';
import {
    Avatar, Badge, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar,
    ListItemButton, ListItemText, Typography, useTheme
} from "@mui/material";
import { styled } from '@mui/material/styles';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import LoadFacebookSdk from '../loadFacebookSdk';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FacebookPageAuthorizationModal from './facebookPageAuthorizationModal';
import Facebook from '../../../assets/facebook.svg'
import { Config } from 'src/config';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { CommonHelper } from 'src/utils/commonHelper';
import ThreeDotLoader from "src/utils/Loader/threeDotLoader.compontent";

const FacebookPageIntegration = (props) => {
    const theme = useTheme();
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isFbAccountModalOpen, setFbAccountModal] = useState(false);
    const [isNoAccountForIntegrate, setNoAccountIntegrated] = useState(false);
    const [pages, setPages] = useState([]);
    const [profileId, setProfileId] = useState("");
    const [selectedPages, setSelectedPages] = useState([]);
    const [loaderReplies, setLoaderReplies] = useState(false);
    const [pagination, setPagination] = useState("");
    const [isLoadSdk, setLoadSdk] = useState(false);
    const { showToast } = useToaster();
    const [isLoading, setLoading] = useState(false);
    const isCustomDomain = CommonHelper.CustomDomain();
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthoCodeReceivedFromFacebook();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleMessage = (event) => {
        const { type, code } = event.data;
        if (type === "fb_auth_code" && code) {
            setAuthorizationModal(false);
            setLoadSdk(true);
            getFacebookPagesAndGroups(code);
        }
    };

    const checkAuthoCodeReceivedFromFacebook = () => {
        window.addEventListener("message", handleMessage, false);
    };

    const fbPagesAndGroupsSuccessCallback = (data, message) => {
        if (data.accounts &&
            data.accounts.data &&
            data.accounts.data.length > 0) {
            let isNoAccountForIntegrate = !data.accounts.data.some(a => !a.is_integrated);
            setNoAccountIntegrated(isNoAccountForIntegrate);
            setAuthorizationModal(false);
            setFbAccountModal(true);
            setProfileId(data.id)
            setPages(data.accounts.data);
            setPagination(data.pagination)
        }
    }
    const fbNextPagesSuccessCallback = (data, message) => {
        if (data.accounts &&
            data.accounts.data &&
            data.accounts.data.length > 0) {
            let newAccounts = data.accounts.data.filter(
                (newAccount) => !pages.some((existingPage) => existingPage.id === newAccount.id)
            );
            let updatedPages = [...pages, ...newAccounts];
            setPages(updatedPages);
            if (pagination && pagination != data.pagination) {
                setPagination(data.pagination)
            }
            else {
                setPagination('')
            }
        }
        else {
            setPagination('')
        }
        setLoaderReplies(false)
    }

    const fetchNextPagesFromServer = () => {
        setLoaderReplies(true)
        let form = {
            temp_connected_profile_id: profileId,
            pagination: pagination,
        };
        let postProps = {
            url: integrateModuleURL.fetchNextFacebookPagesAndGroups,
            body: form,
            successCallback: fbNextPagesSuccessCallback,
            failureCallback: fbPagesAndGroupsFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const fbPagesAndGroupsFailureCallback = (message) => {
        showToast(message, 'e');
        setLoaderReplies(false)
    }
    const getFacebookPagesAndGroups = (code) => {
        let facebookForm = {
            code,
        };
        let postProps = {
            url: integrateModuleURL.getFacebookPagesAndGroups,
            body: facebookForm,
            successCallback: fbPagesAndGroupsSuccessCallback,
            failureCallback: fbPagesAndGroupsFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const facebookSuccessCallback = (data, message) => {
        setLoading(false);
        showToast(message, 's');
        handleClose();
    }
    const facebookFailureCallback = (message) => {
        setLoading(false);
        showToast(message, 'e');
        handleClose();
    }
    const handleSubmitPagesAndGroups = () => {
        setLoading(true);
        let facebookForm = {
            pages: selectedPages,
            id: profileId
        };
        let postProps = {
            url: integrateModuleURL.integrateFacebookPagesAndGroups,
            body: facebookForm,
            successCallback: facebookSuccessCallback,
            failureCallback: facebookFailureCallback
        }
        HttpServices.Post(postProps);
    };

    const handlePages = (pageId) => {
        let selectedPageList = [...selectedPages];
        let index = selectedPageList.findIndex((a) => a.id === pageId);
        if (index >= 0) selectedPageList.splice(index, 1);
        else {
            let selectedPage = pages.find((a) => a.id === pageId);
            selectedPageList.push(selectedPage);
        }
        setSelectedPages(selectedPageList);
    };

    const handleConnectFacebook = () => {
        setLoadSdk(true);
    }

    const handleClose = () => {
        props.handleClose();
    };
    const checkAccountIsIntegrated = (account) => {
        let isChecked = false;
        if (account.is_integrated)
            isChecked = true;
        else {
            let selectedPageList = [...selectedPages];
            if (selectedPageList && selectedPageList.length > 0) {
                let index = selectedPageList.findIndex(a => a.id === account.id);
                if (index >= 0)
                    isChecked = true;
            }
        }
        return isChecked;
    }
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    }));
    return (
        <>
            {isAuthorizationModalOpen &&
                <FacebookPageAuthorizationModal
                    handleConnectFacebook={handleConnectFacebook}
                    handleClose={handleClose}
                />}
            {isFbAccountModalOpen ? (
                <Dialog onClose={handleClose} open={isFbAccountModalOpen} fullWidth>
                    <DialogTitle>Facebook Pages</DialogTitle>
                    <DialogContent dividers>
                        <div className="l2s-space-between">
                            <Box sx={{
                                minHeight: '220px',
                            }}>

                                {pages && pages.length > 0 ?
                                    <>
                                        <List>
                                            {pages.map((a) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <Checkbox
                                                                edge="end"
                                                                value={a.id}
                                                                checked={checkAccountIsIntegrated(a)}
                                                                onClick={isNoAccountForIntegrate ? null : () => { handlePages(a.id) }}
                                                                disabled={a.is_integrated}
                                                            />
                                                        }
                                                        disablePadding
                                                    >
                                                        <ListItemButton onClick={isNoAccountForIntegrate ? null : () => { handlePages(a.id) }} disabled={a.is_integrated}>
                                                            <ListItemAvatar>
                                                                <Badge
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    badgeContent={
                                                                        <SmallAvatar alt="" src={Facebook} />
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        src={a.profile_image ? a.profile_image : a.name || a.id}
                                                                        alt={a.name ? a.name : a.id}
                                                                        style={{ color: '#ffffff' }}
                                                                    />
                                                                </Badge>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={a.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                        {pagination !== '' && (
                                            <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
                                                {loaderReplies ? (
                                                    <ThreeDotLoader />
                                                ) : (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontWeight: 700,
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer',
                                                            color: theme.palette.grey[500],
                                                        }}
                                                        onClick={fetchNextPagesFromServer}
                                                    >
                                                        Next pages
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                        {isNoAccountForIntegrate && <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            All of your accounts are integrated
                                        </Typography>}
                                    </>
                                    :
                                    <Typography sx={{
                                        color: 'red',
                                        margin: '10px'
                                    }}>
                                        There is no Facebook pages to integrate
                                    </Typography>
                                }
                            </Box>
                            <iframe
                                id="iframe-id"
                                src="https://stage.brand2social.com/facebook-redirect"
                                style={{ display: "none" }}
                                title="Facebook Authorization"
                            ></iframe>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                        {pages && pages.length > 0 && !isNoAccountForIntegrate &&
                            <LoadingButton variant="contained" disabled={selectedPages.length === 0} loading={isLoading} autoFocus onClick={handleSubmitPagesAndGroups}>
                                Submit
                            </LoadingButton>}
                    </DialogActions>
                </Dialog>
            ) : null}
            {isLoadSdk && <LoadFacebookSdk />}
        </>
    );
};
export default FacebookPageIntegration;
