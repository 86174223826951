import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const generatePalette = (primaryColor, secondaryColor) => {
    const PRIMARY = {
        lighter: alpha(primaryColor, 0.1),
        light: alpha(primaryColor, 0.3),
        main: primaryColor,
        dark: alpha(primaryColor, 0.7),
        darker: alpha(primaryColor, 0.9),
    };

    const SECONDARY = {
        lighter: alpha(secondaryColor, 0.1),
        light: alpha(secondaryColor, 0.3),
        main: secondaryColor,
        dark: alpha(secondaryColor, 0.7),
        darker: alpha(secondaryColor, 0.9),
    };

    const INFO = {
        lighter: '#D0F2FF',
        light: '#74CAFF',
        main: '#1890FF',
        dark: '#0C53B7',
        darker: '#04297A',
    };

    const SUCCESS = {
        lighter: '#E9FCD4',
        light: '#AAF27F',
        main: '#54D62C',
        dark: '#229A16',
        darker: '#08660D',
    };

    const WARNING = {
        lighter: '#FFF7CD',
        light: '#FFE16A',
        main: '#FFC107',
        dark: '#B78103',
        darker: '#7A4F01',
    };

    const ERROR = {
        lighter: '#FFE7D9',
        light: '#FFA48D',
        main: '#FF4842',
        dark: '#B72136',
        darker: '#7A0C2E',
    };

    const GREY = {
        0: '#FFFFFF',
        100: '#F9FAFB',
        200: '#F4F6F8',
        250: '#E9E9E9',
        300: '#DFE3E8',
        400: '#C4CDD5',
        500: '#919EAB',
        600: '#637381',
        700: '#454F5B',
        800: '#212B36',
        900: '#161C24',
        500_8: alpha(primaryColor, 0.08),
        500_12: alpha(primaryColor, 0.12),
        500_16: alpha(primaryColor, 0.16),
        500_24: alpha(primaryColor, 0.24),
        500_32: alpha(primaryColor, 0.32),
        500_48: alpha(primaryColor, 0.48),
        500_56: alpha(primaryColor, 0.56),
        500_80: alpha(primaryColor, 0.8),
    };

    const GRADIENTS = {
        primary: createGradient(PRIMARY.light, PRIMARY.main),
        info: createGradient(INFO.light, INFO.main),
        success: createGradient(SUCCESS.light, SUCCESS.main),
        warning: createGradient(WARNING.light, WARNING.main),
        error: createGradient(ERROR.light, ERROR.main),
    };

    const PRICING = {
        lightGrey: "#4caf50",
        lightGreen: "#e1ffe8",
        lightGreen1: "#00C853",
        lightGreen1: "#B9F6CA",
        grey: "#5f5f5f"
    }

    const PREVIEW = {
        postcard: { color: '#EEEEEE', icon: '#5E6D7B', dotcolor:'#807F7C'},
        facebook: { color: '#0866ff' },
        comments: { facebook: '#0069FF', instagram: '#E1306C', youtube: '#FF0000', linkedin: '#0077B5', gbusiness: '#EEEEEE'},
        instagram: { color: '#1877F2' , likes: '#FF3030'},
        linkedin: { color: '#0077B5'}
    }

    const ACCORDION = {
        accordion: {
            background: "#f4f6f8",
            color: "#637381"
        }
    }

    const CHART_COLORS = {
        violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
        blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
        green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
        yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
        red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
    };

    const COMMON = {
        common: { black: '#000', white: '#fff' },
        primary: { ...PRIMARY, contrastText: '#fff' },
        secondary: { ...SECONDARY, contrastText: '#fff' },
        info: { ...INFO, contrastText: '#fff' },
        success: { ...SUCCESS, contrastText: GREY[800] },
        warning: { ...WARNING, contrastText: GREY[800] },
        error: { ...ERROR, contrastText: '#fff' },
        grey: GREY,
        gradients: GRADIENTS,
        chart: CHART_COLORS,
        divider: GREY[500_24],
        action: {
            hover: GREY[500_8],
            selected: GREY[500_16],
            disabled: GREY[500_80],
            disabledBackground: GREY[500_24],
            focus: GREY[500_24],
            hoverOpacity: 0.08,
            disabledOpacity: 0.48,
        },
    };

    return {
        ...COMMON,
        pricing: { ...PRICING },
        comments: PREVIEW.comments.facebook,
        preview: { ...PREVIEW, },
        mode: 'light',
        text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] , border:'#D8DDE1'},
        background: { paper: '#fff', default: GREY[200], neutral: GREY[100], primary:'rgba(0, 0, 0, 0.5)', shadow: GREY[250] },
        action: { active: GREY[600], ...COMMON.action },
        ...ACCORDION
    };
}

export default generatePalette;