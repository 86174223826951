import { useCallback, useEffect, useRef, useState } from 'react';
import { Config } from 'src/config';

const scriptNodeRef = useRef < HTMLElement > null;
const LoadFacebookSdk = (props) => {
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const SDK_URL = Config.Facebook.SDK_URL;
  const SCRIPT_ID = Config.Facebook.SCRIPT_ID;
  const appId = Config.Facebook.appId;
  const state = Config.Facebook.state;
  const xfbml = Config.Facebook.xfbml;
  const cookie = Config.Facebook.cookie;
  const version = Config.Facebook.version;
  const response_type = Config.Facebook.responseType;
  const redirect_uri = Config.Facebook.redirectUri;
  const facebookLoginUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${Config.Facebook.appId}&redirect_uri=https://stage.brand2social.com/facebook-redirect&scope=${Config.Facebook.scope}`;

  useEffect(() => {
    !isSdkLoaded && load();
  }, [isSdkLoaded]);

  useEffect(() => {
    if (scriptNodeRef.current) scriptNodeRef.current.remove();
  }, []);

  const insertSDKScript = useCallback((document, cb) => {
    const fbScriptTag = document.createElement('script');
    fbScriptTag.id = SCRIPT_ID;
    fbScriptTag.src = SDK_URL;
    const scriptNode = document.getElementsByTagName('script')[0];
    scriptNode && scriptNode.parentNode && scriptNode.parentNode.insertBefore(fbScriptTag, scriptNode);
    cb();
  }, []);

  const checkIsExistsSDKScript = useCallback(() => {
    return !!document.getElementById(SCRIPT_ID);
  }, []);
  const openFacebook = () => {
    setIsSdkLoaded(true);
    //props.handleFacebookSdk(true);
    window.open(facebookLoginUrl, '_blank');
  };
  const initFbSDK = useCallback((config, document) => {
    const _window = window;
    _window.fbAsyncInit = function () {
      _window.FB && _window.FB.init({ ...config });
      openFacebook();
      let fbRoot = document.getElementById('fb-root');
      if (!fbRoot) {
        fbRoot = document.createElement('div');
        fbRoot.id = 'fb-root';
        document.body.appendChild(fbRoot);
      }
      if (scriptNodeRef) scriptNodeRef.current = fbRoot;
    };
  }, []);
  const load = useCallback(() => {
    if (checkIsExistsSDKScript()) {
      openFacebook();
    } else {
      insertSDKScript(document, () => {
        initFbSDK(
          {
            appId,
            xfbml,
            version,
            state,
            cookie,
            redirect_uri,
            response_type,
          },
          document
        );
      });
    }
  }, [
    state,
    appId,
    xfbml,
    cookie,
    version,
    initFbSDK,
    redirect_uri,
    response_type,
    insertSDKScript,
    checkIsExistsSDKScript,
  ]);
  return null;
};
export default LoadFacebookSdk;
