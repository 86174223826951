import { TablePagination } from "@mui/material";


const FailedPostGridPagination = ({ failedPostList,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage
}) => {



    return (
        <>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 30, 50]}
                count={failedPostList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}


export default FailedPostGridPagination;