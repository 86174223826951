import {
    Box,
    Stack,
    IconButton,
    Tabs,
    Tab,
    tabsClasses,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'src/utils/Loader/spinner';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReportActionPopup from 'src/pages/reports/reportActionPopup';
import ReportComponent from 'src/components/reports/report.body.component';

const ReportHeaderComponent = ({ connectedProfileId, startDate, endDate, viewBy, serviceName }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [height, setHeight] = useState(window.innerHeight - 250);

    const [open, setOpen] = useState(false); // Ensure open state for popover
    const [anchorEl, setAnchorEl] = useState(null); // For managing popover anchor

    useEffect(() => {
        getReportsDetails();
    }, []);

    const reportSuccessCallback = (data) => {
        const filteredData = data?.filter((e) => e.is_display)
        setReports(filteredData);
        setLoading(false);
    };

    const reportFailureCallback = (error) => {
        setReports([]);
        setLoading(false);
    };


    const getReportsDetails = () => {
        const getProps = {
            url: `${integrateModuleURL.getReportsDetails}${serviceName}`,
            successCallback: reportSuccessCallback,
            failureCallback: reportFailureCallback,
        };
        HttpServices.Get(getProps);
    };

    const handleTabChange = (event, value) => {
        setCurrentTab(value);
    };
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };


    return (
        <Box>
            {isLoading ? (
                <Spinner height={height} />
            ) : (
                <>
                 <Stack direction="row" justifyContent="space-between">
                    <Tabs
                        value={currentTab}
                        variant="scrollable"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { display: 'none' },
                            },
                            ...(!isMobile && { gap: '0.5rem', minHeight: '37px' }),
                        }}
                        allowScrollButtonsMobile
                        onChange={handleTabChange}
                    >
                        {reports?.map((report, index) => (
                            <Tab key={report.report_name} label={report.report_display_name} />
                        ))}
                    </Tabs>
                   
                    <IconButton onClick={(event) => handlePopoverOpen(event)}  sx={{ mr: 1.5 }}>
                        <MoreHorizIcon  sx={{ fontSize: 30 }}/>
                    </IconButton>
                    </Stack>
                    {reports?.length > 0 ? (
                        reports.map((report, index) => (
                            currentTab === index && (
                                <Box key={report.report_name}>
                                    <ReportComponent
                                        connectedProfileId={connectedProfileId}
                                        startDate={startDate}
                                        endDate={endDate}
                                        viewBy={viewBy}
                                        summary={report} // Pass report data
                                        serviceName={serviceName}
                                    />
                                </Box>
                            )
                        ))
                    ) : (
                        <NoPostFound height={height} />
                    )}
                       <ReportActionPopup
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        serviceName={serviceName}
                        startDate={startDate}
                        endDate={endDate}
                        viewBy={viewBy}
                        connectedProfileId={connectedProfileId}
                        reportId={reports[currentTab]?.id}
                        currReportData={reports[currentTab]}
                    />
                </>
            )}
        </Box>
    );
};

export default ReportHeaderComponent;
