import { Helmet } from 'react-helmet-async';
import CalendarGrid from './calendar-grid.component';
export default function Calendar() {
  return (
    <>
      <Helmet>
        <title> Calendar </title>
      </Helmet>
      <>
        <CalendarGrid></CalendarGrid>
      </>
    </>
  );
}
