import { Chip, Stack, Typography, TableContainer } from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import EngagementNestedCard from 'src/components/reports/scheduled-report-cards/engagement-nested-card';
import NestedCard from 'src/components/reports/scheduled-report-cards/nested-card';

const ScheduledReportBodyComponent = ({ connectedProfileId, startDate, endDate, viewBy, subReportId, subReportData,serviceName }) => {
  const subReportFilter = subReportId
  ? subReportData?.filter((report) => report.id === parseInt(subReportId))
  : subReportData?.filter((report) => report.is_display);

    const renderComponent = (data) => {
        switch (data.card_type) {
            case "nested_card":
                return (
                    <Stack spacing={2} direction='column'>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h5" gutterBottom>
                                {serviceName} :  {data.card_name}
                            </Typography>
                            <Chip label={`${CommonHelper.formateDateWithYear(startDate)} - ${CommonHelper.formateDateWithYear(endDate)}`} color="primary" />
                        </Stack>

                <NestedCard connectedProfileId={connectedProfileId}
                    startDate={startDate}
                    endDate={endDate}
                    viewBy={viewBy}
                    audienceSummary={data} serviceName={serviceName}/>
            </Stack>
                );
            case "nested_card_and_table":
                return (
                    <Stack spacing={2} direction='column' sx={{ mt: 1 }}>
                 <Stack direction="row" spacing={1}>
                            <Typography variant="h5" gutterBottom>
                                {serviceName} :  {data.card_name}
                            </Typography>
                            <Chip label={`${CommonHelper.formateDateWithYear(startDate)} - ${CommonHelper.formateDateWithYear(endDate)}`} color="primary" />
                        </Stack>
                <EngagementNestedCard connectedProfileId={connectedProfileId}
                    startDate={startDate}
                    endDate={endDate}
                    viewBy={viewBy}
                    engagementSummary={data}
                    serviceName={serviceName}/>
            </Stack>
                );
            default:
                return null;
        }
    };



    return (
        <TableContainer sx={{ minHeight: 'calc(100vh - 30px)', maxHeight: 'calc(100vh - 30px)', p: 3 }}>
             <Stack spacing={4} direction="column">
            {subReportFilter && subReportFilter?.map((data) => renderComponent(data))}
            </Stack>
        </TableContainer>
    );
};

export default ScheduledReportBodyComponent;
