import {
  Box,
  Grid,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import {
  Public as PublicIcon,
  FiberManualRecord as FiberManualRecordIcon,
  MoreHoriz as MoreHorizIcon,
  IosShare as IosShareIcon,
  StarBorder as StarBorderIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

const YoutubePostImagesPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const imageView = () => {
    let view = [];
    let xWidth = 6;
    const remainingImagesCount = mediaFiles.length > 5 ? mediaFiles.length - 4 : 0;
    switch (mediaFiles.length) {
      case 1:
        xWidth = 12;
        break;
      case 2:
        xWidth = 12;
        break;
      case 3:
      case 4:
        xWidth = 6;
        break;
      default:
        xWidth = 6;
        break;
    }
    view = mediaFiles.slice(0, 4).map((image, i) => (
      <Grid
        item
        xs={mediaFiles.length === 3 && i === 2 ? 12 : xWidth}
        key={i}
        sx={{
          maxWidth: 500,
          maxHeight: 460,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          opacity: 1,
        }}
      >
        {image && image?.url ? (
          <>
            <img
              src={image.url}
              alt={image.url}
              width={'100%'}
              height={460}
              style={{ objectFit: 'cover' }}
            />
          </>
        ) : (
          <img src={image} alt={image} width={'100%'} height={200} style={{ objectFit: 'cover' }} />
        )}
      </Grid>
    ));
    return (
      <Grid container sx={{ position: 'relative' }}>
        {view}
        {remainingImagesCount > 0 && (
          <Typography
            variant="h2"
            color="white"
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 1,
              marginRight: '20%',
              marginBottom: '10%',
            }}
          >
            +{remainingImagesCount}
          </Typography>
        )}
      </Grid>
    );
  };

  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">Just Now</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container alignItems="center" justifyContent="center" >
        <Card sx={{ width: 300, maxWidth: 500, minWidth: respwidth ? 300 : 200, boxShadow: 'none', border: 'solid 2px', borderColor: theme.palette.preview.postcard.color }}>
          <Box sx={{ position: 'relative' }}>
            {imageView()}
          </Box>

          <Box sx={{ alignSelf: 'flex-start', p: 1.3 }}>
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem', mb: 1 }}>
                <MoreHorizIcon fontSize="small" />
                <IosShareIcon fontSize='small' />
                <StarBorderIcon fontSize='small' />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ p: 1, borderRadius: '50px', display: 'flex' }}>
                  <Typography sx={{}}>Board</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
                <Box sx={{ p: 1, backgroundColor: 'error.main', borderRadius: '50px' }}>
                  <Typography sx={{ color: 'white' }}>Save</Typography>
                </Box>
              </Paper>
            </Stack>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.9rem',
                color: 'black',
                textDecoration: 'underline',
                marginBottom: '8px',
              }}
            >
              {data.link}
            </Typography>

            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '1.3rem',
                color: 'black',
                fontWeight: 700
              }}
            >
              {data.title}
            </Typography>

            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.8rem',
                color: 'black'
              }}
            >
              {data.description}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                <Avatar
                  src={
                    ConnectedProfiles.connected_profile_image
                      ? ConnectedProfiles.connected_profile_image
                      : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                  }
                  alt={
                    ConnectedProfiles.connected_profile_name
                      ? ConnectedProfiles.connected_profile_name
                      : ConnectedProfiles.connected_profile_id
                  }
                  sx={{
                    height: 25,
                    width: 25,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    WebkitBoxDecorationBreak: 'clone',
                    whiteSpace: 'pre-line',
                    textOverflow: 'ellipsis',
                    fontSize: '1rem',
                    fontWeight: '700'
                  }}
                >
                  {data?.connected_profile_details?.connected_profile_name}
                </Typography>
              </Box>
              <Box sx={{ p: 1, backgroundColor: 'background.shadow', borderRadius: '50px' }}>
                <Typography >Follow</Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default YoutubePostImagesPreview;
