import { PostAdd } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MenuItem, Popover,CircularProgress,Box} from '@mui/material';

const DraftPostActionPopup = ({
    popoverElement,
    handleCloseMenu,
    handleUploadDraft,
    handleDeleteDraft,
    loading
}) => {
    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: { p: 1, width: 140, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
            }}
        >
            {' '}
            {loading ? (
                   <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                   <CircularProgress size={24} />
               </Box>
                ) : 
                <>
            <MenuItem sx={{ color: 'info.main' }} onClick={handleUploadDraft}>
                <PostAdd sx={{ mr: 1 }} /> Upload Draft
            </MenuItem>
            <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteDraft}>
                <DeleteForeverIcon sx={{ mr: 1 }} /> Delete Draft
            </MenuItem>
            </>
            }
        </Popover>
    );
};
export default DraftPostActionPopup;
