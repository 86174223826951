import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MenuItem, Popover } from '@mui/material';

const PendingUserAction = ({
    popoverElement,
    handleCloseMenu,
    handleOpenConfirm,
    handleDeletePendingInvite,
    handleResendPendingInvite,
}) => {
    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: { p: 1, width: 200, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
            }}
        >
            <MenuItem
                sx={{ color: 'error.main' }}
                onClick={() => {
                    handleOpenConfirm('resend')
                }}
            >
                <DeleteForeverIcon sx={{ mr: 1 }} />Resend Invite
            </MenuItem>
            <MenuItem
                sx={{ color: 'error.main' }}
                onClick={() => {
                    handleOpenConfirm('delete')
                }}
            >
                <DeleteForeverIcon sx={{ mr: 1 }} />Delete Invite
            </MenuItem>

        </Popover>
    );
};
export default PendingUserAction;
