import { Box, SvgIcon } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import IconButtonAnimate from '../animate/IconButtonAnimate';

export default function CollapseButton({ onToggleCollapse, collapseClick }) {
  const theme = useTheme();
  const SVGIconStyle = () => {
    return <SvgIcon style={{ width: '16px', height: '16px' }}>{arrowIconSvg}</SvgIcon>
  }
  const arrowIconSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"></path></svg>

    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" fill={theme.palette.primary.main}>
    //   <path d="M137.4 406.6l-128-127.1C3.125 272.4 0 264.2 0 255.1s3.125-16.38 9.375-22.63l128-127.1c9.156-9.156 22.91-11.9 34.88-6.943S192 115.1 192 128v255.1c0 12.94-7.781 24.62-19.75 29.58S146.5 415.8 137.4 406.6z" />
    // </svg>
  )
  const ArrowIconStyle = styled(SVGIconStyle)(({ }) => ({
    // fontSize: '28px !important',
    width: '16px !important',
    height: '16px !important'
  }));
  return (
    <IconButtonAnimate onClick={onToggleCollapse} sx={{
      display: 'inline-flex', alignItems: 'center', justifyContent: 'center', outline: '0px', zIndex: '1101', margin: '0px', border: '1px dashed rgba(145, 158, 171, 0.2)', backdropFilter: 'blur(6px)', backgroundColor: ' rgba(255, 255, 255, 0.48)', cursor: 'pointer', userSelect: 'none', borderRadius: '50%', fontSize: '1.125rem', padding: '4px', top: '20px', position: 'fixed', overflow: 'visible', verticalAlign: 'middle', appearance: 'none', left: collapseClick ? '146px' : '338px',
    }}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        <ArrowIconStyle />
      </Box>
    </IconButtonAnimate>
  );
}
