
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { createContext, useContext } from 'react';

const DesktopContext = createContext();

export const DesktopProvider = ({ children }) => {

  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 1100,
        mdCustom0: 1250
      },
    },
  });
  
  const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));

  return (
    <DesktopContext.Provider value={{ isDesktop }}>
      {children}
    </DesktopContext.Provider>
  );
};

export const useDesktop = () => useContext(DesktopContext);
