const TwitterRedirect = () => {
    const popupWindowURL = new URL(window.location.href);
    const token = popupWindowURL.searchParams.get("oauth_token");
    const tokenSecret = popupWindowURL.searchParams.get("oauth_verifier");
    const error = popupWindowURL.searchParams.get("error");
    const error_description = popupWindowURL.searchParams.get("error_description");
    if (token && tokenSecret) {
        localStorage.setItem("twitter_token", token);
        localStorage.setItem("twitter_token_secret", tokenSecret);
        window.close();
    }
    else if (error) {
        localStorage.setItem("twitter_error", error_description);
        window.close();
    }
    return null;
}
export default TwitterRedirect;