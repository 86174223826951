import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box,
    Card,
    Chip,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import ReportHeaderComponent from 'src/components/reports/report.header.component';

const InstagramReportComponent = ({ connectedNetworks,startDate,endDate,viewBy }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.INTEGRATE_PERMISSION
    );

    const [isLoading, setloading] = useState(true);
    const [instagramPageId, setInstagramPageId] = useState('');
    const [connectedMedias, setConnectedMedias] = useState([]);
    const [height, setHeight] = useState(window.innerHeight - 150);
    //Table Variables


    useEffect(() => {
        let instagramId = connectedNetworks && connectedNetworks.length > 0 && connectedNetworks[0].id;
        instagramId && setInstagramPageId(instagramId);
        setConnectedMedias(connectedNetworks);
        connectedNetworks && connectedNetworks.length === 0 && setloading(false);
        const handleResize = () => {
            setHeight(window.innerHeight - 300);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleInstagramPage = (id) => {
        setInstagramPageId(id);
    };

    const navigateToIntegration = () => {
        navigate('/integration');
    };

    const getNetworkChip = () => {
        return (
            connectedMedias &&
            connectedMedias.length > 0 &&
            connectedMedias.map((c) => {
                let isActive = c.id === instagramPageId;
                return (
                    <Chip
                        style={{
                            borderRadius: 8,
                            opacity: isActive ? 1 : 0.9,
                            ...(
                                isActive && {
                                    backgroundColor: theme.palette.primary.main, color: 'white',
                                    fontWeight: 800
                                }
                            )
                        }}

                        sx={{ m: 0.4 }}
                        onClick={() => { handleInstagramPage(c.id) }}
                        avatar={<Avatar
                            src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                            alt={c.profile_name ? c.profile_name : c.id}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                height: 40,
                                width: 40,
                                bgcolor: isActive ? 'white' : theme.palette.primary.main,
                            }}
                            style={{ color: isActive ? theme.palette.primary.main : 'white' }}
                        />}
                        label={c.profile_name ? c.profile_name : c.id}
                    />
                )
            })
        )
    };
    
    return (
        <Box>
                {connectedMedias && connectedMedias.length > 0 ?
                <>
                        <Card sx={{ p: 2, maxHeight: 100, minHeight: isMobile && 100, overflowY: 'auto', visibility: true }} >
                            {getNetworkChip()}
                        </Card>
                        <ReportHeaderComponent  connectedProfileId={instagramPageId} startDate={startDate} endDate={endDate} viewBy={viewBy} serviceName={"instagram"}/>
                    </>
                    :
                    isLoading ?
                        <Spinner height={height} />
                        :
                        <Card sx={{ mb: 2, p: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                            {hasIntegratationPermission ? <LoadingButton
                                variant="contained"
                                autoFocus
                                onClick={() => {
                                    navigateToIntegration();
                                }}
                            >
                                Connect Social Accounts
                            </LoadingButton> : <NoPostFound />}
                        </Card>}


            </Box>
    );
};
export default InstagramReportComponent;
