import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography,useMediaQuery, useTheme } from '@mui/material';

const DonutChart = ({ title, labels, value }) => {
  const theme = useTheme();
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'donut'
    },
    labels: labels, // Labels for the slices
    plotOptions: {
      pie: {
        customScale: 0.9, // Set the scaling of the pie
        donut: {
          size: '65%', // Adjust the size of the inner donut
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '13px',
              fontWeight: '600',
              color: '#373d3f',
            },
            value: {
              show: true,
              fontSize: '10px',
              fontWeight: '200',
              color: '#373d3f',
              formatter: (val) => `${val}%` 
            }
          }
        },
        expandOnClick: false 
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%"; 
      }
    },
    legend: {
      offsetY: 0,
    
      position: 'right'
    }
  });

  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    if (value.every(val => val === 0)) {
      setChartSeries([0, 100]);
    } else {
      setChartSeries(value);
    }
  }, [value]);

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="270"
        />
      </CardContent>
    </Card>
  );
};

export default DonutChart;
