import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, CardContent, ListItemIcon, Paper, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton'; 
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';

const PinterestCreateBoard = ({ handleClose, profileId,NewBoardCreationCallback}) => {
    const [isLoadingOn, setLoadingOn] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const successCallback = (data,message) => {
        setLoadingOn(false);
        NewBoardCreationCallback(data);
        handleClose();
    };

    const failureCallback = (error) => {
        setLoadingOn(false);
    };

    const createBoard = () => {
        setLoadingOn(true);
        const formBody = {
            connected_profile_id:profileId,
            name: name,
            description: description, 
        };
        const postProps = {
            url: integrateModuleURL.CreateBoard,
            body: formBody,
            successCallback,
            failureCallback,
        };
        HttpServices.Post(postProps); 
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title">
                Create Pinterest Board
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Please provide the board details:
                </Typography>
                <TextField
                  
                    placeholder="Board Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                />
                <TextField
                    
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ width: '100%', padding: '8px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    onClick={createBoard} 
                >
                    Create Board
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default PinterestCreateBoard;
