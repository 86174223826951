import { useTheme } from '@mui/material/styles';

const NoPublishedPost = () => {
    const theme = useTheme();
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 500 500" style={{ enableBackground: "0 0 500 500" }}>
            <style>
                {`
        .primary { fill: ${theme.palette.primary.main}; }
        .secondary { fill: ${theme.palette.secondary.main}; }
      `}
            </style>
            <g id="Background_Complete">
                <g>
                    <path style={{ fill: "#EBEBEB" }} d="M95.603,324.507c-0.046-1.918-0.115-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.169l-13.63,0.065l0.514-0.514l-0.055,11.519l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    L82,330.04l13.749,0.287L95.603,324.507z M82.237,330.614l-6.857,0.115c-2.291,0.048-4.595,0.038-6.892,0.057l-0.457,0.004
                    l-0.002-0.463l-0.055-11.519l-0.003-0.517l0.517,0.002l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L82.237,330.614z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M80.396,339.033c-0.046-1.918-0.116-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.168l-13.63,0.065l0.514-0.514l-0.055,11.519l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    l6.773,0.115l13.749,0.287L80.396,339.033z M67.031,345.14l-6.857,0.115c-2.291,0.048-4.594,0.038-6.892,0.057l-0.457,0.004
                    l-0.002-0.463l-0.055-11.519l-0.002-0.517l0.517,0.002l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L67.031,345.14z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M110.809,339.033c-0.046-1.918-0.115-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.168l-13.63,0.065l0.514-0.514l-0.055,11.519l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    l6.773,0.115l13.749,0.287L110.809,339.033z M97.444,345.14l-6.857,0.115c-2.291,0.048-4.595,0.038-6.892,0.057l-0.457,0.004
                    l-0.002-0.463l-0.055-11.519l-0.002-0.517l0.517,0.002l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L97.444,345.14z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M431.598,339.033c-0.047-1.918-0.116-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.168l-13.63,0.065l0.514-0.514l-0.055,11.519l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    l6.773,0.115l13.749,0.287L431.598,339.033z M418.233,345.14l-6.857,0.115c-2.291,0.048-4.595,0.038-6.892,0.057l-0.457,0.004
                    l-0.002-0.463l-0.055-11.519l-0.003-0.517l0.517,0.002l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L418.233,345.14z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M431.598,55.039c-0.047-1.918-0.116-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.168l-13.63,0.065l0.514-0.514l-0.055,11.519l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    l6.773,0.115l13.749,0.287L431.598,55.039z M418.233,61.146l-6.857,0.115c-2.291,0.049-4.595,0.038-6.892,0.057l-0.457,0.004
                    l-0.002-0.463L403.97,49.34l-0.003-0.517l0.517,0.003l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L418.233,61.146z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M446.805,69.564c-0.046-1.918-0.115-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.168l-13.63,0.065l0.514-0.514l-0.055,11.519l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    l6.773,0.115l13.749,0.287L446.805,69.564z M433.44,75.672l-6.857,0.115c-2.291,0.049-4.595,0.038-6.892,0.057l-0.457,0.004
                    l-0.002-0.463l-0.055-11.519l-0.003-0.517l0.517,0.002l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L433.44,75.672z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M80.396,55.051c-0.046-1.918-0.116-3.814-0.135-5.699l0.281,0.281
                    c-4.543,0.053-9.087,0.163-13.63,0.168l-13.63,0.065l0.514-0.514L53.74,60.872l-0.459-0.459c2.246,0.018,4.486,0.008,6.738,0.057
                    l6.773,0.115l13.749,0.287L80.396,55.051z M67.031,61.159l-6.857,0.115c-2.291,0.049-4.594,0.039-6.892,0.057l-0.457,0.004
                    l-0.002-0.462l-0.055-11.519l-0.002-0.517l0.517,0.002l13.63,0.065c4.543,0.005,9.087,0.116,13.63,0.168l0.284,0.003l-0.003,0.278
                    c-0.019,1.955-0.088,3.898-0.135,5.82l-0.146,5.699L67.031,61.159z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M218.952,175.344c0.047,1.918,0.116,3.814,0.135,5.699l-0.281-0.281
                    c4.543-0.053,9.087-0.163,13.63-0.169l13.63-0.065l-0.514,0.514l0.055-11.519l0.459,0.459c-2.246-0.018-4.486-0.008-6.739-0.057
                    l-6.773-0.115l-13.749-0.287L218.952,175.344z M232.317,169.237l6.857-0.115c2.291-0.049,4.594-0.038,6.892-0.057l0.457-0.004
                    l0.002,0.462l0.055,11.519l0.003,0.517l-0.517-0.003l-13.63-0.065c-4.544-0.005-9.087-0.116-13.63-0.169l-0.284-0.003l0.003-0.278
                    c0.019-1.955,0.088-3.898,0.135-5.82l0.146-5.699L232.317,169.237z"/>
                    <path style={{ fill: "#EBEBEB" }} d="M234.158,160.819c0.046,1.918,0.116,3.814,0.135,5.699l-0.281-0.281
                    c4.544-0.053,9.087-0.163,13.63-0.169l13.63-0.065l-0.514,0.514l0.055-11.519l0.459,0.459c-2.246-0.018-4.486-0.008-6.738-0.057
                    l-6.773-0.115l-13.749-0.287L234.158,160.819z M247.524,154.712l6.857-0.115c2.291-0.048,4.594-0.038,6.892-0.057l0.457-0.004
                    l0.002,0.462l0.055,11.519l0.003,0.517l-0.517-0.003l-13.63-0.065c-4.543-0.005-9.087-0.116-13.63-0.169l-0.284-0.003l0.003-0.278
                    c0.019-1.955,0.088-3.898,0.135-5.82l0.146-5.699L247.524,154.712z"/>
                </g>
                <g>
                    <g>

                        <rect x="57.098" y="182.689" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 284.1257 125.5903)" style={{ fill: "#DBDBDB" }} width="44.339" height="44.339" />

                        <rect x="63.973" y="189.563" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 284.1255 125.5902)" style={{ fill: "#FFFFFF" }} width="30.589" height="30.589" />
                        <polygon style={{ fill: "#EBEBEB" }} points="94.562,189.563 63.973,189.563 63.973,220.152 			" />

                        <rect x="52.765" y="182.689" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 109.8632 409.716)" style={{ fill: "#C7C7C7" }} width="4.333" height="44.339" />
                    </g>
                    <g>

                        <rect x="111.987" y="182.689" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 339.0149 70.7011)" style={{ fill: "#DBDBDB" }} width="44.339" height="44.339" />

                        <rect x="118.862" y="189.563" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 339.0146 70.7011)" style={{ fill: "#FFFFFF" }} width="30.589" height="30.589" />
                        <polygon style={{ fill: "#EBEBEB" }} points="140.47,189.563 118.862,189.563 118.862,211.171 			" />

                        <rect x="107.654" y="182.689" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 219.6416 409.716)" style={{ fill: "#C7C7C7" }} width="4.333" height="44.339" />
                    </g>
                    <g>

                        <rect x="77.738" y="217.001" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 373.3275 159.903)" style={{ fill: "#DBDBDB" }} width="57.948" height="99.228" />

                        <rect x="84.903" y="224.244" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 373.3274 159.9029)" style={{ fill: "#FFFFFF" }} width="43.618" height="84.742" />
                        <polygon style={{ fill: "#EBEBEB" }} points="119.891,244.806 64.341,244.806 64.341,273.399 			" />

                        <rect x="52.765" y="237.641" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 109.8632 533.2305)" style={{ fill: "#C7C7C7" }} width="4.333" height="57.948" />
                    </g>
                    <g>

                        <rect x="57.098" y="73.121" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 229.4473 16.0228)" style={{ fill: "#DBDBDB" }} width="99.228" height="99.228" />
                        <rect x="64.336" y="80.364" style={{ fill: "#FFFFFF" }} width="84.75" height="84.734" />
                        <path style={{ fill: "#EBEBEB" }} d="M64.336,141.372c12.884-12.808,49.388-49.129,61.114-61.008H64.336V141.372z" />

                        <rect x="52.765" y="73.121" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 109.8632 245.4701)" style={{ fill: "#C7C7C7" }} width="4.333" height="99.228" />
                    </g>
                </g>
                <g>
                    <g>
                        <path style={{ fill: "#EBEBEB" }} d="M203.205,284.209c-1.662,1.006-3.505,1.767-5.503,2.227c-5.166,1.197-10.322,0.169-14.466-2.472
                        c-4.054-2.574-7.148-6.687-8.321-11.735c-2.363-10.223,3.993-20.425,14.216-22.788c10.214-2.371,20.417,3.993,22.788,14.216
                        C213.821,271.882,210.064,280.09,203.205,284.209z"/>
                        <g>
                            <path style={{ fill: "#FFFFFF" }} d="M199.216,265.837c-0.038,3.191-2.655,5.747-5.847,5.71c-3.191-0.038-5.747-2.656-5.709-5.847
                            c0.038-3.191,2.655-5.747,5.847-5.71C196.698,260.028,199.254,262.646,199.216,265.837z"/>
                            <path style={{ fill: "#FFFFFF" }} d="M203.234,281.826l-0.028,2.384c-1.662,1.006-3.505,1.767-5.503,2.227
                            c-5.166,1.197-10.322,0.169-14.466-2.472l0.028-2.375c0.065-5.501,4.586-9.932,10.103-9.866
                            C198.885,271.788,203.299,276.325,203.234,281.826z"/>
                        </g>
                    </g>
                    <g>
                        <path style={{ fill: "#EBEBEB" }} d="M426.943,143.768c-3.055,1.848-6.442,3.248-10.114,4.093c-9.494,2.201-18.969,0.311-26.586-4.544
                        c-7.45-4.73-13.136-12.289-15.293-21.567c-4.342-18.787,7.339-37.537,26.127-41.88c18.772-4.358,37.522,7.339,41.879,26.126
                        C446.453,121.113,439.549,136.197,426.943,143.768z"/>
                        <g>
                            <path style={{ fill: "#FFFFFF" }} d="M419.611,110.004c-0.07,5.864-4.88,10.563-10.745,10.493c-5.865-0.07-10.563-4.881-10.493-10.745
                            c0.069-5.865,4.88-10.563,10.745-10.493C414.983,99.328,419.68,104.139,419.611,110.004z"/>
                            <path style={{ fill: "#FFFFFF" }} d="M426.995,139.387l-0.052,4.381c-3.055,1.848-6.442,3.248-10.114,4.093
                            c-9.494,2.201-18.969,0.311-26.586-4.544l0.052-4.365c0.12-10.11,8.428-18.253,18.568-18.132
                            C419.003,120.94,427.115,129.278,426.995,139.387z"/>
                        </g>
                    </g>
                    <g>
                        <g id="aiZGp2_2_">
                            <g>
                                <path style={{ fill: "#EBEBEB" }} d="M422.794,263.913c-0.95,2.412-1.485,4.904-1.638,7.484c-0.037,0.001-0.066,0.004-0.096,0.004
                                c-2.151,0-4.301,0-6.452,0c-0.028,0-0.055-0.003-0.082-0.005v-0.184c0.018-0.169,0.037-0.339,0.053-0.508
                                c0.081-0.882,0.231-1.752,0.439-2.612c0.341-1.416,0.839-2.774,1.473-4.084c0.036-0.076,0.079-0.097,0.159-0.097
                                c2.01,0.002,4.02,0.002,6.029,0.002H422.794z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M422.593,280.632h-0.106c-1.995,0-3.99-0.001-5.984,0.002c-0.079,0-0.115-0.025-0.149-0.096
                                c-0.707-1.469-1.206-3.005-1.511-4.605c-0.148-0.77-0.25-1.548-0.3-2.331c-0.002-0.026-0.011-0.052-0.017-0.079v-0.183
                                c0.033-0.003,0.066-0.008,0.1-0.008c2.138,0,4.277,0,6.415,0c0.118,0,0.112,0,0.121,0.119c0.04,0.532,0.076,1.066,0.127,1.596
                                c0.081,0.845,0.225,1.68,0.407,2.508c0.221,1.014,0.51,2.008,0.867,2.981C422.573,280.565,422.581,280.594,422.593,280.632z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M448.209,282.562c-0.021,0.037-0.036,0.064-0.053,0.091c-0.507,0.799-1.103,1.529-1.726,2.238
                                c-0.761,0.867-1.611,1.636-2.529,2.331c-0.905,0.685-1.866,1.281-2.882,1.786c-0.979,0.486-1.995,0.882-3.045,1.185
                                c-0.833,0.24-1.679,0.417-2.536,0.541c-0.083,0.012-0.167,0.028-0.249,0.042h-0.147c-0.043-0.028-0.085-0.055-0.141-0.09
                                c0.06-0.043,0.11-0.08,0.162-0.114c1.867-1.279,3.471-2.827,4.803-4.658c0.749-1.029,1.403-2.115,1.962-3.257
                                c0.034-0.07,0.072-0.095,0.151-0.094c2.04,0.002,4.081,0.001,6.121,0.001H448.209z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M442.115,271.392h-8.555v-7.475c0.035-0.001,0.067-0.004,0.099-0.004
                                c2.193,0,4.387,0,6.58-0.002c0.073,0,0.106,0.023,0.135,0.089c0.617,1.409,1.068,2.87,1.361,4.381
                                c0.152,0.785,0.266,1.575,0.329,2.372C442.08,270.963,442.098,271.172,442.115,271.392z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M442.064,273.387c-0.008,0.177-0.01,0.353-0.025,0.528c-0.038,0.417-0.067,0.834-0.125,1.248
                                c-0.081,0.573-0.168,1.146-0.282,1.714c-0.252,1.251-0.629,2.466-1.109,3.649c-0.032,0.079-0.067,0.11-0.157,0.11
                                c-2.245-0.004-4.491-0.003-6.736-0.003h-0.121v-7.245H442.064z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M431.631,263.961v7.487h-8.355c0.012-0.19,0.018-0.376,0.037-0.56
                                c0.043-0.418,0.077-0.84,0.143-1.255c0.102-0.634,0.212-1.268,0.348-1.897c0.274-1.268,0.688-2.495,1.207-3.685
                                c0.031-0.071,0.069-0.092,0.142-0.092c2.124,0.002,4.247,0.002,6.37,0.002H431.631z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M431.64,273.486c-0.002,2.347-0.002,4.694-0.002,7.04v0.106h-0.112
                                c-2.188,0-4.376-0.001-6.563,0.002c-0.079,0-0.115-0.025-0.146-0.098c-0.505-1.22-0.894-2.477-1.138-3.773
                                c-0.125-0.665-0.221-1.337-0.305-2.007c-0.056-0.438-0.068-0.882-0.098-1.324c-0.001-0.011,0.001-0.024,0.003-0.047
                                c0.033-0.002,0.064-0.004,0.097-0.004c2.72,0,5.439,0,8.158-0.002C431.621,273.378,431.64,273.404,431.64,273.486z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M451.188,271.445h-7.011c-0.152-2.591-0.68-5.1-1.634-7.533h0.11c2.141,0,4.283,0,6.425-0.002
                                c0.078,0,0.117,0.021,0.153,0.091c0.75,1.489,1.289,3.052,1.616,4.687c0.174,0.869,0.282,1.745,0.341,2.628
                                C451.19,271.358,451.188,271.398,451.188,271.445z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M451.194,273.332c-0.008,0.169-0.012,0.33-0.024,0.49c-0.046,0.634-0.12,1.263-0.23,1.888
                                c-0.258,1.456-0.693,2.857-1.288,4.21c-0.095,0.218-0.199,0.432-0.296,0.648c-0.022,0.049-0.05,0.067-0.104,0.067
                                c-2.147-0.002-4.295-0.002-6.442-0.002c-0.021,0-0.041-0.003-0.07-0.005c0.078-0.225,0.156-0.442,0.23-0.66
                                c0.379-1.115,0.665-2.255,0.868-3.415c0.147-0.842,0.251-1.69,0.3-2.543c0.012-0.201,0.025-0.403,0.034-0.603
                                c0.002-0.054,0.017-0.08,0.074-0.075c0.022,0.002,0.043,0.001,0.065,0.001H451.194z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M448.017,262.004c-0.004,0.01-0.009,0.019-0.013,0.029h-0.089c-2.044,0-4.088,0-6.132,0.001
                                c-0.073,0-0.113-0.018-0.147-0.087c-0.826-1.631-1.829-3.144-3.044-4.51c-0.707-0.794-1.494-1.504-2.335-2.153
                                c-0.418-0.322-0.852-0.623-1.279-0.933c-0.022-0.016-0.043-0.032-0.071-0.053c0.031-0.021,0.056-0.038,0.085-0.058
                                c-0.012-0.039-0.023-0.079-0.04-0.132c2.183,0.254,4.252,0.863,6.2,1.861C443.946,257.401,446.267,259.382,448.017,262.004z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M430.444,290.69c-0.076,0.013-0.129,0.035-0.178,0.029c-0.675-0.087-1.341-0.221-1.999-0.389
                                c-1.233-0.314-2.42-0.753-3.559-1.318c-1.304-0.648-2.513-1.442-3.627-2.38c-0.789-0.665-1.522-1.385-2.181-2.18
                                c-0.495-0.598-0.946-1.228-1.36-1.885c-0.009-0.015-0.016-0.031-0.029-0.058h0.101c1.904,0,3.806,0,5.71-0.002
                                c0.077,0,0.111,0.028,0.144,0.092c0.599,1.202,1.291,2.347,2.098,3.421c0.738,0.981,1.566,1.882,2.459,2.723
                                c0.739,0.697,1.526,1.334,2.367,1.904C430.4,290.652,430.409,290.661,430.444,290.69z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M430.367,254.29c-0.26,0.191-0.568,0.407-0.865,0.635c-1.004,0.77-1.913,1.642-2.766,2.575
                                c-1.148,1.255-2.118,2.634-2.904,4.141c-0.049,0.095-0.093,0.193-0.148,0.284c-0.016,0.028-0.057,0.053-0.089,0.056
                                c-0.103,0.006-0.207,0.002-0.312,0.002h-5.581c3.11-4.391,7.286-7.03,12.6-7.836c-0.012,0.024-0.024,0.048-0.036,0.072
                                C430.303,254.244,430.341,254.27,430.367,254.29z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M439.563,282.567c-0.796,1.394-1.735,2.665-2.825,3.823c-0.971,1.03-2.029,1.956-3.227,2.738
                                v-6.561H439.563z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M431.629,282.569v6.434c-2.458-1.693-4.372-3.84-5.806-6.434H431.629z" />
                                <path style={{ fill: "#EBEBEB" }} d="M439.372,261.977h-5.811v-6.131c1.2,0.786,2.288,1.69,3.26,2.73
                                C437.792,259.614,438.629,260.752,439.372,261.977z"/>
                                <path style={{ fill: "#EBEBEB" }} d="M431.631,256.038v5.99h-5.578C427.466,259.609,429.277,257.583,431.631,256.038z" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <path style={{ fill: "#EBEBEB" }} d="M190.41,115.063h-2.701c-1.71,0-3.061-1.351-3.061-3.061c0-1.652,1.351-3.061,3.061-3.061h3.764
                        l1.767-10.23h-3.42c-1.638,0-3.046-1.408-3.046-3.061c0-1.652,1.408-3.06,3.046-3.06h4.483l1.164-6.998
                        c0.244-1.537,1.538-2.529,3.061-2.529c1.94,0,3.305,1.767,3.061,3.592l-1.049,5.934h9.225l1.178-6.998
                        c0.244-1.537,1.537-2.529,3.118-2.529c1.882,0,3.29,1.767,3.003,3.592l-1.006,5.934h2.716c1.638,0,3.046,1.351,3.046,3.06
                        c0,1.652-1.408,3.061-3.046,3.061h-3.765l-1.767,10.23h3.348c1.71,0,3.06,1.351,3.06,3.061c0,1.652-1.351,3.061-3.06,3.061
                        h-4.411l-1.236,7.227c-0.23,1.48-1.523,2.529-3.061,2.529c-1.94,0-3.348-1.753-3.06-3.578l1.121-6.178h-9.239l-1.236,7.227
                        c-0.23,1.308-1.351,2.529-3.104,2.529c-1.897,0-3.305-1.581-3.003-3.578L190.41,115.063z M206.949,108.942l1.767-10.23h-9.182
                        l-1.767,10.23H206.949z"/>
                    </g>
                    <g>
                        <g>
                            <path style={{ fill: "#EBEBEB" }} d="M348.137,303.257c-13.578-2.784-26.887,5.972-29.663,19.51
                            c-2.784,13.577,6.001,26.893,19.578,29.677c2.038,0.418,4.118,0.579,6.142,0.463c0.616,0.034,1.197-0.214,1.619-0.679
                            c0.387-0.441,0.564-1.007,0.488-1.584c0.051-0.5-0.105-0.981-0.439-1.345c-0.406-0.461-1.067-0.719-1.785-0.703
                            c-1.667,0.118-3.194-0.104-5.134-0.501c-11.178-2.292-18.414-13.248-16.121-24.436c2.284-11.138,13.242-18.334,24.42-16.042
                            c11.148,2.286,18.352,13.205,16.068,24.343c-0.693,3.38-2.11,6.407-4.128,8.78c-0.328,0.504-2.289,2.45-4.266,2.535l-0.49-0.1
                            c-0.627-0.129-0.743-0.357-0.759-0.38c-0.102-0.399-0.183-1.099-0.154-1.44c0.206-2.5,2.041-9.953,2.069-10.039l0.185-0.901
                            c1.508-7.357-2.946-14.508-9.931-15.94c-7.053-1.446-14.021,3.363-15.529,10.72c-1.524,7.435,2.975,14.666,10.028,16.112
                            c3.154,0.647,6.266,0.06,9.085-1.69l-0.231,1.127c-0.245,1.195-0.1,2.48,0.458,3.84c0.402,0.879,1.449,2.441,3.889,2.941
                            c0.431,0.088,0.96,0.197,1.541,0.153c2.603-0.201,5.106-1.505,7.43-3.877c0.077-0.076,0.13-0.137,0.142-0.144l0.114-0.109
                            c2.417-3.026,4.062-6.619,4.898-10.694C370.436,319.313,361.675,306.033,348.137,303.257z M341.27,336.75
                            c-4.624-0.948-7.435-5.608-6.407-10.623c1.016-4.957,5.537-8.236,10.082-7.304c4.506,0.924,7.473,5.82,6.473,10.698
                            C350.408,334.449,345.854,337.69,341.27,336.75z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Background_Simple" style={{ display: "none" }}>
                <path style={{ display: "inline", fill: "#DBDBDB" }} d="M47.035,315.352l-2.311-0.271c0.148-0.493,0.587-0.859,1.128-0.859
                C46.49,314.222,46.999,314.724,47.035,315.352z M53.036,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.185,0.05,0.356,0.125,0.512
                l1.985,0.233c0.168-0.206,0.282-0.458,0.282-0.745C54.232,314.757,53.697,314.222,53.036,314.222z M53.036,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C54.232,307.573,53.697,307.038,53.036,307.038z M49.444,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C50.64,311.165,50.105,310.63,49.444,310.63z M50.64,304.642c0-0.372-0.18-0.692-0.447-0.911
                l-1.116,2.033c0.117,0.038,0.237,0.074,0.367,0.074C50.105,305.838,50.64,305.303,50.64,304.642z M53.036,299.854
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C54.232,300.39,53.697,299.854,53.036,299.854z M60.22,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C61.416,314.757,60.881,314.222,60.22,314.222z M60.22,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C61.416,307.573,60.881,307.038,60.22,307.038z M56.628,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C57.824,311.165,57.289,310.63,56.628,310.63z M60.22,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C61.416,300.39,60.881,299.854,60.22,299.854z M56.628,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C57.824,303.981,57.289,303.446,56.628,303.446z M60.22,292.67
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C61.416,293.206,60.881,292.67,60.22,292.67z M56.628,296.262c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C57.824,296.798,57.289,296.262,56.628,296.262z M57.824,290.275c0-0.115-0.035-0.218-0.065-0.323
                l-0.797,1.451C57.457,291.256,57.824,290.817,57.824,290.275z M60.22,287.879c0.661,0,1.196-0.536,1.196-1.196
                c0-0.661-0.536-1.196-1.196-1.196c-0.004,0-0.007,0.002-0.01,0.002l-0.992,1.807C59.428,287.638,59.789,287.879,60.22,287.879z
                 M67.404,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C68.6,314.757,68.064,314.222,67.404,314.222z M67.404,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196C68.6,307.573,68.064,307.038,67.404,307.038z M63.812,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C65.008,311.165,64.472,310.63,63.812,310.63z M67.404,299.854
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C68.6,300.39,68.064,299.854,67.404,299.854z M63.812,303.446c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C65.008,303.981,64.472,303.446,63.812,303.446z M67.404,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C68.6,293.206,68.064,292.67,67.404,292.67z M63.812,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C65.008,296.798,64.472,296.262,63.812,296.262z M67.404,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C68.6,286.022,68.064,285.486,67.404,285.486z M63.812,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C65.008,289.614,64.472,289.078,63.812,289.078z M67.404,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C68.6,278.838,68.064,278.303,67.404,278.303z M63.812,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C65.008,282.43,64.472,281.895,63.812,281.895z M68.6,272.315c0-0.439-0.248-0.806-0.6-1.014l-1.135,2.067
                c0.164,0.084,0.342,0.144,0.539,0.144C68.064,273.511,68.6,272.976,68.6,272.315z M74.588,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C75.784,314.757,75.248,314.222,74.588,314.222z M70.996,317.814c-0.275,0-0.516,0.108-0.718,0.264l1.634,0.192
                C71.693,317.998,71.372,317.814,70.996,317.814z M74.588,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C75.784,307.573,75.248,307.038,74.588,307.038z M70.996,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C72.192,311.165,71.656,310.63,70.996,310.63z M74.588,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C75.784,300.39,75.248,299.854,74.588,299.854z M70.996,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196C72.192,303.981,71.656,303.446,70.996,303.446z M74.588,292.67
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C75.784,293.206,75.248,292.67,74.588,292.67z M70.996,296.262c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.66,0,1.196-0.536,1.196-1.196C72.192,296.798,71.656,296.262,70.996,296.262z M74.588,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C75.784,286.022,75.248,285.486,74.588,285.486z M70.996,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C72.192,289.614,71.656,289.078,70.996,289.078z M74.588,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C75.784,278.838,75.248,278.303,74.588,278.303z M70.996,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C72.192,282.43,71.656,281.895,70.996,281.895z M74.588,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C75.784,271.654,75.248,271.119,74.588,271.119z M70.996,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C72.192,275.246,71.656,274.711,70.996,274.711z M74.588,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C75.784,264.471,75.248,263.935,74.588,263.935z M70.996,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C72.192,268.062,71.656,267.527,70.996,267.527z M75.784,257.947c0-0.202-0.063-0.383-0.151-0.55l-0.948,1.726
                C75.298,259.072,75.784,258.573,75.784,257.947z M77.069,254.781c0.173,0.449,0.601,0.77,1.11,0.77
                c0.661,0,1.196-0.536,1.196-1.196s-0.536-1.196-1.196-1.196c-0.087,0-0.165,0.032-0.247,0.05L77.069,254.781z M81.771,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C82.968,314.757,82.432,314.222,81.771,314.222z M78.179,317.814c-0.61,0-1.094,0.464-1.167,1.054l2.337,0.274
                c0.005-0.045,0.027-0.085,0.027-0.132C79.376,318.349,78.84,317.814,78.179,317.814z M81.771,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C82.968,307.573,82.432,307.038,81.771,307.038z M78.179,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C79.376,311.165,78.84,310.63,78.179,310.63z M81.771,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C82.968,300.39,82.432,299.854,81.771,299.854z M78.179,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C79.376,303.981,78.84,303.446,78.179,303.446z M81.771,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C82.968,293.206,82.432,292.67,81.771,292.67z M78.179,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C79.376,296.798,78.84,296.262,78.179,296.262z M81.771,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C82.968,286.022,82.432,285.486,81.771,285.486z M78.179,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C79.376,289.614,78.84,289.078,78.179,289.078z M81.771,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C82.968,278.838,82.432,278.303,81.771,278.303z M78.179,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C79.376,282.43,78.84,281.895,78.179,281.895z M81.771,271.119
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C82.968,271.654,82.432,271.119,81.771,271.119z M78.179,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C79.376,275.246,78.84,274.711,78.179,274.711z M81.771,263.935
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C82.968,264.471,82.432,263.935,81.771,263.935z M78.179,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C79.376,268.062,78.84,267.527,78.179,267.527z M81.771,256.751
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C82.968,257.287,82.432,256.751,81.771,256.751z M78.179,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C79.376,260.879,78.84,260.343,78.179,260.343z M81.771,249.567
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S82.432,249.567,81.771,249.567z
                 M86.559,319.01c0-0.661-0.536-1.196-1.196-1.196c-0.661,0-1.196,0.535-1.196,1.196c0,0.279,0.111,0.525,0.271,0.728l1.657,0.194
                C86.371,319.713,86.559,319.389,86.559,319.01z M88.955,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C90.151,314.757,89.616,314.222,88.955,314.222z M88.955,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C90.151,307.573,89.616,307.038,88.955,307.038z M85.363,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C86.559,311.165,86.024,310.63,85.363,310.63z M88.955,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C90.151,300.39,89.616,299.854,88.955,299.854z M85.363,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C86.559,303.981,86.024,303.446,85.363,303.446z M88.955,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C90.151,293.206,89.616,292.67,88.955,292.67z M85.363,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C86.559,296.798,86.024,296.262,85.363,296.262z M88.955,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C90.151,286.022,89.616,285.486,88.955,285.486z M85.363,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C86.559,289.614,86.024,289.078,85.363,289.078z M88.955,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C90.151,278.838,89.616,278.303,88.955,278.303z M85.363,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C86.559,282.43,86.024,281.895,85.363,281.895z M88.955,271.119
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C90.151,271.654,89.616,271.119,88.955,271.119z M85.363,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C86.559,275.246,86.024,274.711,85.363,274.711z M88.955,263.935
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C90.151,264.471,89.616,263.935,88.955,263.935z M85.363,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C86.559,268.062,86.024,267.527,85.363,267.527z M88.955,256.751
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C90.151,257.287,89.616,256.751,88.955,256.751z M85.363,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C86.559,260.879,86.024,260.343,85.363,260.343z M88.955,249.567
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S89.616,249.567,88.955,249.567z
                 M85.363,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S86.024,253.159,85.363,253.159z M88.955,242.384c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S89.616,242.384,88.955,242.384z M85.363,245.975c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C86.559,246.511,86.024,245.975,85.363,245.975z M86.559,239.988
                c0-0.506-0.316-0.933-0.759-1.108l-1.129,2.057c0.198,0.145,0.429,0.247,0.693,0.247C86.024,241.184,86.559,240.648,86.559,239.988
                z M88.955,235.2c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C90.151,235.735,89.616,235.2,88.955,235.2z M96.139,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C97.335,314.757,96.8,314.222,96.139,314.222z M92.547,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C93.743,318.349,93.208,317.814,92.547,317.814z M96.139,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C97.335,307.573,96.8,307.038,96.139,307.038z M92.547,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C93.743,311.165,93.208,310.63,92.547,310.63z M96.139,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C97.335,300.39,96.8,299.854,96.139,299.854z M92.547,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C93.743,303.981,93.208,303.446,92.547,303.446z M96.139,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C97.335,293.206,96.8,292.67,96.139,292.67z M92.547,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C93.743,296.798,93.208,296.262,92.547,296.262z M96.139,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C97.335,286.022,96.8,285.486,96.139,285.486z M92.547,289.078c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C93.743,289.614,93.208,289.078,92.547,289.078z M96.139,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C97.335,278.838,96.8,278.303,96.139,278.303z M92.547,281.895c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C93.743,282.43,93.208,281.895,92.547,281.895z M96.139,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C97.335,271.654,96.8,271.119,96.139,271.119z M92.547,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C93.743,275.246,93.208,274.711,92.547,274.711z M96.139,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C97.335,264.471,96.8,263.935,96.139,263.935z M92.547,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C93.743,268.062,93.208,267.527,92.547,267.527z M96.139,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C97.335,257.287,96.8,256.751,96.139,256.751z M92.547,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C93.743,260.879,93.208,260.343,92.547,260.343z M96.139,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S96.8,249.567,96.139,249.567z M92.547,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S93.208,253.159,92.547,253.159z M96.139,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S96.8,242.384,96.139,242.384z
                 M92.547,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C93.743,246.511,93.208,245.975,92.547,245.975z M96.139,235.2c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C97.335,235.735,96.8,235.2,96.139,235.2z M92.547,238.792c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S93.208,238.792,92.547,238.792z M96.139,228.016
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S96.8,228.016,96.139,228.016z
                 M92.547,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C93.743,232.143,93.208,231.608,92.547,231.608z M93.743,225.62c0-0.276-0.109-0.519-0.267-0.722l-1.041,1.896
                c0.038,0.004,0.072,0.022,0.111,0.022C93.208,226.816,93.743,226.281,93.743,225.62z M96.139,223.225
                c0.661,0,1.196-0.536,1.196-1.196c0-0.661-0.536-1.196-1.196-1.196c-0.176,0-0.34,0.043-0.491,0.111l-0.676,1.231
                C95.047,222.763,95.53,223.225,96.139,223.225z M103.323,321.405c-0.369,0-0.687,0.177-0.907,0.441l1.969,0.231
                C104.19,321.683,103.793,321.405,103.323,321.405z M103.323,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C104.519,314.757,103.983,314.222,103.323,314.222z M99.731,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,318.349,100.392,317.814,99.731,317.814z M103.323,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,307.573,103.983,307.038,103.323,307.038z M99.731,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,311.165,100.392,310.63,99.731,310.63z M103.323,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,300.39,103.983,299.854,103.323,299.854z M99.731,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C100.927,303.981,100.392,303.446,99.731,303.446z M103.323,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C104.519,293.206,103.983,292.67,103.323,292.67z M99.731,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,296.798,100.392,296.262,99.731,296.262z M103.323,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,286.022,103.983,285.486,103.323,285.486z M99.731,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,289.614,100.392,289.078,99.731,289.078z M103.323,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,278.838,103.983,278.303,103.323,278.303z M99.731,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,282.43,100.392,281.895,99.731,281.895z M103.323,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,271.654,103.983,271.119,103.323,271.119z M99.731,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,275.246,100.392,274.711,99.731,274.711z M103.323,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,264.471,103.983,263.935,103.323,263.935z M99.731,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C100.927,268.062,100.392,267.527,99.731,267.527z M103.323,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C104.519,257.287,103.983,256.751,103.323,256.751z M99.731,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C100.927,260.879,100.392,260.343,99.731,260.343z M103.323,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S103.983,249.567,103.323,249.567z M99.731,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S100.392,253.159,99.731,253.159z M103.323,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S103.983,242.384,103.323,242.384z
                 M99.731,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C100.927,246.511,100.392,245.975,99.731,245.975z M103.323,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C104.519,235.735,103.983,235.2,103.323,235.2z M99.731,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S100.392,238.792,99.731,238.792z
                 M103.323,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S103.983,228.016,103.323,228.016z M99.731,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C100.927,232.143,100.392,231.608,99.731,231.608z M103.323,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C104.519,221.368,103.983,220.832,103.323,220.832z M99.731,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C100.927,224.96,100.392,224.424,99.731,224.424z
                 M103.323,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C104.519,214.184,103.983,213.648,103.323,213.648z M99.731,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C100.927,217.776,100.392,217.24,99.731,217.24z
                 M100.903,211.373l-0.441,0.803C100.707,211.981,100.868,211.701,100.903,211.373z M103.323,208.857
                c0.661,0,1.196-0.536,1.196-1.196c0-0.573-0.411-1.029-0.949-1.146l-1.094,1.992C102.693,208.723,102.992,208.857,103.323,208.857z
                 M110.507,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.019,0.01,0.035,0.011,0.054l2.316,0.272
                c0.031-0.106,0.066-0.21,0.066-0.326C111.703,321.941,111.167,321.405,110.507,321.405z M110.507,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C111.703,314.757,111.167,314.222,110.507,314.222z M106.915,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,318.349,107.575,317.814,106.915,317.814z
                 M110.507,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,307.573,111.167,307.038,110.507,307.038z M106.915,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,311.165,107.575,310.63,106.915,310.63z
                 M110.507,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,300.39,111.167,299.854,110.507,299.854z M106.915,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196C108.111,303.981,107.575,303.446,106.915,303.446z
                 M110.507,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C111.703,293.206,111.167,292.67,110.507,292.67z M106.915,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,296.798,107.575,296.262,106.915,296.262z
                 M110.507,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,286.022,111.167,285.486,110.507,285.486z M106.915,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,289.614,107.575,289.078,106.915,289.078z
                 M110.507,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,278.838,111.167,278.303,110.507,278.303z M106.915,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,282.43,107.575,281.895,106.915,281.895z
                 M110.507,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,271.654,111.167,271.119,110.507,271.119z M106.915,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,275.246,107.575,274.711,106.915,274.711z
                 M110.507,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,264.471,111.167,263.935,110.507,263.935z M106.915,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196C108.111,268.062,107.575,267.527,106.915,267.527z
                 M110.507,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C111.703,257.287,111.167,256.751,110.507,256.751z M106.915,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,260.879,107.575,260.343,106.915,260.343z
                 M110.507,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S111.167,249.567,110.507,249.567z M106.915,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.66,0,1.196-0.536,1.196-1.196S107.575,253.159,106.915,253.159z M110.507,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S111.167,242.384,110.507,242.384z M106.915,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C108.111,246.511,107.575,245.975,106.915,245.975z M110.507,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C111.703,235.735,111.167,235.2,110.507,235.2z M106.915,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196S107.575,238.792,106.915,238.792z
                 M110.507,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S111.167,228.016,110.507,228.016z M106.915,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.66,0,1.196-0.536,1.196-1.196C108.111,232.143,107.575,231.608,106.915,231.608z M110.507,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,221.368,111.167,220.832,110.507,220.832z M106.915,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,224.96,107.575,224.424,106.915,224.424z
                 M110.507,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C111.703,214.184,111.167,213.648,110.507,213.648z M106.915,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,217.776,107.575,217.24,106.915,217.24z
                 M110.507,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S111.167,206.465,110.507,206.465z M106.915,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.66,0,1.196-0.536,1.196-1.196C108.111,210.592,107.575,210.057,106.915,210.057z M110.507,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C111.703,199.816,111.167,199.281,110.507,199.281z M106.915,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C108.111,203.408,107.575,202.873,106.915,202.873z
                 M111.703,193.293c0-0.349-0.155-0.656-0.393-0.875l-1.104,2.01c0.098,0.026,0.194,0.061,0.301,0.061
                C111.167,194.489,111.703,193.954,111.703,193.293z M114.098,190.897c0.661,0,1.196-0.536,1.196-1.196
                c0-0.661-0.536-1.196-1.196-1.196c-0.321,0-0.61,0.13-0.825,0.336l-0.313,0.57c-0.024,0.095-0.059,0.187-0.059,0.29
                C112.902,190.362,113.438,190.897,114.098,190.897z M118.887,322.602c0-0.661-0.536-1.196-1.196-1.196
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.401,0.21,0.738,0.513,0.955l1.164,0.137C118.591,323.508,118.887,323.09,118.887,322.602z
                 M117.69,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C118.887,314.757,118.351,314.222,117.69,314.222z M114.098,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,318.349,114.759,317.814,114.098,317.814z M117.69,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,307.573,118.351,307.038,117.69,307.038z M114.098,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,311.165,114.759,310.63,114.098,310.63z M117.69,299.854
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,300.39,118.351,299.854,117.69,299.854z M114.098,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C115.295,303.981,114.759,303.446,114.098,303.446z M117.69,292.67
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C118.887,293.206,118.351,292.67,117.69,292.67z M114.098,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,296.798,114.759,296.262,114.098,296.262z M117.69,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,286.022,118.351,285.486,117.69,285.486z M114.098,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,289.614,114.759,289.078,114.098,289.078z M117.69,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,278.838,118.351,278.303,117.69,278.303z M114.098,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,282.43,114.759,281.895,114.098,281.895z M117.69,271.119
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,271.654,118.351,271.119,117.69,271.119z M114.098,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,275.246,114.759,274.711,114.098,274.711z M117.69,263.935
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,264.471,118.351,263.935,117.69,263.935z M114.098,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C115.295,268.062,114.759,267.527,114.098,267.527z M117.69,256.751
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C118.887,257.287,118.351,256.751,117.69,256.751z M114.098,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,260.879,114.759,260.343,114.098,260.343z M117.69,249.567
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S118.351,249.567,117.69,249.567z
                 M114.098,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S114.759,253.159,114.098,253.159z M117.69,242.384c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S118.351,242.384,117.69,242.384z M114.098,245.975c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C115.295,246.511,114.759,245.975,114.098,245.975z M117.69,235.2
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C118.887,235.735,118.351,235.2,117.69,235.2z M114.098,238.792c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S114.759,238.792,114.098,238.792z M117.69,228.016c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S118.351,228.016,117.69,228.016z M114.098,231.608
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C115.295,232.143,114.759,231.608,114.098,231.608z M117.69,220.832c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C118.887,221.368,118.351,220.832,117.69,220.832z
                 M114.098,224.424c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C115.295,224.96,114.759,224.424,114.098,224.424z M117.69,213.648c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C118.887,214.184,118.351,213.648,117.69,213.648z
                 M114.098,217.24c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C115.295,217.776,114.759,217.24,114.098,217.24z M117.69,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S118.351,206.465,117.69,206.465z M114.098,210.057c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,210.592,114.759,210.057,114.098,210.057z M117.69,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C118.887,199.816,118.351,199.281,117.69,199.281z M114.098,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C115.295,203.408,114.759,202.873,114.098,202.873z M117.69,192.097
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S118.351,192.097,117.69,192.097z
                 M114.098,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S114.759,195.689,114.098,195.689z M117.69,184.913c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S118.351,184.913,117.69,184.913z M118.887,178.926c0-0.08-0.031-0.149-0.045-0.225l-0.734,1.337
                C118.561,179.867,118.887,179.438,118.887,178.926z M121.282,176.53c0.661,0,1.196-0.536,1.196-1.196
                c0-0.64-0.506-1.152-1.138-1.184l-1.02,1.858C120.537,176.315,120.877,176.53,121.282,176.53z M124.874,321.405
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C126.07,321.941,125.535,321.405,124.874,321.405z M124.874,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C126.07,314.757,125.535,314.222,124.874,314.222z M121.282,317.814
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,318.349,121.943,317.814,121.282,317.814z M124.874,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,307.573,125.535,307.038,124.874,307.038z M121.282,310.63
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,311.165,121.943,310.63,121.282,310.63z M124.874,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,300.39,125.535,299.854,124.874,299.854z M121.282,303.446
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C122.478,303.981,121.943,303.446,121.282,303.446z M124.874,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C126.07,293.206,125.535,292.67,124.874,292.67z M121.282,296.262
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,296.798,121.943,296.262,121.282,296.262z M124.874,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,286.022,125.535,285.486,124.874,285.486z M121.282,289.078
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,289.614,121.943,289.078,121.282,289.078z M124.874,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,278.838,125.535,278.303,124.874,278.303z M121.282,281.895
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,282.43,121.943,281.895,121.282,281.895z M124.874,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,271.654,125.535,271.119,124.874,271.119z M121.282,274.711
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,275.246,121.943,274.711,121.282,274.711z M124.874,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,264.471,125.535,263.935,124.874,263.935z M121.282,267.527
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C122.478,268.062,121.943,267.527,121.282,267.527z M124.874,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C126.07,257.287,125.535,256.751,124.874,256.751z M121.282,260.343
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,260.879,121.943,260.343,121.282,260.343z M124.874,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S125.535,249.567,124.874,249.567z M121.282,253.159c-0.66,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S121.943,253.159,121.282,253.159z M124.874,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S125.535,242.384,124.874,242.384z
                 M121.282,245.975c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C122.478,246.511,121.943,245.975,121.282,245.975z M124.874,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,235.735,125.535,235.2,124.874,235.2z M121.282,238.792
                c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S121.943,238.792,121.282,238.792z
                 M124.874,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S125.535,228.016,124.874,228.016z M121.282,231.608c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C122.478,232.143,121.943,231.608,121.282,231.608z M124.874,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C126.07,221.368,125.535,220.832,124.874,220.832z M121.282,224.424c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C122.478,224.96,121.943,224.424,121.282,224.424z
                 M124.874,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C126.07,214.184,125.535,213.648,124.874,213.648z M121.282,217.24c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C122.478,217.776,121.943,217.24,121.282,217.24z
                 M124.874,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S125.535,206.465,124.874,206.465z M121.282,210.057c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C122.478,210.592,121.943,210.057,121.282,210.057z M124.874,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C126.07,199.816,125.535,199.281,124.874,199.281z M121.282,202.873c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C122.478,203.408,121.943,202.873,121.282,202.873z
                 M124.874,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S125.535,192.097,124.874,192.097z M121.282,195.689c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S121.943,195.689,121.282,195.689z M124.874,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S125.535,184.913,124.874,184.913z M121.282,188.505
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C122.478,189.041,121.943,188.505,121.282,188.505z M124.874,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C126.07,178.265,125.535,177.729,124.874,177.729z M121.282,181.321
                c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S121.943,181.321,121.282,181.321z
                 M124.874,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S125.535,170.546,124.874,170.546z M132.058,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196C133.254,321.941,132.719,321.405,132.058,321.405z M132.058,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C133.254,314.757,132.719,314.222,132.058,314.222z M128.466,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,318.349,129.127,317.814,128.466,317.814z M132.058,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,307.573,132.719,307.038,132.058,307.038z M128.466,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,311.165,129.127,310.63,128.466,310.63z M132.058,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,300.39,132.719,299.854,132.058,299.854z M128.466,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C129.662,303.981,129.127,303.446,128.466,303.446z M132.058,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C133.254,293.206,132.719,292.67,132.058,292.67z M128.466,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,296.798,129.127,296.262,128.466,296.262z M132.058,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,286.022,132.719,285.486,132.058,285.486z M128.466,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,289.614,129.127,289.078,128.466,289.078z M132.058,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,278.838,132.719,278.303,132.058,278.303z M128.466,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,282.43,129.127,281.895,128.466,281.895z M132.058,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,271.654,132.719,271.119,132.058,271.119z M128.466,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,275.246,129.127,274.711,128.466,274.711z M132.058,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,264.471,132.719,263.935,132.058,263.935z M128.466,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C129.662,268.062,129.127,267.527,128.466,267.527z M132.058,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C133.254,257.287,132.719,256.751,132.058,256.751z M128.466,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,260.879,129.127,260.343,128.466,260.343z M132.058,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S132.719,249.567,132.058,249.567z M128.466,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S129.127,253.159,128.466,253.159z M132.058,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S132.719,242.384,132.058,242.384z
                 M128.466,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C129.662,246.511,129.127,245.975,128.466,245.975z M132.058,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,235.735,132.719,235.2,132.058,235.2z M128.466,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S129.127,238.792,128.466,238.792z
                 M132.058,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S132.719,228.016,132.058,228.016z M128.466,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C129.662,232.143,129.127,231.608,128.466,231.608z M132.058,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C133.254,221.368,132.719,220.832,132.058,220.832z M128.466,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C129.662,224.96,129.127,224.424,128.466,224.424z
                 M132.058,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C133.254,214.184,132.719,213.648,132.058,213.648z M128.466,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C129.662,217.776,129.127,217.24,128.466,217.24z
                 M132.058,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S132.719,206.465,132.058,206.465z M128.466,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C129.662,210.592,129.127,210.057,128.466,210.057z M132.058,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C133.254,199.816,132.719,199.281,132.058,199.281z M128.466,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C129.662,203.408,129.127,202.873,128.466,202.873z
                 M132.058,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S132.719,192.097,132.058,192.097z M128.466,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S129.127,195.689,128.466,195.689z M132.058,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S132.719,184.913,132.058,184.913z M128.466,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,189.041,129.127,188.505,128.466,188.505z M132.058,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,178.265,132.719,177.729,132.058,177.729z M128.466,181.321
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S129.127,181.321,128.466,181.321z
                 M132.058,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S132.719,170.546,132.058,170.546z M128.466,174.137c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S129.127,174.137,128.466,174.137z M132.058,163.362c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C133.254,163.897,132.719,163.362,132.058,163.362z M128.466,166.954
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C129.662,167.489,129.127,166.954,128.466,166.954z M129.662,160.966c0-0.416-0.225-0.764-0.547-0.978l-1.135,2.067
                c0.149,0.067,0.312,0.107,0.485,0.107C129.127,162.162,129.662,161.627,129.662,160.966z M132.058,156.178
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C133.254,156.714,132.719,156.178,132.058,156.178z M139.242,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C140.438,321.941,139.902,321.405,139.242,321.405z M135.65,324.997
                c-0.45,0-0.827,0.258-1.031,0.625l2.163,0.254C136.64,325.373,136.198,324.997,135.65,324.997z M139.242,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C140.438,314.757,139.902,314.222,139.242,314.222z M135.65,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,318.349,136.311,317.814,135.65,317.814z
                 M139.242,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,307.573,139.902,307.038,139.242,307.038z M135.65,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,311.165,136.311,310.63,135.65,310.63z
                 M139.242,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,300.39,139.902,299.854,139.242,299.854z M135.65,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C136.846,303.981,136.311,303.446,135.65,303.446z
                 M139.242,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C140.438,293.206,139.902,292.67,139.242,292.67z M135.65,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,296.798,136.311,296.262,135.65,296.262z
                 M139.242,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,286.022,139.902,285.486,139.242,285.486z M135.65,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,289.614,136.311,289.078,135.65,289.078z
                 M139.242,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,278.838,139.902,278.303,139.242,278.303z M135.65,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,282.43,136.311,281.895,135.65,281.895z
                 M139.242,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,271.654,139.902,271.119,139.242,271.119z M135.65,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,275.246,136.311,274.711,135.65,274.711z
                 M139.242,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,264.471,139.902,263.935,139.242,263.935z M135.65,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C136.846,268.062,136.311,267.527,135.65,267.527z
                 M139.242,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C140.438,257.287,139.902,256.751,139.242,256.751z M135.65,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,260.879,136.311,260.343,135.65,260.343z
                 M139.242,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S139.902,249.567,139.242,249.567z M135.65,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S136.311,253.159,135.65,253.159z M139.242,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S139.902,242.384,139.242,242.384z M135.65,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C136.846,246.511,136.311,245.975,135.65,245.975z M139.242,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C140.438,235.735,139.902,235.2,139.242,235.2z M135.65,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S136.311,238.792,135.65,238.792z
                 M139.242,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S139.902,228.016,139.242,228.016z M135.65,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C136.846,232.143,136.311,231.608,135.65,231.608z M139.242,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,221.368,139.902,220.832,139.242,220.832z M135.65,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,224.96,136.311,224.424,135.65,224.424z
                 M139.242,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C140.438,214.184,139.902,213.648,139.242,213.648z M135.65,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,217.776,136.311,217.24,135.65,217.24z
                 M139.242,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S139.902,206.465,139.242,206.465z M135.65,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C136.846,210.592,136.311,210.057,135.65,210.057z M139.242,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C140.438,199.816,139.902,199.281,139.242,199.281z M135.65,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C136.846,203.408,136.311,202.873,135.65,202.873z
                 M139.242,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S139.902,192.097,139.242,192.097z M135.65,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S136.311,195.689,135.65,195.689z M139.242,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S139.902,184.913,139.242,184.913z M135.65,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C136.846,189.041,136.311,188.505,135.65,188.505z M139.242,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C140.438,178.265,139.902,177.729,139.242,177.729z M135.65,181.321
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S136.311,181.321,135.65,181.321z
                 M139.242,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S139.902,170.546,139.242,170.546z M135.65,174.137c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S136.311,174.137,135.65,174.137z M139.242,163.362c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C140.438,163.897,139.902,163.362,139.242,163.362z M135.65,166.954
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C136.846,167.489,136.311,166.954,135.65,166.954z M139.242,156.178c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C140.438,156.714,139.902,156.178,139.242,156.178z M135.65,159.77
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S136.311,159.77,135.65,159.77z
                 M139.242,148.994c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S139.902,148.994,139.242,148.994z M135.65,152.586c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C136.846,153.122,136.311,152.586,135.65,152.586z M136.846,146.598
                c0-0.176-0.042-0.339-0.11-0.49l-0.906,1.649C136.402,147.668,136.846,147.195,136.846,146.598z M139.242,144.203
                c0.661,0,1.196-0.535,1.196-1.196c0-0.661-0.536-1.196-1.196-1.196c-0.058,0-0.108,0.025-0.164,0.033l-0.914,1.665
                C138.354,143.916,138.762,144.203,139.242,144.203z M146.426,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C147.622,321.941,147.086,321.405,146.426,321.405z
                 M142.834,324.997c-0.661,0-1.196,0.535-1.196,1.196c0,0.091,0.033,0.172,0.052,0.258l2.212,0.259
                c0.077-0.158,0.129-0.33,0.129-0.517C144.03,325.533,143.494,324.997,142.834,324.997z M146.426,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C147.622,314.757,147.086,314.222,146.426,314.222z M142.834,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,318.349,143.494,317.814,142.834,317.814z
                 M146.426,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,307.573,147.086,307.038,146.426,307.038z M142.834,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,311.165,143.494,310.63,142.834,310.63z
                 M146.426,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,300.39,147.086,299.854,146.426,299.854z M142.834,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C144.03,303.981,143.494,303.446,142.834,303.446z
                 M146.426,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C147.622,293.206,147.086,292.67,146.426,292.67z M142.834,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,296.798,143.494,296.262,142.834,296.262z
                 M146.426,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,286.022,147.086,285.486,146.426,285.486z M142.834,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,289.614,143.494,289.078,142.834,289.078z
                 M146.426,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,278.838,147.086,278.303,146.426,278.303z M142.834,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,282.43,143.494,281.895,142.834,281.895z
                 M146.426,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,271.654,147.086,271.119,146.426,271.119z M142.834,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,275.246,143.494,274.711,142.834,274.711z
                 M146.426,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,264.471,147.086,263.935,146.426,263.935z M142.834,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C144.03,268.062,143.494,267.527,142.834,267.527z
                 M146.426,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C147.622,257.287,147.086,256.751,146.426,256.751z M142.834,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,260.879,143.494,260.343,142.834,260.343z
                 M146.426,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S147.086,249.567,146.426,249.567z M142.834,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S143.494,253.159,142.834,253.159z M146.426,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S147.086,242.384,146.426,242.384z M142.834,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C144.03,246.511,143.494,245.975,142.834,245.975z M146.426,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C147.622,235.735,147.086,235.2,146.426,235.2z M142.834,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S143.494,238.792,142.834,238.792z
                 M146.426,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S147.086,228.016,146.426,228.016z M142.834,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C144.03,232.143,143.494,231.608,142.834,231.608z M146.426,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,221.368,147.086,220.832,146.426,220.832z M142.834,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,224.96,143.494,224.424,142.834,224.424z
                 M146.426,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,214.184,147.086,213.648,146.426,213.648z M142.834,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,217.776,143.494,217.24,142.834,217.24z
                 M146.426,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S147.086,206.465,146.426,206.465z M142.834,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C144.03,210.592,143.494,210.057,142.834,210.057z M146.426,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C147.622,199.816,147.086,199.281,146.426,199.281z M142.834,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,203.408,143.494,202.873,142.834,202.873z
                 M146.426,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S147.086,192.097,146.426,192.097z M142.834,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S143.494,195.689,142.834,195.689z M146.426,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S147.086,184.913,146.426,184.913z M142.834,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C144.03,189.041,143.494,188.505,142.834,188.505z M146.426,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C147.622,178.265,147.086,177.729,146.426,177.729z
                 M142.834,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S143.494,181.321,142.834,181.321z M146.426,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S147.086,170.546,146.426,170.546z M142.834,174.137c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S143.494,174.137,142.834,174.137z M146.426,163.362
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,163.897,147.086,163.362,146.426,163.362z M142.834,166.954c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C144.03,167.489,143.494,166.954,142.834,166.954z
                 M146.426,156.178c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C147.622,156.714,147.086,156.178,146.426,156.178z M142.834,159.77c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S143.494,159.77,142.834,159.77z M146.426,148.994c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S147.086,148.994,146.426,148.994z M142.834,152.586
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C144.03,153.122,143.494,152.586,142.834,152.586z M146.426,141.81c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C147.622,142.346,147.086,141.81,146.426,141.81z
                 M142.834,145.402c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S143.494,145.402,142.834,145.402z M146.426,134.627c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S147.086,134.627,146.426,134.627z M142.834,138.219c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S143.494,138.219,142.834,138.219z M147.622,128.639
                c0-0.482-0.289-0.893-0.7-1.082l-1.135,2.067c0.187,0.122,0.398,0.211,0.639,0.211C147.086,129.835,147.622,129.3,147.622,128.639z
                 M153.609,323.798c0.661,0,1.196-0.536,1.196-1.196c0-0.661-0.536-1.196-1.196-1.196c-0.661,0-1.196,0.536-1.196,1.196
                C152.413,323.262,152.949,323.798,153.609,323.798z M150.017,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C151.214,325.533,150.678,324.997,150.017,324.997z
                 M153.609,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C154.806,314.757,154.27,314.222,153.609,314.222z M150.017,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,318.349,150.678,317.814,150.017,317.814z
                 M153.609,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,307.573,154.27,307.038,153.609,307.038z M150.017,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,311.165,150.678,310.63,150.017,310.63z
                 M153.609,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,300.39,154.27,299.854,153.609,299.854z M150.017,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C151.214,303.981,150.678,303.446,150.017,303.446z
                 M153.609,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C154.806,293.206,154.27,292.67,153.609,292.67z M150.017,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,296.798,150.678,296.262,150.017,296.262z
                 M153.609,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,286.022,154.27,285.486,153.609,285.486z M150.017,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,289.614,150.678,289.078,150.017,289.078z
                 M153.609,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,278.838,154.27,278.303,153.609,278.303z M150.017,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,282.43,150.678,281.895,150.017,281.895z
                 M153.609,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,271.654,154.27,271.119,153.609,271.119z M150.017,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,275.246,150.678,274.711,150.017,274.711z
                 M153.609,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,264.471,154.27,263.935,153.609,263.935z M150.017,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C151.214,268.062,150.678,267.527,150.017,267.527z
                 M153.609,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C154.806,257.287,154.27,256.751,153.609,256.751z M150.017,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,260.879,150.678,260.343,150.017,260.343z
                 M153.609,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S154.27,249.567,153.609,249.567z M150.017,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S150.678,253.159,150.017,253.159z M153.609,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S154.27,242.384,153.609,242.384z M150.017,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C151.214,246.511,150.678,245.975,150.017,245.975z M153.609,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C154.806,235.735,154.27,235.2,153.609,235.2z M150.017,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S150.678,238.792,150.017,238.792z
                 M153.609,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S154.27,228.016,153.609,228.016z M150.017,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C151.214,232.143,150.678,231.608,150.017,231.608z M153.609,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,221.368,154.27,220.832,153.609,220.832z M150.017,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,224.96,150.678,224.424,150.017,224.424z
                 M153.609,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,214.184,154.27,213.648,153.609,213.648z M150.017,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,217.776,150.678,217.24,150.017,217.24z
                 M153.609,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S154.27,206.465,153.609,206.465z M150.017,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C151.214,210.592,150.678,210.057,150.017,210.057z M153.609,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C154.806,199.816,154.27,199.281,153.609,199.281z M150.017,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,203.408,150.678,202.873,150.017,202.873z
                 M153.609,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S154.27,192.097,153.609,192.097z M150.017,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S150.678,195.689,150.017,195.689z M153.609,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S154.27,184.913,153.609,184.913z M150.017,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C151.214,189.041,150.678,188.505,150.017,188.505z M153.609,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C154.806,178.265,154.27,177.729,153.609,177.729z
                 M150.017,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S150.678,181.321,150.017,181.321z M153.609,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S154.27,170.546,153.609,170.546z M150.017,174.137c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S150.678,174.137,150.017,174.137z M153.609,163.362
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,163.897,154.27,163.362,153.609,163.362z M150.017,166.954c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,167.489,150.678,166.954,150.017,166.954z
                 M153.609,156.178c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,156.714,154.27,156.178,153.609,156.178z M150.017,159.77c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S150.678,159.77,150.017,159.77z M153.609,148.994c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S154.27,148.994,153.609,148.994z M150.017,152.586
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C151.214,153.122,150.678,152.586,150.017,152.586z M153.609,141.81c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C154.806,142.346,154.27,141.81,153.609,141.81z
                 M150.017,145.402c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S150.678,145.402,150.017,145.402z M153.609,134.627c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S154.27,134.627,153.609,134.627z M150.017,138.219c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S150.678,138.219,150.017,138.219z M153.609,127.443
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C154.806,127.978,154.27,127.443,153.609,127.443z M150.017,131.035c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C151.214,131.57,150.678,131.035,150.017,131.035z
                 M152.413,121.455c0,0.661,0.536,1.196,1.196,1.196c0.506,0,0.933-0.316,1.108-0.759l-1.413-1.572
                C152.795,120.458,152.413,120.903,152.413,121.455z M156.005,125.047c0,0.661,0.536,1.196,1.196,1.196
                c0.404,0,0.744-0.214,0.96-0.521l-1.534-1.705C156.263,124.222,156.005,124.598,156.005,125.047z M150.017,123.851
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C151.214,124.386,150.678,123.851,150.017,123.851z M160.473,328.654l0.899,0.105c-0.174-0.099-0.364-0.17-0.579-0.17
                C160.68,328.589,160.577,328.624,160.473,328.654z M160.793,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,321.941,161.454,321.405,160.793,321.405z M157.201,324.997
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C158.397,325.533,157.862,324.997,157.201,324.997z M160.793,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C161.989,314.757,161.454,314.222,160.793,314.222z M157.201,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,318.349,157.862,317.814,157.201,317.814z M160.793,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,307.573,161.454,307.038,160.793,307.038z M157.201,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,311.165,157.862,310.63,157.201,310.63z M160.793,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,300.39,161.454,299.854,160.793,299.854z M157.201,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C158.397,303.981,157.862,303.446,157.201,303.446z M160.793,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C161.989,293.206,161.454,292.67,160.793,292.67z M157.201,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,296.798,157.862,296.262,157.201,296.262z M160.793,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,286.022,161.454,285.486,160.793,285.486z M157.201,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,289.614,157.862,289.078,157.201,289.078z M160.793,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,278.838,161.454,278.303,160.793,278.303z M157.201,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,282.43,157.862,281.895,157.201,281.895z M160.793,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,271.654,161.454,271.119,160.793,271.119z M157.201,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,275.246,157.862,274.711,157.201,274.711z M160.793,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,264.471,161.454,263.935,160.793,263.935z M157.201,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C158.397,268.062,157.862,267.527,157.201,267.527z M160.793,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C161.989,257.287,161.454,256.751,160.793,256.751z M157.201,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,260.879,157.862,260.343,157.201,260.343z M160.793,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S161.454,249.567,160.793,249.567z M157.201,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S157.862,253.159,157.201,253.159z M160.793,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S161.454,242.384,160.793,242.384z
                 M157.201,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C158.397,246.511,157.862,245.975,157.201,245.975z M160.793,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,235.735,161.454,235.2,160.793,235.2z M157.201,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S157.862,238.792,157.201,238.792z
                 M160.793,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S161.454,228.016,160.793,228.016z M157.201,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C158.397,232.143,157.862,231.608,157.201,231.608z M160.793,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C161.989,221.368,161.454,220.832,160.793,220.832z M157.201,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C158.397,224.96,157.862,224.424,157.201,224.424z
                 M160.793,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C161.989,214.184,161.454,213.648,160.793,213.648z M157.201,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C158.397,217.776,157.862,217.24,157.201,217.24z
                 M160.793,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S161.454,206.465,160.793,206.465z M157.201,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C158.397,210.592,157.862,210.057,157.201,210.057z M160.793,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C161.989,199.816,161.454,199.281,160.793,199.281z M157.201,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C158.397,203.408,157.862,202.873,157.201,202.873z
                 M160.793,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S161.454,192.097,160.793,192.097z M157.201,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S157.862,195.689,157.201,195.689z M160.793,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S161.454,184.913,160.793,184.913z M157.201,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,189.041,157.862,188.505,157.201,188.505z M160.793,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,178.265,161.454,177.729,160.793,177.729z M157.201,181.321
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S157.862,181.321,157.201,181.321z
                 M160.793,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S161.454,170.546,160.793,170.546z M157.201,174.137c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S157.862,174.137,157.201,174.137z M160.793,163.362c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,163.897,161.454,163.362,160.793,163.362z M157.201,166.954
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,167.489,157.862,166.954,157.201,166.954z M160.793,156.178c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C161.989,156.714,161.454,156.178,160.793,156.178z M157.201,159.77
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S157.862,159.77,157.201,159.77z
                 M160.793,148.994c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S161.454,148.994,160.793,148.994z M157.201,152.586c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.535,1.196-1.196C158.397,153.122,157.862,152.586,157.201,152.586z M160.793,141.81
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C161.989,142.346,161.454,141.81,160.793,141.81z M157.201,145.402c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S157.862,145.402,157.201,145.402z M160.793,134.627c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S161.454,134.627,160.793,134.627z M164.385,133.427
                c0.207,0,0.392-0.066,0.562-0.159l-1.53-1.702c-0.133,0.193-0.228,0.413-0.228,0.664
                C163.189,132.891,163.724,133.427,164.385,133.427z M157.201,138.219c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S157.862,138.219,157.201,138.219z M159.597,128.639c0,0.661,0.536,1.196,1.196,1.196
                c0.305,0,0.576-0.123,0.787-0.311l-1.585-1.763C159.754,127.98,159.597,128.288,159.597,128.639z M157.201,131.035
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C158.397,131.57,157.862,131.035,157.201,131.035z M167.977,328.589c-0.525,0-0.958,0.343-1.119,0.814l2.292,0.269
                C169.09,329.067,168.597,328.589,167.977,328.589z M167.977,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,321.941,168.638,321.405,167.977,321.405z
                 M164.385,324.997c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C165.581,325.533,165.046,324.997,164.385,324.997z M167.977,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C169.173,314.757,168.638,314.222,167.977,314.222z
                 M164.385,317.814c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,318.349,165.046,317.814,164.385,317.814z M167.977,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,307.573,168.638,307.038,167.977,307.038z
                 M164.385,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,311.165,165.046,310.63,164.385,310.63z M167.977,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,300.39,168.638,299.854,167.977,299.854z
                 M164.385,303.446c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C165.581,303.981,165.046,303.446,164.385,303.446z M167.977,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C169.173,293.206,168.638,292.67,167.977,292.67z
                 M164.385,296.262c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,296.798,165.046,296.262,164.385,296.262z M167.977,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,286.022,168.638,285.486,167.977,285.486z
                 M164.385,289.078c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,289.614,165.046,289.078,164.385,289.078z M167.977,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,278.838,168.638,278.303,167.977,278.303z
                 M164.385,281.895c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,282.43,165.046,281.895,164.385,281.895z M167.977,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,271.654,168.638,271.119,167.977,271.119z
                 M164.385,274.711c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,275.246,165.046,274.711,164.385,274.711z M167.977,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,264.471,168.638,263.935,167.977,263.935z
                 M164.385,267.527c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C165.581,268.062,165.046,267.527,164.385,267.527z M167.977,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C169.173,257.287,168.638,256.751,167.977,256.751z
                 M164.385,260.343c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,260.879,165.046,260.343,164.385,260.343z M167.977,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S168.638,249.567,167.977,249.567z M164.385,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S165.046,253.159,164.385,253.159z M167.977,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S168.638,242.384,167.977,242.384z
                 M164.385,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C165.581,246.511,165.046,245.975,164.385,245.975z M167.977,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,235.735,168.638,235.2,167.977,235.2z M164.385,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S165.046,238.792,164.385,238.792z
                 M167.977,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S168.638,228.016,167.977,228.016z M164.385,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C165.581,232.143,165.046,231.608,164.385,231.608z M167.977,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C169.173,221.368,168.638,220.832,167.977,220.832z M164.385,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C165.581,224.96,165.046,224.424,164.385,224.424z
                 M167.977,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C169.173,214.184,168.638,213.648,167.977,213.648z M164.385,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C165.581,217.776,165.046,217.24,164.385,217.24z
                 M167.977,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S168.638,206.465,167.977,206.465z M164.385,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C165.581,210.592,165.046,210.057,164.385,210.057z M167.977,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C169.173,199.816,168.638,199.281,167.977,199.281z M164.385,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C165.581,203.408,165.046,202.873,164.385,202.873z
                 M167.977,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S168.638,192.097,167.977,192.097z M164.385,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S165.046,195.689,164.385,195.689z M167.977,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S168.638,184.913,167.977,184.913z M164.385,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C165.581,189.041,165.046,188.505,164.385,188.505z M167.977,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C169.173,178.265,168.638,177.729,167.977,177.729z
                 M164.385,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S165.046,181.321,164.385,181.321z M167.977,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S168.638,170.546,167.977,170.546z M164.385,174.137c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S165.046,174.137,164.385,174.137z M167.977,163.362
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C169.173,163.897,168.638,163.362,167.977,163.362z M164.385,166.954c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C165.581,167.489,165.046,166.954,164.385,166.954z
                 M167.977,156.178c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C169.173,156.714,168.638,156.178,167.977,156.178z M164.385,159.77c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S165.046,159.77,164.385,159.77z M167.977,148.994c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S168.638,148.994,167.977,148.994z M164.385,152.586
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C165.581,153.122,165.046,152.586,164.385,152.586z M167.977,141.81c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C169.173,142.346,168.638,141.81,167.977,141.81z
                 M171.546,140.606l-1.154-1.284c-0.003,0.032-0.019,0.06-0.019,0.092C170.373,140.067,170.896,140.593,171.546,140.606z
                 M164.385,145.402c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S165.046,145.402,164.385,145.402z M167.977,137.019c0.103,0,0.195-0.034,0.29-0.059l-1.401-1.558
                c-0.05,0.132-0.085,0.272-0.085,0.421C166.781,136.483,167.316,137.019,167.977,137.019z M164.385,138.219
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S165.046,138.219,164.385,138.219z
                 M175.161,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.164,0.034,0.319,0.093,0.461l2.046,0.24
                c0.148-0.199,0.253-0.434,0.253-0.702C176.357,329.125,175.821,328.589,175.161,328.589z M175.161,321.405
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,321.941,175.821,321.405,175.161,321.405z M171.569,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C172.765,325.533,172.229,324.997,171.569,324.997z M175.161,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C176.357,314.757,175.821,314.222,175.161,314.222z M171.569,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,318.349,172.229,317.814,171.569,317.814z M175.161,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,307.573,175.821,307.038,175.161,307.038z M171.569,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,311.165,172.229,310.63,171.569,310.63z M175.161,299.854
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,300.39,175.821,299.854,175.161,299.854z M171.569,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C172.765,303.981,172.229,303.446,171.569,303.446z M175.161,292.67
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C176.357,293.206,175.821,292.67,175.161,292.67z M171.569,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,296.798,172.229,296.262,171.569,296.262z M175.161,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,286.022,175.821,285.486,175.161,285.486z M171.569,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,289.614,172.229,289.078,171.569,289.078z M175.161,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,278.838,175.821,278.303,175.161,278.303z M171.569,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,282.43,172.229,281.895,171.569,281.895z M175.161,271.119
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,271.654,175.821,271.119,175.161,271.119z M171.569,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,275.246,172.229,274.711,171.569,274.711z M175.161,263.935
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,264.471,175.821,263.935,175.161,263.935z M171.569,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C172.765,268.062,172.229,267.527,171.569,267.527z M175.161,256.751
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C176.357,257.287,175.821,256.751,175.161,256.751z M171.569,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,260.879,172.229,260.343,171.569,260.343z M175.161,249.567
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S175.821,249.567,175.161,249.567z
                 M171.569,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S172.229,253.159,171.569,253.159z M175.161,242.384c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S175.821,242.384,175.161,242.384z M171.569,245.975c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C172.765,246.511,172.229,245.975,171.569,245.975z M175.161,235.2
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,235.735,175.821,235.2,175.161,235.2z M171.569,238.792c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S172.229,238.792,171.569,238.792z M175.161,228.016c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S175.821,228.016,175.161,228.016z M171.569,231.608
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C172.765,232.143,172.229,231.608,171.569,231.608z M175.161,220.832c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C176.357,221.368,175.821,220.832,175.161,220.832z M171.569,224.424
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C172.765,224.96,172.229,224.424,171.569,224.424z M175.161,213.648c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C176.357,214.184,175.821,213.648,175.161,213.648z M171.569,217.24
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C172.765,217.776,172.229,217.24,171.569,217.24z M175.161,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S175.821,206.465,175.161,206.465z M171.569,210.057c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,210.592,172.229,210.057,171.569,210.057z M175.161,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C176.357,199.816,175.821,199.281,175.161,199.281z M171.569,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,203.408,172.229,202.873,171.569,202.873z M175.161,192.097
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S175.821,192.097,175.161,192.097z
                 M171.569,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S172.229,195.689,171.569,195.689z M175.161,184.913c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S175.821,184.913,175.161,184.913z M171.569,188.505c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,189.041,172.229,188.505,171.569,188.505z M175.161,177.729
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,178.265,175.821,177.729,175.161,177.729z M171.569,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S172.229,181.321,171.569,181.321z M175.161,170.546c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S175.821,170.546,175.161,170.546z M171.569,174.137
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S172.229,174.137,171.569,174.137z
                 M175.161,163.362c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,163.897,175.821,163.362,175.161,163.362z M171.569,166.954c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C172.765,167.489,172.229,166.954,171.569,166.954z M175.161,156.178
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C176.357,156.714,175.821,156.178,175.161,156.178z M171.569,159.77c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S172.229,159.77,171.569,159.77z M175.161,148.994c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S175.821,148.994,175.161,148.994z M171.569,152.586
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C172.765,153.122,172.229,152.586,171.569,152.586z M174.695,144.109l-0.655-0.729
                C174.149,143.706,174.381,143.976,174.695,144.109z M171.569,145.402c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S172.229,145.402,171.569,145.402z M182.345,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C183.541,329.125,183.005,328.589,182.345,328.589z
                 M182.345,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,321.941,183.005,321.405,182.345,321.405z M178.753,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C179.949,325.533,179.413,324.997,178.753,324.997z
                 M182.345,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C183.541,314.757,183.005,314.222,182.345,314.222z M178.753,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,318.349,179.413,317.814,178.753,317.814z
                 M182.345,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,307.573,183.005,307.038,182.345,307.038z M178.753,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,311.165,179.413,310.63,178.753,310.63z
                 M182.345,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,300.39,183.005,299.854,182.345,299.854z M178.753,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C179.949,303.981,179.413,303.446,178.753,303.446z
                 M182.345,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C183.541,293.206,183.005,292.67,182.345,292.67z M178.753,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,296.798,179.413,296.262,178.753,296.262z
                 M182.345,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,286.022,183.005,285.486,182.345,285.486z M178.753,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,289.614,179.413,289.078,178.753,289.078z
                 M182.345,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,278.838,183.005,278.303,182.345,278.303z M178.753,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,282.43,179.413,281.895,178.753,281.895z
                 M182.345,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,271.654,183.005,271.119,182.345,271.119z M178.753,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,275.246,179.413,274.711,178.753,274.711z
                 M182.345,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,264.471,183.005,263.935,182.345,263.935z M178.753,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C179.949,268.062,179.413,267.527,178.753,267.527z
                 M182.345,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C183.541,257.287,183.005,256.751,182.345,256.751z M178.753,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,260.879,179.413,260.343,178.753,260.343z
                 M182.345,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S183.005,249.567,182.345,249.567z M178.753,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S179.413,253.159,178.753,253.159z M182.345,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S183.005,242.384,182.345,242.384z M178.753,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C179.949,246.511,179.413,245.975,178.753,245.975z M182.345,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C183.541,235.735,183.005,235.2,182.345,235.2z M178.753,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S179.413,238.792,178.753,238.792z
                 M182.345,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S183.005,228.016,182.345,228.016z M178.753,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C179.949,232.143,179.413,231.608,178.753,231.608z M182.345,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,221.368,183.005,220.832,182.345,220.832z M178.753,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,224.96,179.413,224.424,178.753,224.424z
                 M182.345,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,214.184,183.005,213.648,182.345,213.648z M178.753,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,217.776,179.413,217.24,178.753,217.24z
                 M182.345,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S183.005,206.465,182.345,206.465z M178.753,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C179.949,210.592,179.413,210.057,178.753,210.057z M182.345,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C183.541,199.816,183.005,199.281,182.345,199.281z M178.753,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,203.408,179.413,202.873,178.753,202.873z
                 M182.345,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S183.005,192.097,182.345,192.097z M178.753,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S179.413,195.689,178.753,195.689z M182.345,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S183.005,184.913,182.345,184.913z M178.753,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C179.949,189.041,179.413,188.505,178.753,188.505z M182.345,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C183.541,178.265,183.005,177.729,182.345,177.729z
                 M178.753,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S179.413,181.321,178.753,181.321z M182.345,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S183.005,170.546,182.345,170.546z M178.753,174.137c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S179.413,174.137,178.753,174.137z M182.345,163.362
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,163.897,183.005,163.362,182.345,163.362z M178.753,166.954c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C179.949,167.489,179.413,166.954,178.753,166.954z
                 M182.345,156.178c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C183.541,156.714,183.005,156.178,182.345,156.178z M178.753,159.77c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S179.413,159.77,178.753,159.77z M178.753,152.586c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C179.949,153.122,179.413,152.586,178.753,152.586z
                 M189.528,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C190.725,329.125,190.189,328.589,189.528,328.589z M189.528,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,321.941,190.189,321.405,189.528,321.405z M185.936,324.997
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C187.133,325.533,186.597,324.997,185.936,324.997z M189.528,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C190.725,314.757,190.189,314.222,189.528,314.222z M185.936,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,318.349,186.597,317.814,185.936,317.814z M189.528,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,307.573,190.189,307.038,189.528,307.038z M185.936,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,311.165,186.597,310.63,185.936,310.63z M189.528,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,300.39,190.189,299.854,189.528,299.854z M185.936,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C187.133,303.981,186.597,303.446,185.936,303.446z M189.528,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C190.725,293.206,190.189,292.67,189.528,292.67z M185.936,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,296.798,186.597,296.262,185.936,296.262z M189.528,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,286.022,190.189,285.486,189.528,285.486z M185.936,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,289.614,186.597,289.078,185.936,289.078z M189.528,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,278.838,190.189,278.303,189.528,278.303z M185.936,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,282.43,186.597,281.895,185.936,281.895z M189.528,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,271.654,190.189,271.119,189.528,271.119z M185.936,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,275.246,186.597,274.711,185.936,274.711z M189.528,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,264.471,190.189,263.935,189.528,263.935z M185.936,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C187.133,268.062,186.597,267.527,185.936,267.527z M189.528,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C190.725,257.287,190.189,256.751,189.528,256.751z M185.936,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,260.879,186.597,260.343,185.936,260.343z M189.528,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S190.189,249.567,189.528,249.567z M185.936,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S186.597,253.159,185.936,253.159z M189.528,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S190.189,242.384,189.528,242.384z
                 M185.936,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C187.133,246.511,186.597,245.975,185.936,245.975z M189.528,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,235.735,190.189,235.2,189.528,235.2z M185.936,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S186.597,238.792,185.936,238.792z
                 M189.528,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S190.189,228.016,189.528,228.016z M185.936,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196C187.133,232.143,186.597,231.608,185.936,231.608z M189.528,220.832c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,221.368,190.189,220.832,189.528,220.832z M185.936,224.424
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,224.96,186.597,224.424,185.936,224.424z M189.528,213.648c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C190.725,214.184,190.189,213.648,189.528,213.648z M185.936,217.24
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C187.133,217.776,186.597,217.24,185.936,217.24z M189.528,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S190.189,206.465,189.528,206.465z M185.936,210.057c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C187.133,210.592,186.597,210.057,185.936,210.057z M189.528,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C190.725,199.816,190.189,199.281,189.528,199.281z M185.936,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C187.133,203.408,186.597,202.873,185.936,202.873z M189.528,192.097
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S190.189,192.097,189.528,192.097z
                 M185.936,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S186.597,195.689,185.936,195.689z M189.528,184.913c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S190.189,184.913,189.528,184.913z M185.936,188.505c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C187.133,189.041,186.597,188.505,185.936,188.505z M189.528,177.729
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C190.725,178.265,190.189,177.729,189.528,177.729z M185.936,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S186.597,181.321,185.936,181.321z M189.528,170.546c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S190.189,170.546,189.528,170.546z M185.936,174.137
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S186.597,174.137,185.936,174.137z
                 M189.528,163.362c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C190.725,163.897,190.189,163.362,189.528,163.362z M185.936,166.954c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C187.133,167.489,186.597,166.954,185.936,166.954z M185.936,159.77
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S186.597,159.77,185.936,159.77z
                 M196.712,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C197.908,329.125,197.373,328.589,196.712,328.589z M193.12,332.181c-0.249,0-0.467,0.094-0.658,0.224l1.541,0.181
                C193.784,332.341,193.474,332.181,193.12,332.181z M196.712,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,321.941,197.373,321.405,196.712,321.405z M193.12,324.997
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C194.316,325.533,193.781,324.997,193.12,324.997z M196.712,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C197.908,314.757,197.373,314.222,196.712,314.222z M193.12,317.814
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,318.349,193.781,317.814,193.12,317.814z M196.712,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,307.573,197.373,307.038,196.712,307.038z M193.12,310.63
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,311.165,193.781,310.63,193.12,310.63z M196.712,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,300.39,197.373,299.854,196.712,299.854z M193.12,303.446
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C194.316,303.981,193.781,303.446,193.12,303.446z M196.712,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C197.908,293.206,197.373,292.67,196.712,292.67z M193.12,296.262
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,296.798,193.781,296.262,193.12,296.262z M196.712,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,286.022,197.373,285.486,196.712,285.486z M193.12,289.078
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,289.614,193.781,289.078,193.12,289.078z M196.712,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,278.838,197.373,278.303,196.712,278.303z M193.12,281.895
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,282.43,193.781,281.895,193.12,281.895z M196.712,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,271.654,197.373,271.119,196.712,271.119z M193.12,274.711
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,275.246,193.781,274.711,193.12,274.711z M196.712,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,264.471,197.373,263.935,196.712,263.935z M193.12,267.527
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C194.316,268.062,193.781,267.527,193.12,267.527z M196.712,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C197.908,257.287,197.373,256.751,196.712,256.751z M193.12,260.343
                c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,260.879,193.781,260.343,193.12,260.343z M196.712,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S197.373,249.567,196.712,249.567z M193.12,253.159c-0.66,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S193.781,253.159,193.12,253.159z M196.712,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S197.373,242.384,196.712,242.384z
                 M193.12,245.975c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C194.316,246.511,193.781,245.975,193.12,245.975z M196.712,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,235.735,197.373,235.2,196.712,235.2z M193.12,238.792
                c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S193.781,238.792,193.12,238.792z
                 M196.712,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S197.373,228.016,196.712,228.016z M193.12,231.608c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C194.316,232.143,193.781,231.608,193.12,231.608z M196.712,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C197.908,221.368,197.373,220.832,196.712,220.832z M193.12,224.424c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C194.316,224.96,193.781,224.424,193.12,224.424z
                 M196.712,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C197.908,214.184,197.373,213.648,196.712,213.648z M193.12,217.24c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C194.316,217.776,193.781,217.24,193.12,217.24z
                 M196.712,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S197.373,206.465,196.712,206.465z M193.12,210.057c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C194.316,210.592,193.781,210.057,193.12,210.057z M196.712,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C197.908,199.816,197.373,199.281,196.712,199.281z M193.12,202.873c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C194.316,203.408,193.781,202.873,193.12,202.873z
                 M196.712,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S197.373,192.097,196.712,192.097z M193.12,195.689c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S193.781,195.689,193.12,195.689z M196.712,184.913c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S197.373,184.913,196.712,184.913z M193.12,188.505
                c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C194.316,189.041,193.781,188.505,193.12,188.505z M196.712,177.729c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C197.908,178.265,197.373,177.729,196.712,177.729z M193.12,181.321
                c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S193.781,181.321,193.12,181.321z
                 M196.712,170.546c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S197.373,170.546,196.712,170.546z M193.12,174.137c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S193.781,174.137,193.12,174.137z M193.12,166.954c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C194.316,167.489,193.781,166.954,193.12,166.954z
                 M203.896,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,329.125,204.557,328.589,203.896,328.589z M200.304,332.181c-0.594,0-1.064,0.44-1.158,1.008l2.337,0.274
                c0.002-0.03,0.017-0.055,0.017-0.086C201.5,332.717,200.965,332.181,200.304,332.181z M203.896,321.405
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,321.941,204.557,321.405,203.896,321.405z M200.304,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.535,1.196-1.196C201.5,325.533,200.965,324.997,200.304,324.997z M203.896,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C205.092,314.757,204.557,314.222,203.896,314.222z M200.304,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,318.349,200.965,317.814,200.304,317.814z M203.896,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,307.573,204.557,307.038,203.896,307.038z M200.304,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,311.165,200.965,310.63,200.304,310.63z M203.896,299.854
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,300.39,204.557,299.854,203.896,299.854z M200.304,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.535,1.196-1.196C201.5,303.981,200.965,303.446,200.304,303.446z M203.896,292.67
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C205.092,293.206,204.557,292.67,203.896,292.67z M200.304,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,296.798,200.965,296.262,200.304,296.262z M203.896,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,286.022,204.557,285.486,203.896,285.486z M200.304,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,289.614,200.965,289.078,200.304,289.078z M203.896,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,278.838,204.557,278.303,203.896,278.303z M200.304,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,282.43,200.965,281.895,200.304,281.895z M203.896,271.119
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,271.654,204.557,271.119,203.896,271.119z M200.304,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,275.246,200.965,274.711,200.304,274.711z M203.896,263.935
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,264.471,204.557,263.935,203.896,263.935z M200.304,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.535,1.196-1.196C201.5,268.062,200.965,267.527,200.304,267.527z M203.896,256.751
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C205.092,257.287,204.557,256.751,203.896,256.751z M200.304,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,260.879,200.965,260.343,200.304,260.343z M203.896,249.567
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S204.557,249.567,203.896,249.567z
                 M200.304,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S200.965,253.159,200.304,253.159z M203.896,242.384c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S204.557,242.384,203.896,242.384z M200.304,245.975c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.535,1.196-1.196C201.5,246.511,200.965,245.975,200.304,245.975z M203.896,235.2
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,235.735,204.557,235.2,203.896,235.2z M200.304,238.792c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S200.965,238.792,200.304,238.792z M203.896,228.016c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S204.557,228.016,203.896,228.016z M200.304,231.608
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C201.5,232.143,200.965,231.608,200.304,231.608z M203.896,220.832c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C205.092,221.368,204.557,220.832,203.896,220.832z
                 M200.304,224.424c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C201.5,224.96,200.965,224.424,200.304,224.424z M203.896,213.648c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C205.092,214.184,204.557,213.648,203.896,213.648z
                 M200.304,217.24c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C201.5,217.776,200.965,217.24,200.304,217.24z M203.896,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S204.557,206.465,203.896,206.465z M200.304,210.057c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,210.592,200.965,210.057,200.304,210.057z M203.896,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C205.092,199.816,204.557,199.281,203.896,199.281z M200.304,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,203.408,200.965,202.873,200.304,202.873z M203.896,192.097
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S204.557,192.097,203.896,192.097z
                 M200.304,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S200.965,195.689,200.304,195.689z M203.896,184.913c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S204.557,184.913,203.896,184.913z M200.304,188.505c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196C201.5,189.041,200.965,188.505,200.304,188.505z M203.896,177.729
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C205.092,178.265,204.557,177.729,203.896,177.729z M200.304,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S200.965,181.321,200.304,181.321z M200.304,174.137c-0.661,0-1.196,0.536-1.196,1.196
                s0.535,1.196,1.196,1.196s1.196-0.536,1.196-1.196S200.965,174.137,200.304,174.137z M208.684,333.377
                c0-0.661-0.536-1.196-1.196-1.196c-0.661,0-1.196,0.536-1.196,1.196c0,0.257,0.099,0.482,0.237,0.677l1.751,0.205
                C208.524,334.041,208.684,333.731,208.684,333.377z M211.08,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C212.276,329.125,211.74,328.589,211.08,328.589z
                 M211.08,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,321.941,211.74,321.405,211.08,321.405z M207.488,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C208.684,325.533,208.148,324.997,207.488,324.997z
                 M211.08,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C212.276,314.757,211.74,314.222,211.08,314.222z M207.488,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,318.349,208.148,317.814,207.488,317.814z
                 M211.08,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,307.573,211.74,307.038,211.08,307.038z M207.488,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,311.165,208.148,310.63,207.488,310.63z
                 M211.08,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,300.39,211.74,299.854,211.08,299.854z M207.488,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C208.684,303.981,208.148,303.446,207.488,303.446z
                 M211.08,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C212.276,293.206,211.74,292.67,211.08,292.67z M207.488,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,296.798,208.148,296.262,207.488,296.262z
                 M211.08,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,286.022,211.74,285.486,211.08,285.486z M207.488,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,289.614,208.148,289.078,207.488,289.078z
                 M211.08,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,278.838,211.74,278.303,211.08,278.303z M207.488,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,282.43,208.148,281.895,207.488,281.895z
                 M211.08,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,271.654,211.74,271.119,211.08,271.119z M207.488,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,275.246,208.148,274.711,207.488,274.711z
                 M211.08,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,264.471,211.74,263.935,211.08,263.935z M207.488,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C208.684,268.062,208.148,267.527,207.488,267.527z
                 M211.08,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C212.276,257.287,211.74,256.751,211.08,256.751z M207.488,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,260.879,208.148,260.343,207.488,260.343z
                 M211.08,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S211.74,249.567,211.08,249.567z M207.488,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S208.148,253.159,207.488,253.159z M211.08,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S211.74,242.384,211.08,242.384z M207.488,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C208.684,246.511,208.148,245.975,207.488,245.975z M211.08,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C212.276,235.735,211.74,235.2,211.08,235.2z M207.488,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S208.148,238.792,207.488,238.792z
                 M211.08,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S211.74,228.016,211.08,228.016z M207.488,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C208.684,232.143,208.148,231.608,207.488,231.608z M211.08,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,221.368,211.74,220.832,211.08,220.832z M207.488,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,224.96,208.148,224.424,207.488,224.424z
                 M211.08,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C212.276,214.184,211.74,213.648,211.08,213.648z M207.488,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,217.776,208.148,217.24,207.488,217.24z
                 M211.08,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S211.74,206.465,211.08,206.465z M207.488,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C208.684,210.592,208.148,210.057,207.488,210.057z M211.08,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C212.276,199.816,211.74,199.281,211.08,199.281z M207.488,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C208.684,203.408,208.148,202.873,207.488,202.873z
                 M211.08,192.097c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S211.74,192.097,211.08,192.097z M207.488,195.689c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S208.148,195.689,207.488,195.689z M209.884,186.109c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196c0-0.096-0.033-0.181-0.055-0.27l-0.764-0.85c-0.12-0.041-0.243-0.076-0.377-0.076
                C210.419,184.913,209.884,185.449,209.884,186.109z M213.475,189.701c0,0.661,0.536,1.196,1.196,1.196
                c0.605,0,1.084-0.456,1.164-1.038l-1.209-1.345C213.988,188.539,213.475,189.057,213.475,189.701z M207.488,188.505
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C208.684,189.041,208.148,188.505,207.488,188.505z M207.488,181.321c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S208.148,181.321,207.488,181.321z M218.264,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,329.125,218.924,328.589,218.264,328.589z M214.672,332.181
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,332.717,215.332,332.181,214.672,332.181z M218.264,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,321.941,218.924,321.405,218.264,321.405z M214.672,324.997
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C215.868,325.533,215.332,324.997,214.672,324.997z M218.264,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C219.46,314.757,218.924,314.222,218.264,314.222z M214.672,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,318.349,215.332,317.814,214.672,317.814z M218.264,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,307.573,218.924,307.038,218.264,307.038z M214.672,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,311.165,215.332,310.63,214.672,310.63z M218.264,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,300.39,218.924,299.854,218.264,299.854z M214.672,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C215.868,303.981,215.332,303.446,214.672,303.446z M218.264,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C219.46,293.206,218.924,292.67,218.264,292.67z M214.672,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,296.798,215.332,296.262,214.672,296.262z M218.264,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,286.022,218.924,285.486,218.264,285.486z M214.672,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,289.614,215.332,289.078,214.672,289.078z M218.264,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,278.838,218.924,278.303,218.264,278.303z M214.672,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,282.43,215.332,281.895,214.672,281.895z M218.264,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,271.654,218.924,271.119,218.264,271.119z M214.672,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,275.246,215.332,274.711,214.672,274.711z M218.264,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,264.471,218.924,263.935,218.264,263.935z M214.672,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C215.868,268.062,215.332,267.527,214.672,267.527z M218.264,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C219.46,257.287,218.924,256.751,218.264,256.751z M214.672,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C215.868,260.879,215.332,260.343,214.672,260.343z M218.264,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S218.924,249.567,218.264,249.567z M214.672,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196S215.332,253.159,214.672,253.159z M218.264,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S218.924,242.384,218.264,242.384z
                 M214.672,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C215.868,246.511,215.332,245.975,214.672,245.975z M218.264,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C219.46,235.735,218.924,235.2,218.264,235.2z M214.672,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196S215.332,238.792,214.672,238.792z
                 M218.264,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S218.924,228.016,218.264,228.016z M214.672,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.66,0,1.196-0.536,1.196-1.196C215.868,232.143,215.332,231.608,214.672,231.608z M218.264,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C219.46,221.368,218.924,220.832,218.264,220.832z M214.672,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C215.868,224.96,215.332,224.424,214.672,224.424z
                 M218.264,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C219.46,214.184,218.924,213.648,218.264,213.648z M214.672,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C215.868,217.776,215.332,217.24,214.672,217.24z
                 M218.264,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S218.924,206.465,218.264,206.465z M214.672,210.057c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.66,0,1.196-0.536,1.196-1.196C215.868,210.592,215.332,210.057,214.672,210.057z M218.264,199.281
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C219.46,199.816,218.924,199.281,218.264,199.281z M214.672,202.873c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C215.868,203.408,215.332,202.873,214.672,202.873z
                 M217.067,193.293c0,0.661,0.536,1.196,1.196,1.196c0.489,0,0.907-0.295,1.092-0.716l-1.444-1.605
                C217.426,192.32,217.067,192.757,217.067,193.293z M220.659,196.885c0,0.661,0.536,1.196,1.196,1.196
                c0.389,0,0.719-0.198,0.937-0.486l-1.544-1.717C220.903,196.088,220.659,196.451,220.659,196.885z M214.672,195.689
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196S215.332,195.689,214.672,195.689z
                 M225.447,335.773c-0.351,0-0.659,0.157-0.877,0.397l1.905,0.223C226.27,336.029,225.895,335.773,225.447,335.773z
                 M225.447,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,329.125,226.108,328.589,225.447,328.589z M221.855,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,332.717,222.516,332.181,221.855,332.181z
                 M225.447,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,321.941,226.108,321.405,225.447,321.405z M221.855,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C223.052,325.533,222.516,324.997,221.855,324.997z
                 M225.447,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C226.644,314.757,226.108,314.222,225.447,314.222z M221.855,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,318.349,222.516,317.814,221.855,317.814z
                 M225.447,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,307.573,226.108,307.038,225.447,307.038z M221.855,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,311.165,222.516,310.63,221.855,310.63z
                 M225.447,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,300.39,226.108,299.854,225.447,299.854z M221.855,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C223.052,303.981,222.516,303.446,221.855,303.446z
                 M225.447,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C226.644,293.206,226.108,292.67,225.447,292.67z M221.855,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,296.798,222.516,296.262,221.855,296.262z
                 M225.447,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,286.022,226.108,285.486,225.447,285.486z M221.855,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,289.614,222.516,289.078,221.855,289.078z
                 M225.447,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,278.838,226.108,278.303,225.447,278.303z M221.855,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,282.43,222.516,281.895,221.855,281.895z
                 M225.447,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,271.654,226.108,271.119,225.447,271.119z M221.855,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,275.246,222.516,274.711,221.855,274.711z
                 M225.447,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,264.471,226.108,263.935,225.447,263.935z M221.855,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C223.052,268.062,222.516,267.527,221.855,267.527z
                 M225.447,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C226.644,257.287,226.108,256.751,225.447,256.751z M221.855,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,260.879,222.516,260.343,221.855,260.343z
                 M225.447,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S226.108,249.567,225.447,249.567z M221.855,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S222.516,253.159,221.855,253.159z M225.447,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S226.108,242.384,225.447,242.384z M221.855,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C223.052,246.511,222.516,245.975,221.855,245.975z M225.447,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C226.644,235.735,226.108,235.2,225.447,235.2z M221.855,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S222.516,238.792,221.855,238.792z
                 M225.447,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S226.108,228.016,225.447,228.016z M221.855,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C223.052,232.143,222.516,231.608,221.855,231.608z M225.447,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,221.368,226.108,220.832,225.447,220.832z M221.855,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,224.96,222.516,224.424,221.855,224.424z
                 M225.447,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C226.644,214.184,226.108,213.648,225.447,213.648z M221.855,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C223.052,217.776,222.516,217.24,221.855,217.24z
                 M225.447,206.465c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S226.108,206.465,225.447,206.465z M229.039,205.265c0.192,0,0.367-0.056,0.528-0.136l-1.52-1.69
                c-0.118,0.186-0.205,0.393-0.205,0.63C227.843,204.73,228.379,205.265,229.039,205.265z M221.855,210.057
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C223.052,210.592,222.516,210.057,221.855,210.057z M224.251,200.477c0,0.661,0.536,1.196,1.196,1.196
                c0.29,0,0.546-0.116,0.754-0.288l-1.586-1.763C224.392,199.839,224.251,200.141,224.251,200.477z M221.855,202.873
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C223.052,203.408,222.516,202.873,221.855,202.873z M232.631,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.002,0.001,0.004,0.001,0.006l2.335,0.274c0.023-0.092,0.057-0.181,0.057-0.28
                C233.827,336.309,233.292,335.773,232.631,335.773z M232.631,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,329.125,233.292,328.589,232.631,328.589z M229.039,332.181
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,332.717,229.7,332.181,229.039,332.181z M232.631,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,321.941,233.292,321.405,232.631,321.405z M229.039,324.997
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C230.235,325.533,229.7,324.997,229.039,324.997z M232.631,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C233.827,314.757,233.292,314.222,232.631,314.222z M229.039,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,318.349,229.7,317.814,229.039,317.814z M232.631,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,307.573,233.292,307.038,232.631,307.038z M229.039,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,311.165,229.7,310.63,229.039,310.63z M232.631,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,300.39,233.292,299.854,232.631,299.854z M229.039,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C230.235,303.981,229.7,303.446,229.039,303.446z M232.631,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C233.827,293.206,233.292,292.67,232.631,292.67z M229.039,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,296.798,229.7,296.262,229.039,296.262z M232.631,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,286.022,233.292,285.486,232.631,285.486z M229.039,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,289.614,229.7,289.078,229.039,289.078z M232.631,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,278.838,233.292,278.303,232.631,278.303z M229.039,281.895
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,282.43,229.7,281.895,229.039,281.895z M232.631,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,271.654,233.292,271.119,232.631,271.119z M229.039,274.711
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,275.246,229.7,274.711,229.039,274.711z M232.631,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,264.471,233.292,263.935,232.631,263.935z M229.039,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C230.235,268.062,229.7,267.527,229.039,267.527z M232.631,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C233.827,257.287,233.292,256.751,232.631,256.751z M229.039,260.343
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C230.235,260.879,229.7,260.343,229.039,260.343z M232.631,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S233.292,249.567,232.631,249.567z M229.039,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S229.7,253.159,229.039,253.159z M232.631,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196S233.292,242.384,232.631,242.384z
                 M229.039,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C230.235,246.511,229.7,245.975,229.039,245.975z M232.631,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C233.827,235.735,233.292,235.2,232.631,235.2z M229.039,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S229.7,238.792,229.039,238.792z
                 M232.631,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S233.292,228.016,232.631,228.016z M229.039,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C230.235,232.143,229.7,231.608,229.039,231.608z M232.631,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C233.827,221.368,233.292,220.832,232.631,220.832z M229.039,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C230.235,224.96,229.7,224.424,229.039,224.424z
                 M232.631,213.648c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C233.827,214.184,233.292,213.648,232.631,213.648z M236.134,212.431l-1.098-1.221c-0.001,0.015-0.009,0.028-0.009,0.043
                C235.027,211.882,235.517,212.383,236.134,212.431z M229.039,217.24c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C230.235,217.776,229.7,217.24,229.039,217.24z M232.631,208.857
                c0.087,0,0.163-0.032,0.244-0.049l-1.366-1.519c-0.039,0.119-0.075,0.24-0.075,0.372
                C231.435,208.321,231.971,208.857,232.631,208.857z M229.039,210.057c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C230.235,210.592,229.7,210.057,229.039,210.057z
                 M241.011,336.969c0-0.661-0.535-1.196-1.196-1.196c-0.661,0-1.196,0.536-1.196,1.196c0,0.364,0.172,0.679,0.429,0.899l1.308,0.153
                C240.74,337.822,241.011,337.432,241.011,336.969z M239.815,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,329.125,240.476,328.589,239.815,328.589z
                 M236.223,332.181c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,332.717,236.884,332.181,236.223,332.181z M239.815,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,321.941,240.476,321.405,239.815,321.405z
                 M236.223,324.997c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C237.419,325.533,236.884,324.997,236.223,324.997z M239.815,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C241.011,314.757,240.476,314.222,239.815,314.222z
                 M236.223,317.814c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,318.349,236.884,317.814,236.223,317.814z M239.815,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,307.573,240.476,307.038,239.815,307.038z
                 M236.223,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,311.165,236.884,310.63,236.223,310.63z M239.815,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,300.39,240.476,299.854,239.815,299.854z
                 M236.223,303.446c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C237.419,303.981,236.884,303.446,236.223,303.446z M239.815,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C241.011,293.206,240.476,292.67,239.815,292.67z
                 M236.223,296.262c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,296.798,236.884,296.262,236.223,296.262z M239.815,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,286.022,240.476,285.486,239.815,285.486z
                 M236.223,289.078c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,289.614,236.884,289.078,236.223,289.078z M239.815,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,278.838,240.476,278.303,239.815,278.303z
                 M236.223,281.895c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,282.43,236.884,281.895,236.223,281.895z M239.815,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,271.654,240.476,271.119,239.815,271.119z
                 M236.223,274.711c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,275.246,236.884,274.711,236.223,274.711z M239.815,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,264.471,240.476,263.935,239.815,263.935z
                 M236.223,267.527c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C237.419,268.062,236.884,267.527,236.223,267.527z M239.815,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C241.011,257.287,240.476,256.751,239.815,256.751z
                 M236.223,260.343c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,260.879,236.884,260.343,236.223,260.343z M239.815,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S240.476,249.567,239.815,249.567z M236.223,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S236.884,253.159,236.223,253.159z M239.815,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S240.476,242.384,239.815,242.384z
                 M236.223,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C237.419,246.511,236.884,245.975,236.223,245.975z M239.815,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C241.011,235.735,240.476,235.2,239.815,235.2z M236.223,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S236.884,238.792,236.223,238.792z
                 M239.815,228.016c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S240.476,228.016,239.815,228.016z M236.223,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196C237.419,232.143,236.884,231.608,236.223,231.608z M239.815,220.832
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C241.011,221.368,240.476,220.832,239.815,220.832z M236.223,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C237.419,224.96,236.884,224.424,236.223,224.424z
                 M239.212,215.854l-0.503-0.559C238.808,215.536,238.992,215.722,239.212,215.854z M236.223,217.24
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C237.419,217.776,236.884,217.24,236.223,217.24z M246.999,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C248.195,336.309,247.659,335.773,246.999,335.773z
                 M246.999,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,329.125,247.659,328.589,246.999,328.589z M243.407,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,332.717,244.067,332.181,243.407,332.181z M246.999,321.405
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,321.941,247.659,321.405,246.999,321.405z M243.407,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C244.603,325.533,244.067,324.997,243.407,324.997z M246.999,314.222
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C248.195,314.757,247.659,314.222,246.999,314.222z M243.407,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,318.349,244.067,317.814,243.407,317.814z M246.999,307.038
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,307.573,247.659,307.038,246.999,307.038z M243.407,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,311.165,244.067,310.63,243.407,310.63z M246.999,299.854
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,300.39,247.659,299.854,246.999,299.854z M243.407,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C244.603,303.981,244.067,303.446,243.407,303.446z M246.999,292.67
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C248.195,293.206,247.659,292.67,246.999,292.67z M243.407,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,296.798,244.067,296.262,243.407,296.262z M246.999,285.486
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,286.022,247.659,285.486,246.999,285.486z M243.407,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,289.614,244.067,289.078,243.407,289.078z M246.999,278.303
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,278.838,247.659,278.303,246.999,278.303z M243.407,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,282.43,244.067,281.895,243.407,281.895z M246.999,271.119
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,271.654,247.659,271.119,246.999,271.119z M243.407,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,275.246,244.067,274.711,243.407,274.711z M246.999,263.935
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,264.471,247.659,263.935,246.999,263.935z M243.407,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C244.603,268.062,244.067,267.527,243.407,267.527z M246.999,256.751
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C248.195,257.287,247.659,256.751,246.999,256.751z M243.407,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,260.879,244.067,260.343,243.407,260.343z M246.999,249.567
                c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S247.659,249.567,246.999,249.567z
                 M243.407,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                S244.067,253.159,243.407,253.159z M246.999,242.384c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S247.659,242.384,246.999,242.384z M243.407,245.975c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C244.603,246.511,244.067,245.975,243.407,245.975z M246.999,235.2
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C248.195,235.735,247.659,235.2,246.999,235.2z M243.407,238.792c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                s1.196-0.536,1.196-1.196S244.067,238.792,243.407,238.792z M246.999,228.016c-0.661,0-1.196,0.536-1.196,1.196
                s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S247.659,228.016,246.999,228.016z M243.407,231.608
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C244.603,232.143,244.067,231.608,243.407,231.608z M243.407,224.424c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C244.603,224.96,244.067,224.424,243.407,224.424z M254.183,335.773
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C255.379,336.309,254.843,335.773,254.183,335.773z M254.183,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,329.125,254.843,328.589,254.183,328.589z
                 M250.591,332.181c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,332.717,251.251,332.181,250.591,332.181z M254.183,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,321.941,254.843,321.405,254.183,321.405z
                 M250.591,324.997c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C251.787,325.533,251.251,324.997,250.591,324.997z M254.183,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C255.379,314.757,254.843,314.222,254.183,314.222z
                 M250.591,317.814c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,318.349,251.251,317.814,250.591,317.814z M254.183,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,307.573,254.843,307.038,254.183,307.038z
                 M250.591,310.63c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,311.165,251.251,310.63,250.591,310.63z M254.183,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,300.39,254.843,299.854,254.183,299.854z
                 M250.591,303.446c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C251.787,303.981,251.251,303.446,250.591,303.446z M254.183,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C255.379,293.206,254.843,292.67,254.183,292.67z
                 M250.591,296.262c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,296.798,251.251,296.262,250.591,296.262z M254.183,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,286.022,254.843,285.486,254.183,285.486z
                 M250.591,289.078c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,289.614,251.251,289.078,250.591,289.078z M254.183,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,278.838,254.843,278.303,254.183,278.303z
                 M250.591,281.895c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,282.43,251.251,281.895,250.591,281.895z M254.183,271.119c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,271.654,254.843,271.119,254.183,271.119z
                 M250.591,274.711c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,275.246,251.251,274.711,250.591,274.711z M254.183,263.935c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,264.471,254.843,263.935,254.183,263.935z
                 M250.591,267.527c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C251.787,268.062,251.251,267.527,250.591,267.527z M254.183,256.751c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C255.379,257.287,254.843,256.751,254.183,256.751z
                 M250.591,260.343c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,260.879,251.251,260.343,250.591,260.343z M254.183,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S254.843,249.567,254.183,249.567z M250.591,253.159c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S251.251,253.159,250.591,253.159z M254.183,242.384
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S254.843,242.384,254.183,242.384z
                 M250.591,245.975c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C251.787,246.511,251.251,245.975,250.591,245.975z M254.183,235.2c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C255.379,235.735,254.843,235.2,254.183,235.2z M250.591,238.792
                c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S251.251,238.792,250.591,238.792z
                 M250.591,231.608c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C251.787,232.143,251.251,231.608,250.591,231.608z M261.366,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C262.563,336.309,262.027,335.773,261.366,335.773z
                 M257.774,339.365c-0.431,0-0.791,0.24-1.002,0.581l2.124,0.249C258.74,339.717,258.305,339.365,257.774,339.365z M261.366,328.589
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,329.125,262.027,328.589,261.366,328.589z M257.774,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,332.717,258.435,332.181,257.774,332.181z
                 M261.366,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,321.941,262.027,321.405,261.366,321.405z M257.774,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C258.971,325.533,258.435,324.997,257.774,324.997z
                 M261.366,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C262.563,314.757,262.027,314.222,261.366,314.222z M257.774,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,318.349,258.435,317.814,257.774,317.814z
                 M261.366,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,307.573,262.027,307.038,261.366,307.038z M257.774,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,311.165,258.435,310.63,257.774,310.63z
                 M261.366,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,300.39,262.027,299.854,261.366,299.854z M257.774,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C258.971,303.981,258.435,303.446,257.774,303.446z
                 M261.366,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C262.563,293.206,262.027,292.67,261.366,292.67z M257.774,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,296.798,258.435,296.262,257.774,296.262z
                 M261.366,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,286.022,262.027,285.486,261.366,285.486z M257.774,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,289.614,258.435,289.078,257.774,289.078z
                 M261.366,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,278.838,262.027,278.303,261.366,278.303z M257.774,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,282.43,258.435,281.895,257.774,281.895z
                 M261.366,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,271.654,262.027,271.119,261.366,271.119z M257.774,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,275.246,258.435,274.711,257.774,274.711z
                 M261.366,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C262.563,264.471,262.027,263.935,261.366,263.935z M257.774,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C258.971,268.062,258.435,267.527,257.774,267.527z
                 M261.366,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C262.563,257.287,262.027,256.751,261.366,256.751z M257.774,260.343c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C258.971,260.879,258.435,260.343,257.774,260.343z
                 M261.366,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S262.027,249.567,261.366,249.567z M257.774,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S258.435,253.159,257.774,253.159z M261.366,242.384c-0.661,0-1.196,0.536-1.196,1.196
                s0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196S262.027,242.384,261.366,242.384z M257.774,245.975
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C258.971,246.511,258.435,245.975,257.774,245.975z M257.774,238.792c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S258.435,238.792,257.774,238.792z M268.55,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C269.746,336.309,269.211,335.773,268.55,335.773z
                 M264.958,339.365c-0.66,0-1.196,0.535-1.196,1.196c0,0.074,0.029,0.139,0.042,0.21l2.251,0.264
                c0.063-0.145,0.099-0.305,0.099-0.474C266.154,339.9,265.619,339.365,264.958,339.365z M268.55,328.589
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,329.125,269.211,328.589,268.55,328.589z M264.958,332.181c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,332.717,265.619,332.181,264.958,332.181z
                 M268.55,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,321.941,269.211,321.405,268.55,321.405z M264.958,324.997c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C266.154,325.533,265.619,324.997,264.958,324.997z
                 M268.55,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C269.746,314.757,269.211,314.222,268.55,314.222z M264.958,317.814c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,318.349,265.619,317.814,264.958,317.814z
                 M268.55,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,307.573,269.211,307.038,268.55,307.038z M264.958,310.63c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,311.165,265.619,310.63,264.958,310.63z
                 M268.55,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,300.39,269.211,299.854,268.55,299.854z M264.958,303.446c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C266.154,303.981,265.619,303.446,264.958,303.446z
                 M268.55,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C269.746,293.206,269.211,292.67,268.55,292.67z M264.958,296.262c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,296.798,265.619,296.262,264.958,296.262z
                 M268.55,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,286.022,269.211,285.486,268.55,285.486z M264.958,289.078c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,289.614,265.619,289.078,264.958,289.078z
                 M268.55,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,278.838,269.211,278.303,268.55,278.303z M264.958,281.895c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,282.43,265.619,281.895,264.958,281.895z
                 M268.55,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,271.654,269.211,271.119,268.55,271.119z M264.958,274.711c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,275.246,265.619,274.711,264.958,274.711z
                 M268.55,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C269.746,264.471,269.211,263.935,268.55,263.935z M264.958,267.527c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C266.154,268.062,265.619,267.527,264.958,267.527z
                 M268.55,256.751c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C269.746,257.287,269.211,256.751,268.55,256.751z M264.958,260.343c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C266.154,260.879,265.619,260.343,264.958,260.343z
                 M268.55,249.567c-0.661,0-1.196,0.536-1.196,1.196s0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                S269.211,249.567,268.55,249.567z M264.958,253.159c-0.66,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S265.619,253.159,264.958,253.159z M264.958,245.975c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C266.154,246.511,265.619,245.975,264.958,245.975z
                 M273.338,340.561c0-0.661-0.535-1.196-1.196-1.196c-0.661,0-1.196,0.535-1.196,1.196c0,0.624,0.483,1.121,1.093,1.175l0.13,0.015
                C272.817,341.736,273.338,341.212,273.338,340.561z M275.734,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C276.93,336.309,276.395,335.773,275.734,335.773z
                 M275.734,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,329.125,276.395,328.589,275.734,328.589z M272.142,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,332.717,272.803,332.181,272.142,332.181z
                 M275.734,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,321.941,276.395,321.405,275.734,321.405z M272.142,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C273.338,325.533,272.803,324.997,272.142,324.997z
                 M275.734,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C276.93,314.757,276.395,314.222,275.734,314.222z M272.142,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,318.349,272.803,317.814,272.142,317.814z
                 M275.734,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,307.573,276.395,307.038,275.734,307.038z M272.142,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,311.165,272.803,310.63,272.142,310.63z
                 M275.734,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,300.39,276.395,299.854,275.734,299.854z M272.142,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C273.338,303.981,272.803,303.446,272.142,303.446z
                 M275.734,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C276.93,293.206,276.395,292.67,275.734,292.67z M272.142,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,296.798,272.803,296.262,272.142,296.262z
                 M275.734,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,286.022,276.395,285.486,275.734,285.486z M272.142,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,289.614,272.803,289.078,272.142,289.078z
                 M275.734,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,278.838,276.395,278.303,275.734,278.303z M272.142,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,282.43,272.803,281.895,272.142,281.895z
                 M275.734,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,271.654,276.395,271.119,275.734,271.119z M272.142,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C273.338,275.246,272.803,274.711,272.142,274.711z
                 M275.734,263.935c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C276.93,264.471,276.395,263.935,275.734,263.935z M272.142,267.527c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C273.338,268.062,272.803,267.527,272.142,267.527z
                 M274.538,257.947c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196c0-0.068-0.028-0.127-0.039-0.192l-0.846-0.941
                c-0.102-0.028-0.201-0.063-0.311-0.063C275.073,256.751,274.538,257.287,274.538,257.947z M278.13,261.539
                c0,0.661,0.535,1.196,1.196,1.196c0.587,0,1.053-0.431,1.154-0.989l-1.245-1.385C278.619,260.41,278.13,260.911,278.13,261.539z
                 M272.142,260.343c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C273.338,260.879,272.803,260.343,272.142,260.343z M272.142,253.159c-0.661,0-1.196,0.536-1.196,1.196s0.536,1.196,1.196,1.196
                c0.661,0,1.196-0.536,1.196-1.196S272.803,253.159,272.142,253.159z M282.745,342.992l0.667,0.078
                c-0.151-0.069-0.317-0.113-0.494-0.113C282.856,342.957,282.803,342.983,282.745,342.992z M282.918,335.773
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,336.309,283.578,335.773,282.918,335.773z M279.326,339.365c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,339.9,279.986,339.365,279.326,339.365z
                 M282.918,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,329.125,283.578,328.589,282.918,328.589z M279.326,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,332.717,279.986,332.181,279.326,332.181z
                 M282.918,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,321.941,283.578,321.405,282.918,321.405z M279.326,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C280.522,325.533,279.986,324.997,279.326,324.997z
                 M282.918,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C284.114,314.757,283.578,314.222,282.918,314.222z M279.326,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,318.349,279.986,317.814,279.326,317.814z
                 M282.918,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,307.573,283.578,307.038,282.918,307.038z M279.326,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,311.165,279.986,310.63,279.326,310.63z
                 M282.918,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,300.39,283.578,299.854,282.918,299.854z M279.326,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C280.522,303.981,279.986,303.446,279.326,303.446z
                 M282.918,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C284.114,293.206,283.578,292.67,282.918,292.67z M279.326,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,296.798,279.986,296.262,279.326,296.262z
                 M282.918,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,286.022,283.578,285.486,282.918,285.486z M279.326,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,289.614,279.986,289.078,279.326,289.078z
                 M282.918,278.303c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,278.838,283.578,278.303,282.918,278.303z M279.326,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,282.43,279.986,281.895,279.326,281.895z
                 M282.918,271.119c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C284.114,271.654,283.578,271.119,282.918,271.119z M279.326,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C280.522,275.246,279.986,274.711,279.326,274.711z
                 M281.722,265.131c0,0.661,0.536,1.196,1.196,1.196c0.474,0,0.876-0.282,1.069-0.682l-1.466-1.63
                C282.058,264.18,281.722,264.611,281.722,265.131z M285.314,268.723c0,0.661,0.535,1.196,1.196,1.196
                c0.374,0,0.695-0.182,0.914-0.452l-1.555-1.729C285.542,267.952,285.314,268.303,285.314,268.723z M279.326,267.527
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C280.522,268.062,279.986,267.527,279.326,267.527z M290.102,342.957c-0.509,0-0.937,0.32-1.11,0.768l2.273,0.267
                C291.183,343.41,290.705,342.957,290.102,342.957z M290.102,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,336.309,290.762,335.773,290.102,335.773z M286.51,339.365
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C287.706,339.9,287.17,339.365,286.51,339.365z M290.102,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,329.125,290.762,328.589,290.102,328.589z M286.51,332.181
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C287.706,332.717,287.17,332.181,286.51,332.181z M290.102,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,321.941,290.762,321.405,290.102,321.405z M286.51,324.997
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C287.706,325.533,287.17,324.997,286.51,324.997z M290.102,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C291.298,314.757,290.762,314.222,290.102,314.222z M286.51,317.814
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C287.706,318.349,287.17,317.814,286.51,317.814z M290.102,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,307.573,290.762,307.038,290.102,307.038z M286.51,310.63
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C287.706,311.165,287.17,310.63,286.51,310.63z M290.102,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,300.39,290.762,299.854,290.102,299.854z M286.51,303.446
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C287.706,303.981,287.17,303.446,286.51,303.446z M290.102,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196C291.298,293.206,290.762,292.67,290.102,292.67z M286.51,296.262
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C287.706,296.798,287.17,296.262,286.51,296.262z M290.102,285.486c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,286.022,290.762,285.486,290.102,285.486z M286.51,289.078
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C287.706,289.614,287.17,289.078,286.51,289.078z M290.102,278.303c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C291.298,278.838,290.762,278.303,290.102,278.303z M293.693,277.103
                c0.178,0,0.343-0.044,0.495-0.113l-1.509-1.678c-0.105,0.178-0.182,0.374-0.182,0.596
                C292.497,276.568,293.033,277.103,293.693,277.103z M286.51,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C287.706,282.43,287.17,281.895,286.51,281.895z
                 M290.102,273.511c0.275,0,0.518-0.109,0.72-0.265l-1.579-1.756c-0.206,0.215-0.337,0.504-0.337,0.826
                C288.905,272.976,289.441,273.511,290.102,273.511z M286.51,274.711c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C287.706,275.246,287.17,274.711,286.51,274.711z
                 M297.285,342.957c-0.661,0-1.196,0.536-1.196,1.196c0,0.147,0.035,0.284,0.084,0.413l2.085,0.245
                c0.13-0.191,0.224-0.409,0.224-0.658C298.482,343.492,297.946,342.957,297.285,342.957z M297.285,335.773
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C298.482,336.309,297.946,335.773,297.285,335.773z M293.693,339.365c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,339.9,294.354,339.365,293.693,339.365z
                 M297.285,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C298.482,329.125,297.946,328.589,297.285,328.589z M293.693,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,332.717,294.354,332.181,293.693,332.181z
                 M297.285,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C298.482,321.941,297.946,321.405,297.285,321.405z M293.693,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C294.89,325.533,294.354,324.997,293.693,324.997z
                 M297.285,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C298.482,314.757,297.946,314.222,297.285,314.222z M293.693,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,318.349,294.354,317.814,293.693,317.814z
                 M297.285,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C298.482,307.573,297.946,307.038,297.285,307.038z M293.693,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,311.165,294.354,310.63,293.693,310.63z
                 M297.285,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C298.482,300.39,297.946,299.854,297.285,299.854z M293.693,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C294.89,303.981,294.354,303.446,293.693,303.446z
                 M297.285,292.67c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C298.482,293.206,297.946,292.67,297.285,292.67z M293.693,296.262c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,296.798,294.354,296.262,293.693,296.262z
                 M297.285,285.486c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C298.482,286.022,297.946,285.486,297.285,285.486z M300.722,284.255l-1.039-1.155
                C299.688,283.701,300.141,284.177,300.722,284.255z M293.693,289.078c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,289.614,294.354,289.078,293.693,289.078z
                 M297.285,280.695c0.07,0,0.132-0.028,0.199-0.04l-1.33-1.479c-0.03,0.105-0.065,0.208-0.065,0.323
                C296.089,280.159,296.625,280.695,297.285,280.695z M293.693,281.895c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C294.89,282.43,294.354,281.895,293.693,281.895z
                 M304.469,342.957c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C305.665,343.492,305.13,342.957,304.469,342.957z M304.469,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C305.665,336.309,305.13,335.773,304.469,335.773z
                 M300.877,339.365c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C302.073,339.9,301.538,339.365,300.877,339.365z M304.469,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C305.665,329.125,305.13,328.589,304.469,328.589z
                 M300.877,332.181c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C302.073,332.717,301.538,332.181,300.877,332.181z M304.469,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C305.665,321.941,305.13,321.405,304.469,321.405z
                 M300.877,324.997c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C302.073,325.533,301.538,324.997,300.877,324.997z M304.469,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C305.665,314.757,305.13,314.222,304.469,314.222z
                 M300.877,317.814c-0.66,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C302.073,318.349,301.538,317.814,300.877,317.814z M304.469,307.038c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C305.665,307.573,305.13,307.038,304.469,307.038z
                 M300.877,310.63c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C302.073,311.165,301.538,310.63,300.877,310.63z M304.469,299.854c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C305.665,300.39,305.13,299.854,304.469,299.854z
                 M300.877,303.446c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C302.073,303.981,301.538,303.446,300.877,303.446z M304.469,292.67c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C305.665,293.206,305.13,292.67,304.469,292.67z
                 M300.877,296.262c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C302.073,296.798,301.538,296.262,300.877,296.262z M303.729,287.6l-0.231-0.257C303.565,287.44,303.638,287.526,303.729,287.6z
                 M300.877,289.078c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C302.073,289.614,301.538,289.078,300.877,289.078z M311.653,342.957c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C312.849,343.492,312.314,342.957,311.653,342.957z
                 M311.653,335.773c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C312.849,336.309,312.314,335.773,311.653,335.773z M308.061,339.365c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C309.257,339.9,308.722,339.365,308.061,339.365z
                 M311.653,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C312.849,329.125,312.314,328.589,311.653,328.589z M308.061,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C309.257,332.717,308.722,332.181,308.061,332.181z
                 M311.653,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C312.849,321.941,312.314,321.405,311.653,321.405z M308.061,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C309.257,325.533,308.722,324.997,308.061,324.997z
                 M311.653,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C312.849,314.757,312.314,314.222,311.653,314.222z M308.061,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C309.257,318.349,308.722,317.814,308.061,317.814z
                 M311.653,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C312.849,307.573,312.314,307.038,311.653,307.038z M308.061,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C309.257,311.165,308.722,310.63,308.061,310.63z
                 M311.653,299.854c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C312.849,300.39,312.314,299.854,311.653,299.854z M308.061,303.446c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C309.257,303.981,308.722,303.446,308.061,303.446z
                 M308.061,296.262c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C309.257,296.798,308.722,296.262,308.061,296.262z M318.837,342.957c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C320.033,343.492,319.497,342.957,318.837,342.957z
                 M315.245,346.549c-0.223,0-0.42,0.078-0.599,0.184l1.447,0.17C315.876,346.684,315.577,346.549,315.245,346.549z M318.837,335.773
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C320.033,336.309,319.497,335.773,318.837,335.773z M315.245,339.365c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C316.441,339.9,315.905,339.365,315.245,339.365z
                 M318.837,328.589c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C320.033,329.125,319.497,328.589,318.837,328.589z M315.245,332.181c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C316.441,332.717,315.905,332.181,315.245,332.181z
                 M318.837,321.405c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C320.033,321.941,319.497,321.405,318.837,321.405z M315.245,324.997c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C316.441,325.533,315.905,324.997,315.245,324.997z
                 M318.837,314.222c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C320.033,314.757,319.497,314.222,318.837,314.222z M315.245,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C316.441,318.349,315.905,317.814,315.245,317.814z
                 M318.837,307.038c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C320.033,307.573,319.497,307.038,318.837,307.038z M315.245,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C316.441,311.165,315.905,310.63,315.245,310.63z
                 M315.245,303.446c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196
                C316.441,303.981,315.905,303.446,315.245,303.446z M326.021,342.957c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C327.217,343.492,326.681,342.957,326.021,342.957z
                 M322.429,346.549c-0.578,0-1.037,0.418-1.149,0.962l2.337,0.274c0.001-0.014,0.008-0.026,0.008-0.04
                C323.625,347.084,323.089,346.549,322.429,346.549z M326.021,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C327.217,336.309,326.681,335.773,326.021,335.773z
                 M322.429,339.365c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C323.625,339.9,323.089,339.365,322.429,339.365z M326.021,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C327.217,329.125,326.681,328.589,326.021,328.589z
                 M322.429,332.181c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C323.625,332.717,323.089,332.181,322.429,332.181z M326.021,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C327.217,321.941,326.681,321.405,326.021,321.405z
                 M322.429,324.997c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.535,1.196-1.196
                C323.625,325.533,323.089,324.997,322.429,324.997z M326.021,314.222c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C327.217,314.757,326.681,314.222,326.021,314.222z
                 M322.429,317.814c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196
                C323.625,318.349,323.089,317.814,322.429,317.814z M322.429,310.63c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.66,0,1.196-0.536,1.196-1.196C323.625,311.165,323.089,310.63,322.429,310.63z
                 M333.204,342.957c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C334.401,343.492,333.865,342.957,333.204,342.957z M329.612,346.549c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.235,0.086,0.441,0.202,0.626l1.842,0.216c0.215-0.216,0.348-0.513,0.348-0.842
                C330.809,347.084,330.273,346.549,329.612,346.549z M333.204,335.773c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C334.401,336.309,333.865,335.773,333.204,335.773z
                 M329.612,339.365c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C330.809,339.9,330.273,339.365,329.612,339.365z M333.204,328.589c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C334.401,329.125,333.865,328.589,333.204,328.589z
                 M329.612,332.181c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196
                C330.809,332.717,330.273,332.181,329.612,332.181z M333.204,321.405c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C334.401,321.941,333.865,321.405,333.204,321.405z
                 M329.612,324.997c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196s1.196-0.535,1.196-1.196
                C330.809,325.533,330.273,324.997,329.612,324.997z M329.612,317.814c-0.661,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196s1.196-0.536,1.196-1.196C330.809,318.349,330.273,317.814,329.612,317.814z M340.388,342.957
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C341.584,343.492,341.049,342.957,340.388,342.957z M336.796,346.549c-0.66,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C337.992,347.084,337.457,346.549,336.796,346.549z
                 M340.388,335.773c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C341.584,336.309,341.049,335.773,340.388,335.773z M336.796,339.365c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C337.992,339.9,337.457,339.365,336.796,339.365z
                 M339.192,329.785c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196c0-0.041-0.019-0.075-0.023-0.115l-0.928-1.032
                c-0.082-0.018-0.158-0.049-0.245-0.049C339.728,328.589,339.192,329.125,339.192,329.785z M342.784,333.377
                c0,0.661,0.536,1.196,1.196,1.196c0.57,0,1.024-0.407,1.145-0.94l-1.281-1.424C343.25,332.279,342.784,332.765,342.784,333.377z
                 M336.796,332.181c-0.66,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C337.992,332.717,337.457,332.181,336.796,332.181z M336.796,324.997c-0.66,0-1.196,0.535-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.535,1.196-1.196C337.992,325.533,337.457,324.997,336.796,324.997z
                 M347.572,350.141c-0.332,0-0.631,0.135-0.848,0.354l1.842,0.216C348.354,350.375,347.997,350.141,347.572,350.141z
                 M347.572,342.957c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C348.768,343.492,348.233,342.957,347.572,342.957z M343.98,346.549c-0.661,0-1.196,0.536-1.196,1.196
                c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196C345.176,347.084,344.641,346.549,343.98,346.549z
                 M346.376,336.969c0,0.661,0.536,1.196,1.196,1.196c0.459,0,0.846-0.268,1.046-0.647l-1.489-1.656
                C346.689,336.039,346.376,336.466,346.376,336.969z M349.968,340.561c0,0.661,0.535,1.196,1.196,1.196
                c0.359,0,0.672-0.166,0.891-0.417l-1.565-1.741C350.182,339.816,349.968,340.156,349.968,340.561z M343.98,339.365
                c-0.661,0-1.196,0.535-1.196,1.196c0,0.661,0.536,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C345.176,339.9,344.641,339.365,343.98,339.365z M355.952,351.337c0-0.661-0.536-1.196-1.196-1.196
                c-0.646,0-1.166,0.515-1.188,1.156l2.337,0.274C355.921,351.492,355.952,351.42,355.952,351.337z M358.348,348.941
                c0.163,0,0.318-0.034,0.459-0.093l-1.497-1.665c-0.092,0.17-0.158,0.355-0.158,0.561
                C357.151,348.405,357.687,348.941,358.348,348.941z M354.756,345.349c0.261,0,0.49-0.101,0.686-0.243l-1.569-1.745
                c-0.19,0.212-0.313,0.485-0.313,0.791C353.56,344.814,354.095,345.349,354.756,345.349z M351.164,346.549
                c-0.661,0-1.196,0.536-1.196,1.196c0,0.661,0.535,1.196,1.196,1.196c0.661,0,1.196-0.536,1.196-1.196
                C352.36,347.084,351.824,346.549,351.164,346.549z M360.799,351.063c-0.022,0.09-0.055,0.176-0.055,0.274
                c0,0.329,0.133,0.626,0.348,0.842l0.794,0.093L360.799,351.063z M329.556,79.114c0,0.66-0.54,1.19-1.2,1.19
                c-0.66,0-1.19-0.53-1.19-1.19c0-0.66,0.53-1.2,1.19-1.2C329.016,77.914,329.556,78.454,329.556,79.114z M328.356,85.104
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C329.556,85.634,329.016,85.104,328.356,85.104z
                 M328.356,106.654c-0.66,0-1.19,0.53-1.19,1.19c0,0.05,0.02,0.1,0.03,0.15l0.97,1.01c0.07,0.01,0.13,0.04,0.19,0.04
                c0.66,0,1.2-0.54,1.2-1.2C329.556,107.184,329.016,106.654,328.356,106.654z M328.356,99.464c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C329.556,100.004,329.016,99.464,328.356,99.464z M328.356,92.284
                c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C329.556,92.814,329.016,92.284,328.356,92.284z
                 M321.176,70.734c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C322.376,71.264,321.836,70.734,321.176,70.734z M324.766,74.324c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C325.966,74.864,325.426,74.324,324.766,74.324z M324.766,103.054c-0.65,0-1.18,0.53-1.19,1.19l1.16,1.2
                c0.01,0,0.02,0.01,0.03,0.01c0.66,0,1.2-0.54,1.2-1.2C325.966,103.594,325.426,103.054,324.766,103.054z M324.766,88.694
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C325.966,89.224,325.426,88.694,324.766,88.694z
                 M321.176,57.144l-1.14,0.12c-0.02,0.1-0.06,0.19-0.06,0.3c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                c0-0.19-0.06-0.37-0.13-0.52L321.176,57.144z M321.176,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C322.376,64.084,321.836,63.544,321.176,63.544z M324.766,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S325.426,59.954,324.766,59.954z M324.766,95.874c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C325.966,96.414,325.426,95.874,324.766,95.874z M324.766,81.504c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C325.966,82.044,325.426,81.504,324.766,81.504z M324.766,67.144
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C325.966,67.674,325.426,67.144,324.766,67.144z
                 M321.176,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C322.376,78.454,321.836,77.914,321.176,77.914z M321.176,85.104c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C322.376,85.634,321.836,85.104,321.176,85.104z M321.176,99.464c-0.62,0-1.11,0.48-1.17,1.08l0.12,0.12
                l1.05,1.09l0.08,0.09c0.62-0.05,1.12-0.55,1.12-1.18C322.376,100.004,321.836,99.464,321.176,99.464z M321.176,92.284
                c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C322.376,92.814,321.836,92.284,321.176,92.284z
                 M317.586,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S318.246,59.954,317.586,59.954z
                 M317.586,95.874c-0.58,0-1.04,0.42-1.15,0.97l1.34,1.38c0.56-0.09,1-0.56,1-1.15C318.776,96.414,318.246,95.874,317.586,95.874z
                 M313.996,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C315.186,78.454,314.656,77.914,313.996,77.914z M317.586,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C318.776,67.674,318.246,67.144,317.586,67.144z M317.586,74.324c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C318.776,74.864,318.246,74.324,317.586,74.324z M313.996,85.104
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C315.186,85.634,314.656,85.104,313.996,85.104z
                 M313.996,70.734c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C315.186,71.264,314.656,70.734,313.996,70.734z M313.996,92.284c-0.54,0-0.98,0.36-1.13,0.86l0.33,0.34l0.8,0.82l0.29,0.31
                c0.52-0.13,0.9-0.58,0.9-1.13C315.186,92.814,314.656,92.284,313.996,92.284z M313.996,63.544c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C315.186,64.084,314.656,63.544,313.996,63.544z M317.586,88.694
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C318.776,89.224,318.246,88.694,317.586,88.694z
                 M317.586,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C318.776,82.044,318.246,81.504,317.586,81.504z M313.996,58.754c0.58,0,1.05-0.43,1.15-0.99l-1.15,0.12l-1.11,0.11
                C313.056,58.444,313.486,58.754,313.996,58.754z M306.806,63.544c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C308.006,64.084,307.466,63.544,306.806,63.544z M310.396,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                c0.67,0,1.2-0.53,1.2-1.19S311.066,59.954,310.396,59.954z M306.806,77.914c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C308.006,78.454,307.466,77.914,306.806,77.914z M310.396,81.504c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C311.596,82.044,311.066,81.504,310.396,81.504z M310.396,88.694
                c-0.5,0-0.92,0.31-1.1,0.75l1.51,1.56c0.46-0.17,0.79-0.6,0.79-1.12C311.596,89.224,311.066,88.694,310.396,88.694z
                 M310.396,74.324c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19
                C311.596,74.864,311.066,74.324,310.396,74.324z M306.806,70.734c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C308.006,71.264,307.466,70.734,306.806,70.734z M310.396,67.144c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C311.596,67.674,311.066,67.144,310.396,67.144z M307.436,58.554l-0.63,0.06
                l-0.46,0.05c0.15,0.06,0.3,0.09,0.46,0.09C307.046,58.754,307.256,58.674,307.436,58.554z M306.806,85.104
                c-0.46,0-0.86,0.27-1.06,0.66l0.52,0.53l0.54,0.57l0.5,0.51c0.41-0.19,0.7-0.6,0.7-1.08
                C308.006,85.634,307.466,85.104,306.806,85.104z M303.216,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C304.416,74.864,303.876,74.324,303.216,74.324z M303.216,67.144c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C304.416,67.674,303.876,67.144,303.216,67.144z M303.216,81.504
                c-0.43,0-0.8,0.25-1.01,0.59l1.58,1.64c0.37-0.21,0.63-0.58,0.63-1.03C304.416,82.044,303.876,81.504,303.216,81.504z
                 M303.216,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19S303.876,59.954,303.216,59.954z M296.036,59.954
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S296.696,59.954,296.036,59.954z M285.256,63.544
                c-0.27,0-0.5,0.11-0.7,0.26l0.7,0.72l0.21,0.22l0.71,0.73c0.17-0.2,0.28-0.45,0.28-0.73
                C286.456,64.084,285.916,63.544,285.256,63.544z M292.436,70.734c-0.33,0-0.63,0.13-0.85,0.35l0.81,0.84l0.04,0.05l0.81,0.83
                c0.24-0.22,0.39-0.53,0.39-0.88C293.636,71.264,293.106,70.734,292.436,70.734z M299.626,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C300.816,71.264,300.286,70.734,299.626,70.734z M282.006,61.154l0.63,0.66
                c0.13-0.2,0.23-0.41,0.23-0.66c0-0.03-0.02-0.06-0.02-0.09L282.006,61.154z M296.036,67.144c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C297.226,67.674,296.696,67.144,296.036,67.144z M292.436,63.544
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C293.636,64.084,293.106,63.544,292.436,63.544z
                 M287.836,60.554c-0.11,0.18-0.18,0.38-0.18,0.6c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19c0-0.31-0.12-0.58-0.31-0.79
                L287.836,60.554z M299.626,77.914c-0.4,0-0.74,0.21-0.96,0.51l0.66,0.69l0.3,0.3l0.65,0.68c0.32-0.22,0.54-0.57,0.54-0.98
                C300.816,78.454,300.286,77.914,299.626,77.914z M288.846,67.144c-0.3,0-0.56,0.12-0.77,0.3l1.64,1.7c0.2-0.21,0.33-0.49,0.33-0.81
                C290.046,67.674,289.506,67.144,288.846,67.144z M296.036,74.324c-0.37,0-0.69,0.17-0.91,0.43l1.63,1.69
                c0.28-0.22,0.47-0.54,0.47-0.92C297.226,74.864,296.696,74.324,296.036,74.324z M299.626,63.544c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C300.816,64.084,300.286,63.544,299.626,63.544z M353.506,117.424
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C354.696,117.964,354.166,117.424,353.506,117.424z
                 M353.506,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C354.696,74.864,354.166,74.324,353.506,74.324z M353.506,88.694c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C354.696,89.224,354.166,88.694,353.506,88.694z M353.506,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19S354.166,59.954,353.506,59.954z M353.506,110.244c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2
                s1.19-0.53,1.19-1.2C354.696,110.774,354.166,110.244,353.506,110.244z M353.506,81.504c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C354.696,82.044,354.166,81.504,353.506,81.504z M353.506,103.054
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C354.696,103.594,354.166,103.054,353.506,103.054z
                 M352.306,53.964c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2c0-0.08-0.03-0.16-0.05-0.24L352.306,53.964z M353.506,95.874
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C354.696,96.414,354.166,95.874,353.506,95.874z
                 M353.506,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C354.696,67.674,354.166,67.144,353.506,67.144z M349.916,92.284c-0.67,0-1.2,0.53-1.2,1.2c0,0.66,0.53,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19C351.106,92.814,350.576,92.284,349.916,92.284z M349.916,106.654c-0.67,0-1.2,0.53-1.2,1.19
                c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C351.106,107.184,350.576,106.654,349.916,106.654z M349.916,85.104
                c-0.67,0-1.2,0.53-1.2,1.19c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C351.106,85.634,350.576,85.104,349.916,85.104z
                 M349.916,63.544c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C351.106,64.084,350.576,63.544,349.916,63.544z M349.916,99.464c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2C351.106,100.004,350.576,99.464,349.916,99.464z M349.916,70.734c-0.67,0-1.2,0.53-1.2,1.19
                c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C351.106,71.264,350.576,70.734,349.916,70.734z M349.916,77.914
                c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C351.106,78.454,350.576,77.914,349.916,77.914z
                 M349.916,56.364c-0.67,0-1.2,0.54-1.2,1.2s0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19S350.576,56.364,349.916,56.364z
                 M353.506,131.794c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C354.696,132.334,354.166,131.794,353.506,131.794z M353.506,124.614c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2S354.166,124.614,353.506,124.614z M349.916,113.834c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19C351.106,114.374,350.576,113.834,349.916,113.834z M349.916,121.014c-0.67,0-1.2,0.54-1.2,1.2
                c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C351.106,121.554,350.576,121.014,349.916,121.014z M349.916,128.204
                c-0.67,0-1.2,0.53-1.2,1.19c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C351.106,128.734,350.576,128.204,349.916,128.204z
                 M346.316,95.874c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C347.516,96.414,346.976,95.874,346.316,95.874z M346.316,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S346.976,59.954,346.316,59.954z M346.316,74.324c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C347.516,74.864,346.976,74.324,346.316,74.324z M346.316,88.694c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C347.516,89.224,346.976,88.694,346.316,88.694z M346.316,117.424
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C347.516,117.964,346.976,117.424,346.316,117.424z
                 M346.316,55.164c0.48,0,0.89-0.29,1.08-0.7l-2.02,0.21C345.596,54.964,345.926,55.164,346.316,55.164z M346.316,67.144
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C347.516,67.674,346.976,67.144,346.316,67.144z
                 M346.316,110.244c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2s1.2-0.53,1.2-1.2
                C347.516,110.774,346.976,110.244,346.316,110.244z M346.316,81.504c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C347.516,82.044,346.976,81.504,346.316,81.504z M346.316,103.054c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C347.516,103.594,346.976,103.054,346.316,103.054z M342.726,106.654
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C343.926,107.184,343.386,106.654,342.726,106.654z
                 M342.726,113.834c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C343.926,114.374,343.386,113.834,342.726,113.834z M342.726,56.364c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S343.386,56.364,342.726,56.364z M342.726,92.284c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C343.926,92.814,343.386,92.284,342.726,92.284z M342.726,63.544c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C343.926,64.084,343.386,63.544,342.726,63.544z M342.726,70.734
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C343.926,71.264,343.386,70.734,342.726,70.734z
                 M342.726,85.104c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C343.926,85.634,343.386,85.104,342.726,85.104z M342.726,99.464c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C343.926,100.004,343.386,99.464,342.726,99.464z M342.726,77.914c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C343.926,78.454,343.386,77.914,342.726,77.914z M342.726,121.014
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C343.926,121.554,343.386,121.014,342.726,121.014z
                 M346.316,124.614c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S346.976,124.614,346.316,124.614z
                 M335.546,56.364c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19S336.206,56.364,335.546,56.364z
                 M335.546,113.834c-0.66,0-1.2,0.54-1.2,1.2c0,0.17,0.05,0.34,0.11,0.49l0.53,0.55c0.17,0.09,0.35,0.15,0.56,0.15
                c0.66,0,1.19-0.53,1.19-1.19C336.736,114.374,336.206,113.834,335.546,113.834z M335.546,77.914c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C336.736,78.454,336.206,77.914,335.546,77.914z M328.526,56.394l-0.17,0.02
                l-0.47,0.05c-0.42,0.18-0.72,0.6-0.72,1.1c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19
                C329.556,56.954,329.106,56.484,328.526,56.394z M331.956,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                S332.616,59.954,331.956,59.954z M331.956,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C333.146,103.594,332.616,103.054,331.956,103.054z M331.956,110.244c-0.66,0-1.2,0.53-1.2,1.19c0,0.11,0.03,0.21,0.06,0.32
                l0.79,0.81c0.11,0.04,0.22,0.07,0.35,0.07c0.66,0,1.19-0.53,1.19-1.2C333.146,110.774,332.616,110.244,331.956,110.244z
                 M331.956,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C333.146,67.674,332.616,67.144,331.956,67.144z M331.956,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C333.146,82.044,332.616,81.504,331.956,81.504z M339.136,95.874c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19C340.336,96.414,339.796,95.874,339.136,95.874z M339.136,59.954
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19S339.796,59.954,339.136,59.954z M331.956,95.874
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C333.146,96.414,332.616,95.874,331.956,95.874z
                 M339.136,117.424c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19
                C340.336,117.964,339.796,117.424,339.136,117.424z M339.136,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2C340.336,103.594,339.796,103.054,339.136,103.054z M335.546,92.284c-0.66,0-1.2,0.53-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C336.736,92.814,336.206,92.284,335.546,92.284z M339.136,110.244
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2c0.66,0,1.2-0.53,1.2-1.2C340.336,110.774,339.796,110.244,339.136,110.244z
                 M328.356,70.734c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2
                C329.556,71.264,329.016,70.734,328.356,70.734z M335.546,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2C336.736,64.084,336.206,63.544,335.546,63.544z M335.546,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C336.736,71.264,336.206,70.734,335.546,70.734z M339.136,67.144
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.2-0.54,1.2-1.2C340.336,67.674,339.796,67.144,339.136,67.144z
                 M339.136,88.694c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.2-0.54,1.2-1.2
                C340.336,89.224,339.796,88.694,339.136,88.694z M339.136,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2C340.336,82.044,339.796,81.504,339.136,81.504z M335.546,85.104c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C336.736,85.634,336.206,85.104,335.546,85.104z M331.956,88.694
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C333.146,89.224,332.616,88.694,331.956,88.694z
                 M335.546,106.654c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C336.736,107.184,336.206,106.654,335.546,106.654z M331.956,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C333.146,74.864,332.616,74.324,331.956,74.324z M328.356,63.544c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C329.556,64.084,329.016,63.544,328.356,63.544z M335.546,99.464
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C336.736,100.004,336.206,99.464,335.546,99.464z
                 M339.136,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19
                C340.336,74.864,339.796,74.324,339.136,74.324z M357.096,85.104c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C358.296,85.634,357.756,85.104,357.096,85.104z M360.686,74.324c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C361.886,74.864,361.346,74.324,360.686,74.324z M357.096,121.014
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C358.296,121.554,357.756,121.014,357.096,121.014z
                 M360.686,138.974c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S361.346,138.974,360.686,138.974z M357.096,92.284
                c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C358.296,92.814,357.756,92.284,357.096,92.284z
                 M360.686,95.874c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C361.886,96.414,361.346,95.874,360.686,95.874z M357.096,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C358.296,64.084,357.756,63.544,357.096,63.544z M360.686,131.794c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C361.886,132.334,361.346,131.794,360.686,131.794z M360.686,67.144
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C361.886,67.674,361.346,67.144,360.686,67.144z
                 M357.096,128.204c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C358.296,128.734,357.756,128.204,357.096,128.204z M360.686,110.244c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2
                s1.2-0.53,1.2-1.2C361.886,110.774,361.346,110.244,360.686,110.244z M361.406,53.034l-1.62,0.17c-0.18,0.21-0.3,0.47-0.3,0.76
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C361.886,53.584,361.686,53.254,361.406,53.034z M357.096,113.834
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C358.296,114.374,357.756,113.834,357.096,113.834z
                 M360.686,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C361.886,103.594,361.346,103.054,360.686,103.054z M360.686,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19S361.346,59.954,360.686,59.954z M360.686,88.694c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C361.886,89.224,361.346,88.694,360.686,88.694z M360.686,124.614c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2S361.346,124.614,360.686,124.614z M357.096,135.384c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C358.296,135.924,357.756,135.384,357.096,135.384z M357.096,99.464c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C358.296,100.004,357.756,99.464,357.096,99.464z M357.096,70.734
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C358.296,71.264,357.756,70.734,357.096,70.734z
                 M357.096,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C358.296,78.454,357.756,77.914,357.096,77.914z M357.096,106.654c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C358.296,107.184,357.756,106.654,357.096,106.654z M360.686,81.504c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C361.886,82.044,361.346,81.504,360.686,81.504z M357.096,56.364
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19S357.756,56.364,357.096,56.364z M360.686,117.424
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C361.886,117.964,361.346,117.424,360.686,117.424z
                 M371.466,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S372.126,142.574,371.466,142.574z
                 M371.466,128.204c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C372.656,128.734,372.126,128.204,371.466,128.204z M371.466,135.384c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C372.656,135.924,372.126,135.384,371.466,135.384z M371.466,149.754c-0.66,0-1.2,0.53-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C372.656,150.284,372.126,149.754,371.466,149.754z M364.276,70.734
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C365.476,71.264,364.936,70.734,364.276,70.734z
                 M367.866,52.774c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2S368.536,52.774,367.866,52.774z
                 M367.866,117.424c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19
                C369.066,117.964,368.536,117.424,367.866,117.424z M367.866,110.244c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2
                c0.67,0,1.2-0.53,1.2-1.2C369.066,110.774,368.536,110.244,367.866,110.244z M364.276,77.914c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C365.476,78.454,364.936,77.914,364.276,77.914z M367.866,59.954
                c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19S368.536,59.954,367.866,59.954z M364.276,56.364
                c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S364.936,56.364,364.276,56.364z M364.276,113.834
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C365.476,114.374,364.936,113.834,364.276,113.834z
                 M367.866,95.874c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19
                C369.066,96.414,368.536,95.874,367.866,95.874z M364.276,99.464c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C365.476,100.004,364.936,99.464,364.276,99.464z M364.276,85.104c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C365.476,85.634,364.936,85.104,364.276,85.104z M367.866,74.324
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19C369.066,74.864,368.536,74.324,367.866,74.324z
                 M367.866,124.614c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2S368.536,124.614,367.866,124.614z
                 M364.276,135.384c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C365.476,135.924,364.936,135.384,364.276,135.384z M367.866,138.974c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2
                c0.67,0,1.2-0.54,1.2-1.2S368.536,138.974,367.866,138.974z M367.866,67.144c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                c0.67,0,1.2-0.54,1.2-1.2C369.066,67.674,368.536,67.144,367.866,67.144z M367.866,131.794c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19C369.066,132.334,368.536,131.794,367.866,131.794z M367.866,146.164
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C369.066,146.694,368.536,146.164,367.866,146.164z
                 M367.866,88.694c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2
                C369.066,89.224,368.536,88.694,367.866,88.694z M364.276,92.284c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C365.476,92.814,364.936,92.284,364.276,92.284z M364.276,63.544c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C365.476,64.084,364.936,63.544,364.276,63.544z M364.276,121.014
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C365.476,121.554,364.936,121.014,364.276,121.014z
                 M364.276,142.574c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S364.936,142.574,364.276,142.574z
                 M367.866,103.054c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2
                C369.066,103.594,368.536,103.054,367.866,103.054z M367.866,81.504c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                c0.67,0,1.2-0.54,1.2-1.2C369.066,82.044,368.536,81.504,367.866,81.504z M364.276,128.204c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C365.476,128.734,364.936,128.204,364.276,128.204z M364.276,106.654
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C365.476,107.184,364.936,106.654,364.276,106.654z
                 M371.466,106.654c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C372.656,107.184,372.126,106.654,371.466,106.654z M375.056,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C376.246,103.594,375.716,103.054,375.056,103.054z M375.056,146.164c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C376.246,146.694,375.716,146.164,375.056,146.164z M371.466,77.914
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C372.656,78.454,372.126,77.914,371.466,77.914z
                 M371.466,99.464c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C372.656,100.004,372.126,99.464,371.466,99.464z M375.056,110.244c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2
                s1.19-0.53,1.19-1.2C376.246,110.774,375.716,110.244,375.056,110.244z M371.466,113.834c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C372.656,114.374,372.126,113.834,371.466,113.834z M378.646,99.464
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C379.846,100.004,379.306,99.464,378.646,99.464z
                 M371.466,85.104c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C372.656,85.634,372.126,85.104,371.466,85.104z M375.056,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                S375.716,59.954,375.056,59.954z M375.056,88.694c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C376.246,89.224,375.716,88.694,375.056,88.694z M375.056,153.344c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19S375.716,153.344,375.056,153.344z M378.646,149.754c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C379.846,150.284,379.306,149.754,378.646,149.754z M375.056,95.874c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C376.246,96.414,375.716,95.874,375.056,95.874z M375.056,131.794
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C376.246,132.334,375.716,131.794,375.056,131.794z
                 M375.056,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C376.246,82.044,375.716,81.504,375.056,81.504z M371.466,70.734c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C372.656,71.264,372.126,70.734,371.466,70.734z M378.646,156.934c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C379.846,157.474,379.306,156.934,378.646,156.934z M375.056,52.774
                c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S375.716,52.774,375.056,52.774z M378.646,128.204
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C379.846,128.734,379.306,128.204,378.646,128.204z
                 M375.056,117.424c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C376.246,117.964,375.716,117.424,375.056,117.424z M371.466,121.014c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C372.656,121.554,372.126,121.014,371.466,121.014z M378.646,135.384c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C379.846,135.924,379.306,135.384,378.646,135.384z M378.646,121.014
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C379.846,121.554,379.306,121.014,378.646,121.014z
                 M378.646,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S379.306,142.574,378.646,142.574z M375.056,67.144
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C376.246,67.674,375.716,67.144,375.056,67.144z
                 M375.056,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C376.246,74.864,375.716,74.324,375.056,74.324z M375.056,138.974c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                S375.716,138.974,375.056,138.974z M375.056,124.614c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                S375.716,124.614,375.056,124.614z M371.466,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C372.656,64.084,372.126,63.544,371.466,63.544z M378.646,113.834c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C379.846,114.374,379.306,113.834,378.646,113.834z M371.466,92.284c-0.66,0-1.2,0.53-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C372.656,92.814,372.126,92.284,371.466,92.284z M378.646,106.654
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C379.846,107.184,379.306,106.654,378.646,106.654z
                 M371.466,56.364c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S372.126,56.364,371.466,56.364z
                 M379.516,51.184l-0.87,0.09l-0.67,0.07c0.19,0.13,0.42,0.23,0.67,0.23C378.996,51.574,379.296,51.424,379.516,51.184z
                 M382.236,67.144c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C383.436,67.674,382.896,67.144,382.236,67.144z M382.236,95.874c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C383.436,96.414,382.896,95.874,382.236,95.874z M385.826,106.654c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C387.026,107.184,386.486,106.654,385.826,106.654z M382.236,110.244
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2s1.2-0.53,1.2-1.2C383.436,110.774,382.896,110.244,382.236,110.244z
                 M378.646,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C379.846,78.454,379.306,77.914,378.646,77.914z M385.826,85.104c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2C387.026,85.634,386.486,85.104,385.826,85.104z M382.236,52.774c-0.66,0-1.19,0.53-1.19,1.19
                s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S382.896,52.774,382.236,52.774z M385.826,135.384c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C387.026,135.924,386.486,135.384,385.826,135.384z M385.826,70.734
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C387.026,71.264,386.486,70.734,385.826,70.734z
                 M385.826,51.574c0.65,0,1.17-0.52,1.19-1.16l-1.19,0.12l-1.14,0.12C384.816,51.174,385.266,51.574,385.826,51.574z
                 M382.236,117.424c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C383.436,117.964,382.896,117.424,382.236,117.424z M378.646,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C379.846,64.084,379.306,63.544,378.646,63.544z M382.236,153.344c-0.66,0-1.19,0.54-1.19,1.2
                s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S382.896,153.344,382.236,153.344z M378.646,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C379.846,71.264,379.306,70.734,378.646,70.734z M378.646,56.364
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19S379.306,56.364,378.646,56.364z M382.236,81.504
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C383.436,82.044,382.896,81.504,382.236,81.504z
                 M378.646,85.104c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C379.846,85.634,379.306,85.104,378.646,85.104z M382.236,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S382.896,59.954,382.236,59.954z M385.826,142.574c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2S386.486,142.574,385.826,142.574z M385.826,156.934c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2C387.026,157.474,386.486,156.934,385.826,156.934z M385.826,63.544c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C387.026,64.084,386.486,63.544,385.826,63.544z M382.236,74.324
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C383.436,74.864,382.896,74.324,382.236,74.324z
                 M385.826,149.754c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19
                C387.026,150.284,386.486,149.754,385.826,149.754z M382.236,160.534c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C383.436,161.064,382.896,160.534,382.236,160.534z M382.236,103.054c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C383.436,103.594,382.896,103.054,382.236,103.054z M385.826,77.914
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C387.026,78.454,386.486,77.914,385.826,77.914z
                 M382.236,124.614c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S382.896,124.614,382.236,124.614z
                 M382.236,146.164c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C383.436,146.694,382.896,146.164,382.236,146.164z M385.826,164.124c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2S386.486,164.124,385.826,164.124z M385.826,113.834c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C387.026,114.374,386.486,113.834,385.826,113.834z M382.236,138.974
                c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S382.896,138.974,382.236,138.974z M382.236,131.794
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C383.436,132.334,382.896,131.794,382.236,131.794z
                 M378.646,92.284c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C379.846,92.814,379.306,92.284,378.646,92.284z M385.826,128.204c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2C387.026,128.734,386.486,128.204,385.826,128.204z M385.826,92.284c-0.66,0-1.19,0.53-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C387.026,92.814,386.486,92.284,385.826,92.284z M382.236,88.694
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C383.436,89.224,382.896,88.694,382.236,88.694z
                 M385.826,121.014c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2
                C387.026,121.554,386.486,121.014,385.826,121.014z M385.826,99.464c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2C387.026,100.004,386.486,99.464,385.826,99.464z M389.426,138.974c-0.66,0-1.2,0.54-1.2,1.2
                s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S390.086,138.974,389.426,138.974z M393.016,156.934c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C394.206,157.474,393.676,156.934,393.016,156.934z M389.426,117.424
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C390.616,117.964,390.086,117.424,389.426,117.424z
                 M389.426,146.164c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C390.616,146.694,390.086,146.164,389.426,146.164z M389.426,95.874c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C390.616,96.414,390.086,95.874,389.426,95.874z M385.826,56.364c-0.66,0-1.19,0.54-1.19,1.2
                s0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19S386.486,56.364,385.826,56.364z M389.426,52.774c-0.66,0-1.2,0.53-1.2,1.19
                s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S390.086,52.774,389.426,52.774z M389.426,124.614c-0.66,0-1.2,0.53-1.2,1.19
                s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S390.086,124.614,389.426,124.614z M393.016,128.204c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C394.206,128.734,393.676,128.204,393.016,128.204z M393.016,135.384
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C394.206,135.924,393.676,135.384,393.016,135.384z
                 M393.016,164.124c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S393.676,164.124,393.016,164.124z
                 M389.426,167.714c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C390.616,168.244,390.086,167.714,389.426,167.714z M389.426,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C390.616,67.674,390.086,67.144,389.426,67.144z M389.426,88.694c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C390.616,89.224,390.086,88.694,389.426,88.694z M389.426,74.324
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C390.616,74.864,390.086,74.324,389.426,74.324z
                 M393.016,106.654c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C394.206,107.184,393.676,106.654,393.016,106.654z M393.016,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2S393.676,142.574,393.016,142.574z M389.426,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C390.616,82.044,390.086,81.504,389.426,81.504z M389.426,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19S390.086,59.954,389.426,59.954z M393.016,149.754c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C394.206,150.284,393.676,149.754,393.016,149.754z M393.016,113.834c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C394.206,114.374,393.676,113.834,393.016,113.834z M393.016,171.304
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C394.206,171.844,393.676,171.304,393.016,171.304z
                 M393.016,121.014c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C394.206,121.554,393.676,121.014,393.016,121.014z M389.426,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C390.616,103.594,390.086,103.054,389.426,103.054z M389.426,110.244c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.67,0.54,1.2,1.2,1.2s1.19-0.53,1.19-1.2C390.616,110.774,390.086,110.244,389.426,110.244z M389.426,131.794
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C390.616,132.334,390.086,131.794,389.426,131.794z
                 M389.426,160.534c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C390.616,161.064,390.086,160.534,389.426,160.534z M389.426,153.344c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19S390.086,153.344,389.426,153.344z M396.606,138.974c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2S397.266,138.974,396.606,138.974z M396.606,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                c0.66,0,1.2-0.53,1.2-1.19C397.806,74.864,397.266,74.324,396.606,74.324z M393.016,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C394.206,71.264,393.676,70.734,393.016,70.734z M396.606,88.694
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.2-0.54,1.2-1.2C397.806,89.224,397.266,88.694,396.606,88.694z
                 M396.606,174.894c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2c0.66,0,1.2-0.54,1.2-1.2S397.266,174.894,396.606,174.894z
                 M396.606,160.534c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.2-0.54,1.2-1.2
                C397.806,161.064,397.266,160.534,396.606,160.534z M396.606,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                c0.66,0,1.2-0.53,1.2-1.19S397.266,59.954,396.606,59.954z M396.606,146.164c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2C397.806,146.694,397.266,146.164,396.606,146.164z M393.016,56.364c-0.66,0-1.2,0.54-1.2,1.2
                s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S393.676,56.364,393.016,56.364z M396.606,95.874c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19C397.806,96.414,397.266,95.874,396.606,95.874z M393.016,85.104
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C394.206,85.634,393.676,85.104,393.016,85.104z
                 M393.016,49.804l-1.11,0.11c-0.05,0.14-0.09,0.3-0.09,0.46c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                c0-0.25-0.09-0.47-0.23-0.67L393.016,49.804z M396.606,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2C397.806,67.674,397.266,67.144,396.606,67.144z M393.016,92.284c-0.66,0-1.2,0.53-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C394.206,92.814,393.676,92.284,393.016,92.284z M396.606,131.794
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19C397.806,132.334,397.266,131.794,396.606,131.794z
                 M396.606,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.2-0.54,1.2-1.2
                C397.806,103.594,397.266,103.054,396.606,103.054z M393.016,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C394.206,78.454,393.676,77.914,393.016,77.914z M396.606,52.774c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2S397.266,52.774,396.606,52.774z M396.606,124.614c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2S397.266,124.614,396.606,124.614z M396.606,153.344c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                c0.66,0,1.2-0.53,1.2-1.19S397.266,153.344,396.606,153.344z M396.606,110.244c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.53,1.2-1.2C397.806,110.774,397.266,110.244,396.606,110.244z M396.606,117.424c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19C397.806,117.964,397.266,117.424,396.606,117.424z M393.016,63.544
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C394.206,64.084,393.676,63.544,393.016,63.544z
                 M396.606,167.714c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.2-0.53,1.2-1.19
                C397.806,168.244,397.266,167.714,396.606,167.714z M396.606,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.2-0.54,1.2-1.2C397.806,82.044,397.266,81.504,396.606,81.504z M393.016,99.464c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C394.206,100.004,393.676,99.464,393.016,99.464z M400.196,99.464
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C401.396,100.004,400.856,99.464,400.196,99.464z
                 M403.786,138.974c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S404.446,138.974,403.786,138.974z
                 M400.196,63.544c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2
                C401.396,64.084,400.856,63.544,400.196,63.544z M400.196,149.754c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                c0.66,0,1.2-0.53,1.2-1.19C401.396,150.284,400.856,149.754,400.196,149.754z M403.786,117.424c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C404.986,117.964,404.446,117.424,403.786,117.424z M403.786,110.244
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2s1.2-0.53,1.2-1.2C404.986,110.774,404.446,110.244,403.786,110.244z
                 M407.386,156.934c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C408.576,157.474,408.046,156.934,407.386,156.934z M403.786,95.874c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C404.986,96.414,404.446,95.874,403.786,95.874z M400.196,77.914c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C401.396,78.454,400.856,77.914,400.196,77.914z M403.786,153.344
                c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S404.446,153.344,403.786,153.344z M403.786,131.794
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C404.986,132.334,404.446,131.794,403.786,131.794z
                 M403.786,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S404.446,59.954,403.786,59.954z
                 M400.196,56.364c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19S400.856,56.364,400.196,56.364z
                 M400.196,164.124c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2S400.856,164.124,400.196,164.124z
                 M403.786,67.144c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C404.986,67.674,404.446,67.144,403.786,67.144z M403.786,160.534c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C404.986,161.064,404.446,160.534,403.786,160.534z M400.196,92.284c-0.66,0-1.19,0.53-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19C401.396,92.814,400.856,92.284,400.196,92.284z M407.386,149.754
                c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C408.576,150.284,408.046,149.754,407.386,149.754
                z M400.196,70.734c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2
                C401.396,71.264,400.856,70.734,400.196,70.734z M400.196,135.384c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                c0.66,0,1.2-0.53,1.2-1.19C401.396,135.924,400.856,135.384,400.196,135.384z M400.196,142.574c-0.66,0-1.19,0.53-1.19,1.19
                s0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2S400.856,142.574,400.196,142.574z M403.786,88.694c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C404.986,89.224,404.446,88.694,403.786,88.694z M400.196,128.204
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C401.396,128.734,400.856,128.204,400.196,128.204z
                 M400.196,121.014c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2
                C401.396,121.554,400.856,121.014,400.196,121.014z M403.786,81.504c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C404.986,82.044,404.446,81.504,403.786,81.504z M400.196,156.934c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C401.396,157.474,400.856,156.934,400.196,156.934z M400.196,171.304
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.66,0,1.2-0.53,1.2-1.19
                C401.396,171.844,400.856,171.304,400.196,171.304z M403.786,74.324c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C404.986,74.864,404.446,74.324,403.786,74.324z M400.196,49.184c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2
                c0.66,0,1.2-0.54,1.2-1.2S400.856,49.184,400.196,49.184z M400.196,113.834c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                c0.66,0,1.2-0.53,1.2-1.19C401.396,114.374,400.856,113.834,400.196,113.834z M407.386,178.484c-0.66,0-1.2,0.54-1.2,1.2
                s0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S408.046,178.484,407.386,178.484z M407.386,185.674c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.67,0.54,1.2,1.2,1.2c0.66,0,1.19-0.53,1.19-1.2C408.576,186.204,408.046,185.674,407.386,185.674z M400.196,85.104
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C401.396,85.634,400.856,85.104,400.196,85.104z
                 M400.196,178.484c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2S400.856,178.484,400.196,178.484z
                 M403.786,167.714c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C404.986,168.244,404.446,167.714,403.786,167.714z M403.786,174.894c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2S404.446,174.894,403.786,174.894z M407.386,164.124c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2S408.046,164.124,407.386,164.124z M403.786,182.084c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C404.986,182.614,404.446,182.084,403.786,182.084z M403.786,146.164
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C404.986,146.694,404.446,146.164,403.786,146.164z
                 M403.786,103.054c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C404.986,103.594,404.446,103.054,403.786,103.054z M407.386,171.304c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19C408.576,171.844,408.046,171.304,407.386,171.304z M400.196,106.654c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2c0.66,0,1.2-0.54,1.2-1.2C401.396,107.184,400.856,106.654,400.196,106.654z M403.786,52.774
                c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S404.446,52.774,403.786,52.774z M403.786,124.614
                c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S404.446,124.614,403.786,124.614z M418.156,52.774
                c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S418.816,52.774,418.156,52.774z M418.156,47.984
                c0.54,0,0.98-0.37,1.13-0.87l-2.17,0.23C417.316,47.714,417.696,47.984,418.156,47.984z M421.746,200.044
                c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S422.406,200.044,421.746,200.044z M421.746,192.854
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C422.946,193.394,422.406,192.854,421.746,192.854z
                 M421.746,185.674c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2s1.2-0.53,1.2-1.2
                C422.946,186.204,422.406,185.674,421.746,185.674z M421.746,178.484c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2S422.406,178.484,421.746,178.484z M421.746,171.304c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C422.946,171.844,422.406,171.304,421.746,171.304z M421.746,164.124c-0.66,0-1.19,0.53-1.19,1.19
                s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S422.406,164.124,421.746,164.124z M421.746,156.934c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C422.946,157.474,422.406,156.934,421.746,156.934z M421.746,149.754
                c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C422.946,150.284,422.406,149.754,421.746,149.754z
                 M421.746,142.574c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S422.406,142.574,421.746,142.574z
                 M421.746,135.384c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C422.946,135.924,422.406,135.384,421.746,135.384z M421.746,128.204c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C422.946,128.734,422.406,128.204,421.746,128.204z M421.746,121.014c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C422.946,121.554,422.406,121.014,421.746,121.014z M421.746,113.834
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C422.946,114.374,422.406,113.834,421.746,113.834z
                 M421.746,106.654c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C422.946,107.184,422.406,106.654,421.746,106.654z M421.746,99.464c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C422.946,100.004,422.406,99.464,421.746,99.464z M421.746,92.284c-0.66,0-1.19,0.53-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C422.946,92.814,422.406,92.284,421.746,92.284z M421.746,85.104
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C422.946,85.634,422.406,85.104,421.746,85.104z
                 M421.746,77.914c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C422.946,78.454,422.406,77.914,421.746,77.914z M421.746,70.734c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C422.946,71.264,422.406,70.734,421.746,70.734z M421.746,63.544c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C422.946,64.084,422.406,63.544,421.746,63.544z M421.746,56.364
                c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S422.406,56.364,421.746,56.364z M421.746,49.184
                c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S422.406,49.184,421.746,49.184z M425.346,203.634
                c-0.67,0-1.2,0.53-1.2,1.19s0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S426.006,203.634,425.346,203.634z M425.346,196.444
                c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C426.536,196.984,426.006,196.444,425.346,196.444z
                 M425.346,189.264c-0.67,0-1.2,0.54-1.2,1.2s0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19S426.006,189.264,425.346,189.264z
                 M425.346,182.084c-0.67,0-1.2,0.53-1.2,1.19c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C426.536,182.614,426.006,182.084,425.346,182.084z M425.346,174.894c-0.67,0-1.2,0.54-1.2,1.2s0.53,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2S426.006,174.894,425.346,174.894z M425.346,167.714c-0.67,0-1.2,0.53-1.2,1.2
                c0,0.66,0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C426.536,168.244,426.006,167.714,425.346,167.714z M425.346,160.534
                c-0.67,0-1.2,0.53-1.2,1.19c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C426.536,161.064,426.006,160.534,425.346,160.534z
                 M425.346,153.344c-0.67,0-1.2,0.54-1.2,1.2s0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19S426.006,153.344,425.346,153.344z
                 M425.346,146.164c-0.67,0-1.2,0.53-1.2,1.19c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C426.536,146.694,426.006,146.164,425.346,146.164z M425.346,138.974c-0.67,0-1.2,0.54-1.2,1.2s0.53,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2S426.006,138.974,425.346,138.974z M425.346,131.794c-0.67,0-1.2,0.54-1.2,1.2
                c0,0.66,0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C426.536,132.334,426.006,131.794,425.346,131.794z M425.346,124.614
                c-0.67,0-1.2,0.53-1.2,1.19s0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S426.006,124.614,425.346,124.614z M425.346,117.424
                c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C426.536,117.964,426.006,117.424,425.346,117.424
                z M425.346,110.244c-0.67,0-1.2,0.53-1.2,1.19c0,0.67,0.53,1.2,1.2,1.2c0.66,0,1.19-0.53,1.19-1.2
                C426.536,110.774,426.006,110.244,425.346,110.244z M425.346,103.054c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2C426.536,103.594,426.006,103.054,425.346,103.054z M425.346,95.874c-0.67,0-1.2,0.54-1.2,1.2
                c0,0.66,0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C426.536,96.414,426.006,95.874,425.346,95.874z M425.346,88.694
                c-0.67,0-1.2,0.53-1.2,1.19c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C426.536,89.224,426.006,88.694,425.346,88.694z
                 M425.346,81.504c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C426.536,82.044,426.006,81.504,425.346,81.504z M425.346,74.324c-0.67,0-1.2,0.54-1.2,1.2c0,0.66,0.53,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19C426.536,74.864,426.006,74.324,425.346,74.324z M425.346,67.144c-0.67,0-1.2,0.53-1.2,1.19
                c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C426.536,67.674,426.006,67.144,425.346,67.144z M425.346,59.954
                c-0.67,0-1.2,0.54-1.2,1.2s0.53,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19S426.006,59.954,425.346,59.954z M425.346,52.774
                c-0.67,0-1.2,0.53-1.2,1.19s0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S426.006,52.774,425.346,52.774z M424.176,46.614
                c-0.01,0.06-0.03,0.11-0.03,0.17c0,0.66,0.53,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2c0-0.14-0.03-0.27-0.08-0.4L424.176,46.614z
                 M428.936,207.224c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S429.596,207.224,428.936,207.224z
                 M428.936,200.044c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S429.596,200.044,428.936,200.044z
                 M428.936,192.854c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C430.126,193.394,429.596,192.854,428.936,192.854z M428.936,185.674c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2
                s1.19-0.53,1.19-1.2C430.126,186.204,429.596,185.674,428.936,185.674z M428.936,178.484c-0.66,0-1.2,0.54-1.2,1.2
                s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S429.596,178.484,428.936,178.484z M428.936,171.304c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C430.126,171.844,429.596,171.304,428.936,171.304z M418.156,74.324
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C419.356,74.864,418.816,74.324,418.156,74.324z
                 M418.156,167.714c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C419.356,168.244,418.816,167.714,418.156,167.714z M407.386,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2S408.046,142.574,407.386,142.574z M410.976,153.344c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19S411.636,153.344,410.976,153.344z M407.386,128.204c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C408.576,128.734,408.046,128.204,407.386,128.204z M410.976,160.534
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C412.166,161.064,411.636,160.534,410.976,160.534z
                 M407.386,92.284c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19
                C408.576,92.814,408.046,92.284,407.386,92.284z M418.156,146.164c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C419.356,146.694,418.816,146.164,418.156,146.164z M418.156,189.264c-0.66,0-1.19,0.54-1.19,1.2
                s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S418.816,189.264,418.156,189.264z M418.156,138.974c-0.66,0-1.19,0.54-1.19,1.2
                s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S418.816,138.974,418.156,138.974z M418.156,95.874c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C419.356,96.414,418.816,95.874,418.156,95.874z M418.156,67.144
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C419.356,67.674,418.816,67.144,418.156,67.144z
                 M418.156,88.694c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C419.356,89.224,418.816,88.694,418.156,88.694z M418.156,153.344c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S418.816,153.344,418.156,153.344z M418.156,110.244c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2
                s1.2-0.53,1.2-1.2C419.356,110.774,418.816,110.244,418.156,110.244z M410.976,167.714c-0.66,0-1.2,0.53-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C412.166,168.244,411.636,167.714,410.976,167.714z M410.976,88.694
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C412.166,89.224,411.636,88.694,410.976,88.694z
                 M410.976,174.894c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S411.636,174.894,410.976,174.894z
                 M418.156,182.084c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C419.356,182.614,418.816,182.084,418.156,182.084z M407.386,99.464c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2C408.576,100.004,408.046,99.464,407.386,99.464z M407.386,85.104c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C408.576,85.634,408.046,85.104,407.386,85.104z M407.386,121.014
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C408.576,121.554,408.046,121.014,407.386,121.014z
                 M407.386,106.654c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C408.576,107.184,408.046,106.654,407.386,106.654z M407.386,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19C408.576,78.454,408.046,77.914,407.386,77.914z M407.386,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C408.576,71.264,408.046,70.734,407.386,70.734z M407.386,63.544
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C408.576,64.084,408.046,63.544,407.386,63.544z
                 M407.386,113.834c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19
                C408.576,114.374,408.046,113.834,407.386,113.834z M407.386,56.364c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19S408.046,56.364,407.386,56.364z M411.106,47.954l-0.18,0.02c0.02,0,0.03,0.01,0.05,0.01
                C411.016,47.984,411.056,47.964,411.106,47.954z M410.976,189.264c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19S411.636,189.264,410.976,189.264z M410.976,182.084c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C412.166,182.614,411.636,182.084,410.976,182.084z M410.976,131.794
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C412.166,132.334,411.636,131.794,410.976,131.794
                z M410.976,138.974c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S411.636,138.974,410.976,138.974z
                 M410.976,117.424c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19
                C412.166,117.964,411.636,117.424,410.976,117.424z M410.976,124.614c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2S411.636,124.614,410.976,124.614z M410.976,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                c0.66,0,1.19-0.54,1.19-1.2C412.166,103.594,411.636,103.054,410.976,103.054z M410.976,110.244c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.67,0.54,1.2,1.2,1.2c0.66,0,1.19-0.53,1.19-1.2C412.166,110.774,411.636,110.244,410.976,110.244z M410.976,95.874
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C412.166,96.414,411.636,95.874,410.976,95.874z
                 M407.386,49.184c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S408.046,49.184,407.386,49.184z
                 M410.976,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2
                C412.166,67.674,411.636,67.144,410.976,67.144z M410.976,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                c0.66,0,1.19-0.53,1.19-1.19C412.166,74.864,411.636,74.324,410.976,74.324z M410.976,59.954c-0.66,0-1.2,0.54-1.2,1.2
                s0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19S411.636,59.954,410.976,59.954z M410.976,52.774c-0.66,0-1.2,0.53-1.2,1.19
                s0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2S411.636,52.774,410.976,52.774z M410.976,146.164c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C412.166,146.694,411.636,146.164,410.976,146.164z M410.976,81.504
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2c0.66,0,1.19-0.54,1.19-1.2C412.166,82.044,411.636,81.504,410.976,81.504z
                 M414.566,85.104c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C415.766,85.634,415.226,85.104,414.566,85.104z M418.156,81.504c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C419.356,82.044,418.816,81.504,418.156,81.504z M418.156,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S418.816,59.954,418.156,59.954z M418.156,124.614c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2S418.816,124.614,418.156,124.614z M418.156,103.054c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C419.356,103.594,418.816,103.054,418.156,103.054z M418.156,117.424c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C419.356,117.964,418.816,117.424,418.156,117.424z M418.156,131.794
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C419.356,132.334,418.816,131.794,418.156,131.794z
                 M414.566,70.734c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C415.766,71.264,415.226,70.734,414.566,70.734z M418.156,160.534c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C419.356,161.064,418.816,160.534,418.156,160.534z M407.386,135.384c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19c0.66,0,1.19-0.53,1.19-1.19C408.576,135.924,408.046,135.384,407.386,135.384z M414.566,178.484
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S415.226,178.484,414.566,178.484z M414.566,185.674
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2s1.2-0.53,1.2-1.2C415.766,186.204,415.226,185.674,414.566,185.674z
                 M418.156,174.894c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S418.816,174.894,418.156,174.894z
                 M414.566,113.834c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C415.766,114.374,415.226,113.834,414.566,113.834z M414.566,192.854c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C415.766,193.394,415.226,192.854,414.566,192.854z M414.566,171.304c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C415.766,171.844,415.226,171.304,414.566,171.304z M414.566,156.934
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C415.766,157.474,415.226,156.934,414.566,156.934z
                 M414.566,164.124c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S415.226,164.124,414.566,164.124z
                 M414.566,149.754c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C415.766,150.284,415.226,149.754,414.566,149.754z M414.566,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                S415.226,142.574,414.566,142.574z M414.566,128.204c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C415.766,128.734,415.226,128.204,414.566,128.204z M414.566,106.654c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C415.766,107.184,415.226,106.654,414.566,106.654z M414.566,121.014c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C415.766,121.554,415.226,121.014,414.566,121.014z M414.566,77.914
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C415.766,78.454,415.226,77.914,414.566,77.914z
                 M414.566,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C415.766,64.084,415.226,63.544,414.566,63.544z M418.156,196.444c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C419.356,196.984,418.816,196.444,418.156,196.444z M414.566,49.184c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2S415.226,49.184,414.566,49.184z M414.566,135.384c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C415.766,135.924,415.226,135.384,414.566,135.384z M414.566,56.364c-0.66,0-1.2,0.54-1.2,1.2
                s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19S415.226,56.364,414.566,56.364z M414.566,99.464c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C415.766,100.004,415.226,99.464,414.566,99.464z M414.566,92.284
                c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C415.766,92.814,415.226,92.284,414.566,92.284z
                 M436.116,185.674c-0.66,0-1.2,0.53-1.2,1.19c0,0.67,0.54,1.2,1.2,1.2s1.2-0.53,1.2-1.2
                C437.316,186.204,436.776,185.674,436.116,185.674z M436.116,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                S436.776,142.574,436.116,142.574z M436.116,156.934c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C437.316,157.474,436.776,156.934,436.116,156.934z M436.116,171.304c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C437.316,171.844,436.776,171.304,436.116,171.304z M436.116,99.464c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C437.316,100.004,436.776,99.464,436.116,99.464z M436.116,106.654
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C437.316,107.184,436.776,106.654,436.116,106.654z
                 M436.116,192.854c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19c0.27,0,0.5-0.1,0.7-0.25l0.12-0.94l0.1-0.74
                C436.816,193.044,436.496,192.854,436.116,192.854z M434.916,201.234c0,0.57,0.41,1.02,0.94,1.15l0.15-1.15l0.11-0.86l0.04-0.33
                h-0.04C435.456,200.044,434.916,200.574,434.916,201.234z M436.116,128.204c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C437.316,128.734,436.776,128.204,436.116,128.204z M436.116,92.284c-0.66,0-1.2,0.53-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C437.316,92.814,436.776,92.284,436.116,92.284z M436.116,85.104
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C437.316,85.634,436.776,85.104,436.116,85.104z
                 M436.116,135.384c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C437.316,135.924,436.776,135.384,436.116,135.384z M436.116,149.754c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C437.316,150.284,436.776,149.754,436.116,149.754z M436.116,77.914c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C437.316,78.454,436.776,77.914,436.116,77.914z M439.706,153.344
                c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19S440.366,153.344,439.706,153.344z M436.116,121.014
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C437.316,121.554,436.776,121.014,436.116,121.014z
                 M436.116,178.484c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S436.776,178.484,436.116,178.484z M436.116,164.124
                c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S436.776,164.124,436.116,164.124z M439.706,110.244
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.67,0.53,1.2,1.19,1.2s1.2-0.53,1.2-1.2C440.906,110.774,440.366,110.244,439.706,110.244z
                 M439.706,95.874c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C440.906,96.414,440.366,95.874,439.706,95.874z M439.706,117.424c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19C440.906,117.964,440.366,117.424,439.706,117.424z M436.116,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C437.316,71.264,436.776,70.734,436.116,70.734z M436.116,63.544
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C437.316,64.084,436.776,63.544,436.116,63.544z
                 M436.116,113.834c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C437.316,114.374,436.776,113.834,436.116,113.834z M436.116,49.184c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                S436.776,49.184,436.116,49.184z M432.526,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                S433.186,59.954,432.526,59.954z M439.706,74.324c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19
                C440.906,74.864,440.366,74.324,439.706,74.324z M439.706,103.054c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C440.906,103.594,440.366,103.054,439.706,103.054z M436.116,56.364c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19S436.776,56.364,436.116,56.364z M432.526,74.324c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C433.726,74.864,433.186,74.324,432.526,74.324z M432.526,189.264c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19S433.186,189.264,432.526,189.264z M435.016,208.884l0.06-0.46l0.09-0.69c-0.14,0.2-0.25,0.43-0.25,0.69
                C434.916,208.584,434.956,208.734,435.016,208.884z M432.526,124.614c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                S433.186,124.614,432.526,124.614z M438.516,176.094c0,0.45,0.25,0.82,0.62,1.03l0.28-2.17
                C438.896,175.094,438.516,175.534,438.516,176.094z M439.706,59.954c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19
                s1.2-0.53,1.2-1.19S440.366,59.954,439.706,59.954z M433.036,45.714l-1.2,0.12c-0.3,0.22-0.51,0.55-0.51,0.95
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C433.726,46.304,433.436,45.904,433.036,45.714z M439.706,131.794
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19s1.2-0.53,1.2-1.19C440.906,132.334,440.366,131.794,439.706,131.794z
                 M432.526,52.774c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S433.186,52.774,432.526,52.774z M439.706,81.504
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C440.906,82.044,440.366,81.504,439.706,81.504z
                 M439.706,138.974c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2S440.366,138.974,439.706,138.974z
                 M439.706,160.534c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                C440.906,161.064,440.366,160.534,439.706,160.534z M428.936,135.384c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C430.126,135.924,429.596,135.384,428.936,135.384z M428.936,99.464c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C430.126,100.004,429.596,99.464,428.936,99.464z M428.936,106.654
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C430.126,107.184,429.596,106.654,428.936,106.654z
                 M428.936,63.544c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C430.126,64.084,429.596,63.544,428.936,63.544z M432.526,153.344c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                S433.186,153.344,432.526,153.344z M432.526,131.794c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19
                C433.726,132.334,433.186,131.794,432.526,131.794z M432.526,167.714c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.2-0.53,1.2-1.19C433.726,168.244,433.186,167.714,432.526,167.714z M432.526,88.694c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C433.726,89.224,433.186,88.694,432.526,88.694z M432.526,67.144
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C433.726,67.674,433.186,67.144,432.526,67.144z
                 M432.526,103.054c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C433.726,103.594,433.186,103.054,432.526,103.054z M432.526,81.504c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.2-0.54,1.2-1.2C433.726,82.044,433.186,81.504,432.526,81.504z M432.526,110.244c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.67,0.54,1.2,1.2,1.2s1.2-0.53,1.2-1.2C433.726,110.774,433.186,110.244,432.526,110.244z M432.526,117.424
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C433.726,117.964,433.186,117.424,432.526,117.424z
                 M432.526,138.974c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2S433.186,138.974,432.526,138.974z M432.526,210.814
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19S433.186,210.814,432.526,210.814z M432.526,95.874
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.2-0.53,1.2-1.19C433.726,96.414,433.186,95.874,432.526,95.874z
                 M428.936,56.364c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S429.596,56.364,428.936,56.364z
                 M432.526,146.164c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C433.726,146.694,433.186,146.164,432.526,146.164z M432.526,174.894c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                S433.186,174.894,432.526,174.894z M432.526,182.084c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C433.726,182.614,433.186,182.084,432.526,182.084z M432.526,203.634c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                S433.186,203.634,432.526,203.634z M432.526,196.444c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2
                C433.726,196.984,433.186,196.444,432.526,196.444z M428.936,92.284c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19
                s1.19-0.53,1.19-1.19C430.126,92.814,429.596,92.284,428.936,92.284z M428.936,70.734c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C430.126,71.264,429.596,70.734,428.936,70.734z M428.936,128.204
                c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C430.126,128.734,429.596,128.204,428.936,128.204z
                 M428.936,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C430.126,78.454,429.596,77.914,428.936,77.914z M428.936,85.104c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C430.126,85.634,429.596,85.104,428.936,85.104z M432.526,160.534c-0.66,0-1.2,0.53-1.2,1.19
                c0,0.66,0.54,1.2,1.2,1.2s1.2-0.54,1.2-1.2C433.726,161.064,433.186,160.534,432.526,160.534z M428.936,49.184
                c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S429.596,49.184,428.936,49.184z M439.706,146.164
                c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C440.906,146.694,440.366,146.164,439.706,146.164z
                 M428.936,121.014c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C430.126,121.554,429.596,121.014,428.936,121.014z M439.706,88.694c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2C440.906,89.224,440.366,88.694,439.706,88.694z M439.706,124.614c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2S440.366,124.614,439.706,124.614z M428.936,156.934c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C430.126,157.474,429.596,156.934,428.936,156.934z M439.706,67.144c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2C440.906,67.674,440.366,67.144,439.706,67.144z M428.936,113.834
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C430.126,114.374,429.596,113.834,428.936,113.834z
                 M428.936,164.124c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S429.596,164.124,428.936,164.124z
                 M428.936,142.574c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S429.596,142.574,428.936,142.574z
                 M428.936,149.754c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C430.126,150.284,429.596,149.754,428.936,149.754z M439.706,52.774c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2
                s1.2-0.54,1.2-1.2S440.366,52.774,439.706,52.774z M439.706,45.584c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.2,1.19,1.2s1.2-0.54,1.2-1.2
                S440.366,45.584,439.706,45.584z M438.516,168.914c0,0.66,0.53,1.19,1.19,1.19c0.12,0,0.23-0.03,0.34-0.07l0.28-2.12
                c-0.18-0.12-0.39-0.2-0.62-0.2C439.046,167.714,438.516,168.244,438.516,168.914z M442.106,150.954c0,0.3,0.12,0.58,0.32,0.79
                l0.1-0.79l0.13-0.98C442.326,150.184,442.106,150.534,442.106,150.954z M442.106,143.764c0,0.66,0.53,1.2,1.19,1.2
                c0.01,0,0.01,0,0.01,0l0.15-1.2l0.15-1.13c-0.1-0.03-0.2-0.06-0.31-0.06C442.636,142.574,442.106,143.104,442.106,143.764z
                 M443.296,135.384c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.44,0,0.81-0.24,1.01-0.59l0.08-0.6l0.05-0.33
                C444.286,135.754,443.846,135.384,443.296,135.384z M443.296,128.204c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                c0.67,0,1.2-0.54,1.2-1.2C444.496,128.734,443.966,128.204,443.296,128.204z M443.296,121.014c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C444.496,121.554,443.966,121.014,443.296,121.014z M443.296,113.834
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19
                C444.496,114.374,443.966,113.834,443.296,113.834z M443.296,106.654c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2
                c0.67,0,1.2-0.54,1.2-1.2C444.496,107.184,443.966,106.654,443.296,106.654z M443.296,99.464c-0.66,0-1.19,0.54-1.19,1.2
                c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C444.496,100.004,443.966,99.464,443.296,99.464z M443.296,92.284
                c-0.66,0-1.19,0.53-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19C444.496,92.814,443.966,92.284,443.296,92.284z
                 M443.296,85.104c-0.66,0-1.19,0.53-1.19,1.19c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2
                C444.496,85.634,443.966,85.104,443.296,85.104z M443.296,77.914c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.19,1.19,1.19
                c0.67,0,1.2-0.53,1.2-1.19C444.496,78.454,443.966,77.914,443.296,77.914z M443.296,70.734c-0.66,0-1.19,0.53-1.19,1.19
                c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C444.496,71.264,443.966,70.734,443.296,70.734z M443.296,63.544
                c-0.66,0-1.19,0.54-1.19,1.2c0,0.66,0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2C444.496,64.084,443.966,63.544,443.296,63.544z
                 M443.296,56.364c-0.66,0-1.19,0.54-1.19,1.2s0.53,1.19,1.19,1.19c0.67,0,1.2-0.53,1.2-1.19S443.966,56.364,443.296,56.364z
                 M443.296,49.184c-0.66,0-1.19,0.53-1.19,1.19s0.53,1.2,1.19,1.2c0.67,0,1.2-0.54,1.2-1.2S443.966,49.184,443.296,49.184z
                 M445.756,126.074l0.1-0.84c-0.09,0.18-0.16,0.36-0.16,0.57C445.696,125.904,445.726,125.984,445.756,126.074z M445.696,118.624
                c0,0.55,0.37,0.99,0.87,1.13l0.31-2.32C446.226,117.444,445.696,117.974,445.696,118.624z M446.896,103.054
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C448.086,103.594,447.556,103.054,446.896,103.054z
                 M446.896,95.874c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C448.086,96.414,447.556,95.874,446.896,95.874z M446.896,88.694c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C448.086,89.224,447.556,88.694,446.896,88.694z M446.896,81.504c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C448.086,82.044,447.556,81.504,446.896,81.504z M446.896,74.324
                c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19C448.086,74.864,447.556,74.324,446.896,74.324z
                 M446.896,67.144c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                C448.086,67.674,447.556,67.144,446.896,67.144z M446.896,59.954c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                S447.556,59.954,446.896,59.954z M446.896,52.774c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                S447.556,52.774,446.896,52.774z M446.896,45.584c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2
                S447.556,45.584,446.896,45.584z M445.696,111.434c0,0.67,0.54,1.2,1.2,1.2c0.23,0,0.44-0.08,0.62-0.2l0.24-1.82
                c-0.22-0.23-0.52-0.37-0.86-0.37C446.236,110.244,445.696,110.774,445.696,111.434z M449.286,93.484c0,0.42,0.23,0.77,0.56,0.98
                l0.13-0.98l0.15-1.13C449.646,92.514,449.286,92.944,449.286,93.484z M449.286,86.294c0,0.66,0.54,1.2,1.2,1.2
                c0.1,0,0.18-0.04,0.28-0.06l0.14-1.14l0.14-1.04c-0.17-0.09-0.36-0.15-0.56-0.15C449.826,85.104,449.286,85.634,449.286,86.294z
                 M451.476,43.824l-0.99,0.1l-0.86,0.09c0.22,0.23,0.52,0.37,0.86,0.37C450.906,44.384,451.256,44.154,451.476,43.824z
                 M450.486,77.914c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19
                C451.676,78.454,451.146,77.914,450.486,77.914z M450.486,70.734c-0.66,0-1.2,0.53-1.2,1.19c0,0.66,0.54,1.2,1.2,1.2
                s1.19-0.54,1.19-1.2C451.676,71.264,451.146,70.734,450.486,70.734z M450.486,63.544c-0.66,0-1.2,0.54-1.2,1.2
                c0,0.66,0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2C451.676,64.084,451.146,63.544,450.486,63.544z M450.486,56.364
                c-0.66,0-1.2,0.54-1.2,1.2s0.54,1.19,1.2,1.19s1.19-0.53,1.19-1.19S451.146,56.364,450.486,56.364z M450.486,49.184
                c-0.66,0-1.2,0.53-1.2,1.19s0.54,1.2,1.2,1.2s1.19-0.54,1.19-1.2S451.146,49.184,450.486,49.184z M452.876,68.334
                c0,0.28,0.11,0.52,0.27,0.72l0.21-1.65C453.076,67.624,452.876,67.954,452.876,68.334z M454.076,47.984c0.66,0,1.2-0.54,1.2-1.2
                s-0.54-1.2-1.2-1.2s-1.2,0.54-1.2,1.2S453.416,47.984,454.076,47.984z M452.876,61.154c0,0.64,0.51,1.15,1.14,1.18l0.3-2.33
                c-0.08-0.02-0.15-0.05-0.24-0.05C453.416,59.954,452.876,60.494,452.876,61.154z M454.076,52.774c-0.66,0-1.2,0.53-1.2,1.19
                s0.54,1.2,1.2,1.2c0.39,0,0.71-0.19,0.93-0.48l0.16-1.2C454.976,53.064,454.566,52.774,454.076,52.774z"/>
            </g>
            <g id="Shadow">
                <ellipse style={{ fill: "#EBEBEB" }} cx="250" cy="434.827" rx="169.217" ry="15.358" />
            </g>
            <g id="Plant">
                <g>
                    <g>
                        <g>
                            <path className="primary" d="M449.475,318.826c-13.702,18.557-27.988,40.092-31.467,63.362
                            c-0.042,0.352,0.522,0.467,0.604,0.117c1.591-6.382,3.603-12.58,6.059-18.632c9.008-2.479,17.849-4.515,22.58-13.456
                            c4.825-9.191,4.212-21.174,3.294-31.185C450.481,318.496,449.754,318.47,449.475,318.826z"/>
                            <g>
                                <path style={{ fill: "#263238" }} d="M424.807,363.5c6.155-13.812,14.057-27.681,24.101-39.039c0.068-0.076,0.173,0.034,0.11,0.111
                                c-6.576,8.089-11.572,16.899-16.452,25.863c4.537-4.606,9.508-8.731,13.805-13.615c0.084-0.095,0.218,0.043,0.138,0.138
                                c-4.267,5.12-8.929,10.685-14.486,14.474c-1.493,2.75-2.996,5.509-4.52,8.267c0.373-0.208,0.759-0.395,1.142-0.584
                                c0.068-0.033,0.127,0.067,0.059,0.101c-0.424,0.214-0.843,0.44-1.281,0.626c-0.722,1.305-1.437,2.612-2.179,3.914
                                C425.08,364.046,424.677,363.792,424.807,363.5z"/>
                                <path style={{ fill: "#263238" }} d="M429.386,358.681c3.672-2.106,7.213-4.368,10.518-7.021c0.09-0.072,0.22,0.052,0.129,0.129
                                c-3.245,2.747-6.705,5.207-10.495,7.151C429.367,359.027,429.218,358.777,429.386,358.681z"/>
                            </g>
                        </g>
                        <g>
                            <path className="primary" d="M416.423,378.73c0.028,0.282,0.507,0.277,0.501-0.023c0.071-7.663,0.007-15.362,0.122-23.06
                            c1.963-3.962,6.177-6.155,9.107-9.33c2.113-2.291,3.92-4.876,5.466-7.61c3.623-6.484,5.586-13.773,6.341-21.14
                            c0.809-8.197-2.301-35.246-5.757-34.307c-1.792,0.487-7.591,13.885-9.129,18.313c-2.519,7.286-4.292,14.837-5.454,22.456
                            C414.819,342.064,415.052,360.582,416.423,378.73z"/>
                            <g>
                                <path style={{ fill: "#263238" }} d="M416.368,345.671c0.012-0.01,0.023-0.02,0.035-0.03c0.42-9.697,2.605-19.489,4.649-28.893
                                c2.203-10.131,4.778-19.671,9.992-28.707c0.097-0.168,0.346-0.02,0.259,0.151c-3.548,6.991-6.085,14.413-8.026,22.035
                                c0.349-0.547,0.773-1.064,1.136-1.58c0.143-0.203,0.478-0.014,0.337,0.197c-0.511,0.764-0.973,1.654-1.629,2.301
                                c-0.022,0.021-0.045,0.024-0.068,0.031c-1.795,7.241-3.083,14.649-4.185,22.038c0.083-0.094,0.162-0.195,0.245-0.285
                                c0.057-0.061,0.152,0.017,0.113,0.087c-0.136,0.245-0.289,0.488-0.439,0.733c-0.555,3.751-1.07,7.494-1.588,11.204
                                c6.678-5.708,12.381-12.435,15.597-20.725c0.015-0.038,0.077-0.022,0.063,0.017c-3.151,8.599-8.119,16.242-15.766,21.458
                                c-0.14,1-0.279,2-0.422,2.994c-0.029,0.207-0.359,0.152-0.358-0.048c0.003-0.883,0.046-1.769,0.08-2.654
                                C416.298,345.91,416.253,345.767,416.368,345.671z"/>
                                <path style={{ fill: "#263238" }} d="M419.514,331.622c5.103-7.616,9.87-15.013,13.604-23.431c0.041-0.094,0.203-0.027,0.166,0.07
                                c-3.147,8.365-7.554,16.84-13.515,23.558C419.648,331.956,419.411,331.776,419.514,331.622z"/>
                                <path style={{ fill: "#263238" }} d="M426.296,306.309c2.114-3.74,4.343-7.38,6.333-11.194c0.056-0.107,0.202-0.013,0.158,0.092
                                c-1.67,3.898-3.665,7.857-6.164,11.293C426.482,306.693,426.173,306.526,426.296,306.309z"/>
                                <path style={{ fill: "#263238" }} d="M425.47,307.936c0.042,0,0.042,0.065,0,0.065C425.428,308.002,425.428,307.936,425.47,307.936z"
                                />
                            </g>
                        </g>
                        <g>
                            <path className="primary" d="M411.236,356.568c-0.13,5.078-0.679,10.156-1.79,15.098c0.078,0.546,0.157,1.091,0.237,1.653
                            c1.6-5.501,2.437-11.139,2.701-16.829c0.087-0.158,0.123-0.328,0.024-0.521c0,0,0,0-0.001-0.016
                            c0.289-7.599-0.415-15.283-1.691-22.802c-2.373-14.084-7.558-27.6-17.38-38.155c-0.311-0.325-0.933-0.157-1.085,0.224
                            c-3.963,10.078-2.58,22.103,0.796,32.157c1.837,5.416,4.46,10.616,7.789,15.273
                            C404.204,347.386,408.499,351.394,411.236,356.568z"/>
                            <g>
                                <path style={{ fill: "#263238" }} d="M394.497,299.711c9.308,15.107,15.785,33.019,16.577,50.865c0.014,0.316-0.518,0.4-0.563,0.076
                                c-0.22-1.603-0.465-3.193-0.722-4.775c-0.005-0.005-0.011-0.001-0.016-0.006c-3.851-3.689-7.804-7.58-10.562-12.189
                                c-0.048-0.081,0.069-0.176,0.133-0.102c3.35,3.829,6.476,7.752,10.259,11.166c-0.667-3.946-1.456-7.829-2.392-11.655
                                c-0.249-0.285-0.492-0.578-0.736-0.865c-0.162-0.19,0.108-0.465,0.276-0.277c0.074,0.083,0.149,0.165,0.224,0.248
                                c-1.319-5.254-2.928-10.398-4.867-15.452c-2.37-2.827-5.019-5.27-6.745-8.674c-0.043-0.083,0.071-0.147,0.124-0.072
                                c1.388,1.947,2.73,3.906,4.305,5.713c0.627,0.72,1.316,1.393,1.966,2.095c-2.118-5.395-4.632-10.685-7.621-15.885
                                C394.003,299.688,394.356,299.483,394.497,299.711z"/>
                                <path style={{ fill: "#263238" }} d="M396.378,318.347c3.009,4.049,6.438,7.913,9.234,12.098c0.081,0.12-0.095,0.243-0.194,0.15
                                c-3.594-3.372-6.635-7.976-9.242-12.129C396.094,318.335,396.291,318.229,396.378,318.347z"/>
                                <path style={{ fill: "#263238" }} d="M406.034,331.383l0.128,0.128c0.089,0.089-0.049,0.227-0.138,0.138l-0.129-0.128
                                C405.807,331.432,405.945,331.294,406.034,331.383z"/>
                            </g>
                        </g>
                        <g>
                            <path className="primary" d="M379.912,328.753c0.748,7.773,4.837,17.057,9.823,23.046c2.21,2.649,4.89,4.676,7.966,6.216
                            c3.45,1.707,7.062,3.672,10.631,5.073c1.668,4.259,2.945,8.721,3.764,13.37c0.115-0.389,0.23-0.795,0.345-1.184
                            c-2.291-19.679-15.025-36.499-31.313-47.296C380.541,327.59,379.847,328.089,379.912,328.753z"/>
                            <g>
                                <path style={{ fill: "#263238" }} d="M385.54,334.567c-0.115-0.101,0.04-0.308,0.166-0.215c8.632,6.394,16.41,15.718,20.754,25.579
                                c0.119,0.269-0.251,0.505-0.404,0.236c-0.757-1.327-1.529-2.617-2.314-3.885c-0.004-0.001-0.006,0.003-0.011,0.002
                                c-3.718-0.7-7.238-3.316-9.998-5.8c-0.075-0.067,0.035-0.177,0.11-0.11c1.441,1.27,2.987,2.432,4.704,3.304
                                c1.516,0.77,3.147,1.255,4.725,1.871c-1.378-2.187-2.814-4.284-4.313-6.318c-4.001-1.363-7.811-3.82-11.173-6.281
                                c-0.106-0.077-0.012-0.251,0.105-0.18c3.427,2.063,6.807,3.879,10.385,5.552c-2.254-2.986-4.694-5.814-7.332-8.549
                                c-1.263-0.667-2.545-1.338-3.77-2.051c-0.1-0.058-0.034-0.228,0.08-0.19c0.976,0.325,1.969,0.836,2.893,1.421
                                C388.675,337.466,387.161,335.999,385.54,334.567z"/>
                                <path style={{ fill: "#263238" }} d="M385.886,341.362c0.225,0.143,0.428,0.272,0.627,0.447c0.06,0.053-0.016,0.13-0.079,0.103
                                c-0.238-0.104-0.434-0.23-0.653-0.37C385.669,341.47,385.773,341.29,385.886,341.362z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon style={{ fill: "#455A64" }} points="437.86,419.074 393.227,419.074 396.83,378.428 397.621,369.464 433.466,369.464 
                            434.257,378.428 				"/>
                            <polygon style={{ fill: "#263238" }} points="434.257,378.428 396.83,378.428 397.621,369.464 433.466,369.464 				" />
                        </g>
                        <rect x="395.486" y="363.197" style={{ fill: "#455A64" }} width="40.116" height="8.871" />
                    </g>
                </g>
            </g>
            <g id="Table">
                <g>
                    <polygon style={{ fill: "#263238" }} points="45.754,419.074 96.815,418.831 147.877,418.743 250,418.576 352.123,418.742 
                    403.184,418.83 454.246,419.074 403.184,419.319 352.123,419.407 250,419.573 147.877,419.406 96.815,419.318 		"/>
                </g>
            </g>
            <g id="Light_Bulb">
                <g>
                    <g>
                        <g>
                            <path className="primary" d="M375.808,176.331c-6.612,56.602-42.97,67.012-49.058,82.109
                            c-3.253,8.173-7.563,28.945-7.563,28.945l-59.136,0.009c0,0-4.279-20.77-7.572-28.942
                            c-6.056-15.095-42.455-25.494-49.047-82.094c-4.357-37.191,23.593-95.915,86.172-95.925
                            C352.183,80.423,380.115,139.138,375.808,176.331z"/>
                            <path style={{ opacity: 0.6, fill: "#FFFFFF" }} d="M375.808,176.331c-6.612,56.602-42.97,67.012-49.058,82.109
                            c-3.253,8.173-7.563,28.945-7.563,28.945l-59.136,0.009c0,0-4.279-20.77-7.572-28.942
                            c-6.056-15.095-42.455-25.494-49.047-82.094c-4.357-37.191,23.593-95.915,86.172-95.925
                            C352.183,80.423,380.115,139.138,375.808,176.331z"/>
                        </g>
                        <g>
                            <line style={{ fill: "none", stroke: "#FFFFFF" }} x1="275.098" y1="291.253" x2="257.536" y2="197.187" />
                            <line style={{ fill: "none", stroke: "#FFFFFF" }} x1="304.172" y1="291.249" x2="321.704" y2="197.177" />
                            <path style={{ fill: "none", stroke: "#FFFFFF" }} d="M259.038,200.779
                            c0,0,3.478,18.42,11.819,18.418c8.341-0.001,16.157-27.459,5.209-27.457c-10.948,0.002-0.344,35.971,13.559,35.969
                            c13.903-0.002,21.891-35.974,12.159-35.973c-9.731,0.002-6.654,28.674,6.726,28.671c7.181-0.001,12.307-18.47,12.307-18.47"/>
                        </g>
                        <g>
                            <rect x="253.153" y="284.857" style={{ fill: "#455A64" }} width="72.964" height="14.259" />
                            <rect x="253.156" y="301.423" style={{ fill: "#455A64" }} width="72.964" height="14.259" />
                            <rect x="253.158" y="317.989" style={{ fill: "#455A64" }} width="72.964" height="14.259" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M288.603,70.284c-0.459-3.569-0.615-7.145-0.698-10.721c-0.052-3.577-0.028-7.155,0.302-10.739
                            c0.461,3.569,0.617,7.145,0.697,10.721C288.954,63.121,288.93,66.7,288.603,70.284z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M239.604,83.956c-2.182-2.862-4.105-5.88-5.964-8.936c-1.833-3.072-3.602-6.183-5.108-9.451
                            c2.184,2.861,4.107,5.879,5.964,8.936C236.328,77.577,238.096,80.688,239.604,83.956z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M204.006,120.295c-3.321-1.387-6.495-3.039-9.633-4.757c-3.124-1.744-6.21-3.554-9.149-5.631
                            c3.322,1.385,6.496,3.038,9.633,4.757C197.979,116.409,201.066,118.22,204.006,120.295z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M191.347,169.565c-3.57,0.459-7.145,0.615-10.721,0.697c-3.577,0.052-7.155,0.028-10.739-0.302
                            c3.569-0.462,7.145-0.617,10.721-0.697C184.185,169.213,187.763,169.238,191.347,169.565z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M205.019,218.563c-2.862,2.182-5.88,4.105-8.936,5.964c-3.072,1.833-6.183,3.602-9.452,5.108
                            c2.861-2.184,5.879-4.107,8.936-5.965C198.64,221.84,201.752,220.072,205.019,218.563z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M375.225,216.809c3.321,1.387,6.495,3.04,9.633,4.757c3.124,1.744,6.211,3.554,9.15,5.631
                            c-3.322-1.385-6.496-3.038-9.633-4.757C381.252,220.695,378.165,218.885,375.225,216.809z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M387.884,167.539c3.57-0.459,7.145-0.615,10.721-0.697c3.577-0.052,7.155-0.027,10.74,0.302
                            c-3.569,0.461-7.145,0.617-10.721,0.697C395.046,167.891,391.468,167.867,387.884,167.539z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M374.212,118.541c2.862-2.182,5.88-4.105,8.936-5.964c3.072-1.833,6.183-3.602,9.451-5.108
                            c-2.86,2.184-5.879,4.107-8.936,5.964C380.591,115.264,377.48,117.032,374.212,118.541z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M337.872,82.943c1.387-3.321,3.04-6.495,4.757-9.633c1.744-3.124,3.554-6.211,5.631-9.15
                            c-1.385,3.322-3.038,6.496-4.757,9.633C341.758,76.916,339.948,80.003,337.872,82.943z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Social_Icons">
                <g>
                    <g>
                        <g>
                            <path style={{ fill: "#263238" }} d="M308.617,146.207c0,3.521-2.855,6.374-6.375,6.374c-3.521,0-6.374-2.854-6.374-6.374
                            c0-3.52,2.854-6.374,6.374-6.374C305.762,139.833,308.617,142.687,308.617,146.207z"/>
                            <path style={{ fill: "#263238" }} d="M313.259,163.777v2.331h-22.048v-2.331c0-6.059,4.941-11.001,11.047-11.001
                            C308.317,152.777,313.259,157.718,313.259,163.777z"/>
                        </g>
                        <g>
                            <path style={{ fill: "#455A64" }} d="M292.288,150.05c0,3.521-2.855,6.374-6.375,6.374c-3.52,0-6.374-2.854-6.374-6.374
                            c0-3.521,2.854-6.374,6.374-6.374C289.434,143.675,292.288,146.529,292.288,150.05z"/>
                            <path style={{ fill: "#455A64" }} d="M296.93,167.619v2.331h-22.048v-2.331c0-6.059,4.941-11,11.047-11
                            C291.989,156.619,296.93,161.56,296.93,167.619z"/>
                        </g>
                    </g>
                    <path style={{ fill: "#263238" }} d="M248.755,238.005c0.539-0.335,1.214-0.316,1.757,0.012c0.147,0.089,0.298,0.175,0.451,0.257
                    c0.154,0.083,0.31,0.161,0.466,0.235c0.572,0.27,0.959,0.82,0.979,1.451l0.067,2.161l7.037-0.219l-0.066-2.159
                    c-0.019-0.634,0.327-1.214,0.884-1.518c0.306-0.167,0.604-0.351,0.892-0.551c0.519-0.36,1.191-0.41,1.747-0.112l1.9,1.019
                    l3.328-6.206l-1.9-1.019c-0.556-0.299-0.886-0.886-0.873-1.517c0.007-0.351-0.004-0.701-0.034-1.048
                    c-0.055-0.632,0.237-1.242,0.775-1.576l1.836-1.139l-3.711-5.983l-1.846,1.143c-0.537,0.333-1.208,0.313-1.748-0.013
                    c-0.147-0.089-0.298-0.175-0.45-0.257c-0.153-0.082-0.307-0.16-0.463-0.233c-0.573-0.271-0.962-0.823-0.981-1.457l-0.065-2.162
                    l-7.037,0.219l0.068,2.155c0.02,0.633-0.326,1.214-0.882,1.518c-0.307,0.168-0.606,0.353-0.895,0.554
                    c-0.519,0.361-1.192,0.411-1.749,0.113l-1.899-1.018l-3.329,6.207l1.899,1.019c0.557,0.299,0.887,0.887,0.874,1.519
                    c-0.008,0.352,0.003,0.703,0.033,1.053c0.054,0.632-0.238,1.241-0.777,1.575l-1.832,1.135l3.711,5.983L248.755,238.005z
                     M250.519,226.893c1.505-2.807,5.005-3.863,7.812-2.358c2.807,1.505,3.858,5.003,2.353,7.809
                    c-1.505,2.807-4.997,3.861-7.804,2.355C250.073,233.195,249.013,229.7,250.519,226.893z"/>
                    <path style={{ fill: "#455A64" }} d="M209.133,169.84c0.385,0.222,0.597,0.645,0.574,1.089c-0.006,0.12-0.009,0.242-0.009,0.363
                    c0,0.123,0.003,0.245,0.009,0.366c0.023,0.442-0.188,0.864-0.572,1.085l-1.312,0.757l2.465,4.272l1.311-0.756
                    c0.385-0.222,0.858-0.2,1.23,0.043c0.205,0.134,0.417,0.257,0.636,0.368c0.394,0.201,0.648,0.599,0.648,1.042v1.51h4.934v-1.51
                    c0-0.443,0.253-0.841,0.648-1.042c0.219-0.111,0.431-0.235,0.636-0.368c0.372-0.243,0.845-0.265,1.23-0.043l1.311,0.756
                    l2.465-4.272l-1.317-0.761c-0.383-0.221-0.593-0.642-0.571-1.084c0.006-0.12,0.009-0.242,0.009-0.363
                    c0-0.122-0.003-0.243-0.009-0.363c-0.023-0.444,0.189-0.866,0.574-1.088l1.313-0.756l-2.465-4.272l-1.308,0.755
                    c-0.385,0.222-0.857,0.201-1.229-0.042c-0.206-0.134-0.419-0.257-0.639-0.369c-0.395-0.201-0.649-0.599-0.649-1.042v-1.51h-4.934
                    v1.51c0,0.443-0.254,0.842-0.649,1.042c-0.22,0.112-0.433,0.235-0.639,0.369c-0.372,0.243-0.845,0.264-1.229,0.042l-1.307-0.755
                    l-2.465,4.272L209.133,169.84z M216.578,167.25c2.231,0,4.042,1.811,4.042,4.042c0,2.231-1.811,4.038-4.042,4.038
                    c-2.231,0-4.038-1.807-4.038-4.038C212.539,169.061,214.347,167.25,216.578,167.25z"/>
                    <path className="primary" d="M238.509,131.596c-0.63-6.329-4.991-12.628-12.657-12.628c-7.656,0-12.018,6.299-12.648,12.628
                    c-0.969,9.769,5.621,18.618,12.648,26.691C232.888,150.214,239.479,141.365,238.509,131.596z M225.852,138.623
                    c-3.828,0-6.93-3.101-6.93-6.93c0-3.828,3.102-6.929,6.93-6.929c3.838,0,6.939,3.101,6.939,6.929
                    C232.791,135.521,229.69,138.623,225.852,138.623z"/>
                    <g>
                        <circle style={{ fill: "#455A64" }} cx="269.108" cy="113.083" r="28.979" />
                        <path style={{ fill: "#FFFFFF" }} d="M268.405,105.052c-0.066,1.844-0.022,3.709-0.022,5.707h7.199
                        c-0.307,2.37-0.571,4.565-0.922,6.738c-0.022,0.219-0.571,0.483-0.878,0.505c-1.734,0.044-3.468,0.022-5.355,0.022v24.012
                        c-2.656-0.044-5.202-0.461-7.638-1.185v-22.783h-6.299v-7.243h6.168c0.022-0.549,0.066-0.988,0.066-1.405
                        c0.044-1.91-0.022-3.819,0.154-5.707c0.395-4.148,2.941-7.067,7.046-7.638c2.546-0.351,5.202-0.066,7.88-0.066v6.585
                        c-0.944,0-1.909-0.022-2.853,0c-0.834,0.022-1.712-0.022-2.524,0.176C269.261,103.033,268.449,103.823,268.405,105.052z"/>
                    </g>
                    <g>
                        <circle style={{ fill: "#455A64" }} cx="344.757" cy="154.613" r="28.979" />
                        <g>
                            <path style={{ fill: "#FFFFFF" }} d="M328.934,168.214c3.855,0.073,7.315-0.864,10.44-3.218c-4.319-1.311-6.04-2.63-6.669-5.085
                            c0.968-0.047,1.932-0.095,2.897-0.143c-3.408-1.309-5.411-3.599-5.673-7.339c1.045,0.277,2.013,0.533,2.982,0.789
                            c-3.128-3.934-3.76-6.986-1.893-9.834c4.042,4.614,9.07,7.234,15.316,7.66c-0.028-0.488-0.056-0.855-0.07-1.222
                            c-0.123-3.225,1.23-5.663,4.103-7.083c2.865-1.417,5.652-1.037,8.137,0.982c0.553,0.449,1.015,0.572,1.642,0.336
                            c1.163-0.437,2.332-0.857,3.615-1.327c-0.535,1.552-1.538,2.707-2.843,3.646c0.027,0.07,0.055,0.14,0.082,0.21
                            c1.193-0.306,2.387-0.613,3.581-0.919c0.048,0.048,0.096,0.095,0.143,0.142c-0.925,0.932-1.812,1.907-2.791,2.777
                            c-0.514,0.458-0.78,0.866-0.779,1.597c0.032,10.932-8.218,20.026-19.07,21.002c-4.38,0.393-8.555-0.397-12.436-2.538
                            C329.405,168.512,329.172,168.359,328.934,168.214z"/>
                        </g>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.1592 -0.9873 0.9873 0.1592 -4.9652 399.0066)" style={{ fill: "#455A64" }} cx="231.763" cy="202.418" rx="20.324" ry="20.324" />
                        <g id="_x32_dqxSk.tif_2_">
                            <g>
                                <path style={{ fill: "#FFFFFF" }} d="M223.799,191.764c0.028,0.009,0.056,0.024,0.085,0.026c0.623,0.047,1.162,0.284,1.606,0.725
                                c0.396,0.394,0.644,0.87,0.725,1.424c0.137,0.94-0.153,1.732-0.861,2.363c-0.345,0.308-0.756,0.49-1.211,0.576
                                c-0.235,0.044-0.473,0.053-0.708,0.031c-1.085-0.099-1.942-0.858-2.217-1.819c-0.05-0.175-0.085-0.353-0.094-0.535
                                c-0.002-0.035,0.006-0.077-0.042-0.093c0-0.074,0-0.148,0-0.222c0.045-0.017,0.038-0.058,0.042-0.094
                                c0.007-0.073,0.011-0.147,0.023-0.22c0.082-0.502,0.286-0.947,0.631-1.321c0.461-0.5,1.035-0.769,1.714-0.817
                                c0.029-0.002,0.057-0.017,0.086-0.025C223.651,191.764,223.725,191.764,223.799,191.764z"/>
                                <path style={{ fill: "#FFFFFF" }} d="M232.936,200.724c0.021-0.029,0.044-0.056,0.064-0.086c0.218-0.319,0.463-0.615,0.747-0.877
                                c0.595-0.548,1.285-0.918,2.07-1.11c0.378-0.093,0.763-0.134,1.151-0.156c0.262-0.015,0.524-0.01,0.785,0.009
                                c0.758,0.057,1.495,0.203,2.182,0.542c0.706,0.348,1.244,0.875,1.635,1.557c0.247,0.431,0.411,0.894,0.542,1.37
                                c0.09,0.327,0.155,0.659,0.199,0.995c0.022,0.168,0.032,0.337,0.042,0.506c0.02,0.313,0.035,0.627,0.053,0.94
                                c0,0.009,0.001,0.018,0.001,0.028c0.008,0.299,0.02,0.597,0.022,0.896c0.007,1.001,0.013,2.002,0.014,3.004
                                c0.002,1.513,0.001,3.026,0.001,4.539c0,0.169-0.007,0.176-0.178,0.176c-0.641,0-1.282,0-1.923,0
                                c-0.598,0.001-1.196,0.001-1.793,0.003c-0.095,0-0.19,0.008-0.286,0.013c-0.015,0.001-0.031,0.001-0.046,0
                                c-0.212-0.011-0.215-0.06-0.215-0.219c0.001-1.254,0.002-2.508,0.002-3.762c0-1.245,0-2.49-0.005-3.735
                                c-0.002-0.472-0.043-0.941-0.182-1.396c-0.081-0.266-0.184-0.523-0.342-0.754c-0.274-0.402-0.654-0.643-1.127-0.745
                                c-0.175-0.038-0.352-0.061-0.531-0.056c-0.191,0.004-0.383-0.002-0.571,0.021c-0.837,0.103-1.457,0.515-1.801,1.302
                                c-0.148,0.338-0.234,0.693-0.285,1.057c-0.054,0.388-0.072,0.778-0.072,1.169c0,2.28,0,4.561,0,6.841
                                c0,0.052,0.002,0.105-0.002,0.157c-0.006,0.07-0.036,0.098-0.106,0.102c-0.04,0.003-0.08,0.002-0.12,0.002
                                c-0.727,0-1.454,0-2.182,0c-0.459,0-0.918,0-1.377,0.003c-0.135,0.001-0.271,0.008-0.406,0.013
                                c-0.018,0.001-0.037,0.001-0.055,0c-0.18-0.018-0.194-0.064-0.192-0.197c0.001-0.068,0.001-0.136,0.001-0.203
                                c0-3.005,0.001-6.009,0.001-9.013c0-1.516-0.001-3.032-0.001-4.548c0-0.04-0.001-0.08,0.001-0.12
                                c0.005-0.1,0.04-0.136,0.142-0.142c0.025-0.002,0.049-0.001,0.074-0.001c1.276,0,2.551,0,3.827,0c0.025,0,0.049,0,0.074,0.001
                                c0.098,0.006,0.135,0.038,0.136,0.137c0.001,0.172-0.007,0.345-0.007,0.517c0,0.252,0.003,0.505,0.005,0.757
                                c0.001,0.12,0.002,0.24,0.004,0.36c0,0.033,0.003,0.065,0.004,0.098C232.917,200.719,232.926,200.722,232.936,200.724z"/>
                                <path style={{ fill: "#FFFFFF" }} d="M221.46,205.945c0-2.299,0-4.597,0-6.896c0-0.04,0-0.08,0.005-0.12
                                c0.007-0.048,0.033-0.078,0.086-0.079c0.034,0,0.068-0.003,0.102-0.003c1.346,0,2.693-0.001,4.039-0.001
                                c0.009,0,0.019,0,0.028,0c0.155,0.002,0.195,0.041,0.195,0.2c0.001,0.521,0,1.041,0,1.562c0,4.067,0,8.135,0,12.202
                                c0,0.049-0.002,0.099-0.01,0.147c-0.01,0.057-0.044,0.097-0.105,0.1c-0.104,0.006-0.208,0.009-0.312,0.01
                                c-0.04,0.001-0.08-0.007-0.12-0.007c-1.131-0.001-2.262-0.001-3.392,0c-0.089,0-0.178,0.008-0.267,0.012
                                c-0.018,0.001-0.037,0.002-0.055,0c-0.15-0.017-0.195-0.033-0.195-0.203C221.46,210.56,221.46,208.252,221.46,205.945
                                C221.46,205.945,221.46,205.945,221.46,205.945z"/>
                            </g>
                        </g>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.2269 -0.9739 0.9739 0.2269 28.3206 441.352)" className="primary" cx="292.169" cy="202.837" rx="28.952" ry="28.952" />
                        <g>
                            <g>
                                <path style={{ fill: "#FFFFFF" }} d="M309.434,193.298c-0.02-4.699-2.954-7.738-7.612-7.782c-6.444-0.065-12.893-0.069-19.337,0
                                c-4.558,0.048-7.512,3.067-7.581,7.617c-0.044,3.216-0.008,6.436-0.008,9.652c0,3.147-0.02,6.299,0.004,9.446
                                c0.04,4.804,2.97,7.875,7.726,7.923c6.376,0.069,12.755,0.073,19.131,0c4.663-0.052,7.649-3.12,7.673-7.762
                                C309.466,206.029,309.462,199.661,309.434,193.298z M306.352,212.449c-0.033,2.97-1.633,4.615-4.607,4.635
                                c-6.376,0.04-12.751,0.04-19.127,0c-2.986-0.016-4.602-1.657-4.618-4.615c-0.028-6.432-0.028-12.864,0-19.296
                                c0.012-2.946,1.66-4.639,4.602-4.659c6.376-0.044,12.752-0.044,19.127,0c2.95,0.02,4.59,1.704,4.623,4.642
                                c0.036,3.216,0.008,6.432,0.008,9.648C306.36,206.017,306.388,209.233,306.352,212.449z"/>
                                <path style={{ fill: "#FFFFFF" }} d="M292.19,193.774c-4.917-0.012-8.951,3.982-9,8.923c-0.048,4.997,3.821,9.028,8.766,9.124
                                c5.065,0.097,9.152-3.849,9.201-8.891C301.209,197.884,297.199,193.786,292.19,193.774z M292.077,208.806
                                c-3.212-0.065-5.884-2.753-5.916-5.94c-0.032-3.289,2.68-5.993,6.013-5.993c3.325,0,6.045,2.704,6.017,5.989
                                C298.162,206.122,295.337,208.87,292.077,208.806z"/>
                                <path style={{ fill: "#FFFFFF" }} d="M303.897,193.559c-0.096,1.266-0.907,1.927-2.152,1.866c-1.366-0.064-2.063-0.903-1.963-2.248
                                c0.093-1.262,0.915-1.846,2.176-1.919C303.24,191.476,303.998,192.206,303.897,193.559z"/>
                            </g>
                        </g>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.2683 -0.9633 0.9633 0.2683 131.0273 389.9547)" className="primary" cx="322.211" cy="108.725" rx="19.577" ry="19.577" />
                        <g>
                            <polygon style={{ fill: "#FFFFFF" }} points="314.909,116.242 314.909,101.209 332.834,108.726 				" />
                        </g>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.9258 -0.378 0.378 0.9258 -78.637 123.3081)" className="primary" cx="274.807" cy="261.98" rx="20.324" ry="20.324" />
                        <path style={{ fill: "#FFFFFF" }} d="M286.137,264.571c-3.355,6.764-11.333,10.778-11.333,10.778s-7.979-4.014-11.327-10.778
                        c-2.793-5.642-1.024-11.74,3.385-13.072c6.32-1.911,7.942,9.095,7.942,9.095s1.622-11.006,7.948-9.095
                        C287.16,252.831,288.93,258.929,286.137,264.571z"/>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -41.625 226.0774)" style={{ fill: "#263238" }} cx="252.087" cy="163.284" rx="20.324" ry="20.324" />
                        <g id="QfoS7x.tif_2_">
                            <g>
                                <path style={{ fill: "#FEFEFE" }} d="M258.006,154.966c0.105,0.193,0.221,0.38,0.352,0.559c0.001,0.002,0.02,0.014,0.031,0.025
                                C258.353,155.498,258.185,155.286,258.006,154.966z M258.396,155.559c0,0.001,0,0.001,0,0.002c0,0,0,0.001,0,0.001c0,0,0,0,0,0
                                c0.001,0,0.001,0.001,0.001,0.001c0.071,0.05,0.141,0.101,0.212,0.15C258.539,155.663,258.467,155.609,258.396,155.559z
                                 M261.843,159.254c0-0.532,0-1.065,0-1.597c-0.212-0.035-0.421-0.085-0.627-0.145c-0.356-0.103-0.701-0.235-1.031-0.406
                                c-0.325-0.168-0.63-0.367-0.913-0.601c-0.149-0.124-0.296-0.249-0.428-0.39c-0.02-0.022-0.04-0.043-0.061-0.065
                                c-0.017-0.019-0.035-0.038-0.052-0.058c-0.121-0.135-0.236-0.276-0.335-0.431c-0.049-0.012-0.117-0.074-0.15-0.101
                                c-0.231-0.197-0.443-0.411-0.638-0.645c-0.298-0.36-0.544-0.751-0.745-1.171c-0.201-0.42-0.356-0.856-0.464-1.309
                                c-0.039-0.164-0.074-0.33-0.109-0.495c0-0.001-0.001-0.002-0.001-0.004c-0.001-0.003-0.001-0.007-0.001-0.011
                                c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004,0-0.006c0-0.014,0.002-0.029,0.002-0.043h-0.001v-0.001c-0.642,0-1.284,0-1.926,0
                                c-0.047,0-0.093,0-0.139,0c-0.155,0-0.309,0-0.455-0.001c-0.06,0-0.12,0-0.177,0c-0.145,0-0.28-0.001-0.401-0.001
                                c-0.072,0-0.085,0.021-0.085,0.088c0.001,4.9,0.001,9.801,0.001,14.701c0,0.43,0.003,0.859-0.001,1.289
                                c-0.002,0.245-0.025,0.489-0.051,0.733c-0.033,0.309-0.085,0.614-0.167,0.913c-0.038,0.137-0.116,0.256-0.186,0.378
                                c-0.176,0.311-0.399,0.585-0.661,0.828c-0.19,0.177-0.399,0.328-0.627,0.453c-0.313,0.171-0.643,0.294-0.994,0.36
                                c-0.124,0.023-0.25,0.039-0.376,0.052c-0.126,0.012-0.251,0.012-0.376,0.01c-0.438-0.006-0.858-0.097-1.264-0.259
                                c-0.293-0.117-0.563-0.275-0.807-0.474c-0.264-0.216-0.486-0.47-0.653-0.795c0.004-0.013-0.162-0.321-0.268-0.706
                                c0.061,0.232,0.141,0.458,0.253,0.673c0.004,0.009,0.006,0.017,0.004,0.027c-0.001,0.003-0.001,0.006-0.002,0.009
                                c-0.094-0.048-0.176-0.111-0.257-0.175c-0.005-0.004-0.01-0.008-0.016-0.012c-0.097-0.077-0.192-0.157-0.278-0.248
                                c-0.254-0.268-0.444-0.574-0.57-0.922c-0.072-0.199-0.132-0.401-0.172-0.608c-0.032-0.162-0.054-0.327-0.063-0.494
                                c-0.005-0.1-0.014-0.199-0.011-0.299c0.01-0.355,0.059-0.704,0.163-1.045c0.108-0.353,0.27-0.678,0.486-0.974
                                c0.239-0.327,0.536-0.592,0.884-0.802c0.319-0.192,0.662-0.323,1.023-0.406c0.157-0.036,0.316-0.057,0.476-0.076
                                c0.249-0.03,0.499-0.032,0.748-0.023c0.174,0.006,0.351,0.014,0.523,0.051c0.071,0.015,0.085,0.004,0.085-0.073
                                c0-1.127,0-2.253,0-3.38c-0.258-0.001-0.517-0.012-0.775,0.001c-0.3,0.016-0.599,0.05-0.896,0.102
                                c-0.335,0.059-0.664,0.14-0.987,0.245c-0.23,0.075-0.456,0.165-0.677,0.266c-0.342,0.156-0.669,0.337-0.98,0.548
                                c-0.223,0.151-0.436,0.317-0.642,0.491c-0.208,0.176-0.401,0.367-0.586,0.565c-0.235,0.252-0.449,0.522-0.646,0.806
                                c-0.234,0.339-0.442,0.693-0.621,1.063c-0.133,0.276-0.249,0.559-0.352,0.848c-0.145,0.406-0.253,0.822-0.336,1.244
                                c-0.039,0.2-0.063,0.402-0.089,0.604c-0.049,0.396-0.06,0.794-0.052,1.192c0.005,0.231,0.026,0.461,0.052,0.691
                                c0.04,0.365,0.107,0.724,0.2,1.079c0.098,0.375,0.226,0.74,0.381,1.097c0.173,0.399,0.386,0.776,0.627,1.138
                                c0.057,0.086,0.119,0.169,0.168,0.264c-0.008,0.013,0.317,0.377,0.496,0.569c-0.132-0.143-0.257-0.293-0.381-0.445
                                c-0.008-0.01-0.011-0.02-0.001-0.034c0.02,0.01,0.038,0.022,0.056,0.035c0.051,0.037,0.096,0.081,0.144,0.121
                                c0.005,0.004,0.01,0.008,0.014,0.012c0.21,0.167,0.427,0.324,0.653,0.468c0.287,0.184,0.586,0.348,0.895,0.493
                                c0.388,0.183,0.788,0.336,1.198,0.461c0.204,0.062,0.417,0.09,0.627,0.131c0.186,0.037,0.374,0.06,0.561,0.092
                                c0.086,0.015,0.174,0.022,0.261,0.029c0.282,0.022,0.565,0.051,0.848,0.041c0.243-0.008,0.486-0.022,0.729-0.051
                                c0.284-0.034,0.566-0.079,0.845-0.141c0.421-0.094,0.83-0.227,1.225-0.401c0.462-0.204,0.893-0.46,1.298-0.763
                                c0.326-0.244,0.619-0.524,0.897-0.819c0.212-0.225,0.401-0.471,0.578-0.725c0.247-0.353,0.462-0.725,0.646-1.114
                                c0.208-0.436,0.372-0.888,0.503-1.352c0.072-0.257,0.13-0.517,0.18-0.779c0.037-0.191,0.062-0.383,0.092-0.575
                                c0.017-0.106,0.022-0.215,0.029-0.323c0.087-1.47,0.029-2.942,0.039-4.413c0.01-1.474,0.002-2.948,0.002-4.422v-0.079
                                c0.059,0.049,0.106,0.089,0.153,0.129c0.237,0.201,0.491,0.378,0.756,0.541c0.238,0.146,0.483,0.277,0.736,0.396
                                c0.436,0.206,0.888,0.371,1.351,0.508c0.335,0.099,0.673,0.181,1.015,0.25c0.26,0.052,0.522,0.097,0.785,0.132
                                c0.16,0.021,0.322,0.036,0.484,0.047c0.107,0.007,0.213,0.003,0.319,0.012c0.057,0.004,0.066-0.018,0.066-0.068
                                C261.842,160.265,261.843,159.76,261.843,159.254z M243.786,172.847c-0.004-0.001-0.007-0.003-0.01-0.005
                                c0.001-0.001,0.003-0.005,0.004-0.004c0.004,0.001,0.007,0.003,0.011,0.005C243.789,172.844,243.787,172.848,243.786,172.847z
                                 M243.84,172.895c-0.004-0.002-0.007-0.005-0.011-0.008c0.003-0.003,0.007-0.007,0.01-0.007c0.003-0.001,0.01,0.003,0.01,0.005
                                C243.85,172.891,243.85,172.899,243.84,172.895z M244.241,173.378c0.012,0.013,0.024,0.026,0.035,0.037
                                C244.264,173.403,244.252,173.391,244.241,173.378z M254.364,151.772c0.947,0.001,1.926,0.001,1.926,0.001v-0.001
                                C255.648,151.772,255.006,151.772,254.364,151.772z M256.29,151.773c0,0-0.002,0.023-0.001,0.043
                                c0-0.014,0.002-0.029,0.002-0.043H256.29z"/>
                                <path style={{ fill: "#455A64" }} d="M262.818,161.806c0,0.059-0.012,0.076-0.074,0.075c-0.23-0.006-0.459-0.03-0.688-0.054
                                c-0.183-0.019-0.365-0.045-0.546-0.073c-0.206-0.033-0.411-0.077-0.616-0.122c-0.399-0.088-0.793-0.195-1.179-0.329
                                c-0.311-0.107-0.615-0.229-0.914-0.368c-0.373-0.173-0.73-0.372-1.068-0.605c-0.194-0.134-0.382-0.275-0.557-0.433
                                c-0.018,0.006-0.009,0.022-0.009,0.034c0.009,0.618-0.016,1.237-0.015,1.855c0.003,2.167,0.003,4.333-0.001,6.5
                                c-0.001,0.222-0.021,0.445-0.05,0.667c-0.019,0.147-0.033,0.294-0.056,0.44c-0.033,0.211-0.075,0.421-0.121,0.63
                                c-0.063,0.289-0.143,0.573-0.237,0.853c-0.112,0.337-0.248,0.665-0.402,0.986c-0.186,0.391-0.409,0.76-0.656,1.114
                                c-0.115,0.165-0.242,0.322-0.372,0.477c-0.115,0.137-0.236,0.269-0.362,0.395c-0.177,0.177-0.362,0.346-0.559,0.501
                                c-0.179,0.141-0.362,0.276-0.554,0.399c-0.325,0.209-0.666,0.386-1.023,0.533c-0.315,0.13-0.637,0.236-0.967,0.317
                                c-0.199,0.049-0.4,0.086-0.603,0.119c-0.203,0.033-0.408,0.052-0.612,0.081c-0.057,0.008-0.116-0.016-0.171,0.014h-0.809
                                c-0.013-0.025-0.036-0.012-0.055-0.013c-0.223-0.008-0.443-0.036-0.664-0.067c-0.175-0.024-0.348-0.057-0.523-0.083
                                c-0.39-0.057-0.762-0.172-1.129-0.309c-0.218-0.082-0.432-0.175-0.644-0.272c-0.365-0.167-0.712-0.367-1.046-0.588
                                c-0.196-0.129-0.383-0.271-0.565-0.419c-0.238-0.193-0.463-0.401-0.674-0.622c-0.002-0.002-0.005-0.005-0.007-0.007
                                c-0.005-0.005-0.01-0.011-0.015-0.017c-0.011-0.011-0.022-0.023-0.035-0.037c-0.179-0.192-0.504-0.556-0.496-0.569
                                c0.041,0.021,0.107,0.073,0.17,0.125c0.055,0.045,0.108,0.092,0.144,0.121c0.005,0.004,0.01,0.008,0.014,0.012
                                c0.21,0.167,0.427,0.324,0.653,0.468c0.287,0.184,0.586,0.348,0.895,0.493c0.388,0.183,0.788,0.336,1.198,0.461
                                c0.204,0.062,0.417,0.09,0.627,0.131c0.186,0.037,0.374,0.06,0.561,0.092c0.086,0.015,0.174,0.022,0.261,0.029
                                c0.282,0.022,0.565,0.051,0.848,0.041c0.243-0.008,0.486-0.022,0.729-0.051c0.284-0.034,0.566-0.079,0.845-0.141
                                c0.421-0.094,0.83-0.227,1.225-0.401c0.462-0.204,0.893-0.46,1.298-0.763c0.326-0.244,0.619-0.524,0.897-0.819
                                c0.212-0.225,0.401-0.471,0.578-0.725c0.247-0.353,0.462-0.725,0.646-1.114c0.208-0.436,0.372-0.888,0.503-1.352
                                c0.072-0.257,0.13-0.517,0.18-0.779c0.037-0.191,0.062-0.383,0.092-0.575c0.017-0.106,0.022-0.215,0.029-0.323
                                c0.087-1.47,0.029-2.942,0.039-4.413c0.01-1.474,0.002-2.948,0.002-4.422v-0.079c0.059,0.049,0.106,0.089,0.153,0.129
                                c0.237,0.201,0.491,0.378,0.756,0.541c0.238,0.146,0.483,0.277,0.736,0.396c0.436,0.206,0.888,0.371,1.351,0.508
                                c0.335,0.099,0.673,0.181,1.015,0.25c0.26,0.052,0.522,0.097,0.785,0.132c0.16,0.021,0.322,0.036,0.484,0.047
                                c0.107,0.007,0.213,0.003,0.319,0.012c0.057,0.004,0.066-0.018,0.066-0.068c-0.002-0.505-0.001-1.01-0.001-1.515
                                c0-0.532,0-1.065,0-1.597c0.004-0.001,0.008-0.002,0.012-0.001c0.093,0.03,0.19,0.039,0.286,0.053
                                c0.148,0.023,0.298,0.039,0.447,0.055c0.063,0.007,0.126,0.001,0.189,0.013c0.034,0.006,0.043,0.016,0.039,0.046
                                c-0.001,0.009,0,0.018,0,0.027C262.817,159.169,262.816,160.487,262.818,161.806z"/>
                                <path className="primary" d="M249.968,160.56c-0.005,0.001-0.009,0.003-0.013,0.004c-0.258-0.001-0.517-0.012-0.775,0.001
                                c-0.3,0.016-0.599,0.05-0.896,0.102c-0.335,0.059-0.664,0.14-0.987,0.245c-0.23,0.075-0.456,0.165-0.677,0.266
                                c-0.342,0.156-0.669,0.337-0.98,0.548c-0.223,0.151-0.436,0.317-0.642,0.491c-0.208,0.176-0.401,0.367-0.586,0.565
                                c-0.235,0.252-0.449,0.522-0.646,0.806c-0.234,0.339-0.442,0.693-0.621,1.063c-0.133,0.276-0.249,0.559-0.352,0.848
                                c-0.145,0.406-0.253,0.822-0.336,1.244c-0.039,0.2-0.063,0.402-0.089,0.604c-0.049,0.396-0.06,0.794-0.052,1.192
                                c0.005,0.231,0.026,0.461,0.052,0.691c0.04,0.365,0.107,0.724,0.2,1.079c0.098,0.375,0.226,0.74,0.381,1.097
                                c0.173,0.399,0.386,0.776,0.627,1.138c0.057,0.086,0.119,0.169,0.168,0.264c-0.055-0.047-0.112-0.091-0.163-0.141
                                c-0.265-0.26-0.513-0.534-0.738-0.83c-0.226-0.297-0.426-0.609-0.605-0.936c-0.225-0.412-0.4-0.845-0.542-1.291
                                c-0.106-0.332-0.184-0.672-0.241-1.015c-0.033-0.198-0.056-0.398-0.076-0.599c-0.008-0.082,0.008-0.167-0.024-0.247v-0.96
                                c0.017-0.01,0.014-0.027,0.014-0.043c0.001-0.191,0.031-0.379,0.053-0.568c0.043-0.383,0.125-0.758,0.225-1.13
                                c0.091-0.337,0.205-0.667,0.34-0.99c0.221-0.53,0.492-1.033,0.821-1.504c0.144-0.206,0.3-0.403,0.461-0.596
                                c0.182-0.218,0.382-0.418,0.588-0.61c0.351-0.327,0.736-0.61,1.148-0.856c0.437-0.26,0.898-0.467,1.382-0.622
                                c0.339-0.108,0.683-0.193,1.036-0.243c0.193-0.027,0.385-0.05,0.579-0.069c0.309-0.03,0.619-0.033,0.928-0.022
                                c0.244,0.009,0.489,0.024,0.73,0.064c0.08,0.013,0.162,0.021,0.243,0.028c0.049,0.004,0.065,0.025,0.064,0.074
                                C249.967,159.989,249.968,160.275,249.968,160.56z"/>
                                <path className="primary" d="M256.29,151.772L256.29,151.772c0,0.001-1.077,0.001-2.065-0.001c-0.155,0-0.309,0-0.455-0.001
                                c-0.06,0-0.12,0-0.177,0c-0.145,0-0.28-0.001-0.401-0.001c-0.072,0-0.085,0.021-0.085,0.088
                                c0.001,4.9,0.001,9.801,0.001,14.701c0,0.43,0.003,0.859-0.001,1.289c-0.002,0.245-0.025,0.489-0.051,0.733
                                c-0.033,0.309-0.085,0.614-0.167,0.913c-0.038,0.137-0.116,0.256-0.186,0.378c-0.176,0.311-0.399,0.585-0.661,0.828
                                c-0.19,0.177-0.399,0.328-0.627,0.453c-0.313,0.171-0.643,0.294-0.994,0.36c-0.124,0.023-0.25,0.039-0.376,0.052
                                c-0.126,0.012-0.251,0.012-0.376,0.01c-0.438-0.006-0.858-0.097-1.264-0.259c-0.293-0.117-0.563-0.275-0.807-0.474
                                c-0.264-0.216-0.486-0.47-0.653-0.795c0.062,0.033,0.108,0.056,0.153,0.081c0.283,0.159,0.585,0.264,0.9,0.339
                                c0.349,0.084,0.703,0.099,1.057,0.077c0.459-0.029,0.896-0.155,1.306-0.367c0.222-0.115,0.428-0.254,0.619-0.417
                                c0.285-0.243,0.518-0.528,0.717-0.843c0.162-0.258,0.27-0.535,0.315-0.839c0.031-0.208,0.062-0.415,0.09-0.624
                                c0.017-0.126,0.028-0.253,0.028-0.38c0-0.217,0.017-0.433,0.017-0.649c-0.002-5.186-0.001-10.372-0.001-15.558
                                c0-0.021,0.001-0.041,0-0.062c-0.003-0.035,0.01-0.051,0.047-0.048c0.016,0.002,0.032,0,0.048,0h3.86
                                c0.102,0,0.105,0.002,0.104,0.103c-0.003,0.28,0.032,0.556,0.08,0.831C256.292,151.717,256.289,151.745,256.29,151.772z"/>
                                <path style={{ fill: "#455A64" }} d="M250.941,161.494v3.49c0,0.089,0.001,0.085-0.087,0.069c-0.09-0.016-0.182-0.025-0.274-0.033
                                c-0.133-0.011-0.268-0.011-0.401-0.016c-0.354-0.012-0.702,0.022-1.049,0.095c-0.329,0.07-0.641,0.181-0.935,0.342
                                c-0.2,0.11-0.385,0.242-0.559,0.391c-0.284,0.246-0.507,0.538-0.684,0.867c-0.157,0.291-0.264,0.601-0.33,0.924
                                c-0.027,0.132-0.042,0.265-0.057,0.398c-0.019,0.173-0.013,0.346-0.01,0.518c0.004,0.218,0.037,0.433,0.086,0.645
                                c0.011,0.053,0.024,0.104,0.038,0.154c0.106,0.385,0.272,0.693,0.268,0.706c-0.004-0.002-0.008-0.004-0.012-0.006
                                c-0.096-0.05-0.193-0.113-0.275-0.178c-0.097-0.077-0.192-0.157-0.278-0.248c-0.254-0.268-0.444-0.574-0.57-0.922
                                c-0.072-0.199-0.132-0.401-0.172-0.608c-0.032-0.162-0.054-0.327-0.063-0.494c-0.005-0.1-0.014-0.199-0.011-0.299
                                c0.01-0.355,0.059-0.704,0.163-1.045c0.108-0.353,0.27-0.678,0.486-0.974c0.239-0.327,0.536-0.592,0.884-0.802
                                c0.319-0.192,0.662-0.323,1.023-0.406c0.157-0.036,0.316-0.057,0.476-0.076c0.249-0.03,0.499-0.032,0.748-0.023
                                c0.174,0.006,0.351,0.014,0.523,0.051c0.071,0.015,0.085,0.004,0.085-0.073c0-1.127,0-2.253,0-3.38
                                c0.004-0.001,0.009-0.003,0.013-0.004c0.031,0.015,0.065,0.015,0.099,0.017c0.234,0.006,0.467,0.029,0.698,0.065
                                c0.042,0.007,0.084,0.02,0.127,0.015c0.047-0.005,0.051,0.017,0.051,0.055C250.941,160.973,250.941,161.234,250.941,161.494z"
                                />
                                <path style={{ fill: "#263238" }} d="M258.397,155.562C258.397,155.562,258.397,155.562,258.397,155.562
                                c-0.049-0.012-0.117-0.073-0.15-0.101c-0.231-0.197-0.443-0.411-0.638-0.645c-0.298-0.36-0.544-0.751-0.745-1.171
                                c-0.201-0.42-0.356-0.856-0.464-1.309c-0.039-0.164-0.074-0.33-0.109-0.495c0-0.001-0.001-0.002-0.001-0.004
                                c-0.001-0.003-0.001-0.007-0.001-0.011c0-0.001,0-0.002,0-0.003c-0.001-0.022,0.001-0.05,0.001-0.05h0.001
                                c0.276,0,0.552,0,0.828-0.002c0.04,0,0.049,0.013,0.049,0.051c-0.002,0.242,0.023,0.482,0.056,0.721
                                c0.055,0.39,0.136,0.775,0.252,1.153c0.102,0.332,0.224,0.655,0.375,0.968c0.05,0.108,0.103,0.209,0.154,0.3
                                c0,0.001,0.001,0.002,0.001,0.002c0.179,0.32,0.346,0.532,0.383,0.584c0,0,0,0,0,0c0.003,0.003,0.005,0.006,0.006,0.008
                                c0,0,0,0,0,0c0,0.001,0.001,0.001,0.001,0.002C258.397,155.561,258.397,155.562,258.397,155.562z"/>
                                <path className="primary" d="M261.857,156.799c-0.001,0.285-0.001,0.571-0.002,0.857c-0.004,0-0.008,0-0.012,0.001
                                c-0.212-0.035-0.421-0.085-0.627-0.145c-0.356-0.103-0.701-0.235-1.031-0.406c-0.325-0.168-0.63-0.367-0.913-0.601
                                c-0.149-0.124-0.296-0.249-0.428-0.39c-0.02-0.022-0.04-0.043-0.061-0.065c-0.139-0.152-0.273-0.312-0.386-0.487
                                c0-0.001-0.001-0.001-0.001-0.002c0,0,0-0.001-0.001-0.001c0-0.001-0.001-0.001-0.001-0.002c0,0,0.001,0.001,0.001,0.001
                                c0.071,0.05,0.142,0.104,0.213,0.154c0.011,0.008,0.022,0.015,0.033,0.023c0.254,0.177,0.523,0.327,0.805,0.455
                                c0.184,0.084,0.371,0.16,0.562,0.223c0.349,0.114,0.704,0.201,1.067,0.255c0.244,0.036,0.488,0.068,0.735,0.08
                                C261.849,156.751,261.857,156.765,261.857,156.799z"/>
                            </g>
                        </g>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.9258 -0.378 0.378 0.9258 -52.5263 145.6422)" style={{ fill: "#263238" }} cx="344.757" cy="206.631" rx="20.324" ry="20.324" />
                        <g>
                            <path style={{ fill: "#FFFFFF" }} d="M332.331,218.965c0.532-1.916,1.004-3.696,1.53-5.461c0.171-0.573,0.125-1.03-0.147-1.578
                            c-3.709-7.477,1.212-16.514,9.524-17.53c7.642-0.934,14.107,4.829,13.943,12.43c-0.134,6.228-5.42,11.598-11.672,11.879
                            c-2.134,0.095-4.163-0.278-6.114-1.156c-0.34-0.153-0.806-0.176-1.172-0.089C336.308,217.916,334.405,218.429,332.331,218.965z
                             M335.32,216.065c1.219-0.315,2.247-0.615,3.294-0.831c0.342-0.071,0.779-0.02,1.086,0.14c1.563,0.818,3.204,1.263,4.971,1.294
                            c6.348,0.112,11.461-5.785,10.252-11.968c-0.836-4.276-3.465-7.081-7.709-8.052c-4.355-0.997-8.059,0.406-10.693,4.003
                            c-2.575,3.517-2.643,7.307-0.54,11.142c0.184,0.335,0.233,0.826,0.155,1.206C335.932,213.987,335.623,214.954,335.32,216.065z"
                            />
                            <path style={{ fill: "#FFFFFF" }} d="M339.398,201.571c0.838-1.129,1.962-0.906,2.549,0.34c0.229,0.488,0.284,0.651,0.509,1.208
                            c0.338,0.838,0.263,1.402-0.342,1.944c-0.44,0.394-0.399,0.741-0.094,1.195c0.951,1.41,2.18,2.468,3.739,3.158
                            c0.474,0.21,0.786,0.163,1.133-0.251c1.082-1.294,1.098-1.28,2.627-0.561c0.176,0.083,0.351,0.168,0.527,0.251
                            c1.174,0.563,1.352,1.619,0.396,2.549c-0.975,0.95-2.157,1.166-3.449,0.839c-3.298-0.837-5.66-2.912-7.484-5.688
                            c-0.556-0.845-0.972-1.743-0.938-2.789C338.677,202.797,338.942,202.186,339.398,201.571z"/>
                        </g>
                    </g>
                    <g id="xsrjP0_2_">
                        <g>
                            <path style={{ fill: "#455A64" }} d="M315.246,247.432c-0.741-0.462-1.387-1.135-2.379-1.037
                            c-0.38,0.039-0.297-0.276-0.3-0.483c-0.018-3.728-0.029-7.452-0.045-11.177c-0.001-0.271,0.015-0.494,0.31-0.644
                            c2.841-1.444,4.066-4.272,5.801-6.691c1.856-2.581,2.486-5.374,2.036-8.461c-0.122-0.838,0.268-1.215,1.023-1.389
                            c0.909-0.211,1.815,0.038,2.129,0.662c0.771,1.547,1.436,3.156,1.642,4.878c0.169,1.413-0.333,2.79-0.505,4.189
                            c-0.126,1.024-0.361,2.035-0.552,3.072c0.25,0.181,0.54,0.085,0.808,0.088c3.147-0.006,6.295-0.018,9.443-0.028
                            c1.326-0.004,1.977,0.374,2.164,1.254c0.23,1.096-0.346,2.213-1.362,2.734c-0.235,0.12-0.66,0.014-0.688,0.382
                            c-0.027,0.376,0.411,0.336,0.629,0.486c1.142,0.778,0.936,2.391-0.38,3.327c-0.316,0.223-0.932,0.123-0.945,0.588
                            c-0.006,0.371,0.57,0.365,0.831,0.625c0.923,0.906,0.471,2.604-0.901,3.294c-0.176,0.088-0.395,0.105-0.552,0.215
                            c-0.156,0.106-0.309,0.224-0.005,0.438c1.366,0.969,1.342,2.325-0.027,3.318c-0.134,0.097-0.272,0.197-0.409,0.296
                            c-0.498,0.367-1.087,0.364-1.656,0.368c-4.936,0.027-9.872,0.04-14.808,0.053C316.085,247.79,315.617,247.778,315.246,247.432z"
                            />
                            <path style={{ fill: "#455A64" }} d="M301.189,240.907c-0.008-2.366-0.005-4.736-0.032-7.102
                            c-0.005-0.482,0.044-0.718,0.634-0.714c2.889,0.025,5.773,0.015,8.661-0.026c0.567-0.008,0.655,0.187,0.653,0.69
                            c0,4.764,0.012,9.528,0.051,14.292c0.006,0.606-0.159,0.778-0.767,0.77c-2.801-0.022-5.602-0.02-8.401,0.034
                            c-0.697,0.013-0.794-0.231-0.789-0.841C301.227,245.643,301.197,243.276,301.189,240.907z"/>
                        </g>
                    </g>
                    <g>
                        <path style={{ fill: "#263238" }} d="M300.885,273.062h-1.854c-1.174,0-2.101-0.927-2.101-2.101c0-1.134,0.927-2.101,2.101-2.101h2.584
                        l1.213-7.022h-2.347c-1.124,0-2.091-0.967-2.091-2.101c0-1.134,0.966-2.101,2.091-2.101h3.077l0.799-4.803
                        c0.168-1.055,1.055-1.736,2.101-1.736c1.332,0,2.268,1.213,2.101,2.466l-0.72,4.073h6.332l0.809-4.803
                        c0.168-1.055,1.055-1.736,2.14-1.736c1.292,0,2.259,1.213,2.061,2.466l-0.69,4.073h1.864c1.125,0,2.091,0.927,2.091,2.101
                        c0,1.134-0.966,2.101-2.091,2.101h-2.584l-1.213,7.022h2.298c1.174,0,2.101,0.927,2.101,2.101c0,1.134-0.927,2.101-2.101,2.101
                        h-3.028l-0.848,4.961c-0.158,1.016-1.046,1.736-2.101,1.736c-1.332,0-2.298-1.203-2.101-2.456l0.769-4.241h-6.342l-0.848,4.961
                        c-0.158,0.898-0.927,1.736-2.13,1.736c-1.302,0-2.268-1.085-2.061-2.456L300.885,273.062z M312.237,268.86l1.213-7.022h-6.302
                        l-1.213,7.022H312.237z"/>
                    </g>
                </g>
            </g>
            <g id="Stars">
                <g>
                    <polygon className="primary" points="343.929,269.773 337.952,270.878 336.616,277.174 335.28,270.878 329.303,269.77 
                    335.255,268.317 336.616,262.369 337.978,268.317 		"/>
                    <polygon className="primary" points="200.73,201.156 194.752,202.26 193.416,208.557 192.081,202.26 186.103,201.153 
                    192.055,199.699 193.416,193.752 194.778,199.699 		"/>
                    <polygon className="primary" points="322.377,73.157 316.4,74.261 315.064,80.558 313.728,74.261 307.751,73.154 
                    313.703,71.701 315.064,65.753 316.426,71.701 		"/>
                    <polygon className="primary" points="200.326,152.937 196.985,153.555 196.238,157.074 195.492,153.555 192.151,152.935 
                    195.477,152.123 196.238,148.799 196.999,152.123 		"/>
                    <polygon className="primary" points="220.76,261.963 217.419,262.58 216.672,266.099 215.925,262.58 212.585,261.961 
                    215.911,261.149 216.672,257.824 217.433,261.149 		"/>
                    <polygon className="primary" points="389.431,197.479 386.091,198.096 385.344,201.615 384.597,198.096 381.256,197.477 
                    384.583,196.665 385.344,193.34 386.105,196.665 		"/>
                    <polygon className="primary" points="352.105,258.233 348.764,258.85 348.017,262.369 347.27,258.85 343.929,258.231 
                    347.256,257.419 348.017,254.095 348.778,257.419 		"/>
                    <polygon className="primary" points="210.16,215.592 206.819,216.21 206.073,219.729 205.326,216.21 201.985,215.591 
                    205.312,214.778 206.073,211.454 206.834,214.778 		"/>
                    <polygon className="primary" points="304.041,61.978 300.7,62.595 299.954,66.114 299.207,62.595 295.866,61.976 
                    299.193,61.164 299.954,57.84 300.714,61.164 		"/>
                    <polygon className="primary" points="220.634,99.806 217.293,100.423 216.546,103.942 215.8,100.423 212.459,99.804 
                    215.785,98.992 216.546,95.667 217.307,98.992 		"/>
                    <path className="primary" d="M356.444,271.473c-1.246,0-1.248,1.935,0,1.935C357.69,273.409,357.692,271.473,356.444,271.473z"
                    />
                    <path className="primary" d="M225.147,87.018c-1.246,0-1.248,1.935,0,1.935C226.393,88.954,226.395,87.018,225.147,87.018z" />
                    <path className="primary" d="M363.766,114.951c-1.246,0-1.248,1.935,0,1.935C365.013,116.886,365.015,114.951,363.766,114.951z"
                    />
                    <path className="primary" d="M404.932,190.779c-1.246,0-1.248,1.935,0,1.935C406.178,192.714,406.18,190.779,404.932,190.779z"
                    />
                    <path className="primary" d="M231.565,263.719c-1.246,0-1.248,1.935,0,1.935C232.811,265.655,232.813,263.719,231.565,263.719z"
                    />
                    <path className="primary" d="M391.909,185.147c-0.882,0-0.883,1.369,0,1.369C392.791,186.516,392.792,185.147,391.909,185.147z"
                    />
                    <path className="primary" d="M227.335,276.918c-0.882,0-0.883,1.369,0,1.369C228.217,278.288,228.218,276.918,227.335,276.918z"
                    />
                    <path className="primary" d="M185.551,140.656c-0.882,0-0.883,1.369,0,1.369C186.432,142.025,186.434,140.656,185.551,140.656z"
                    />
                    <path className="primary" d="M363.766,104.548c-2.39,0-2.394,3.711,0,3.711C366.156,108.259,366.16,104.548,363.766,104.548z" />
                    <path className="primary" d="M178.246,155.233c-2.39,0-2.394,3.711,0,3.711C180.636,158.944,180.639,155.233,178.246,155.233z"
                    />
                </g>
            </g>
            <g id="Character">
                <g>
                    <g>
                        <g>
                            <path style={{ fill: "#EB9481" }} d="M141.418,304.821c7.527,33.897,23.326,74.577,30.144,81.211
                            c8.623,8.389,65.985,24.073,68.849,24.404c7.094,0.768,21.682-50.227,14.131-51.373c-11.495-1.745-53.316-4.323-55.805-6.915
                            c-2.582-2.711-20.078-25.105-38.056-50.396C148.276,284.274,136.747,283.789,141.418,304.821z"/>
                            <g>
                                <path style={{ fill: "#EB9481" }} d="M234.733,407.639c0.842,0.435,12.807,6.561,20.217,8.766c5.528,1.646,21.568,5.956,23.984,0.05
                                c1.653-4.026-7.481-7.671-13.882-10.393c-3.372-1.434-7.515-5.004-6.502-7.172c1.77-3.789,10.451,0.092,16.153,3.461
                                c11.027,6.514,25.694,18.201,32.499,12.633c2.569-2.106-0.371-5.994-4.687-9.817c7.016,4.47,14.507,8.149,17.454,5.923
                                c1.692-1.267,1.783-3.023,0.86-5.036c2.171,0.723,4.095,0.598,5.446-0.809c2.309-2.381,1.218-5.928-1.263-9.574
                                c0.804-0.078,1.53-0.482,2.091-1.352c4.905-7.361-19.223-28.773-27.606-30.821c-24.664-6.051-50.705-5.146-50.705-5.146
                                C239.029,366.038,234.713,407.622,234.733,407.639z"/>
                                <g>
                                    <path style={{ fill: "#263238" }} d="M271.226,385.158c5.812,0.953,11.523,3.17,16.433,6.462
                                    c6.233,4.179,10.353,9.047,15.573,13.958c0.137,0.129-0.059,0.346-0.197,0.217c-2.488-2.334-12.8-11.117-15.467-13.115
                                    c-4.887-3.662-10.356-6.248-16.365-7.422C271.138,385.245,271.161,385.147,271.226,385.158z"/>
                                    <path style={{ fill: "#263238" }} d="M275.905,374.708c6.48,1.822,13.121,3.665,19.125,6.78c6.571,3.409,11.693,8.925,16.73,14.235
                                    c3.025,3.189,5.983,6.44,8.859,9.764c0.12,0.139-0.067,0.357-0.197,0.217c-5.11-5.485-10.383-10.822-15.819-15.984
                                    c-5.114-4.856-10.829-8.586-17.406-11.146c-3.706-1.443-7.514-2.599-11.317-3.751
                                    C275.808,374.801,275.832,374.687,275.905,374.708z"/>
                                    <path style={{ fill: "#263238" }} d="M282.908,365.59c6.299,1.341,11.795,2.489,17.357,5.91
                                    c7.363,4.529,14.448,12.121,16.934,14.813c2.746,2.973,5.389,6.034,7.987,9.138c0.106,0.126-0.111,0.314-0.221,0.188
                                    c-5.938-6.786-12.342-13.237-19.109-19.198c-3.233-2.849-6.752-5.336-10.71-7.075c-3.914-1.719-8.113-2.707-12.248-3.732
                                    C282.871,365.626,282.88,365.584,282.908,365.59z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path className="primary" d="M244.827,356.234l-18.079,55.862c0,0-47.753-14.004-56.429-23.499
                            c-8.676-9.495-24.464-58.306-29.042-81.941c-4.578-23.636,7.353-22.966,18.214-8.006c10.861,14.96,37.908,51.223,39.429,52.017
                            C200.441,351.461,244.827,356.234,244.827,356.234z"/>
                            <path style={{ fill: "#FFFFFF" }} d="M145.89,315.44c-0.4-0.05-0.7-0.15-0.98-0.33c-0.54-0.37-0.86-0.95-0.85-1.59
                            c0-0.98-0.66-1.9-1.55-2.21c-0.09-0.03-0.18-0.06-0.27-0.08c0.06,0.32,0.14,0.64,0.21,0.97l0.01,0.01
                            c0.39,0.33,0.63,0.82,0.63,1.33c-0.02,0.94,0.6,1.92,1.48,2.25c0.42,0.19,0.93,0.2,1.32,0.13c0.39-0.08,0.78-0.13,1.18-0.24
                            C146.67,315.57,146.28,315.52,145.89,315.44z M231.91,365.07c-0.4-0.05-0.69-0.15-0.97-0.33c-0.55-0.37-0.86-0.94-0.85-1.59
                            c-0.01-0.98-0.67-1.9-1.56-2.21c-0.42-0.18-0.94-0.16-1.31-0.09c-0.39,0.08-0.79,0.13-1.18,0.24c0.39,0.11,0.79,0.16,1.18,0.24
                            c0.42,0.04,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.9,1.54c-0.02,0.94,0.59,1.92,1.47,2.25c0.43,0.19,0.94,0.2,1.32,0.13
                            c0.4-0.08,0.79-0.13,1.19-0.24C232.7,365.2,232.31,365.15,231.91,365.07z M154.81,349.04c-0.4-0.04-0.69-0.14-0.97-0.33
                            c-0.55-0.36-0.86-0.94-0.85-1.58c-0.01-0.91-0.57-1.78-1.37-2.14c0.44,1.37,0.89,2.74,1.35,4.1c0.16,0.13,0.33,0.23,0.52,0.3
                            c0.43,0.19,0.94,0.2,1.32,0.13c0.4-0.08,0.79-0.13,1.19-0.24C155.6,349.17,155.21,349.12,154.81,349.04z M209.11,401.67
                            c-0.39-0.05-0.69-0.15-0.97-0.33c-0.54-0.37-0.86-0.95-0.85-1.59c0-0.98-0.66-1.9-1.55-2.21c-0.43-0.18-0.95-0.16-1.32-0.09
                            c-0.39,0.08-0.78,0.13-1.18,0.24c0.4,0.11,0.79,0.16,1.18,0.24c0.42,0.04,0.71,0.12,1,0.3c0.55,0.33,0.9,0.93,0.9,1.54
                            c-0.02,0.94,0.59,1.91,1.48,2.25c0.42,0.19,0.93,0.2,1.31,0.13c0.4-0.09,0.79-0.13,1.19-0.24
                            C209.9,401.8,209.51,401.75,209.11,401.67z M187.56,369.54c-0.4-0.04-0.69-0.14-0.98-0.33c-0.54-0.36-0.86-0.94-0.84-1.58
                            c-0.01-0.98-0.67-1.91-1.56-2.22c-0.42-0.17-0.95-0.16-1.31-0.08c-0.4,0.08-0.79,0.12-1.19,0.23c0.4,0.11,0.79,0.16,1.19,0.24
                            c0.42,0.05,0.7,0.13,0.99,0.3c0.56,0.34,0.91,0.93,0.9,1.54c-0.01,0.94,0.6,1.92,1.48,2.25c0.42,0.19,0.93,0.2,1.32,0.13
                            c0.39-0.08,0.79-0.13,1.18-0.24C188.35,369.67,187.95,369.62,187.56,369.54z M182.68,344.73c-0.32,0.26-0.59,0.41-0.91,0.49
                            c-0.63,0.15-1.3-0.02-1.73-0.46c-0.65-0.67-1.78-0.93-2.64-0.54c-0.43,0.16-0.8,0.52-1.02,0.84c-0.22,0.34-0.46,0.65-0.67,1.01
                            c0.36-0.2,0.67-0.45,1.01-0.67c0.32-0.25,0.6-0.39,0.92-0.46c0.65-0.12,1.28,0.06,1.72,0.53c0.7,0.68,1.82,0.87,2.67,0.46
                            c0.42-0.17,0.78-0.56,0.99-0.87c0.22-0.33,0.47-0.64,0.67-1C183.33,344.26,183.02,344.51,182.68,344.73z M230.34,387.88
                            c-0.08-0.39-0.13-0.79-0.24-1.18c-0.11,0.39-0.15,0.79-0.24,1.18c-0.04,0.42-0.12,0.71-0.3,1c-0.33,0.55-0.93,0.9-1.54,0.9
                            c-0.94-0.02-1.91,0.59-2.25,1.47c-0.19,0.43-0.2,0.94-0.13,1.32c0.09,0.4,0.13,0.79,0.24,1.19c0.11-0.4,0.16-0.79,0.24-1.19
                            c0.05-0.4,0.15-0.69,0.33-0.97c0.37-0.55,0.95-0.86,1.59-0.85c0.98-0.01,1.9-0.67,2.21-1.56
                            C230.43,388.77,230.41,388.25,230.34,387.88z M202.64,351.33c-0.22,0.21-0.39,0.46-0.49,0.73c-0.19,0.42-0.2,0.93-0.13,1.32
                            c0.08,0.4,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.78,0.24-1.18c0.05-0.4,0.15-0.69,0.33-0.97c0.34-0.53,0.9-0.84,1.52-0.85
                            C203.74,351.48,203.16,351.4,202.64,351.33z M168.37,324.68c-0.08-0.4-0.12-0.79-0.23-1.19c-0.11,0.4-0.16,0.79-0.24,1.19
                            c-0.05,0.42-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9c-0.95-0.01-1.92,0.6-2.26,1.48c-0.19,0.42-0.2,0.93-0.12,1.32
                            c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18c0.04-0.4,0.14-0.7,0.32-0.98c0.37-0.54,0.95-0.86,1.59-0.84
                            c0.98-0.01,1.91-0.67,2.22-1.56C168.46,325.57,168.45,325.04,168.37,324.68z M161.62,301.56h-0.01
                            c-0.41-0.04-0.83,0.03-1.2,0.21c-0.42,0.17-0.78,0.55-0.99,0.86c-0.22,0.34-0.46,0.65-0.66,1.01c0.35-0.2,0.66-0.45,1-0.67
                            c0.33-0.26,0.59-0.41,0.92-0.49c0.63-0.16,1.3,0.02,1.72,0.45c0.15,0.15,0.31,0.27,0.49,0.37
                            C162.45,302.7,162.02,302.12,161.62,301.56z M163.37,369.45c-0.32,0.25-0.6,0.38-0.92,0.45c-0.65,0.13-1.28-0.06-1.72-0.52
                            c-0.16-0.16-0.33-0.29-0.53-0.39c0.21,0.55,0.43,1.09,0.66,1.63c0.59,0.24,1.27,0.26,1.83,0.01c0.43-0.17,0.8-0.52,1.02-0.85
                            c0.22-0.33,0.46-0.64,0.67-1C164.02,368.98,163.71,369.22,163.37,369.45z M213.86,375.36c-0.32,0.25-0.6,0.38-0.93,0.45
                            c-0.64,0.13-1.27-0.06-1.72-0.52c-0.7-0.69-1.81-0.88-2.66-0.47c-0.43,0.18-0.78,0.56-0.99,0.87c-0.23,0.34-0.47,0.65-0.67,1.01
                            c0.36-0.2,0.67-0.45,1-0.67c0.33-0.26,0.59-0.41,0.92-0.49c0.63-0.16,1.3,0.02,1.72,0.45c0.66,0.68,1.78,0.94,2.64,0.55
                            c0.43-0.17,0.8-0.52,1.02-0.84c0.23-0.34,0.47-0.65,0.67-1.01C214.5,374.89,214.19,375.14,213.86,375.36z M192.18,387.08
                            c-0.31,0.25-0.59,0.39-0.92,0.46c-0.64,0.12-1.28-0.06-1.72-0.53c-0.7-0.68-1.82-0.87-2.67-0.46c-0.42,0.17-0.78,0.55-0.99,0.86
                            c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.2,0.67-0.45,1.01-0.67c0.33-0.26,0.59-0.41,0.91-0.49c0.64-0.15,1.3,0.02,1.73,0.45
                            c0.65,0.68,1.78,0.94,2.64,0.55c0.43-0.16,0.8-0.52,1.02-0.84c0.22-0.34,0.47-0.65,0.67-1.01
                            C192.83,386.61,192.52,386.86,192.18,387.08z"/>
                            <path style={{ fill: "#263238" }} d="M221.496,386.518c2.047-6.236,4.144-12.459,5.96-18.775c1.031-3.588,2.082-7.172,3.079-10.771
                            c0.038-0.136-0.19-0.221-0.24-0.087c-2.303,6.176-4.599,12.376-6.618,18.66c-2.009,6.251-4.071,12.486-5.999,18.766
                            c-1.096,3.57-2.081,7.161-2.958,10.798c-0.017,0.072,0.098,0.116,0.127,0.046C217.359,399.052,219.435,392.798,221.496,386.518z
                            "/>
                            <path style={{ fill: "#263238" }} d="M202.67,404.227c-0.705-0.253-1.411-0.505-2.116-0.758c-3.976-1.451-8.03-3.01-11.845-4.602
                            c-0.494-0.198-0.978-0.405-1.454-0.611c-0.767-0.324-1.533-0.656-2.273-0.989c-0.379-0.162-0.74-0.333-1.11-0.494
                            c-0.132-0.063-0.264-0.117-0.396-0.18c-0.387-0.18-0.766-0.359-1.145-0.539c-1.515-0.709-2.951-1.418-4.289-2.134
                            c-0.308-0.161-0.625-0.332-0.924-0.501c-2.439-1.343-4.462-2.666-5.876-3.922c-0.167-0.151-0.334-0.303-0.483-0.454
                            c-0.167-0.151-0.307-0.303-0.439-0.445c-8.681-9.493-24.468-58.304-29.044-81.943c-0.051-0.274-0.102-0.549-0.153-0.814
                            c-0.008-0.062-0.017-0.115-0.025-0.177c-0.305-1.796-0.572-4.017-0.785-6.422l0-0.027c-0.578-11.75,4.994-13.628,11.824-8.417
                            C169.063,327.685,194.846,388.58,202.67,404.227z"/>
                        </g>
                    </g>
                    <g>
                        <path className="primary" d="M181.151,419.239l-117.576-0.193c0.279-2.318,33.427-112.149,55.958-130.878
                        c9.604-7.983,27.259-2.126,29.507-0.291C170.831,305.67,182.793,417.885,181.151,419.239z"/>
                        <path style={{ fill: "#FFFFFF" }} d="M95.55,332.22c-0.32,0.25-0.59,0.39-0.92,0.46c-0.25,0.05-0.5,0.05-0.74,0
                        c-0.12,0.28-0.24,0.56-0.36,0.85c0.45,0.09,0.93,0.06,1.34-0.13c0.43-0.16,0.8-0.51,1.02-0.84c0.22-0.34,0.47-0.65,0.67-1.01
                        C96.2,331.76,95.89,332,95.55,332.22z M99.12,405.53c-0.32,0.25-0.6,0.38-0.92,0.45c-0.65,0.13-1.28-0.06-1.72-0.52
                        c-0.7-0.69-1.82-0.88-2.67-0.47c-0.42,0.18-0.78,0.56-0.99,0.87c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.2,0.67-0.45,1.01-0.67
                        c0.32-0.26,0.59-0.41,0.91-0.49c0.63-0.16,1.3,0.02,1.73,0.45c0.65,0.68,1.78,0.94,2.64,0.55c0.43-0.17,0.8-0.52,1.02-0.84
                        c0.22-0.34,0.46-0.65,0.67-1.01C99.77,405.06,99.46,405.31,99.12,405.53z M143.72,400.46c-0.32,0.25-0.6,0.39-0.92,0.46
                        c-0.65,0.13-1.28-0.06-1.73-0.52c-0.69-0.69-1.81-0.88-2.66-0.47c-0.43,0.18-0.78,0.56-0.99,0.87c-0.22,0.34-0.47,0.65-0.67,1
                        c0.36-0.2,0.67-0.44,1-0.66c0.33-0.27,0.59-0.41,0.92-0.5c0.63-0.15,1.3,0.02,1.73,0.46c0.65,0.68,1.77,0.93,2.63,0.55
                        c0.44-0.17,0.8-0.52,1.03-0.85c0.22-0.33,0.46-0.65,0.66-1C144.37,400,144.05,400.24,143.72,400.46z M163.37,369.45
                        c-0.32,0.25-0.6,0.38-0.92,0.45c-0.65,0.13-1.28-0.06-1.72-0.52c-0.7-0.69-1.82-0.88-2.67-0.47c-0.42,0.18-0.78,0.56-0.99,0.87
                        c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.21,0.67-0.45,1.01-0.67c0.32-0.27,0.59-0.41,0.91-0.49c0.63-0.16,1.3,0.01,1.73,0.45
                        c0.65,0.68,1.78,0.93,2.64,0.55c0.43-0.17,0.8-0.52,1.02-0.85c0.22-0.33,0.46-0.64,0.67-1
                        C164.02,368.98,163.71,369.22,163.37,369.45z M121.51,418.1c-0.31,0.25-0.59,0.39-0.92,0.46c-0.64,0.12-1.28-0.06-1.72-0.53
                        c-0.7-0.68-1.82-0.87-2.67-0.46c-0.42,0.17-0.78,0.55-0.99,0.86c-0.15,0.24-0.32,0.46-0.47,0.7h0.27
                        c0.18-0.11,0.36-0.24,0.54-0.36c0.33-0.26,0.59-0.41,0.92-0.49c0.63-0.15,1.29,0.02,1.72,0.45c0.16,0.16,0.34,0.3,0.54,0.41h2.38
                        c0.31-0.18,0.57-0.45,0.74-0.7c0.22-0.34,0.47-0.65,0.67-1.01C122.16,417.63,121.85,417.88,121.51,418.1z M112.16,373.83
                        c-0.31,0.25-0.59,0.38-0.92,0.45c-0.64,0.13-1.28-0.06-1.72-0.52c-0.7-0.69-1.82-0.88-2.67-0.47c-0.42,0.18-0.78,0.56-0.99,0.87
                        c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.21,0.67-0.45,1.01-0.67c0.33-0.27,0.59-0.41,0.92-0.49c0.63-0.16,1.29,0.02,1.72,0.45
                        c0.65,0.68,1.78,0.93,2.64,0.55c0.43-0.17,0.8-0.52,1.02-0.85c0.22-0.33,0.47-0.64,0.67-1
                        C112.81,373.36,112.5,373.6,112.16,373.83z M137.78,332.22c-0.32,0.25-0.6,0.39-0.92,0.46c-0.65,0.13-1.28-0.06-1.72-0.53
                        c-0.7-0.68-1.82-0.87-2.67-0.46c-0.42,0.17-0.78,0.56-0.99,0.87c-0.22,0.33-0.47,0.65-0.67,1c0.36-0.2,0.67-0.44,1.01-0.67
                        c0.32-0.26,0.58-0.4,0.91-0.49c0.63-0.15,1.3,0.02,1.73,0.46c0.65,0.67,1.78,0.93,2.64,0.54c0.43-0.16,0.8-0.51,1.02-0.84
                        c0.22-0.34,0.46-0.65,0.66-1.01C138.43,331.76,138.11,332,137.78,332.22z M158.95,303.34c-0.07,0.1-0.13,0.2-0.19,0.3
                        c0.09-0.05,0.17-0.1,0.25-0.16C158.99,303.43,158.97,303.39,158.95,303.34z M72.71,391.68c-0.08-0.39-0.13-0.79-0.24-1.18
                        c-0.11,0.39-0.16,0.79-0.24,1.18c-0.04,0.42-0.12,0.7-0.3,1c-0.09,0.15-0.21,0.29-0.34,0.42l-0.01,0.01
                        c-0.14,0.44-0.28,0.88-0.43,1.31c0.67-0.24,1.24-0.77,1.47-1.43C72.8,392.57,72.78,392.05,72.71,391.68z M117.78,394.93
                        c-0.08-0.4-0.12-0.79-0.23-1.19c-0.11,0.4-0.16,0.79-0.24,1.19c-0.05,0.41-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9
                        c-0.95-0.01-1.92,0.6-2.26,1.48c-0.19,0.42-0.2,0.93-0.12,1.32c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18
                        c0.04-0.4,0.14-0.7,0.32-0.98c0.37-0.54,0.95-0.86,1.59-0.84c0.98-0.01,1.91-0.67,2.22-1.56
                        C117.87,395.82,117.86,395.29,117.78,394.93z M166.62,326.48c-0.18,0.06-0.37,0.09-0.56,0.09c-0.95-0.01-1.92,0.6-2.26,1.48
                        c-0.19,0.42-0.2,0.93-0.12,1.32c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18c0.04-0.4,0.14-0.7,0.32-0.98
                        c0.37-0.54,0.95-0.86,1.59-0.84c0.27,0,0.54-0.06,0.79-0.15C166.78,327.09,166.7,326.79,166.62,326.48z M113.64,316.27
                        c-0.08-0.4-0.13-0.79-0.24-1.18c-0.11,0.39-0.16,0.78-0.24,1.18c-0.05,0.42-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9
                        c-0.94-0.01-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18
                        c0.05-0.4,0.15-0.69,0.33-0.98c0.36-0.54,0.94-0.86,1.59-0.84c0.98-0.01,1.9-0.67,2.21-1.56
                        C113.73,317.16,113.71,316.63,113.64,316.27z M94.92,356.42c-0.08-0.39-0.13-0.79-0.24-1.18c-0.11,0.39-0.16,0.79-0.24,1.18
                        c-0.05,0.42-0.13,0.71-0.3,1c-0.34,0.55-0.93,0.9-1.54,0.89c-0.94-0.01-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32
                        c0.08,0.4,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.78,0.24-1.18c0.05-0.4,0.15-0.69,0.33-0.97c0.36-0.55,0.94-0.86,1.59-0.85
                        c0.97-0.01,1.9-0.67,2.21-1.56C95.01,357.31,94.99,356.79,94.92,356.42z M180.58,407.23c-0.26,0.14-0.55,0.22-0.85,0.22
                        c-0.94-0.01-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18
                        c0.05-0.4,0.15-0.7,0.33-0.98c0.37-0.54,0.95-0.86,1.59-0.84c0.32,0,0.63-0.08,0.92-0.2
                        C180.64,407.9,180.61,407.57,180.58,407.23z M133.16,353.94c-0.08-0.4-0.13-0.79-0.24-1.18c-0.11,0.39-0.16,0.78-0.24,1.18
                        c-0.04,0.42-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9c-0.94-0.01-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32
                        c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18c0.05-0.4,0.15-0.69,0.33-0.98c0.37-0.54,0.95-0.86,1.59-0.84
                        c0.98-0.01,1.9-0.67,2.21-1.56C133.25,354.83,133.23,354.3,133.16,353.94z M141.94,285.22c-0.04,0.12-0.1,0.23-0.16,0.34
                        c-0.34,0.55-0.93,0.91-1.54,0.9c-0.94-0.02-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32c0.08,0.39,0.13,0.79,0.24,1.18
                        c0.11-0.39,0.16-0.79,0.24-1.18c0.05-0.4,0.15-0.7,0.33-0.98c0.36-0.54,0.94-0.86,1.59-0.84c0.98-0.01,1.9-0.67,2.21-1.56
                        c0.07-0.16,0.1-0.33,0.12-0.5C142.38,285.33,142.16,285.27,141.94,285.22z M124.52,299.37c-0.33,0.27-0.59,0.41-0.92,0.5
                        c-0.63,0.15-1.3-0.02-1.72-0.46c-0.66-0.68-1.78-0.93-2.64-0.55c-0.43,0.17-0.8,0.52-1.02,0.85c-0.22,0.33-0.47,0.65-0.67,1
                        c0.36-0.2,0.67-0.44,1.01-0.66c0.31-0.25,0.59-0.39,0.92-0.46c0.64-0.13,1.27,0.06,1.72,0.52c0.7,0.69,1.82,0.88,2.67,0.47
                        c0.42-0.18,0.78-0.56,0.99-0.87c0.22-0.34,0.46-0.65,0.66-1C125.17,298.91,124.86,299.15,124.52,299.37z M154.81,349.04
                        c-0.4-0.04-0.69-0.14-0.97-0.33c-0.55-0.36-0.86-0.94-0.85-1.58c-0.01-0.98-0.66-1.91-1.56-2.22c-0.42-0.17-0.94-0.16-1.31-0.08
                        c-0.39,0.08-0.79,0.12-1.18,0.23c0.39,0.11,0.79,0.16,1.18,0.24c0.42,0.05,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.9,1.54
                        c-0.02,0.95,0.59,1.92,1.47,2.25c0.43,0.19,0.94,0.2,1.32,0.13c0.4-0.08,0.79-0.13,1.19-0.24
                        C155.6,349.17,155.21,349.12,154.81,349.04z M92.81,388.18c-0.4-0.05-0.7-0.15-0.98-0.33c-0.54-0.36-0.86-0.94-0.85-1.59
                        c0-0.98-0.66-1.9-1.55-2.21c-0.43-0.18-0.95-0.16-1.32-0.09c-0.39,0.08-0.78,0.13-1.18,0.24c0.4,0.11,0.79,0.16,1.18,0.24
                        c0.42,0.04,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.9,1.54c-0.02,0.94,0.59,1.92,1.48,2.25c0.42,0.19,0.93,0.2,1.32,0.13
                        c0.39-0.08,0.78-0.13,1.18-0.24C93.59,388.31,93.2,388.26,92.81,388.18z M135.27,381.2c-0.4-0.05-0.7-0.15-0.98-0.33
                        c-0.54-0.37-0.86-0.95-0.84-1.59c-0.01-0.98-0.67-1.91-1.56-2.21c-0.43-0.18-0.95-0.16-1.31-0.09c-0.4,0.08-0.79,0.13-1.19,0.24
                        c0.4,0.11,0.79,0.15,1.19,0.23c0.41,0.05,0.7,0.13,0.99,0.31c0.55,0.33,0.91,0.93,0.9,1.54c-0.02,0.94,0.6,1.91,1.48,2.25
                        c0.42,0.19,0.93,0.2,1.32,0.12c0.39-0.08,0.79-0.12,1.18-0.23C136.06,381.32,135.66,381.28,135.27,381.2z M78.64,417.72
                        c-0.4-0.05-0.7-0.15-0.98-0.33c-0.54-0.36-0.86-0.94-0.85-1.59c0-0.98-0.66-1.9-1.55-2.21c-0.43-0.18-0.95-0.16-1.32-0.09
                        c-0.39,0.08-0.78,0.13-1.18,0.24c0.4,0.11,0.79,0.16,1.18,0.24c0.42,0.04,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.9,1.54
                        c-0.02,0.94,0.59,1.92,1.48,2.25c0.42,0.19,0.93,0.2,1.32,0.13c0.39-0.08,0.78-0.13,1.18-0.24
                        C79.42,417.85,79.03,417.8,78.64,417.72z M156.4,417.71c-0.01-0.98-0.67-1.9-1.56-2.21c-0.42-0.18-0.94-0.16-1.31-0.09
                        c-0.4,0.08-0.79,0.13-1.18,0.24c0.39,0.11,0.78,0.16,1.18,0.24c0.42,0.05,0.7,0.13,0.99,0.3c0.56,0.34,0.91,0.93,0.9,1.54
                        c-0.01,0.52,0.18,1.04,0.49,1.47h1.21C156.65,418.84,156.38,418.31,156.4,417.71z M111.6,348.1c-0.4-0.04-0.69-0.15-0.97-0.33
                        c-0.55-0.36-0.86-0.94-0.85-1.59c-0.01-0.97-0.67-1.9-1.56-2.21c-0.42-0.18-0.94-0.16-1.31-0.09c-0.39,0.08-0.79,0.13-1.18,0.24
                        c0.39,0.11,0.79,0.16,1.18,0.24c0.42,0.05,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.89,1.54c-0.01,0.94,0.6,1.92,1.48,2.25
                        c0.42,0.19,0.94,0.2,1.32,0.13c0.4-0.08,0.79-0.13,1.18-0.24C112.39,348.23,112,348.18,111.6,348.1z M164.38,393.5
                        c-0.4-0.04-0.69-0.14-0.98-0.32c-0.54-0.37-0.86-0.95-0.84-1.59c-0.01-0.98-0.67-1.91-1.56-2.22c-0.42-0.17-0.94-0.15-1.31-0.08
                        c-0.4,0.08-0.79,0.12-1.18,0.23c0.39,0.12,0.78,0.16,1.18,0.24c0.42,0.05,0.7,0.13,0.99,0.3c0.56,0.34,0.91,0.94,0.9,1.54
                        c-0.01,0.95,0.6,1.92,1.48,2.26c0.42,0.19,0.93,0.2,1.32,0.12c0.39-0.08,0.79-0.13,1.18-0.24
                        C165.17,393.63,164.77,393.59,164.38,393.5z M145.89,315.44c-0.4-0.05-0.7-0.15-0.98-0.33c-0.54-0.37-0.86-0.95-0.85-1.59
                        c0-0.98-0.66-1.9-1.55-2.21c-0.43-0.18-0.95-0.16-1.32-0.09c-0.39,0.08-0.78,0.13-1.18,0.24c0.4,0.11,0.79,0.16,1.18,0.24
                        c0.42,0.04,0.71,0.12,1,0.3c0.55,0.33,0.9,0.93,0.9,1.54c-0.02,0.94,0.6,1.92,1.48,2.25c0.42,0.19,0.93,0.2,1.32,0.13
                        c0.39-0.08,0.78-0.13,1.18-0.24C146.67,315.57,146.28,315.52,145.89,315.44z"/>
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path style={{ fill: "#DD6A57" }} d="M176.43,263.454c0,0-5.033-9.46-11.205-5.505c-6.172,3.956,0.321,12.48,0.321,12.48
                                    L176.43,263.454z"/>

                                    <rect x="184.625" y="260.63" transform="matrix(-0.8419 0.5396 -0.5396 -0.8419 512.9339 446.3471)" className="primary" width="12.926" height="75.35" />

                                    <rect x="163.802" y="266.008" transform="matrix(-0.8419 0.5396 -0.5396 -0.8419 459.9567 400.2678)" style={{ fill: "#37474F" }} width="15.094" height="2.995" />

                                    <rect x="165.589" y="268.795" transform="matrix(-0.8419 0.5396 -0.5396 -0.8419 464.7517 404.4365)" style={{ fill: "#37474F" }} width="15.094" height="2.995" />
                                    <g>
                                        <path style={{ fill: "#EBEBEB" }} d="M205.969,333.514l9.114,5.206l2.999,1.727l-0.293-3.461l-0.924-10.455l-2.115-0.874
                                        c0,0-0.056,2.232-0.065,2.271l-2.114-0.875c0,0-0.056,2.232-0.065,2.271l-2.09-0.89c0,0-0.08,2.247-0.089,2.286l-2.09-0.89
                                        c0,0-0.08,2.247-0.089,2.286l-2.09-0.89C206.058,331.227,205.978,333.474,205.969,333.514z"/>
                                        <polygon style={{ fill: "#37474F" }} points="215.083,338.72 218.082,340.447 217.789,336.986 							" />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path style={{ fill: "#EB9481" }} d="M105.897,309.526c-11.318,26.427-28.694,81.84-16.388,99.509
                                c11.851,17.017,81.097-20.1,95.141-27.701c5.764-3.12-8.904-52.392-15.429-49.838c-14.33,5.607-43.642,25.173-44.408,24.802
                                c-0.68-0.329,0.549-22.826,0.981-44.975C126.424,279.092,114.033,290.53,105.897,309.526z"/>
                                <g>
                                    <path style={{ fill: "#EB9481" }} d="M210.28,301.079c4.7,4.044,0.45,8.833,0.45,8.833s4.331-0.209,7.452,2.549
                                    c3.575,3.159,0.19,10.15,0.19,10.15s4.428,0.696,7.432,3.941c3.118,3.367,0.708,10.909,0.708,10.909s3.768,2.914,4.352,7.581
                                    c1,7.98-8.473,15.798-14.53,20.725c-8.999,7.319-27.713,13.523-31.685,15.568l-32.364-38.265c0,0,20.869-29.574,28.455-34.908
                                    C188.489,302.713,205.659,297.103,210.28,301.079z"/>
                                    <g>
                                        <path style={{ fill: "#263238" }} d="M209.945,309.521c-8.116,2.662-15.003,7.578-22.156,12.095
                                        c-0.159,0.1,0.001,0.349,0.159,0.246c7.062-4.574,14.94-7.688,22.169-11.973C210.318,309.77,210.164,309.449,209.945,309.521
                                        z"/>
                                        <path style={{ fill: "#263238" }} d="M217.826,322.213c-1.817,0.658-3.413,1.913-4.981,3.016c-1.688,1.188-3.353,2.404-5.002,3.647
                                        c-3.299,2.487-6.541,5.05-9.705,7.706c-0.14,0.118,0.005,0.361,0.159,0.246c3.292-2.473,6.621-4.896,10.014-7.229
                                        c1.698-1.167,3.406-2.32,5.122-3.461c1.596-1.062,3.368-2.049,4.698-3.448C218.327,322.482,218.1,322.114,217.826,322.213z"
                                        />
                                        <path style={{ fill: "#263238" }} d="M226.37,337.56c-1.603,0.508-3.036,1.928-4.339,2.954c-1.479,1.164-2.965,2.321-4.457,3.469
                                        c-2.984,2.296-5.969,4.59-9.031,6.78c-0.152,0.109,0.004,0.356,0.159,0.246c3.122-2.209,6.275-4.376,9.378-6.613
                                        c1.524-1.099,3.041-2.206,4.553-3.321c1.285-0.948,2.999-1.987,3.859-3.369C226.539,337.628,226.45,337.535,226.37,337.56z"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path className="primary" d="M152.027,338.181l25.764,51.059c0,0-59.529,27.488-80.336,24.453
                                c-20.807-3.035-10.667-42.748-9.285-51.708c0.988-6.405,12.379-45.056,21.096-60.725c8.717-15.668,16.256-15.845,17.171,5.641
                                c0.915,21.486-1.146,47.57-0.278,47.191C127.027,353.712,152.027,338.181,152.027,338.181z"/>
                                <path style={{ fill: "#FFFFFF" }} d="M164.38,393.5c-0.4-0.04-0.69-0.14-0.98-0.32c-0.54-0.37-0.86-0.95-0.84-1.59
                                c-0.01-0.98-0.67-1.91-1.56-2.22c-0.42-0.17-0.94-0.15-1.31-0.08c-0.4,0.08-0.79,0.12-1.18,0.23c0.39,0.12,0.78,0.16,1.18,0.24
                                c0.42,0.05,0.7,0.13,0.99,0.3c0.56,0.34,0.91,0.94,0.9,1.54c-0.01,0.95,0.6,1.92,1.48,2.26c0.42,0.19,0.93,0.2,1.32,0.12
                                c0.39-0.08,0.79-0.13,1.18-0.24C165.17,393.63,164.77,393.59,164.38,393.5z M111.6,348.1c-0.4-0.04-0.69-0.15-0.97-0.33
                                c-0.55-0.36-0.86-0.94-0.85-1.59c-0.01-0.97-0.67-1.9-1.56-2.21c-0.42-0.18-0.94-0.16-1.31-0.09
                                c-0.39,0.08-0.79,0.13-1.18,0.24c0.39,0.11,0.79,0.16,1.18,0.24c0.42,0.05,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.89,1.54
                                c-0.01,0.94,0.6,1.92,1.48,2.25c0.42,0.19,0.94,0.2,1.32,0.13c0.4-0.08,0.79-0.13,1.18-0.24
                                C112.39,348.23,112,348.18,111.6,348.1z M135.27,381.2c-0.4-0.05-0.7-0.15-0.98-0.33c-0.54-0.37-0.86-0.95-0.84-1.59
                                c-0.01-0.98-0.67-1.91-1.56-2.21c-0.43-0.18-0.95-0.16-1.31-0.09c-0.4,0.08-0.79,0.13-1.19,0.24c0.4,0.11,0.79,0.15,1.19,0.23
                                c0.41,0.05,0.7,0.13,0.99,0.31c0.55,0.33,0.91,0.93,0.9,1.54c-0.02,0.94,0.6,1.91,1.48,2.25c0.42,0.19,0.93,0.2,1.32,0.12
                                c0.39-0.08,0.79-0.12,1.18-0.23C136.06,381.32,135.66,381.28,135.27,381.2z M92.81,388.18c-0.4-0.05-0.7-0.15-0.98-0.33
                                c-0.54-0.36-0.86-0.94-0.85-1.59c0-0.98-0.66-1.9-1.55-2.21c-0.43-0.18-0.95-0.16-1.32-0.09c-0.39,0.08-0.78,0.13-1.18,0.24
                                c0.4,0.11,0.79,0.16,1.18,0.24c0.42,0.04,0.71,0.13,1,0.3c0.55,0.34,0.9,0.93,0.9,1.54c-0.02,0.94,0.59,1.92,1.48,2.25
                                c0.42,0.19,0.93,0.2,1.32,0.13c0.39-0.08,0.78-0.13,1.18-0.24C93.59,388.31,93.2,388.26,92.81,388.18z M154.81,349.04
                                c-0.4-0.04-0.69-0.14-0.97-0.33c-0.55-0.36-0.86-0.94-0.85-1.58c-0.01-0.98-0.66-1.91-1.56-2.22
                                c-0.42-0.17-0.94-0.16-1.31-0.08c-0.39,0.08-0.79,0.12-1.18,0.23c0.39,0.11,0.79,0.16,1.18,0.24c0.42,0.05,0.71,0.13,1,0.3
                                c0.55,0.34,0.9,0.93,0.9,1.54c-0.02,0.95,0.59,1.92,1.47,2.25c0.43,0.19,0.94,0.2,1.32,0.13c0.4-0.08,0.79-0.13,1.19-0.24
                                C155.6,349.17,155.21,349.12,154.81,349.04z M124.52,299.37c-0.33,0.27-0.59,0.41-0.92,0.5c-0.63,0.15-1.3-0.02-1.72-0.46
                                c-0.66-0.68-1.78-0.93-2.64-0.55c-0.43,0.17-0.8,0.52-1.02,0.85c-0.22,0.33-0.47,0.65-0.67,1c0.36-0.2,0.67-0.44,1.01-0.66
                                c0.31-0.25,0.59-0.39,0.92-0.46c0.64-0.13,1.27,0.06,1.72,0.52c0.7,0.69,1.82,0.88,2.67,0.47c0.42-0.18,0.78-0.56,0.99-0.87
                                c0.22-0.34,0.46-0.65,0.66-1C125.17,298.91,124.86,299.15,124.52,299.37z M133.16,353.94c-0.08-0.4-0.13-0.79-0.24-1.18
                                c-0.11,0.39-0.16,0.78-0.24,1.18c-0.04,0.42-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9c-0.94-0.01-1.92,0.6-2.25,1.48
                                c-0.19,0.42-0.2,0.93-0.13,1.32c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18c0.05-0.4,0.15-0.69,0.33-0.98
                                c0.37-0.54,0.95-0.86,1.59-0.84c0.98-0.01,1.9-0.67,2.21-1.56C133.25,354.83,133.23,354.3,133.16,353.94z M94.92,356.42
                                c-0.08-0.39-0.13-0.79-0.24-1.18c-0.11,0.39-0.16,0.79-0.24,1.18c-0.05,0.42-0.13,0.71-0.3,1c-0.34,0.55-0.93,0.9-1.54,0.89
                                c-0.94-0.01-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32c0.08,0.4,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.78,0.24-1.18
                                c0.05-0.4,0.15-0.69,0.33-0.97c0.36-0.55,0.94-0.86,1.59-0.85c0.97-0.01,1.9-0.67,2.21-1.56
                                C95.01,357.31,94.99,356.79,94.92,356.42z M113.64,316.27c-0.08-0.4-0.13-0.79-0.24-1.18c-0.11,0.39-0.16,0.78-0.24,1.18
                                c-0.05,0.42-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9c-0.94-0.01-1.92,0.6-2.25,1.48c-0.19,0.42-0.2,0.93-0.13,1.32
                                c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18c0.05-0.4,0.15-0.69,0.33-0.98c0.36-0.54,0.94-0.86,1.59-0.84
                                c0.98-0.01,1.9-0.67,2.21-1.56C113.73,317.16,113.71,316.63,113.64,316.27z M117.78,394.93c-0.08-0.4-0.12-0.79-0.23-1.19
                                c-0.11,0.4-0.16,0.79-0.24,1.19c-0.05,0.41-0.13,0.7-0.3,0.99c-0.34,0.56-0.93,0.91-1.54,0.9c-0.95-0.01-1.92,0.6-2.26,1.48
                                c-0.19,0.42-0.2,0.93-0.12,1.32c0.08,0.39,0.13,0.79,0.24,1.18c0.11-0.39,0.16-0.79,0.24-1.18c0.04-0.4,0.14-0.7,0.32-0.98
                                c0.37-0.54,0.95-0.86,1.59-0.84c0.98-0.01,1.91-0.67,2.22-1.56C117.87,395.82,117.86,395.29,117.78,394.93z M112.16,373.83
                                c-0.31,0.25-0.59,0.38-0.92,0.45c-0.64,0.13-1.28-0.06-1.72-0.52c-0.7-0.69-1.82-0.88-2.67-0.47
                                c-0.42,0.18-0.78,0.56-0.99,0.87c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.21,0.67-0.45,1.01-0.67c0.33-0.27,0.59-0.41,0.92-0.49
                                c0.63-0.16,1.29,0.02,1.72,0.45c0.65,0.68,1.78,0.93,2.64,0.55c0.43-0.17,0.8-0.52,1.02-0.85c0.22-0.33,0.47-0.64,0.67-1
                                C112.81,373.36,112.5,373.6,112.16,373.83z M163.37,369.45c-0.32,0.25-0.6,0.38-0.92,0.45c-0.65,0.13-1.28-0.06-1.72-0.52
                                c-0.7-0.69-1.82-0.88-2.67-0.47c-0.42,0.18-0.78,0.56-0.99,0.87c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.21,0.67-0.45,1.01-0.67
                                c0.32-0.27,0.59-0.41,0.91-0.49c0.63-0.16,1.3,0.01,1.73,0.45c0.65,0.68,1.78,0.93,2.64,0.55c0.43-0.17,0.8-0.52,1.02-0.85
                                c0.22-0.33,0.46-0.64,0.67-1C164.02,368.98,163.71,369.22,163.37,369.45z M143.72,400.46c-0.32,0.25-0.6,0.39-0.92,0.46
                                c-0.65,0.13-1.28-0.06-1.73-0.52c-0.69-0.69-1.81-0.88-2.66-0.47c-0.43,0.18-0.78,0.56-0.99,0.87c-0.22,0.34-0.47,0.65-0.67,1
                                c0.36-0.2,0.67-0.44,1-0.66c0.33-0.27,0.59-0.41,0.92-0.5c0.63-0.15,1.3,0.02,1.73,0.46c0.65,0.68,1.77,0.93,2.63,0.55
                                c0.44-0.17,0.8-0.52,1.03-0.85c0.22-0.33,0.46-0.65,0.66-1C144.37,400,144.05,400.24,143.72,400.46z M99.12,405.53
                                c-0.32,0.25-0.6,0.38-0.92,0.45c-0.65,0.13-1.28-0.06-1.72-0.52c-0.7-0.69-1.82-0.88-2.67-0.47c-0.42,0.18-0.78,0.56-0.99,0.87
                                c-0.22,0.34-0.47,0.65-0.67,1.01c0.36-0.2,0.67-0.45,1.01-0.67c0.32-0.26,0.59-0.41,0.91-0.49c0.63-0.16,1.3,0.02,1.73,0.45
                                c0.65,0.68,1.78,0.94,2.64,0.55c0.43-0.17,0.8-0.52,1.02-0.84c0.22-0.34,0.46-0.65,0.67-1.01
                                C99.77,405.06,99.46,405.31,99.12,405.53z"/>
                                <g>
                                    <path style={{ fill: "#263238" }} d="M126.064,353.379c-2.797,2.798-4.835,5.879-6.897,8.91c-2.092,3.074-3.7,6.252-5.404,9.419
                                    c-0.039,0.072,0.149,0.11,0.192,0.04c1.902-3.095,4.179-6.097,6.275-9.14c2.088-3.033,4.52-5.973,6.415-9.056
                                    C126.766,353.354,126.242,353.202,126.064,353.379z"/>
                                    <path style={{ fill: "#263238" }} d="M92.085,347.068c-1.636,6.375-3.105,12.788-4.276,19.251
                                    c-1.217,6.449-2.163,12.957-2.496,19.474c-0.108,3.263-0.087,6.491,0.281,9.725c0.353,3.213,0.932,6.423,2.176,9.317
                                    c1.23,2.883,3.285,5.351,6.075,6.642c2.775,1.316,6.005,1.623,9.184,1.494c6.412-0.281,12.771-2.06,19.047-3.888
                                    c6.284-1.864,12.491-4.017,18.695-6.177c6.208-2.154,12.384-4.411,18.536-6.736c6.155-2.316,12.315-4.63,18.44-7.033
                                    l-0.056,0.154l-1.593-3.406l1.794,3.305l0.056,0.104l-0.113,0.051c-6.007,2.695-12.046,5.319-18.133,7.829
                                    c-6.094,2.494-12.216,4.922-18.38,7.252c-6.171,2.313-12.404,4.465-18.713,6.421c-6.313,1.877-12.726,3.816-19.506,4.105
                                    c-3.36,0.102-6.889-0.227-10.084-1.771c-1.591-0.759-3.022-1.876-4.182-3.201c-1.164-1.326-2.054-2.852-2.725-4.434
                                    c-1.331-3.182-1.887-6.538-2.225-9.851c-0.375-3.311-0.27-6.684-0.077-9.988C84.909,372.472,88.115,359.611,92.085,347.068z"
                                    />
                                    <path style={{ fill: "#263238" }} d="M126.677,319.522c0.075,2.708,0.064,5.413,0.104,8.12l-0.02,8.114
                                    c0.012,5.404-0.125,10.824-0.068,16.197l0.028,0.995l0.024,0.48l0.019,0.218l0.011,0.078c0.002,0.018,0.004,0.022-0.002-0.003
                                    l-0.008-0.022l-0.059-0.114c-0.078-0.132-0.234-0.245-0.369-0.281l-0.103-0.037c-0.059-0.017-0.126-0.02-0.185-0.009
                                    c-0.03,0.005-0.058,0.014-0.083,0.024c-0.019,0.008,0.009-0.005,0.009-0.005l0.044-0.02c0.062-0.03,0.111-0.046,0.185-0.086
                                    l0.427-0.223l0.871-0.485c1.164-0.663,2.325-1.355,3.483-2.047l6.971-4.139l6.991-4.117l7.045-4.035l0.067-0.039l0.028,0.065
                                    l0.491,1.121l-0.611-1.061l0.096,0.026c-2.218,1.553-4.449,3.086-6.695,4.596l-6.781,4.463
                                    c-2.266,1.481-4.562,2.912-6.859,4.349c-1.158,0.705-2.314,1.408-3.494,2.094l-0.897,0.509l-0.47,0.25
                                    c-0.074,0.041-0.183,0.087-0.273,0.129l-0.071,0.032l-0.049,0.02c-0.046,0.016-0.094,0.029-0.145,0.039
                                    c-0.101,0.021-0.208,0.028-0.308,0.021c-0.059-0.007-0.08-0.001-0.164-0.026c-0.148-0.052-0.318-0.182-0.409-0.331
                                    c-0.056-0.09-0.071-0.137-0.068-0.131l-0.014-0.041l-0.026-0.083c-0.019-0.065-0.026-0.102-0.029-0.126l-0.027-0.173
                                    l-0.028-0.287l-0.032-0.533l-0.025-1.033c-0.019-5.444,0.162-10.84,0.429-16.25l0.435-8.106
                                    C126.264,324.92,126.421,322.219,126.677,319.522z"/>
                                    <path style={{ fill: "#263238" }} d="M155.28,374.325c-2.927-5.875-5.807-11.776-8.955-17.545c-1.788-3.277-3.559-6.566-5.379-9.827
                                    c-0.069-0.123-0.291-0.024-0.232,0.107c2.702,6.012,5.426,12.037,8.406,17.926c2.964,5.859,5.879,11.744,8.921,17.566
                                    c1.729,3.31,3.553,6.557,5.484,9.761c0.038,0.064,0.151,0.014,0.122-0.056C161.145,386.15,158.227,380.241,155.28,374.325z"/>
                                    <path style={{ fill: "#263238" }} d="M128.72,325.927c-0.201,1.595-0.547,3.152-0.927,4.702c-0.399,1.545-0.832,3.081-1.412,4.58
                                    c0.2-1.595,0.546-3.153,0.927-4.702C127.708,328.963,128.142,327.427,128.72,325.927z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path style={{ fill: "#EB9481" }} d="M148.221,303.989c-6.064,2.807-18.987-4.696-24.918-11.704
                                    c-0.404-0.483-0.739-6.354-1.483-13.468c-0.469-4.316-1.092-9.111-1.977-13.444c-0.186-0.889,28.531,1.053,28.531,1.053
                                    s-0.817,9.63,0.772,17.177c0.14,0.629,0.322,1.204,0.55,1.74c0.026,0.08,0.07,0.174,0.099,0.287
                                    C151.036,288.896,154.08,301.272,148.221,303.989z"/>
                                </g>
                                <path style={{ fill: "#263238" }} d="M121.537,265.144c2.757,7.07,10.929,23.842,25.501,21.209c1.015-0.182,1.932-0.421,2.75-0.719
                                c-0.018-0.123-0.066-0.206-0.096-0.291c-0.221-0.543-0.41-1.108-0.554-1.733c-1.589-7.56-0.766-17.191-0.766-17.191
                                S127.657,265.018,121.537,265.144z"/>
                            </g>
                            <g>
                                <g>
                                    <path style={{ fill: "#EB9481" }} d="M111.219,231.489c-1.671,10.148,7.245,37.626,15.019,42.978
                                    c11.272,7.759,27.847,6.428,33.966-6.691c5.931-12.717-6.322-48.321-14.832-52.992
                                    C132.839,207.904,113.687,216.498,111.219,231.489z"/>
                                </g>
                                <g>
                                    <g>
                                        <path style={{ fill: "#263238" }} d="M141.098,245.007c-0.04-0.064-0.112,0.139-0.082,0.213c0.719,1.809,1.238,4.002-0.199,5.208
                                        c-0.044,0.037-0.006,0.127,0.053,0.097C142.787,249.51,142.069,246.605,141.098,245.007z"/>
                                        <path style={{ fill: "#263238" }} d="M138.773,243.686c-3.024,0.84-1.122,6.826,1.68,6.048
                                        C143.21,248.968,141.307,242.982,138.773,243.686z"/>
                                    </g>
                                    <g>
                                        <path style={{ fill: "#263238" }} d="M151.495,241.764c-0.008-0.076,0.173,0.042,0.195,0.118c0.531,1.873,1.453,3.929,3.326,4.012
                                        c0.058,0.003,0.083,0.098,0.018,0.108C152.895,246.365,151.697,243.623,151.495,241.764z"/>
                                        <path style={{ fill: "#263238" }} d="M152.535,239.301c2.911-1.174,5.045,4.734,2.349,5.821
                                        C152.23,246.193,150.096,240.285,152.535,239.301z"/>
                                    </g>
                                    <path style={{ fill: "#263238" }} d="M135.617,242.448c0.82-0.325,1.522-0.762,2.301-1.167c0.877-0.457,1.623-0.731,2.119-1.637
                                    c0.265-0.486,0.026-1.264-0.422-1.566c-1.043-0.704-2.257-0.411-3.336,0.096c-1.171,0.551-1.932,1.275-2.491,2.439
                                    C133.245,241.741,134.535,242.876,135.617,242.448z"/>
                                    <path style={{ fill: "#263238" }} d="M153.894,234.059c-0.873,0.128-1.699,0.101-2.575,0.139c-0.989,0.043-1.772,0.178-2.654-0.359
                                    c-0.473-0.289-0.654-1.081-0.417-1.568c0.551-1.131,1.749-1.483,2.937-1.583c1.289-0.108,2.311,0.138,3.377,0.868
                                    C155.595,232.261,155.045,233.889,153.894,234.059z"/>
                                    <path style={{ fill: "#263238" }} d="M143.476,263.222c0.582,0.293,1.215,0.75,1.906,0.593c0.663-0.151,1.327-0.682,1.845-1.1
                                    c0.051-0.041,0.13,0.002,0.11,0.07c-0.255,0.865-1.126,1.632-2.034,1.755c-0.886,0.12-1.532-0.474-1.979-1.177
                                    C143.262,263.264,143.391,263.179,143.476,263.222z"/>
                                    <g>
                                        <g>
                                            <path style={{ fill: "#263238" }} d="M143.661,257.05c1.549,1.28,3.355,1.675,5.107,1.471c0.783-0.086,1.567-0.307,2.304-0.618
                                            c0.148-0.051,0.292-0.115,0.418-0.188c0.13-0.06,0.247-0.114,0.386-0.192c0.207-0.101,0.269-0.317,0.24-0.533l-0.005-0.013
                                            c-0.023-0.068-0.041-0.122-0.078-0.184c0,0,0,0-0.004-0.014l-0.055-0.162c-0.415-1.44-1.243-3.567-1.243-3.567
                                            c0.605,0.096,3.645,0.447,3.366-0.286c-2.331-6.057-4.923-12.795-8.521-18.33c-0.109-0.189-0.406-0.043-0.324,0.155
                                            c2.01,5.911,5.045,11.428,7.159,17.334c-0.952-0.053-2.526-0.21-3.268,0.042c-0.184,0.123,1.674,4.083,1.809,4.745
                                            c0,0,0.009,0.027,0.009,0.072c-2.301,1.068-4.34,0.918-7.18,0.048C143.61,256.767,143.503,256.938,143.661,257.05z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <path style={{ fill: "#263238" }} d="M150.001,257.447c0,0-0.724,2.11-2.261,3.278c-0.565,0.432-1.224,0.741-2.007,0.778
                                        c-1.604,0.081-2.073-1.266-2.171-2.515c-0.083-1.027,0.098-1.991,0.098-1.991S146.465,258.334,150.001,257.447z"/>
                                        <path style={{ fill: "#FF98B9" }} d="M147.739,260.724c-0.565,0.432-1.224,0.741-2.007,0.778c-1.604,0.081-2.073-1.266-2.171-2.515
                                        C145.201,258.646,147.115,259.224,147.739,260.724z"/>
                                    </g>
                                </g>
                                <g>
                                    <path style={{ fill: "#263238" }} d="M122.076,246.448c1.206-5.954-3.286-15.088-0.765-18.014
                                    c5.459-6.334,19.445,2.682,28.121,1.626c8.408-1.024,10.526-6.786,8.136-13.409c-2.39-6.624-8.767-10.464-18.377-10.665
                                    c-9.941-0.208-18.329,4.86-22.766,8.576c0,0,1.685-3.675,0.757-4.632c-0.929-0.956-4.625,2.843-5.704,4.399
                                    c-1.096,1.58-1.538,4.313-1.538,4.313s-1.591-0.259-3.887,0.094c-2.129,0.328-4.607,2.346-4.695,2.867
                                    c-0.088,0.522,3.02,0.521,5.177,0.354c0,0-3.317,5.82-2.65,10.351c0.667,4.531,10.498,25.204,12.226,25.22
                                    C118.331,257.549,121.586,248.867,122.076,246.448z"/>
                                    <path style={{ fill: "#263238" }} d="M107.295,221.301c0.069-0.054,0.185,0.037,0.12,0.109c-1.818,2.027-3.211,4.295-3.721,7.007
                                    c-0.593,3.154,0.03,6.393,1.106,9.372c2.094,5.797,5.701,10.836,8.94,16.018c0.095,0.152-0.159,0.316-0.264,0.171
                                    c-3.783-5.269-7.68-11.02-9.649-17.253C102.148,231.41,102.608,224.992,107.295,221.301z"/>
                                    <path style={{ fill: "#263238" }} d="M120.696,226.272c2.422-0.66,5.197-0.778,7.685-0.477c2.452,0.296,4.673,1.515,6.947,2.398
                                    c5.104,1.983,10.597,3.323,16.07,2.279c0.07-0.013,0.087,0.091,0.019,0.108c-4.962,1.278-10.083,0.409-14.845-1.248
                                    c-2.402-0.836-4.74-2.173-7.228-2.713c-2.828-0.614-5.734-0.202-8.569,0.102C120.482,226.753,120.413,226.349,120.696,226.272
                                    z"/>
                                    <path style={{ fill: "#263238" }} d="M121.298,211.512c3.158-2.159,6.692-3.762,10.324-4.941c3.486-1.131,7.139-1.894,10.82-1.768
                                    c6.354,0.218,12.586,3.45,14.862,9.656c1.154,3.145,1.269,6.745-0.026,9.867c-0.087,0.211-0.4,0.095-0.332-0.122
                                    c1.843-5.924-0.028-12.583-5.306-16.1c-5.668-3.777-12.882-3.201-19.121-1.369c-3.928,1.153-7.603,2.876-11.196,4.816
                                    C121.301,211.563,121.276,211.527,121.298,211.512z"/>
                                </g>
                                <g>
                                    <path style={{ fill: "#EB9481" }} d="M120.309,253.639c0,0-7.752-8.103-11.523-5.202c-3.772,2.9,3.034,15.329,8.15,16.383
                                    c5.116,1.055,6.185-3.662,6.185-3.662L120.309,253.639z"/>
                                    <path style={{ fill: "#263238" }} d="M110.825,251.95c-0.079,0.005-0.077,0.116-0.005,0.133c3.604,0.851,5.876,3.721,7.629,6.79
                                    c-1.203-1.1-2.683-1.575-4.179-0.115c-0.078,0.077,0.028,0.202,0.12,0.167c1.344-0.512,2.383-0.426,3.494,0.542
                                    c0.786,0.685,1.394,1.612,2.01,2.448c0.206,0.279,0.729,0.033,0.559-0.299c-0.013-0.024-0.027-0.05-0.04-0.074
                                    C119.832,257.204,115.536,251.669,110.825,251.95z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path style={{ fill: "#EBEBEB" }} d="M149.617,285.207l-3.72,17.205l-23.483-16.96l-4.554,7.504c0,0,25.093,29.029,24.966,27.545
                            l4.268-15.011l11.227,8.021l-4.913-24.109L149.617,285.207z"/>
                            <path style={{ fill: "#263238" }} d="M156.566,301.459c-0.944-3.953-1.785-7.961-2.912-11.866c-0.04-0.139-0.272-0.164-0.278,0.009
                            c-0.142,3.998,3.55,19.096,4.158,22.764c-0.016-0.011-0.034-0.023-0.049-0.035c-0.471-0.349-10.328-6.982-10.39-6.838
                            c-1.063,2.449-4.209,13.386-4.385,14.363c-3.972-4.613-20.567-22.641-25.059-26.878c-0.129-0.121-0.326,0.03-0.216,0.18
                            c3.66,4.963,25.195,28.815,25.424,28.703c0.186,0.099,4.114-13.382,4.436-15.857c1.735,1.516,11.849,9.172,11.777,8.572
                            C158.583,310.53,157.511,305.417,156.566,301.459z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Device">
                <g>
                    <g>
                        <rect x="198.056" y="427.317" style={{ fill: "#455A64" }} width="111.194" height="4.987" />
                        <rect x="198.056" y="427.322" style={{ fill: "#263238" }} width="55.965" height="4.987" />
                    </g>
                    <g>
                        <g>
                            <polygon style={{ fill: "#37474F" }} points="252.723,432.3 361.09,432.279 376.388,358.677 268.021,358.697 				" />
                        </g>
                        <path style={{ fill: "#FFFFFF" }} d="M306.156,397.134c-0.418,3.989,2.476,7.222,6.465,7.221c3.989-0.001,7.562-3.235,7.98-7.224
                        c0.419-3.989-2.476-7.222-6.465-7.221C310.147,389.91,306.574,393.145,306.156,397.134z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default NoPublishedPost;