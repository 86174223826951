import { Box, Card, TableContainer } from '@mui/material';
import { useState, useEffect } from 'react';

import FacebookPreview from 'src/pages/scheduledPost/Preview/FacebookPreview/facebook-preview.component';
import InstagramPreview from 'src/pages/scheduledPost/Preview/InstagramPreview/instagram-preview.component';
import TwitterPreview from 'src/pages/scheduledPost/Preview/TwitterPreview/twitter-preview.component';
import YoutubePreview from 'src/pages/scheduledPost/Preview/YoutubePreview/youtube-preview.component';
import LinkedinPreview from 'src/pages/scheduledPost/Preview/LinkedinPreview/linkedin-preview.component';
import GoogleMyBusinessPreview from 'src/pages/scheduledPost/Preview/GoogleMyBusinessPreview/gbusiness-preview.component';
import PinterestPreview from 'src/pages/scheduledPost/Preview/PinterestPreview/pinterest-preview.component';
import TikTokPreview from 'src/pages/scheduledPost/Preview/TiktokPreview/tiktok-perview-component';

// Function to add connected profile details to post data

const PreviewComponent = ({ newPostForm }) => {
  const [height, setHeight] = useState(window.innerHeight - 200);
  let facebookData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.facebook_requests_data));
  let instagramData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.instagram_requests_data));
  let twitterData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.twitter_requests_data));
  let youtubeData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.youtube_requests_data));
  let linkedinData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.linkedin_requests_data));
  let gbusinessData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.gbusiness_requests_data));
  let pinterestData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.pinterest_requests_data));
  let tiktokData = JSON.parse(JSON.stringify(newPostForm.scheduled_event_requests_data.tiktok_requests_data));

  return (
    <Card style={{ boxShadow: 'none', borderRadius: 1 }}>
      <FacebookPreview postDetails={facebookData} />
      <InstagramPreview postDetails={instagramData} />
      <TwitterPreview postDetails={twitterData} />
      <YoutubePreview postDetails={youtubeData} />
      <LinkedinPreview postDetails={linkedinData} />
      <GoogleMyBusinessPreview postDetails={gbusinessData} />
      <PinterestPreview postDetails={pinterestData} />
      <TikTokPreview postDetails={tiktokData} />
      {/* Add TikTokPreview if it exists and is needed */}
      {/* <TikTokPreview postDetails={modifiedTiktokData} /> */}
    </Card>
  );
};

export default PreviewComponent;
