import moment from 'moment-timezone';

const GetLocalTimefromUTC = (timeString) => {
  let utcTime = new Date(timeString);
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = utcTime.toLocaleString(undefined, { timeZone: clientTimeZone });
  return new Date(localTime);
};

const GetTimeZoneList = () => {
  const timeZones = moment.tz.names();
  return timeZones.map(timeZone => {
    const offset = moment.tz(timeZone).format('Z');
    return { timeZone, offset };
  });
}

export const TimeHelper = {
  GetLocalTimefromUTC,
  GetTimeZoneList
}