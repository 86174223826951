import { createContext, useContext, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
// import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
// import customShadows from './customShadows';
import componentsOverride from './overrides';
import generateTheme from './generate-theme';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

    const [palette, setPalette] = useState(generateTheme());

    const themeOptions = {
        palette,
        shape: { borderRadius: 6 },
        typography,
        // shadows: shadows(),
        // customShadows: customShadows(),
    };

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <ThemeContext.Provider value={{ theme }}>
            <StyledEngineProvider injectFirst>
                <MUIThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles />
                    {children}
                </MUIThemeProvider>
            </StyledEngineProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeProvider = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeProvider must be used within a ThemeProvider');
    }
    return context;
};
