import { AppBar, Avatar, Card, Chip, Container, Toolbar, useMediaQuery, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useResponsive from 'src/hooks/useResponsive';
import HttpServices from 'src/services/httpService';
import { chatModuleURL, postsModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';
import { useNavigate } from 'react-router-dom';
import FacebookIcon from '../../assets/facebook.svg';
import NoMessage from '../../assets/app-image/no-message.svg';
import { LoadingButton } from '@mui/lab';

const NAV_WIDTH = 280;
const HEADER_MOBILE = 42;
const HEADER_DESKTOP = 40;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'white',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function FacebookChat({ connectedNetworks }) {
  const isDesktop = useResponsive('up', 'sm');
  const [chatConversation, setChatConversation] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [pagination, setPagination] = useState('');
  const [convPagination, setConvPagination] = useState('');
  const { showToast } = useToaster();
  const theme = useTheme();
  const isMobileIcon = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMessageLoad, setMessageLoad] = useState(false);
  const [connectedMedias, setConnectedMedias] = useState([]);
  const [facebookPageId, setfacebookPageId] = useState('');
  const [height, setHeight] = useState(window.innerHeight - 300);
  const [userId, setUserId] = useState('');
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isSendLoading, setSendLoading] = useState(false);
  const navigate = useNavigate();
  const [isNextConversationLoading, setsNextConversationLoading] = useState(false);
  const [isNextChatMessageLoading, setsNextChatMessageLoading] = useState(false);
  const [scrollHeightBeforeLoad, setScrollHeightBeforeLoad] = useState(0);

  useEffect(() => {}, [selectedConversation, chatConversation]);

  useEffect(() => {
    let facebookId = connectedNetworks && connectedNetworks.length > 0 && connectedNetworks[0].id;
    facebookId && setfacebookPageId(facebookId);
    setConnectedMedias(connectedNetworks);
    connectedNetworks && connectedNetworks.length === 0 && setMessageLoad(false);
    facebookPageId && getAllConversation();
  }, []);

  useEffect(() => {
    facebookPageId && getAllConversation();
  }, [facebookPageId]);

  const getAllConversation = () => {
    setLoading(true);
    setLoader(true);
    let getProps = {
      url: `${chatModuleURL.getAllFacebookMessageConversations}${facebookPageId}&pagination=`,
      successCallback: chatConversationCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
    setMessageLoad(true);
  };
  const getAllNextConversation = () => {
    let getProps = {
      url: `${chatModuleURL.getAllFacebookMessageConversations}${facebookPageId}&pagination=${pagination}`,
      successCallback: chatConversationCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const navigateToIntegration = () => {
    navigate('/integration');
  };
  const chatConversationCallback = (data, message) => {
    setLoading(false);
    setLoader(false);
    setsNextConversationLoading(false);
    if (data.conversion.length === 0 && userId === data.user_id) {
      return;
    }
    if (pagination) {
      let cConversation = { ...chatConversation };
      cConversation.pagination = data.pagination;
      cConversation.conversion = cConversation.conversion.concat(data.conversion);
      setChatConversation({ ...cConversation });
    } else {
      setChatConversation(data);
      setSelectedConversation(data.conversion[0]);
      setUserId(data.user_id);
    }
    setPagination(data.pagination);
    setMessageLoad(true);
  };

  const failureCallback = (message) => {
    setLoading(false);
    setLoader(false);
    setsNextConversationLoading(false);
    showToast(message, 'e');
    setMessageLoad(false);
    setSendLoading(false);
    setsNextChatMessageLoading(false);
  };
  const addfailureCallback = (message) => {
    showToast(message, 'e');
    setSendLoading(false);
  };

  const handleConversation = (conversation) => {
    setSelectedConversation(conversation);
    setConvPagination('');
    setScrollHeightBeforeLoad(0)
  };
  const handleScrollHeightBeforeLoad =(value) =>{
    setScrollHeightBeforeLoad(value)
  }

  const facebookMessageCallback = (data, message) => {
    getConversationsendMessages();
  };
  const getConversationsendMessages = () => {
    let getProps = {
      url: `${chatModuleURL.getFacebookMessageConversation}${facebookPageId}&conversation_id=${selectedConversation.id}&pagination=`,
      successCallback: chatSendMessageCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const chatSendMessageCallback = (data, message) => {
    let sConversation = { ...selectedConversation };
    sConversation.messages = data.messages;
    sConversation.pagination = data.pagination;
    setSelectedConversation({ ...sConversation });

    let updatedConversations = chatConversation.conversion.map((conversation) =>
      conversation.id === sConversation.id ? sConversation : conversation
    );

    setChatConversation({
      ...chatConversation,
      conversion: updatedConversations,
    });
    setSendLoading(false);
  };

  const handleSendMessage = (messageProps) => {
    setSendLoading(true);
    let type = 'TEXT';
    if (messageProps.imageUrl !== '') {
      type = messageProps.type;
    }
    let chatBody = {
      profile_page_id: facebookPageId,
      recipient_id: messageProps.id,
      text: messageProps.text,
      image_url: messageProps.imageUrl,
      media_type: type,
    };
    let postProps = {
      url: chatModuleURL.sendMessage,
      body: chatBody,
      successCallback: facebookMessageCallback,
      failureCallback: addfailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleNextConversation = () => {
    setsNextConversationLoading(true);
    getAllNextConversation(chatConversation.pagination);
  };

  const getConversationMessages = (pagination) => {
    let getProps = {
      url: `${chatModuleURL.getFacebookMessageConversation}${facebookPageId}&conversation_id=${selectedConversation.id}&pagination=${pagination}`,
      successCallback: chatMessageCallback,
      failureCallback: nextCoversationfailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const nextCoversationfailureCallback = (message) => {
    setsNextChatMessageLoading(false);
    let sConversation = { ...selectedConversation };
    sConversation.pagination = '';
    setSelectedConversation({ ...sConversation });
    showToast(message, 'e');
  };
  const chatMessageCallback = (data, message) => {
    setsNextChatMessageLoading(false);
    let sConversation = { ...selectedConversation };
    sConversation.messages = sConversation.messages.concat(data.messages);
    sConversation.pagination = data.pagination;
    setSelectedConversation({ ...sConversation });
  };

  const handleNextMessage = (pagination) => {
    if (pagination) {
      setsNextChatMessageLoading(true);
      getConversationMessages(pagination);
      setConvPagination(pagination);
    }
  };

  const handleDeleteMessage = (messageId) => {
    let deleteBody = {
      id: messageId,
      page_id: facebookPageId,
    };
    let postProps = {
      url: postsModuleURL.deleteFacebookCommentAndMessage,
      body: deleteBody,
      successCallback: facebookDeleteSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleFacebookPage = (id) => {
    setfacebookPageId(id);
    setPagination('');
    setChatConversation([]);
    setSelectedConversation([]);
    setScrollHeightBeforeLoad(0);
  };

  const facebookDeleteSuccessCallback = (data, message) => {
    let sConversation = { ...selectedConversation };
    let index = sConversation.messages.findIndex((a) => a.id === data);
    if (index >= 0) {
      sConversation.messages.splice(index, 1);
    }
    setSelectedConversation({ ...sConversation });
  };

  const getNetworkChip = () => {
    return (
      connectedMedias &&
      connectedMedias.length > 0 &&
      connectedMedias.map((c) => {
        let isActive = c.id === facebookPageId;
        return (
          <Chip
            key={c.id}
            style={{
              borderRadius: 8,
              opacity: isActive ? 1 : 0.9,
              ...(isActive && {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontWeight: 800,
              }),
            }}
            sx={{ mr: 1, mb: 1 }}
            onClick={() => {
              handleFacebookPage(c.id);
            }}
            avatar={
              <Avatar
                src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                alt={c.profile_name ? c.profile_name : c.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: isActive ? 'white' : theme.palette.primary.main,
                }}
                style={{ color: isActive ? theme.palette.primary.main : 'white' }}
              />
            }
            label={c.profile_name ? c.profile_name : c.id}
          />
        );
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>Chat</title>
      </Helmet>
      <>
        {connectedMedias && connectedMedias.length > 0 ? (
          <>
            <Card sx={{ mb: 2, p: 2 }}>{getNetworkChip()}</Card>
            {isLoading ? (
              <Spinner height={height} />
            ) : (
              <>
                {isMessageLoad && chatConversation.conversion && chatConversation.conversion.length > 0 ? (
                  <Card
                    sx={{
                      maxHeight: 'calc(100vh - 250px)',
                      minHeight: 'calc(100vh - 250px)',
                      display: 'flex',
                    }}
                  >
                    <ChatSidebar
                      chatConversation={chatConversation}
                      handleConversation={handleConversation}
                      selectedConversation={selectedConversation}
                      handleNextConversation={handleNextConversation}
                      isNextConversationLoading={isNextConversationLoading}
                      userId={userId}
                      icon={FacebookIcon}
                    />
                    <ChatWindow
                      selectedConversation={selectedConversation}
                      handleSendMessage={handleSendMessage}
                      handleNextMessage={handleNextMessage}
                      handleDeleteMessage={handleDeleteMessage}
                      isNextChatMessageLoading={isNextChatMessageLoading}
                      userId={userId}
                      isSendLoading={isSendLoading}
                      scrollHeightBeforeLoad={scrollHeightBeforeLoad}
                      handleScrollHeightBeforeLoad={handleScrollHeightBeforeLoad}
                    />
                  </Card>
                ) : (
                  <Card
                    sx={{
                      mb: 2,
                      p: 2,
                      maxHeight: 'calc(100vh - 250px)',
                      minHeight: 'calc(100vh - 250px)',
                      display: 'flex',
                      flexDirection:'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={NoMessage}
                      style={{ width: '45%', height: '45%', maxWidth: '45%', maxHeight: '45%' }}
                      alt="No Message Available"
                    />
                    <Typography variant="h4" sx={{ fontWeight: 800 }}>
                      No Message Available
                    </Typography>
                  </Card>
                )}
              </>
            )}
          </>
        ) : (
          <Card
            sx={{
              mb: 2,
              p: 2,
              maxHeight: 'calc(100vh - 150px)',
              minHeight: 'calc(100vh - 150px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingButton variant="contained" autoFocus onClick={navigateToIntegration}>
              Connect Facebook Accounts
            </LoadingButton>
          </Card>
        )}
      </>
    </>
  );
}
