

import { Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material';
import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, Stack, Typography, useTheme,Button, Paper, useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useState, useRef, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import Linklike from '../../../assets/linkedin_like.svg'
import LinkUnLike from '../../../assets/linkedin_unlike.svg'
import Linkshare from '../../../assets/linkedin_share.svg'
import Linkcomment from '../../../assets/linkedin_comment.svg'
import LinkedinRetweetIcon from '../../../assets/twitter_retweet.svg';
import LinkedinShareIcon from '../../../assets/linkedin-share-icon.svg';
import LinkedinCommentIcon from '../../../assets/linkedin_comment.svg';
import Nodocument from '../../../assets/no-document.svg'

const LinkedInPostCard = ({ media_url, description, name, image_url, created_time, like_count, share_count, comment_count, is_liked, profile, handleUnLikePost, handleLikePost, handleFocusComment }) => {
  const theme = useTheme();
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES);
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [showFullDescription, setShowFullDescription] = useState(false); 
  let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const imageView = () => {
    let view = [];
    let xWidth = 6;
    const remainingImagesCount = mediaFiles.length > 5 ? mediaFiles.length - 4 : 0;
    switch (mediaFiles.length) {
      case 1:
        xWidth = 12;
        break;
      case 2:
        xWidth = 12;
        break;
      case 3:
      case 4:
        xWidth = 6;
        break;
      default:
        xWidth = 6;
        break;
    }
    view = mediaFiles.slice(0, 4).map((image, i) => (
      <Grid
        item
        xs={mediaFiles.length === 3 && i === 2 ? 12 : xWidth}
        key={i}
        sx={{
          maxWidth: 500,
          maxHeight: 250,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          opacity: 1,
          transform:'scale(0.99)'
        }}
      >
        {image && image?.url && image?.type=='image' ? (
          <>
            <img
              src={image.url}
              alt={image.url}
              width={'100%'}
              height={mediaFiles.length > 1 ? 150 : 200}
              style={{ objectFit: 'cover' }}
            />
          </>
        ) : (
          <img src={Nodocument} alt={"No Post Image"} width={'100%'} height={200} style={{ objectFit: 'contain' }} />
        )}
      </Grid>
    ));
    return (
      <Grid container sx={{ position: 'relative' }}>
        {view}
        {remainingImagesCount > 0 && (
          <Typography
            variant="h2"
            color="white"
            style={{
              position: 'absolute',
              top: '75%',
              left: '75%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              width: '208px',
              height: '150px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            +{remainingImagesCount}
          </Typography>
        )}
      </Grid>
    );
  };
  const profileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">{CommonHelper.DateTimeFromCurrentTime(profile?.date)}</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  const checkActionsAreAvailable = () => {
    return (like_count > 0 || comment_count > 0 || share_count > 0);
  }
  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={profile?.profile_image ? profile?.profile_image : profile?.profile_name || profile?.id}
                alt={profile?.profile_name ? profile?.profile_name : profile?.id}
                sx={{
                  alignItems: 'center', justifyContent: 'center',
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }

            title={profile?.profile_name || name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={profileTime()}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />         
           <CardContent >
           <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {description}
            </Typography>
          {!showFullDescription && description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
          </CardContent>
          <CardMedia sx={{ pl: 2, pr: 2 }}>{imageView()}</CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            {checkActionsAreAvailable() && <>
              <Stack
                flexWrap="wrap" direction="row" spacing={2}
                sx={{ justifyContent: 'flex-end', display: 'flex',alignItems:'flex-end', m: 2, mb: 1 }}
              >
                {like_count  !== 0 && <Typography  color={'text.secondary'} >{like_count} likes</Typography>}
                {comment_count  !== 0 && <Typography  color={'text.secondary'}>{comment_count} comments</Typography>}
                {share_count  !== 0 &&  <Typography  color={'text.secondary'}>{share_count} share</Typography>}
              </Stack>
              <Divider />
            </>}
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper  sx={{ display: 'flex', alignItems: 'center', gap: '6.5rem', mt:0.5 }}>
                <IconButton 
                disabled={!hasManageLikesCommentPermission} 
                onClick={is_liked ? handleUnLikePost : handleLikePost}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: { xl: '30px', lg: '24px' },
                  },
                }}>
                  <img src={is_liked ? Linklike : LinkUnLike}  style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }} />
                </IconButton>
                <IconButton  disabled={!hasManageLikesCommentPermission} onClick={handleFocusComment ? handleFocusComment : null} 
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: { xl: '30px', lg: '24px' },
                  },
                }}>
                  <img src={LinkedinCommentIcon} style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }} />
                </IconButton>
                <IconButton onClick={handleFocusComment ? handleFocusComment : null}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: { xl: '30px', lg: '24px' },
                  },
                }}>
                  <img src={LinkedinRetweetIcon} style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }} />
                </IconButton>
                <IconButton disabled onClick={handleFocusComment ? handleFocusComment : null}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: { xl: '30px', lg: '24px' },
                  },
                }}>
                  <img src={LinkedinShareIcon} style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }} />
                </IconButton>
              </Paper>
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};
export default LinkedInPostCard;
