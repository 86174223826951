import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentFailureComponent = () => {
    const navigate = useNavigate();

    const handleRetryPayment = () => {
        navigate("/billing"); 
        
    };

    return (
        <Box sx={{ textAlign: "center", mt: 5 }}>
            <Typography variant="h4" color="error" gutterBottom>
                Payment Failed
            </Typography>
            <Typography variant="body1" gutterBottom>
                Unfortunately, your payment could not be processed at this time. Please try again or contact support.
            </Typography>
            <Box sx={{ mt: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRetryPayment}
                    sx={{ mr: 2 }}
                >
                    Retry Payment
                </Button>
            </Box>
        </Box>
    );
};

export default PaymentFailureComponent;
