import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, Link, ListItemIcon, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TextIconLabel from 'src/components/TextIconLabel';
import ErrorIcon from '@mui/icons-material/Error';
const InstagramAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectInstagram = () => {
        setLoadingOn(true);
        props.handleConnectInstagram();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography gutterBottom variant="h4">
                    Have you converted your Instagram account to a Business Profile?
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800', typography: 'body1' }}>

                    <Typography variant="body1" sx={{ margin: 'auto 0', fontWeight: 'bold' }}>
                        Make sure you've completed these steps before continuing:
                    </Typography>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            You’ve converted your profile to a Business Profile in the Instagram application.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            You’re an Admin on your Facebook Page.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            Once you sign in to the Facebook profile that has Admin permissions for the Facebook Page connected with your Instagram Business account.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <ListItemIcon>
                            <ErrorIcon sx={{ color: "secondary.main", width: 20, height: 20, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>

                        <Typography variant="body1">
                            Learn how to connect an <strong>Instagram account</strong> with a Facebook Page  <Link href="https://brand2social.com/user-guides/setting-up-instagram-on-brand2social/" target="_blank" rel="noopener noreferrer" >
                                <strong>click here.</strong></Link>
                        </Typography>

                    </Paper>
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button
                    color='error'
                    variant="outlined"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectInstagram();
                    }}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default InstagramAuthorizationModal;
