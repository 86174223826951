import {
  Box,
  Grid,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Badge,
  Divider
} from '@mui/material';
import { useState } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import { Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, Share as ShareIcon } from '@mui/icons-material';
import GbusinessVerifyUser from '../../../assets/gbusiness-verify-user.svg';
import GbusinessOfferIcon from '../../../assets/gbusiness-offer-icon.svg';
import { useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';

const GBusinessOfferPreview = ({ offer, event, description, media_url, profile }) => {
  let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [showOffers, setShowOffers] = useState(false);
  const descriptionRef = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
 
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const offerSchedule = () => {
    const startDate = `${event.schedule.startDate.month}/${event.schedule.startDate.day}/${event.schedule.startDate.year}`;
    const endDate = `${event.schedule.endDate.month}/${event.schedule.endDate.day}/${event.schedule.endDate.year}`;

    const formattedSchedule = `From ${startDate} at ${event.schedule.startTime.hours
      .toString()
      .padStart(2, '0')}:00 to ${endDate} at ${event.schedule.endTime.hours.toString().padStart(2, '0')}:00`;
    return <div>{formattedSchedule}</div>;
  };

  const imageView = () => {
    let xWidth = 12

    return (
      <Grid container sx={{ position: 'relative' }}>
        <Grid
          item
          xs={xWidth}
          sx={{
            maxWidth: 500,
            maxHeight: 250,
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-flex',
            opacity: 1,
            transform: 'scale(0.99)'
          }}
        >
          {mediaFiles && mediaFiles[0]?.url && (
            <>
              <img
                src={mediaFiles[0].url}
                alt={mediaFiles[0].url}
                width={'100%'}
                height={200}
                style={{ objectFit: 'cover', borderRadius: '10px' }}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const cardOffers = () => {
    return (
      <>
        <Card sx={{ textAlign: 'center', boxShadow: 'none', p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', border: 'dashed', borderColor: theme.palette.preview.comments.gbusiness, mt: 2, mb: 2 }}>
          <Typography sx={{ mb: 0.5 }}>Show this code at the shop</Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              mb: 0.5
            }}
          >
            {offer.couponCode}
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }}>Valid {offerSchedule()}</Typography>
        </Card>
        <a href={offer.redeemOnlineUrl} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: theme.palette.primary.main,
              mb: 2
            }}
          >
            Redeem online
          </Typography>
        </a>

        <Divider />
        <Typography sx={{ fontSize: '0.8rem', mt: 1 }}>{offer.terms_conditions}</Typography>
      </>
    );
  };

  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">{CommonHelper.DateTimeFromCurrentTime(profile?.date)}</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="flex-start" alignItems="flex-start">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 450 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            height: 'auto',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<img src={GbusinessVerifyUser} sx={{ width: 20, height: 20, overflow: 'hidden', background: 'white', color: theme.palette.preview.postcard.gbverifyicon, ml: 1, mb: 1 }} />}>
                <Avatar
                  src={profile.image_url ? profile.image_url : profile.profile_name}
                  alt={profile.profile_name ? profile.profile_name : profile.id}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: 40,
                    width: 40,
                    bgcolor: theme.palette.primary.main,
                  }}
                  style={{ color: 'white' }}
                />
              </Badge>
            }
            title={profile?.profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          {mediaFiles && <CardMedia sx={{ pl: 2, pr: 2, mt: 2 }}>{imageView()}</CardMedia>}
          <CardContent disableSpacing sx={{ display: 'block', position: 'relative' }}>
            <img src={GbusinessOfferIcon} alt="Business Offer Icon" style={{ width: 25, height: 25, overflow: 'hidden', position: 'absolute', top: 8, right: 10 }} />
            <Typography sx={{ fontSize: '1.5rem', mt: '-1.5rem' }}>{event.title}</Typography>
            <Typography sx={{ fontSize: '0.8rem', mb: 2 }}>Valid {offerSchedule()}</Typography>
            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {description}
            </Typography>
          {!showFullDescription && description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
            {!showOffers && <Typography
              sx={{
                // fontWeight: 700,
                textDecoration: 'underline',
                cursor: 'pointer',
                color: theme.palette.primary.main,
                mb: 2
              }}
              onClick={() => setShowOffers(true)}
            >
              View Offers
            </Typography>}
            {showOffers && cardOffers()}

            <ShareIcon sx={{ position: 'absolute', bottom: 10, right: 10 }} />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default GBusinessOfferPreview;
