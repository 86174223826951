import {
  Box, Tab, Tabs, useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import Facebook_Icon from '../../assets/facebook.svg';
import Gbusiness_Icon from '../../assets/google-my-business-logo.svg';
import Instagram_Icon from '../../assets/instagram.svg';
import FacebookChat from './facebookChat.component';
import GbusinessChat from './gbusinessChat.component';
import InstagramChat from './instagramChat.component';
import { PostUtil } from '../new-post-new/post-util';

export default function Chat() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [currentTab, setCurrentTab] = useState('Facebook Page');
  const [facebookNetworks, setFacebookNetworks] = useState([]);
  const [facebookGroupNetworks, setfacebookGroupNetworks] = useState([]);
  const [instagramNetworks, setInstagramNetworks] = useState([]);
  const [youtubeNetworks, setYoutubeNetworks] = useState([]);
  const [twitterNetworks, setTwitterNetworks] = useState([]);
  const [linkedInNetworks, setLinkedInNetworks] = useState([]);
  const [tiktokNetworks, setTiktokNetworks] = useState([]);
  const [gbusinessNetworks, setGBusinessNetworks] = useState([]);
  const [isNetworksLoaded, setNetworkLoaded] = useState(false);

  const ACCOUNT_TABS = [
    {
      value: 'Facebook Page',
      icon: <img src={Facebook_Icon} width={28} height={20} />,
      component: <FacebookChat connectedNetworks={facebookNetworks} />,
    },
    {
      value: 'Instagram',
      icon: <img src={Instagram_Icon} width={28} height={20} />,
      component: <InstagramChat connectedNetworks={instagramNetworks} />,
    },
    {
      value: 'GoogleMyBusiness',
      icon: <img src={Gbusiness_Icon} width={28} height={20} />,
      component: <GbusinessChat connectedNetworks={gbusinessNetworks} />,
    }
  ];

  useEffect(() => {
    getConnectedNetworks();
  }, []);

  useEffect(() => { }, [
    facebookNetworks,
    instagramNetworks,
    youtubeNetworks,
    twitterNetworks,
    linkedInNetworks,
    tiktokNetworks,
    isNetworksLoaded,
  ]);

  const connectedNetworksSuccessCallback = (data, message) => {
    let fNetworks = data.filter(
      (a) =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK && a.profile_type == PostUtil.ProfileType.PAGE && a.is_enabled
    );
    let fgNetworks = data.filter(
      (a) =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK && a.profile_type == PostUtil.ProfileType.GROUP && a.is_enabled
    );
    let iNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM && a.is_enabled);
    let yNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE && a.is_enabled);
    let tNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.TWITTER && a.is_enabled);
    let lNetworks = data.filter(
      (a) => a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN && a.profile_type === 'PAGE'
    );
    // let lNetworks = data.filter(a => a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN);
    let tiNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.TIKTOK && a.is_enabled);
    let gNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS && a.is_enabled);
    setGBusinessNetworks(gNetworks);
    setFacebookNetworks(fNetworks);
    setfacebookGroupNetworks(fgNetworks);
    setInstagramNetworks(iNetworks);
    setYoutubeNetworks(yNetworks);
    setTwitterNetworks(tNetworks);
    setLinkedInNetworks(lNetworks);
    setTiktokNetworks(tiNetworks);
    setNetworkLoaded(true);
  };

  const connectedNetworkFailureCallback = (message) => {
    setFacebookNetworks([]);
    setInstagramNetworks([]);
    setYoutubeNetworks([]);
    setTwitterNetworks([]);
    setLinkedInNetworks([]);
    setTiktokNetworks([]);
    setGBusinessNetworks([])
  };

  const getConnectedNetworks = () => {
    let getProps = {
      url: integrateModuleURL.getConnectedNetworks,
      successCallback: connectedNetworksSuccessCallback,
      failureCallback: connectedNetworkFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  return (
    <>
      <Helmet>
        <title>Chat</title>
      </Helmet>
      <Box>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          sx={{ gap: '0.5rem', minHeight: '37px' }}
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => {
            return <Tab
              disableRipple
              key={tab.value}
              label={isMobile ? "" : capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
              sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
            />
          })}
        </Tabs>

      </Box>
      <Box sx={{ mb: 2 }} />
      {isNetworksLoaded && ACCOUNT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}
