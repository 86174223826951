import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from  '@mui/icons-material/VerifiedUser';

const FacebookGroupAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectFacebook = () => {
        setLoadingOn(true);
        props.handleConnectFacebook();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Welcome back, Start connecting with Us!
            </DialogTitle>
            <DialogContent dividers>
                <CardContent
                    sx={{
                        p: { xs: 0, md: 0 },
                        color: 'grey.800',
                    }}
                >
                    <Typography gutterBottom variant="h4">
                        Have you converted your Facebook account to a Business Profile?
                        <br />
                    </Typography>
                    <Typography variant="body1" sx={{ mx: 'auto'}}>
                        Make Sure you've completed these steps before continuing:
                    </Typography>
                    <Typography variant="body1" sx={{ mx: 'auto', mt:1}}>
                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18 }} /> You’ve converted your profile to a Business Profile in the Facebook app.<br />
                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18 }} />   You’re an Admin on your Facebook Group.<br />
                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18 }} />  Please create a Facebook group to connect.
                    </Typography>
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button
                color='error'
                    variant="outlined"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectFacebook();
                    }}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default FacebookGroupAuthorizationModal;
