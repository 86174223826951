import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SentIcon } from 'src/components/sent-icon/sentIcon';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ChangePasswordForm from './changePasswordForm';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

export default function ChangePassword() {
    const [password, setPassword] = useState('');
    const { showToast } = useToaster();
    const [sent, setSent] = useState(false);
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let token = new URLSearchParams(params).get('token');
        if (!token) {
            showToast("Your token is invalid", "e");
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        }
        else
            setToken(token);
    })
    const navigateToLogin = () => {
        setSent(true);
        setTimeout(() => {
            navigate("/login");
        }, 2000)
    }
    return (
        <div title="Reset Password" sx={{ height: 1 }}>
            <RootStyle>
                <Container>
                    <Box sx={{ maxWidth: 480, mx: 'auto' }}>
                        {!sent ? (
                            <>
                                <Typography variant="h3" paragraph>
                                    Change Password
                                </Typography>
                                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                                    Please enter your new password here to change.
                                </Typography>

                                <ChangePasswordForm onSent={() => navigateToLogin()} onGetPassword={(value) => setPassword(value)} token={token} />

                                {/* <Button fullWidth size="large" component={RouterLink} to={"/"} sx={{ mt: 1 }}>
                                    Back
                                </Button> */}
                            </>
                        ) : (
                            <Box sx={{ textAlign: 'center' }}>
                                <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                                <Typography variant="h3" gutterBottom>
                                    Your Password has been Changed successfully
                                </Typography>

                                <Button size="large" variant="contained" component={RouterLink} to={"/"} sx={{ mt: 5 }}>
                                    Go to Login
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Container>
            </RootStyle>
        </div>
    );
}
