import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import React, { useState, useEffect } from 'react';
import {
    Box,
    Tab,
    Tabs,
    Button,
    IconButton,
    useMediaQuery,
    Avatar,
    Popover,
    Divider,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Tooltip,
    Typography,
    tabsClasses
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
import EllapsedPost from './ellapsed-approval/ellapsed-post.component';
import PendingApproval from './pending-approval/pending-approval.component';
import RejectedApproval from './rejected-post/rejected-approval.component';
import ScheduledPost from './scheduled-post/scheduled-post.component';
import FailedPost from './failed-post/failedPost.component';
import { LocalStorage } from '../../utils/storage/local-storage';
import { CommonHelper } from 'src/utils/commonHelper';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import ElapsedrequestsIcon from '../../assets/elapsed-requests.svg';
import PendingApprovalIcon from '../../assets/pending-approval.svg';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

const PublicApproval = () => {
    const [currentTab, setCurrentTab] = useState('Pending Approval');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(null);
    const [publicUserName, setPublicUserName] = useState(LocalStorage.getItem('publicUserName') || '');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [nameInput, setNameInput] = useState('');

    useEffect(() => {
        if (!publicUserName) {
            setDialogOpen(true);
        }
    }, [publicUserName]);

    const handleDialogSubmit = () => {
        setPublicUserName(nameInput);
        LocalStorage.setItem('publicUserName', nameInput);
        setDialogOpen(false);
    };

    const handleDialogClose = () => {
        if (!publicUserName) {
            setDialogOpen(true);
        } else {
            setDialogOpen(false);
        }
    };

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpen(null);
    }

    const handleEditName = () => {
        setDialogOpen(true);
        setOpen(null);
    };

    const ACCOUNT_TABS = [
        {
            value: 'Scheduled Posts',
            icon: <ScheduleIcon fontSize='medium' />,
            component: <ScheduledPost publicUserName={publicUserName} />,
        },
        {
            value: 'Failed Posts',
            icon: <RunningWithErrorsIcon fontSize='medium' />,
            component: <FailedPost publicUserName={publicUserName} />,
        },
        {
            value: 'Pending Approval',
            icon: <img src={PendingApprovalIcon} style={{ width: "20px", height: "20px" }} />,
            component: <PendingApproval publicUserName={publicUserName} />,
        },
        {
            value: 'Rejected Approval',
            icon: <UpdateDisabledIcon fontSize='medium' />,
            component: <RejectedApproval publicUserName={publicUserName} />,
        },
        {
            value: 'Ellapsed Requests',
            icon: <img src={ElapsedrequestsIcon} style={{ width: "20px", height: "20px" }} />,
            component: <EllapsedPost publicUserName={publicUserName} />,
        }
    ];
    return (
        <>
            <Helmet>
                <title>Public Approval</title>
            </Helmet>

            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth='xs' fullWidth>
                <DialogTitle>Enter Your Name</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Public User Name"
                        value={nameInput}
                        onChange={(e) => setNameInput(e.target.value)}
                        sx={{ mt: 1 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogSubmit} variant="contained" disabled={nameInput.length === 0}>Submit</Button>
                </DialogActions>
            </Dialog>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white' }}>
                <Tabs
                    value={currentTab}
                    variant="scrollable"
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { display: 'none' },
                        },
                        ...(!isMobile && { gap: '0.5rem', minHeight: '37px', p: 2 }),
                    }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    ))}
                </Tabs>

                <IconButton
                    onClick={handleOpen}
                    sx={{
                        p: 0,
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => theme.palette.grey[900],
                            },
                        }),
                        pr: isMobile && 1,
                    }}
                >
                    <Avatar sx={{ bgcolor: theme.palette.primary.main }} src={publicUserName} alt={publicUserName} />
                </IconButton>

                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleClosePopover}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            p: 0,
                            mt: 1.5,
                            ml: 0.75,
                            width: 180,
                            '& .MuiMenuItem-root': {
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <Box sx={{ my: 1.5, px: 2.5 }} >
                        <Tooltip title={publicUserName}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                {publicUserName || "Anonymous User"}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <MenuItem sx={{ m: 1 }} onClick={handleEditName}>Edit Name</MenuItem>
                </Popover>
            </Box>
            <Box sx={{ mb: 2 }} />
            {
                ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })
            }
        </>
    );
}
export default PublicApproval;