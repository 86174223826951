import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Box, IconButton, ClickAwayListener } from '@mui/material';
import cssStyles from '../../src/utils/cssStyles';
import TagFacesIcon from '@mui/icons-material/TagFaces';

const RootStyle = styled(Box)({
  position: 'relative',
});

const PickerStyle = styled('div')(({ theme }) => ({
  bottom: 40,
  overflow: 'hidden',
  position: 'absolute',
  left: theme.spacing(-2),
  // boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  '& .emoji-mart': {
    border: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  '& .emoji-mart-anchor': {
    color: theme.palette.text.disabled,
    '&:hover, &:focus, &.emoji-mart-anchor-selected': {
      color: theme.palette.text.primary,
    },
  },
  '& .emoji-mart-bar': { borderColor: theme.palette.divider },
  '& .emoji-mart-search input': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[500_32],
    '&::placeholder': {
      ...theme.typography.body2,
      color: theme.palette.text.disabled,
    },
  },
  '& .emoji-mart-search-icon svg': {
    opacity: 1,
    fill: theme.palette.text.disabled,
  },
  '& .emoji-mart-category-label span': {
    ...theme.typography.subtitle2,
    ...cssStyles().bgBlur({ color: theme.palette.background.paper }),
    color: theme.palette.text.primary,
  },
  '& .emoji-mart-title-label': { color: theme.palette.text.primary },
  '& .emoji-mart-category .emoji-mart-emoji:hover:before': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .emoji-mart-emoji': { outline: 'none' },
  '& .emoji-mart-preview-name': {
    color: theme.palette.text.primary,
  },
  '& .emoji-mart-preview-shortname, .emoji-mart-preview-emoticon': {
    color: theme.palette.text.secondary,
  },
}));

export default function EmojiPicker({ handleEmoji }) {
  const theme = useTheme();
  const [emojiPickerState, setEmojiPicker] = useState(false);

  const triggerPicker = () => {
    setEmojiPicker(!emojiPickerState);
  };

  const handleClickAway = () => {
    setEmojiPicker(false);
  };

  const selectedEmoji = (emoji) => {
    handleEmoji(emoji);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <RootStyle>
        <PickerStyle
        >
          {emojiPickerState && (
            <Picker
              data={data}
              color={theme.palette.primary.main}
              // title="Pick your emoji…"
              // emoji="point_up"
              onEmojiSelect={(emoji) => selectedEmoji(emoji?.native)}
            />
          )}
        </PickerStyle>
        <IconButton size="small" onClick={triggerPicker}>
          <TagFacesIcon sx={{ width: "20px", height: "20px"}}/>
        </IconButton>
      </RootStyle>
    </ClickAwayListener>
  );
}
