import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import HttpServices from "src/services/httpService";
import { postsModuleURL } from "src/services/urlService";
import { useToaster } from 'src/utils/toaster/toasterContext';
import FacebookPreview from "src/pages/scheduledPost/Preview/FacebookPreview/facebook-preview.component";
import InstagramPreview from "src/pages/scheduledPost/Preview/InstagramPreview/instagram-preview.component";
import TwitterPreview from "src/pages/scheduledPost/Preview/TwitterPreview/twitter-preview.component";
import YoutubePreview from "src/pages/scheduledPost/Preview/YoutubePreview/youtube-preview.component";
import LinkedinPreview from "src/pages/scheduledPost/Preview/LinkedinPreview/linkedin-preview.component";
import GoogleMyBusinessPreview from "src/pages/scheduledPost/Preview/GoogleMyBusinessPreview/gbusiness-preview.component";
import Spinner from "src/utils/Loader/spinner";
import TikTokPreview from "src/pages/scheduledPost/Preview/TiktokPreview/tiktok-perview-component";
import PinterestPreview from "src/pages/scheduledPost/Preview/PinterestPreview/pinterest-preview.component";

const PublicPostPreview = ({ postDetails, urlKey }) => {
    const { showToast } = useToaster();
    const [scheduleEventInfo, setScheduleEventInfo] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getScheduledPost();
    }, []);

    const getScheduledPost = () => {
        let getProps = {
            url: `${postsModuleURL.getPublicScheduledEventInfo}${postDetails.data.id}&url_key=${urlKey}`,
            successCallback: postSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }

    const postSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setScheduleEventInfo(data);
        setLoader(false);
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }


    return (
        <Box>
            {loader ?
                <Spinner />
                :
                <Card style={{ "marginLeft": "0.5rem" }} >
                    {scheduleEventInfo?.scheduled_event_requests_data && <Card>
                        <FacebookPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.facebook_requests_data}></FacebookPreview>
                        <InstagramPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.instagram_requests_data}></InstagramPreview>
                        <TwitterPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.twitter_requests_data}></TwitterPreview>
                        <YoutubePreview postDetails={scheduleEventInfo.scheduled_event_requests_data.youtube_requests_data}></YoutubePreview>
                        <LinkedinPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.linkedin_requests_data}></LinkedinPreview>
                        <GoogleMyBusinessPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.gbusiness_requests_data}></GoogleMyBusinessPreview>
                        <TikTokPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.tiktok_requests_data}></TikTokPreview>
                        <PinterestPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.pinterest_requests_data}></PinterestPreview>
                    </Card>}
                </Card>
            }
        </Box>
    )
}

export default PublicPostPreview;