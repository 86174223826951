import { IconButton, ListItem } from "@mui/material";
import { PlayArrowSharp as PlayArrowSharpIcon, Close as CloseIcon } from '@mui/icons-material';
import SortableList, { SortableItem } from "react-easy-sort";
import { alpha } from '@mui/material/styles';
import Image from "./Image";

const ImageDraggable = ({
    mediaFiles,
    handleReOrderFiles,
    handleRemoveFiles,
    isFile = false
}) => {
    return isFile ? <SortableList
        onSortEnd={handleReOrderFiles}
    >
        {mediaFiles.map((file) => (
            <SortableItem >
                <ListItem
                    sx={{
                        p: 0, m: 0.5,
                        width: 80, height: 80,
                        borderRadius: 1.25, overflow: 'hidden',
                        position: 'relative', display: 'inline-flex', opacity: 1,
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                >
                    {file.type?.toUpperCase()?.includes('IMAGE') ? (
                        <Image src={URL.createObjectURL(file)} alt={file} ratio="1/1" />
                    ) : (
                        <>
                            <video src={URL.createObjectURL(file)} alt={file} />
                            <PlayArrowSharpIcon
                                style={{
                                    position: 'absolute', top: '50%', left: '50%',
                                    transform: 'translate(-50%, -50%)', zIndex: 1,
                                    borderRadius: '50%', background: 'black',
                                    overflow: 'hidden', color: 'white',
                                }}
                            />
                        </>
                    )}
                    <IconButton
                        size="small"
                        onClick={() => handleRemoveFiles(file)}
                        sx={{
                            top: 6, p: '2px', right: 6,
                            position: 'absolute', color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                            },
                        }}
                    >
                        <CloseIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                </ListItem>
            </SortableItem>
        ))}
    </SortableList>
        : <SortableList
            onSortEnd={handleReOrderFiles}
        >
            {mediaFiles.map((file) => (
                <SortableItem >
                    <ListItem
                        sx={{
                            p: 0, m: 0.5,
                            width: 80, height: 80,
                            borderRadius: 1.25, overflow: 'hidden',
                            position: 'relative', display: 'inline-flex', opacity: 1,
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                        }}
                    >
                        {file.media_type?.toUpperCase() === 'IMAGE' ? (
                            <Image src={file.media_url} alt={file.media_url} ratio="1/1" />
                        ) : (
                            <>
                                {file.thumbnail_urls && file.thumbnail_urls.length > 0 ? (
                                    <img src={file.thumbnail_urls[0]} alt={file.thumbnail_urls[0]} width="100%" height="100%" />
                                ) : (
                                    <video src={file.media_url} alt={file.media_url} />
                                )}
                                <PlayArrowSharpIcon
                                    style={{
                                        position: 'absolute', top: '50%', left: '50%',
                                        transform: 'translate(-50%, -50%)', zIndex: 1,
                                        borderRadius: '50%', background: 'black',
                                        overflow: 'hidden', color: 'white',
                                    }}
                                />
                            </>
                        )}
                        <IconButton
                            size="small"
                            onClick={() => handleRemoveFiles(file.media_url)}
                            sx={{
                                top: 6, p: '2px', right: 6,
                                position: 'absolute', color: 'common.white',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                },
                            }}
                        >
                            <CloseIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    </ListItem>
                </SortableItem>
            ))}
        </SortableList>
}
export default ImageDraggable;