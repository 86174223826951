import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, ListItemIcon, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const ThreadsAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectThreads = () => {
        setLoadingOn(true);
        props.handleConnectThreads();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography gutterBottom variant="h4">
                    Have you converted your Threads account to a Business Profile?
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent
                    sx={{
                        p: { xs: 0, md: 0 },
                        color: 'grey.800',
                    }}
                >
                    <Typography variant="body1" sx={{ mx: 'auto', fontWeight: 'bold' }}>
                        Make Sure you've completed these steps before continuing:
                    </Typography>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            Make sure you’re signed in to your Google Account.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            This app can create a trustworthy and user-friendly experience while ensuring the privacy and security of users' data.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            Please be sure not to violate other's copyright or privacy rights.
                        </Typography>
                    </Paper>

                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant='contained'
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectThreads();
                    }}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default ThreadsAuthorizationModal;
