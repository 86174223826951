import { useTheme } from '@mui/material/styles';
import { useRoutes } from 'react-router-dom';

const MainPage = ({ isDesktop = true, pathname, routes }) => {
  const element = useRoutes(routes);
  const theme = useTheme();
  let currentRoute = routes.find(route => route.path === pathname?.replace("/", ""));
  let isChildRoute = false;
  if (currentRoute) {
    isChildRoute = currentRoute.element?.props?.permissions ? true : false;
  }
  return (
    <div
      style={{
        ...(isChildRoute
          ? {
            flexGrow: 1,
            minHeight: '100%',
            padding: isDesktop && theme.spacing(2),
            position: 'relative',
            top: '64px',
            width: isDesktop ? 'calc(100vw - 352px)' : '100%',
            height: !isDesktop ? 'calc(100vh - 133px)' : '100%',
            overflow: 'auto',
          }
          : {})
      }}
    >
      {element}
    </div>
  );
};

export default MainPage;
