import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import { NavConfig } from 'src/navConfig';

export const ListItemStyle = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})(({ activeRoot, activeSub, subItem, theme }) => ({
    ...theme.typography.body2,
    position: 'relative',
    height: NavConfig.NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    ...(activeRoot && {
        ...theme.typography.subtitle2,
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    }),
    ...(activeSub && {
        ...theme.typography.subtitle2,
        color: theme.palette.text.primary,
    }),
    ...(subItem && {
        height: NavConfig.NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
    }),
}));

export const ListItemTextStyle = styled(ListItemText, {
    shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

export const ListItemIconStyle = styled(ListItemIcon)(({ theme,activeRoot }) => ({
    width: NavConfig.ICON.NAVBAR_ITEM,
    height: NavConfig.ICON.NAVBAR_ITEM,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': { width: '100%', height: '100%' },
    ...(activeRoot && {
        color: theme.palette.primary.main,
    }),
}));