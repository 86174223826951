import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography, IconButton, TableContainer } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import HttpServices from 'src/services/httpService';
import { imageModeuleUrl, userModuleURL } from 'src/services/urlService';
import CountryList from 'src/utils/countryList';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';

export default function Account() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [about, setAbout] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const countryList = CountryList();
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteMeOpen, setDeleteMeOpen] = useState(false);
  const [fileUploadprogress, setFileUploadprogress] = useState(0);
  const [isLeaveOrgLoading, setIsLeaveOrgLoading] = useState(false);
  const [isDeleteMeLoading, setDeleteMeLoading] = useState(false);
  const maxFileSizeInBytes = 10485760;
  const supportedFileFormat = ['image/jpeg', 'image/png'];
  const navigate = useNavigate();

  useEffect(() => {
    generateFormModelSchema();
    getUserInfo();
  }, []);

  useEffect(() => {}, [imageUrl]);

  const generateFormModelSchema = () => {
    let fields = [ValidationTool.GenerateSchema('firstName', 'First Name', 'TEXT', true)];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };
  const userSuccessCallback = (data, message) => {
    setFirstName(data.first_name || '');
    setLastName(data.last_name || '');
    setEmail(data.email || '');
    setPhoneNumber(data.phone_no || '');
    setCountry(data.country || '');
    setState(data.state || '');
    setAbout(data.about || '');
    setImageUrl(data.image_url || '');
  };
  const userFailureCallback = (message) => {
    message && showToast(message, 'e');
  };
  const getUserInfo = () => {
    let postProps = {
      url: userModuleURL.fetchUserDetails,
      successCallback: userSuccessCallback,
      failureCallback: userFailureCallback,
    };
    HttpServices.Get(postProps);
  };

  const defaultValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    phoneNumber: phoneNumber || '',
    country: country || '',
    state: state || '',
    about: about || '',
    imageUrl: imageUrl || '',
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const successCallback = (data, message) => {
    showToast(message, 's');
    setLoader(false);
    // let userDetails = {
    //   first_name: data.first_name,
    //   last_name: data.last_name,
    //   email: data.email,
    //   image_url: data.image_url,
    //   is_verified: data.is_verified
    // }
    // LocalStorage.setItem('user_details', JSON.stringify(userDetails));
    window.location.reload();
  };
  const failureCallback = (message) => {
    showToast(message, 'e');
    setLoader(false);
    setIsLeaveOrgLoading(false);
    setDeleteMeLoading(false);
    setDeleteMeOpen(false);
  };
  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleLeaveOrg = () => {
    setIsLeaveOrgLoading(true);
    let postProps = {
      url: userModuleURL.leaveOrganization,
      successCallback: leaveOrgSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const leaveOrgSuccessCallback = (data, message) => {
    showToast(message, 's');
    setLoader(false);
    navigate('/no-organization');
  };
  const onSubmit = () => {
    let accountProps = {
      first_name: firstName,
      last_name: lastName,
      phone_no: phoneNumber,
      country: country,
      state: state,
      about: about,
      image_url: imageUrl,
    };
    setLoader(true);
    let postProps = {
      url: userModuleURL.updateUserDetails,
      body: accountProps,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleSubmitForm = () => {
    let userForm = {
      firstName: firstName,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else setFormValidationErrors(formValidationErrors);
  };
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFileUpload(file);
  };
  const fileUploadSuccessCallback = (data, message) => {
    setImageUrl(data.media_data[0].media_url);
  };
  const handleFileUpload = (file) => {
    let isFileInCorrectFormat = supportedFileFormat.some((a) => a.includes(file.type));
    if (isFileInCorrectFormat) {
      if (file.size < maxFileSizeInBytes) {
        setFileUploadprogress(1);
        let formData = new FormData();
        formData.append('fileupload', file);
        axios
          .post(imageModeuleUrl.uploadMedia, formData, {
            headers: HttpServices.GetRequestHeader('form', true),
            onUploadProgress: (data) => {
              let loadedProgress = Math.round((100 * data.loaded) / data.total);
              setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
            },
          })
          .then((result) => result)
          .then((response) => {
            setFileUploadprogress(100);
            if (response.status === 200 && response.data.http_code === 200)
              fileUploadSuccessCallback(response.data.data, 'File Uploaded Successfully');
            else showToast('Something went wrong. Please contact our technical team', 'e');
            setFileUploadprogress(0);
          })
          .catch((error) => {
            setFileUploadprogress(0);
            showToast(error.toString(), 'e');
          });
      } else showToast('Maximum file size for image is 10MB. Please select image below 10 MB', 'e');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phoneNumber':
        let phoneNo = parseInt(value).toString() === 'NaN' ? '' : parseInt(value).toString();
        if (phoneNo.length < 11) setPhoneNumber(phoneNo);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'about':
        setAbout(value);
        break;
      case 'state':
        setState(value);
        break;
      default:
        break;
    }
  };
  const handleDeleteMeSuccessCallback = (data, message) => {
    setDeleteMeLoading(false);
    setDeleteMeOpen(false);
    CommonHelper.Logout();
  };
  const handleDeleteMe = () => {
    setDeleteMeLoading(true);
    let postProps = {
      url: userModuleURL.deleteMe,
      successCallback: handleDeleteMeSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleDeleteCurrentUser = () => {
    setDeleteMeOpen(true);
  };
  const handleDeleteProfileImage = () => {
    setImageUrl('');
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <TableContainer sx={{minHeight: 'calc(100vh - 190px)', maxHeight: 'calc(100vh - 200px)'}}>
        <Grid container spacing={3} >
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" >
              <Card sx={{ py: 10, px: 3, textAlign: 'center', flexGrow: 1 }}>
                <Box position="relative" display="inline-block">
                  <RHFUploadAvatar
                    name="imageUrl"
                    accept="image/*"
                    maxSize={5242880}
                    onDrop={handleDrop}
                    handleDeleteProfiles={handleDeleteProfileImage}
                    url={imageUrl}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of 5Mb
                      </Typography>
                    }
                  />
                </Box>
                <Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                  <LoadingButton variant="contained" loading={isLeaveOrgLoading} onClick={handleOpen}>
                    Leave Organization
                  </LoadingButton>
                </Stack>
                <Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                  <LoadingButton variant="contained" loading={isDeleteMeLoading} onClick={handleDeleteCurrentUser}>
                    Delete Account
                  </LoadingButton>
                </Stack>
                {fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box display="flex" flexDirection="column" >
              <Card sx={{ p: 3, flexGrow: 1 }}>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={handleInputChange}
                    error={formValidationErrors?.firstName}
                    helperText={formValidationErrors?.firstName}
                  />
                  <RHFTextField name="lastName" label="Last Name" value={lastName} onChange={handleInputChange} />
                  <RHFTextField name="email" label="Email" disabled={true} value={email} onChange={handleInputChange} />
                  <RHFTextField
                    name="phoneNumber"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />
                  <RHFSelect
                    name="country"
                    label="Country"
                    placeholder="Country"
                    value={country}
                    onChange={handleInputChange}
                  >
                    <option value="" />
                    {countryList.map((option) => (
                      <option key={option.code} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </RHFSelect>
                  <RHFTextField name="state" label="State/Region" value={state} onChange={handleInputChange} />
                </Box>

                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  <RHFTextField
                    name="about"
                    multiline
                    rows={4}
                    label="About"
                    value={about}
                    onChange={handleInputChange}
                  />
                  <LoadingButton type="submit" variant="contained" loading={showLoader}>
                    Save Changes
                  </LoadingButton>
                </Stack>
              </Card>
            </Box>
          </Grid>
        </Grid>
        </TableContainer>
      </FormProvider>
      <ConfirmationDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleLeaveOrg}
        title="Confirm Leave Organization"
        message="Are you sure you want to Leave this Organization?"
      />
      <ConfirmationDialog
        open={confirmDeleteMeOpen}
        onClose={() => setDeleteMeOpen(false)}
        onConfirm={handleDeleteMe}
        title="Delete Account"
        message="Once you proceed with this action it cannot be recovered and you will be logged out. Are you sure to Proceed?"
      />
    </>
  );
}
