import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Avatar, Card, TextField, TableCell, TableRow, tableCellClasses, Stack, Switch, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavConfig } from 'src/navConfig';
import B2SSwitch from '../switch/switch.component';

const NAV_WIDTH = 280;
const HEADER_MOBILE = 42;
const HEADER_DESKTOP = 40;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    color: 'black',
    backgroundColor: 'white',
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 2),
    },
}));
const StyledIcon = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20
}));
const B2SCard = styled(Card)(({ theme }) => ({
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: '0.2em', backgroundColor: theme.palette.primary.lighter },
    '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.primary.main, borderRadius: 50 }
}));

const B2STextField = styled(TextField)(({ theme }) => ({
    '.MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginLeft: 2,
        fontWeight: 800
    },
}));
const LoadingIcon = styled(LoadingButton)(({ theme }) => ({
    '.MuiButton-endIcon': {
        marginLeft: 0,
        marginRight: 0
    },
    '&.MuiLoadingButton-root': {
        paddingLeft: 10,
        paddingRight: 10,
        minWidth: 40
    }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        lineHeight: 1.57143,
        borderBottomStyle: 'dashed',
    },
    border: 0,
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
        cursor: 'pointer',
    },
    position: 'relative',
    '&:hover .hover-content': {
        display: 'block',
    },
}));

const HoverContent = styled('div')(({ theme }) => ({
    //display: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:10
}));

const StyledTableAlternateRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
        cursor: 'pointer',
    },
}));
const StyledStack = styled(Stack)(({ theme }) => ({
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: '0.2em', backgroundColor: theme.palette.primary.lighter },
    '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.primary.main, borderRadius: 50 }
}))
const SwitchIcon = (props) => {
    return <B2SSwitch {...props} />
}
// const SwitchIcon = styled(Switch)(({ theme }) => ({
//     // width: 42,
//     // height: 26,
//     // padding: 0,
//     alignItems: 'center',
//     '& .MuiSwitch-switchBase': {
//         // padding: 0,
//         marginTop: 3,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             // transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 14,
//         height: 14,
//     },
//     '& .MuiSwitch-track': {
//         height: '20px',
//         borderRadius: 10,
//         backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));
const FixedFooter = styled(AppBar)(({ theme, isCollapse }) => ({
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    top: 'auto',
    bottom: 0,
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 1,
    paddingBottom: 1,
    marginBottom: 1,
    marginTop: 5,
    borderRadius: 10,
    backgroundColor: "white",
    transition: 'width 0.3s ease-in-out',
    width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_WIDTH + 70}px)`,
    ...(isCollapse && {
        width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 72}px)`,
    }),
}));
export const StyledComponent = {
    StyledRoot,
    StyledToolbar,
    StyledIcon,
    B2SCard,
    B2STextField,
    LoadingIcon,
    StyledTableCell,
    StyledTableRow,
    StyledStack,
    SwitchIcon,
    HoverContent,
    FixedFooter,
    StyledTableAlternateRow
}