import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { internalReportModuleURL } from 'src/services/urlService';
import { Box } from '@mui/material';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ReportHttpServices from 'src/services/reportsHttpService';
import { useLocation } from 'react-router-dom';
import ScheduledReportBodyComponent from './scheduled-report-body.component';

const ScheduledHeaderReportComponent = ({ connectedProfileId, startDate, endDate, viewBy, reportId, subReportId,serviceName }) => {
    const location = useLocation();
    const { showToast } = useToaster();

    const urlParams = new URLSearchParams(location.search);
    const urlBrandId = urlParams.get('brand_id');
    const urlToken = urlParams.get('token');

    const [isLoading, setLoading] = useState(true);
    const [subReportData, setSubReportData] = useState([]);

    useEffect(() => {
        if (reportId) {
            getSubReports();
        }
    }, [reportId]);

    const failureCallback = (message) => {
        setLoading(false);
        showToast(message, "e");
        setSubReportData([]);
    };

    const successCallback = (data) => {
        setSubReportData(data);
        setLoading(false);
    };

    const getSubReports = () => {
        setLoading(true);
        let getProps = {
            url: `${internalReportModuleURL.fetchSubReports}${parseInt(reportId)}`,
            brandId: urlBrandId,
            token: urlToken,
            successCallback,
            failureCallback
        };
        ReportHttpServices.Get(getProps);
    };

    return (
        <Box id='fb-summary' sx={{mt:4}}>
            {/* Only render SummaryComponent once the data is fetched */}
            {!isLoading && subReportData.length > 0 ? (
                <ScheduledReportBodyComponent
                connectedProfileId={connectedProfileId}
                    startDate={startDate}
                    endDate={endDate}
                    viewBy={viewBy}
                    subReportId={subReportId}
                    subReportData={subReportData}
                    serviceName={serviceName}
                />
            ) : (
                isLoading && <Typography>Loading...</Typography>
            )}
        </Box>
    );
};

export default ScheduledHeaderReportComponent;
