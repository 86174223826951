import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import YoutubeAuthorizationModal from "./youtubeAuthorizationModal";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";

const YouTubeIntegration = (props) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  let intervalId = null;

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromYoutube();
    }, Config.Common.socialAccountsIntegrationInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const getYoutubeOauthToken = () => {
    window.open(
      `${Config.Youtube.authorizeUrl}?client_id=${Config.Youtube.clientId}&scope=${Config.Youtube.scope}&prompt=consent&access_type=offline&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${Config.Youtube.redirectUri}&response_type=code`, "_blank"
    );
  }

  const checkAccessTokenReceivedFromYoutube = () => {
    var token = localStorage.getItem("youtube_token");
    var error = localStorage.getItem("youtube_error");
    if (token) {
      localStorage.removeItem("youtube_token");
      clearInterval(intervalId);
      sendAccessTokenToServer(token);
    }
    else if (error) {
      localStorage.removeItem("youtube_error");
      clearInterval(intervalId);
      showToast(error, 'e');
    }
  };
  const youtubeSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleClose();
  }
  const youtubeFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const sendAccessTokenToServer = (code) => {
    let youtubeForm = {
      code
    };
    let postProps = {
      url: integrateModuleURL.sendYoutubeAccessToken,
      body: youtubeForm,
      successCallback: youtubeSuccessCallback,
      failureCallback: youtubeFailureCallback
    }
    HttpServices.Post(postProps);
  };

  const handleClose = () => {
    props.handleClose();
  }

  return (
    isAuthorizationModalOpen &&
    <YoutubeAuthorizationModal
      handleConnectYoutube={getYoutubeOauthToken}
      handleClose={handleClose}
    />
  );
};
export default YouTubeIntegration;