import { Box, Card, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import Header from 'src/layouts/dashboard/header/header.component';
import NoOrgIcon from '../../assets/icons/no-brnd-org.svg';
import CreateOrganizationAndBrand from './createOrganization.component';
import { CommonHelper } from 'src/utils/commonHelper';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const NoOrganization = () => {
  let orgId = CommonHelper.GetBrandInfoFromLocalStorage("org_id");
  if (orgId) {
    window.location.href = window.location.origin;
  }
  return (
    <>
      <Helmet>
        <title> No Organization </title>
      </Helmet>
      <Header headerName={''} />
      <Container>
        <RootStyle>
          <Container>
            <Box sx={{ maxWidth: 680, mx: 'auto' }}>
              <Card sx={{ py: 3, px: 3, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={NoOrgIcon} style={{ height: 300 }} />
                </Box>
                <Typography variant="h3" paragraph>
                  Currently, you are not associated with any of the Organization
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                  Please click the Create Organization to create a new organization.
                </Typography>
                <CreateOrganizationAndBrand />
              </Card>
            </Box>
          </Container>
        </RootStyle>
      </Container>
    </>
  );
};
export default NoOrganization;
