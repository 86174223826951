import React from "react";
import FacebookCardDetails from "./recentPostCards/facebookCardDetails";
import InstagramCardDetails from "./recentPostCards/instagramCardDetials";
import LinkedInCardDetails from "./recentPostCards/linkedinCardDetials";
import YoutubeCardDetails from "./recentPostCards/youtubeCardDetails";
import GBusinessCardDetails from "./recentPostCards/GbusinessCardDetials";
import { PostUtil } from "../new-post-new/post-util";
import TiktokCardDetails from "./recentPostCards/tiktokCardDetails";
import PinterestCardDetails from "./recentPostCards/pinterestCardDetails";

const RecentPosts = ({ service, serviceName }) => {
  switch (serviceName) {
    case PostUtil.RecentPostServiceName.FACEBOOK:
      return <FacebookCardDetails service={service} />;
    case PostUtil.RecentPostServiceName.INSTAGRAM:
      return <InstagramCardDetails service={service} />;
    case PostUtil.RecentPostServiceName.LINKEDIN :
      return <LinkedInCardDetails service={service} />;
    case PostUtil.RecentPostServiceName.YOUTUBE:
      return <YoutubeCardDetails service={service} />;
    case PostUtil.RecentPostServiceName.GBUSINESS:
      return <GBusinessCardDetails service={service} />;
    case PostUtil.RecentPostServiceName.PINTEREST:
      return <PinterestCardDetails service={service} />;
    case PostUtil.RecentPostServiceName.TIKTOK:
      return <TiktokCardDetails service={service} />;
    default:
      return null;
  }
};

export default RecentPosts;
